import _block from '../pages/Contest/_blocks'
import photoSchema from './photo'
import mediaSchema from './media'
import tourney from './tourney'
import { LANGS_IRI } from '_lib/langs'

const stateChoices = {
  current: 'Aktualny',
  feature: 'Zapowiadany',
  archive: 'Archiwalny',
}

const schema = {
  endpoint: '/api/contests',
  clone_endpoint: '/api/contest/clone',
  access: 'contest',
  resource: {
    definition: '#Contest',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortLead: {
            type: 'textarea',
            description: 'T_GENERAL_SHORT_LEAD_TILE',
            label: 'T_GENERAL_SHORT_LEAD_TILE',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/konkursy/:slug',
             // [LANGS_IRI.EN]: '/en/competitions/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/contest_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },

      dateFrom: {
        type: 'date',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'date',
      },
      stateType: {
        type: 'choice',
        choices: stateChoices,
        label: 'Status konkursu',
        validate: ['required'],
      },

      tourney: {
        type: 'resource',
        endpoint: `${tourney.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Powiązay turniej',
      },

      adminCanEdit: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      showInNews: {
        description: 'Pokaż w aktualnościach',
        hint: 'Generuje wpis w aktualnościach',
      },
      generatedNewsData: {
        type: 'autoNewsData',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/contest_blocks',
      definition: '#ContestBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/contest_block_thumbs',
        '/api/contest_block_anchors',
        '/api/contest_block_files',
        '/api/contest_block_links',
        '/api/contest_block_link_thumbs',
        '/api/contest_block_list_items',
        '/api/contest_block_list_item_thumbs'
      ).types,
    },
  },
}

export default schema
