const schema = {
  endpoint: '/api/matter_form_config_groups',
  resource: {
    definition: '#MatterFormConfigGroup',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            description: 'Treść',
          },
          workingDescription: {
            description: 'Opis roboczy',
            hint: 'Widoczny tylko w CMS',
          },
        },
      },
      classes: {
        type: 'multipleResource',
        endpoint: `/api/matter_form_config_group_types?pagination=false`,
        titleAccessor: 'trans',
        label: 'Typy',
        transTitle: true,
      },
      printableTitle: {
        description: 'Nagłówek drukowany',
      },
      printable: {
        description: 'Grupa drukowana pod wnioskiem',
      },
    },
  },
  subresources: {
    items: {
      endpoint: '/api/matter_form_config_group_items',
      endpointFee: '/api/matter_form_config_group_items/fees',
      definition: '#MatterFormConfigGroupItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'textarea',
              description: 'Treść',
            },
          },
        },
        isDefault: {
          description: 'Domyślny',
        },
      },
    },
  },
}

export default schema
