import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  switch_track: {
    backgroundColor: theme.palette.error.main,
  },
  switch_base: {
    color: theme.palette.error.main,
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: theme.palette.success.main,
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
  ok: {
    color: 'inherit',
  },
  highlighted: {
    backgroundColor: theme.palette.action.hover,
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  label: {
    color: theme.palette.secondary.main,
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      marginLeft: 5,
      marginTop: 3,
      height: '14px !important',
      width: '14px !important',
    },
  },
  item: {
    marginBottom: 5,
  },
  hidden: {
    display: 'none',
  },
}))

export const MultiStatus = ({
  resource,
  accessors,
  disabled = false,
  onSuccess = null,
  handleSyncUpdatedAt = null,
  customEndpoint = '',
}) => {
  const classes = useStyles()

  const [switchStates, setSwitchStates] = useState(
    Object.keys(accessors).reduce((acc, key) => {
      acc[key] = prop(resource, key)
      return acc
    }, {})
  )

  const [switchFetchStates, setSwitchFetchStates] = useState(
    Object.keys(accessors).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
  )

  const handleChange = accessor => {
    if (disabled) {
      return
    }

    const currentStatus = prop(resource, accessor)

    setSwitchStates(prevStates => ({
      ...prevStates,
      [accessor]: !currentStatus,
    }))

    setSwitchFetchStates(prevStates => ({
      ...prevStates,
      [accessor]: true,
    }))

    fetchDataHandleAuthError(
      customEndpoint
        ? customEndpoint.replace('@id', resource?.uuid)
        : resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: !currentStatus,
        }),
      },
      resource => {
        handleSyncUpdatedAt && handleSyncUpdatedAt(resource)
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(accessor, !currentStatus)
        setSwitchFetchStates(prevStates => ({
          ...prevStates,
          [accessor]: false,
        }))
      },
      error => {
        setSwitchStates(prevStates => ({
          ...prevStates,
          [accessor]: currentStatus,
        }))
        setSwitchFetchStates(prevStates => ({
          ...prevStates,
          [accessor]: false,
        }))
        notification('error', error.response.detail, error.response.title)
      }
    )

    return !currentStatus
  }

  return (
    <Grid container direction="column">
      {Object.entries(accessors).map(([accessor, title]) => (
        <Grid item key={accessor} className={classes.item}>
          <FormControlLabel
            control={
              <Switch
                name={`${resource['@id']}-${accessors[accessor]}`}
                onChange={() => {
                  return handleChange(accessor)
                }}
                disabled={disabled || switchFetchStates[accessor]}
                checked={switchStates[accessor]}
                color="primary"
                size="small"
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorPrimary: classes.switch_primary,
                }}
              />
            }
            label={
              <span className={classes.labelWrapper}>
                {title ?? 'brak tytułu'}
                <CircularProgress
                  className={
                    !switchFetchStates[accessor] ? classes.hidden : null
                  }
                />
              </span>
            }
            labelPlacement="end"
            className={classes.label}
          />
        </Grid>
      ))}
    </Grid>
  )
}

MultiStatus.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  handleSyncUpdatedAt: PropTypes.func,
  customEndpoint: PropTypes.string,
}
