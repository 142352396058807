import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Delete } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { useSelector } from 'react-redux'
import { SchemaError } from '../../../_helpers/schemaError'
import { translate } from '../../../_helpers/translate'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../_helpers/notification'
import { constants } from '../../../components/table/_state'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'left',
  },
  delete: {
    backgroundColor: '#dc004d',
  },
  confirmText: {
    marginTop: 12,
  },
}))

export const ElektronicznyNadawcaLabelRemoveModal = ({
  title = translate('Usuń etykietę'),
  minWidth = '150px',
  classes = {},
  resource,
  tableState,
  tableStateDispatch,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [isFetching, setIsFetching] = useState(false)

  const defaultClasses = useStyles()

  const schemaDefinitions = useSelector(state => state.schema)

  if (!schemaDefinitions) {
    throw new SchemaError()
  }

  const handleDelete = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      '/api/shop_elektroniczny_nadawca_remove_shipment',
      'POST',
      {
        body: JSON.stringify({
          order: resource['@id'],
        }),
      },
      resource => {
        setIsFetching(false)

        notification(
          'success',
          'T_GENERAL_ORDER_LABEL_REMOVED',
          'T_FORM_SUCCESS'
        )

        tableStateDispatch({ type: constants.RELOAD })
        handleDialogToggle()
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<Delete />}
        onClick={handleDialogToggle}
        style={{ minWidth: minWidth }}
        className={defaultClasses.delete}
      >
        {title}
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title} - Poczta polska
        </DialogTitleWithClose>
        <DialogContent
          dividers
          className={clsx(defaultClasses.content, classes.content)}
        >
          <DialogContentText className={defaultClasses.confirmText}>
            {translate('Usunąć etykietę dla zamówienia nr: ') +
              resource['orderId'] +
              ' ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogToggle}
            disabled={isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={handleDelete}
            disabled={isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ElektronicznyNadawcaLabelRemoveModal.propTypes = {
  title: PropTypes.string,
  minWidth: PropTypes.string,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
  defaultData: PropTypes.object,
  resource: PropTypes.object.isRequired,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }).isRequired,
    config: PropTypes.shape({
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
}
