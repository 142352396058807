import React, { useEffect, useState } from 'react'
import { Form } from 'pages'
import schemaOriginal from '_schema/accommodationNotice'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import routes from './routes'
import { LANGS_IRI } from '_lib/langs'

export const New = ({ schema: definitions }) => {
  const [schema, setSchema] = useState(schemaOriginal)
  const [fetching, isFetching] = useState(false)

  useEffect(
    () => {
      let controller

      if (controller) {
        controller.abort()
      }

      controller = new AbortController()
      const { signal } = controller

      if (fetching === true) {
        return () => controller.abort()
      }

      isFetching(true)

      fetchDataHandleAuthError(
        `/api/accommodation_config`,
        'GET',
        { signal },
        response => {
          if (response.uuid) {
            setSchema({
              ...schemaOriginal,
              resourceNewAction: {
                ...schemaOriginal.resourceNewAction,
                properties: {
                  ...schemaOriginal.resourceNewAction.properties,
                  mailTitle: {
                    ...schemaOriginal.resourceNewAction.properties.mailTitle,
                    defaultValue:
                      response.translations[LANGS_IRI.PL].mailNotificationTitle,
                  },
                  mailText: {
                    ...schemaOriginal.resourceNewAction.properties.mailText,
                    defaultValue:
                      response.translations[LANGS_IRI.PL].mailNotificationText,
                  },
                },
              },
            })
            isFetching(false)
          } else {
            notification('error', 'Brak configuracji')
          }
        },
        error => {
          if (error.response.title === 'AbortError') {
            isFetching(true)
          } else {
            isFetching(false)
            notification('error', error.response.detail, error.response.title)
          }
        }
      )
      return () => controller.abort()
    },
    // eslint-disable-next-line
    []
  )

  return (
    <>
      {!fetching && (
        <Form
          definitionSchema={definitions[schema.resourceNewAction.definition]}
          definitions={definitions}
          customResourceSchema={schema.resourceNewAction}
          method="POST"
          url={schema.endpoint}
          collectionPath={routes().index.path}
          editPath={routes().edit.path}
          storeCollectionId={schema.endpoint}
          width={800}
        />
      )}
      <br />
      <br />
    </>
  )
}
