import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'components/Paper'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 16,
  },
})

export const HomeBox = ({
  columns,
  route,
  endpoint,
  definition,
  isRowLinkable = false,
  editPath = null,
}) => {
  const classes = useStyles()

  return (
    <Paper header={translate(route.title)}>
      <SchemableCollection
        component={CollectionTable}
        definition={definition}
        endpoint={endpoint}
        columns={columns}
        defaultSorters={{ updatedAt: 'desc' }}
        perPage={5}
        isRowLinkable={isRowLinkable}
        autoWidth={false}
        resetable={false}
        filterable={false}
        sortable={false}
        numerable={false}
        isPaginationVisible={false}
        storeCollectionId={`home:${endpoint}`}
        editPath={editPath}
        transparent={true}
      />
      <div className={classes.buttonContainer}>
        <Button component={Link} to={route.path} size="small" color="primary">
          {translate('T_GENERAL_SHOW_ALL')}
        </Button>
      </div>
    </Paper>
  )
}

HomeBox.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      sortable: PropTypes.bool,
      filterable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Filter: PropTypes.elementType,
      Cell: PropTypes.elementType,
    })
  ).isRequired,
  route: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
  isRowLinkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  editPath: PropTypes.string,
}
