import { authConstants, profileConstants } from '_constants'

export const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case profileConstants.SUCCESS:
    case profileConstants.UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    case profileConstants.FAILURE:
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGOUT:
      return {}
    default:
      return state
  }
}
