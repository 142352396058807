import shippingCountrySchema from './shopShippingCountry'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/customers',
  access: 'customer',
  resource: {
    definition: '#Customer',
    properties: {
      email: {},
      rawPassword: {
        type: 'passwordRepeat',
        name2: 'rawPasswordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['password'],
      },
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
      },
      companyName: {
        description: 'Nazwa firmy',
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      street: {
        description: 'Ulica',
      },
      houseNumber: {
        description: 'Numer domu',
      },
      apartmentNumber: {
        description: 'Numer mieszkania',
      },
      postalCode: {
        description: 'Kod pocztowy',
      },
      city: {
        description: 'Miasto',
      },
      country: {
        type: 'resource',
        endpoint: `${shippingCountrySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Kraj',
      },
      fvCompanyName: {
        description: '[FAKTURA] Nazwa firmy',
      },
      fvNip: {
        description: '[FAKTURA] Numer NIP',
      },
      fvAddress: {
        type: 'textarea',
        description: '[FAKTURA] Adres',
      },
      isConfirmed: {
        description: 'Konto potwierdzone linkiem aktywacyjnym',
      },
      deleted: {
        description: 'Konto usunięte',
        hint:
          'konto poprawnie usunięte przez użytkownika, operacja potwierdzona linkiem',
      },
      stat: {
        description: 'Aktywność',
        hint: 'Jeśli wyłączona, użytkownik nie będzie się mógł zalogować',
      },
    },
  },
  subresources: {
    loginLog: {
      endpoint: '/api/customer_login_logs',
      definition: '#CustomerLoginLog',
      properties: {
        result: {},
        ip: {},
      },
    },
  },
}

export default schema
