import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { constants } from './_state'
import { ElektronicznyNadawcaLabelModal } from '../../pages/ShopOrder/Modal/ElektronicznyNadawcaLabelModal'
import { getApiUrl } from '../../_services/apiUrlProvider'
import schema from '../../_schema/shopElektronicznyNadawcaAddShipment'
import { authHeader } from '../../_helpers/authHeader'
import { notification } from '../../_helpers/notification'

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    margin: 8,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  title: {
    marginRight: 8,
  },
  select: {
    fontSize: 14,
  },
  generateLabelsButton: {
    marginLeft: 20,
    height: 30,
  },
  generateLabelsWarning: {
    marginLeft: 5,
    color: '#e54a4a',
  },
}))

export const MassSelectChoice = ({
  selectable,
  selectable2 = [],
  tableState,
  tableStateDispatch = null,
  classes = {},
}) => {
  const handleMassSelectAction = useCallback(
    e => {
      const operation = selectable.find(item => item.action === e.target.value)

      if (!operation) {
        return
      }

      tableStateDispatch({
        type: constants.MASS_SELECT_ACTION_START,
        payload: {
          action: operation.action,
        },
      })

      operation.execute(tableState, tableStateDispatch)
    },
    [selectable, tableState, tableStateDispatch]
  )

  const handleMassSelectAction2 = useCallback(
    e => {
      const operation = selectable2.find(item => item.action === e.target.value)

      if (!operation) {
        return
      }

      tableStateDispatch({
        type: constants.MASS_SELECT_ACTION_START,
        payload: {
          action: operation.action,
        },
      })

      operation.execute(tableState, tableStateDispatch)
    },
    [selectable2, tableState, tableStateDispatch]
  )

  const canGenerateForOperator = resource => {
    const name = resource?.shippingOperator?.name
    const labelGuid = resource?.elektronicznyNadawcaLabelGuid

    if (!['POCZTA_POLSKA', 'POCZTA_POLSKA_PICKUP_POINT'].includes(name)) {
      return false
    }

    return !labelGuid
  }

  const [generateLabelsWarning, setGenerateLabelsWarning] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    let invalid = false
    let errors = []
    let lastOperator = null
    let lastCountry = null
    let lastCashOnDevliery = null
    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return null
      }

      if (lastOperator === null) {
        lastOperator = tableState.data.items[index]?.shippingOperator?.value
      }

      if (lastCashOnDevliery === null) {
        lastCashOnDevliery = tableState.data.items[index]?.cashOnDelivery
      }

      if (lastCountry === null) {
        lastCountry = tableState.data.items[index]?.countryTitle
      }

      if (!canGenerateForOperator(tableState.data.items[index])) {
        invalid = true
        errors.push(
          'Jedno z zamówień posiada już etykietę lub nie jest z poczty polskiej'
        )
      }

      if (
        lastOperator !==
          tableState.data.items[index]?.shippingOperator?.value ||
        lastCashOnDevliery !== tableState.data.items[index]?.cashOnDelivery
      ) {
        invalid = true
        errors.push('Zamówienia muszą być z tego samego operatora przesyłki')
      }

      if (lastCountry !== tableState.data.items[index]?.countryTitle) {
        invalid = true
        errors.push('Zamówienia muszą być z tego samego kraju')
      }

      return tableState.data.items[index]
    })

    setSelectedItems(items)
    setGenerateLabelsWarning([...new Set(errors)])
  }, [tableState])

  const defaultClasses = useStyles()

  function getfiledownload() {
    let xtitle = null
    fetch(`${getApiUrl()}${schema.endpointPdf}`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ orders: selectedItems }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return !tableState.data.selected.reduce(
    (acc, current) =>
      acc || (typeof current === 'boolean' ? current : current.value),
    false
  ) ? null : (
    <div className={clsx(defaultClasses.container, classes.container)}>
      <span className={clsx(defaultClasses.title, classes.title)}>
        {translate('T_GENERAL_MASS_SELECT_ACTION')}
      </span>
      <Select
        id="mass-select"
        value="choose"
        classes={{
          select: defaultClasses.select,
        }}
        onChange={handleMassSelectAction}
        className={defaultClasses.separator}
      >
        <MenuItem value="choose" key="choose">
          {translate('T_GENERAL_MASS_SELECT_CHOOSE')}
        </MenuItem>
        {selectable.map(item => (
          <MenuItem value={item.action} key={item.action}>
            {item.title}
          </MenuItem>
        ))}
      </Select>

      {selectable2.length > 0 && (
        <>
          <span
            style={{ marginLeft: 15 }}
            className={clsx(defaultClasses.title, classes.title)}
          >
            lub zmień status
          </span>
          <Select
            id="mass-select2"
            value="choose"
            classes={{
              select: defaultClasses.select,
            }}
            onChange={handleMassSelectAction2}
            className={defaultClasses.separator}
          >
            <MenuItem value="choose" key="choose">
              {translate('T_GENERAL_MASS_SELECT_CHOOSE')}
            </MenuItem>
            {selectable2.map(item => (
              <MenuItem value={item.action} key={item.action}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <ElektronicznyNadawcaLabelModal
            onClick={() => {
              getfiledownload()
            }}
            defaultData={{
              orders: selectedItems
                .filter(item => item !== null)
                .map(item => item.uuid),
            }}
            tableState={tableState}
            tableStateDispatch={tableStateDispatch}
            resource={selectedItems?.filter(item => item !== null)}
            disabledButton={generateLabelsWarning.length > 0}
          ></ElektronicznyNadawcaLabelModal>
          <span
            hidden={!generateLabelsWarning.length > 0}
            className={defaultClasses.generateLabelsWarning}
          >
            {generateLabelsWarning.join(', ')}
          </span>
        </>
      )}
    </div>
  )
}

MassSelectChoice.propTypes = {
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      execute: PropTypes.func.isRequired,
    })
  ).isRequired,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      selected: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            value: PropTypes.bool,
          }),
        ])
      ),
      selectAll: PropTypes.bool,
    }).isRequired,
    isInit: PropTypes.bool,
    isFetching: PropTypes.bool,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
  }),
}
