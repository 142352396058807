import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { translate } from '../../../_helpers/translate'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  statusSuccess: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  statusFailure: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}))

export const LoginStatusCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()
  const defaultClasses = useStyles()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const value = prop(resource, accessor)
  return (
    <div onClick={onClick}>
      {value ? (
        <Chip
          label={translate('T_LOGIN_STATUS_SUCCESS')}
          className={clsx(defaultClasses.statusSuccess)}
        ></Chip>
      ) : (
        <Chip
          label={translate('T_LOGIN_STATUS_FAILURE')}
          className={clsx(defaultClasses.statusFailure)}
        ></Chip>
      )}
    </div>
  )
}

LoginStatusCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
