export const addOpenformProperties = (isOpenform, properties) => {
  if (!isOpenform) {
    return {
      ...properties,
      rawPassword: {
        ...properties.rawPassword,
        validate: properties.rawPassword.validate?.filter(
          validator => validator !== 'required'
        ),
      },
    }
  }

  return {
    ...properties,
    rawPassword: {
      ...properties.rawPassword,
      validate: properties.rawPassword.validate?.filter(
        validator => validator !== 'required'
      ),
    },
    /*
    allowedHostnames: {
      type: 'multipleResource',
      endpoint: `/api/hostname_types?pagination=false`,
      titleAccessor: 'trans',
      label: 'T_MODULE_ADMIN_HOSTNAMES_SELECT',
      transTitle: true,
    },
     */
  }
}
