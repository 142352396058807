const schema = {
  endpoint: '/api/lesson_hosts',
  resource: {
    definition: '#LessonHost',
    properties: {
      fullName: {
        description: 'T_GENERAL_FULL_NAME',
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      email: {
        description: 'T_GENERAL_EMAIL',
      },
    },
  },
}

export default schema
