import seaEyeFiacreSchema from './seaEyeFiacre'

const schema = {
  endpoint: '/api/sea_eye_inspection_entries',
  access: 'seaEyeFiacre',
  resource: {
    definition: '#SeaEyeInspectionEntry',
    properties: {
      inspected: {
        type: 'resource',
        endpoint: `${seaEyeFiacreSchema.endpoint}?stat=true&pagination=false`,
        titleAccessor: 'title',
        validate: ['required'],
      },
      inPlaceOf: {
        type: 'resource',
        endpoint: `${seaEyeFiacreSchema.endpoint}?stat=true&pagination=false`,
        titleAccessor: 'title',
      },
      numberOfPeopleAtWagon: {
        type: 'number',
      },
      comments: {
        type: 'tinymce',
      },
    },
  },
}

export default schema
