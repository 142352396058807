const schema = {
  endpoint: '/api/shop_returns',
  resource: {
    definition: '#ShopReturn',
    properties: {
      returnId: {
        description: 'Numer zwrotu',
        disabled: true,
      },
      orderId: {
        description: 'Numer zamówienia',
        validate: ['required'],
      },
      orderDate: {
        type: 'date',
        description: 'Data zamówienia',
        validate: ['required'],
      },
      fullName: {
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      email: {
        description: 'Email',
      },
      phone: {
        description: 'Telefon',
      },
      comment: {
        type: 'textarea',
        description: 'Uwagi klienta',
      },
      status: {
        description: 'Status',
        type: 'resource',
        endpoint: '/api/shop_return_statuses',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      reason: {
        description: 'Powód zwrotu',
        type: 'resource',
        endpoint: '/api/shop_return_reasons',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
    },
  },
  subresources: {
    products: {
      endpoint: '/api/shop_return_products',
      definition: '#ShopReturnProduct',
      properties: {
        title: {
          type: 'string',
          description: 'Nazwa produktu',
          fullWidth: true,
          validate: ['required'],
        },
        quantity: {
          type: 'number',
          description: 'Ilość',
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
