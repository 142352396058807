import React from 'react'
import { TableRow, TableCell, TableSortLabel } from '@material-ui/core'

export const SortableTableHead = ({
  properties,
  order,
  onSortChoose
}) => {
  return (
    <div>
      <TableRow component="div">
        {Object.keys(properties).map((property) => (
          <TableCell
            component="div"
            key={property}
            sortDirection={order.property === property
              ? order.direction
              : false
            }
            style={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <TableSortLabel
              active={order.property === property}
              direction={order.property === property
                ? order.direction
                : 'asc'
              }
              order-property={property}
              order-direction={order.property === property
                ? getOppositeOrder(order.direction)
                : 'asc'}
              onClick={onSortChoose}
            >
              {properties[property]}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </div>
  )
}

const getOppositeOrder = (order) => order === 'asc' ? 'desc' : 'asc'
