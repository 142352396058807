import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { AddCircleOutline } from '@material-ui/icons'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { Form } from '../form'
import { useSelector } from 'react-redux'
import { SchemaError } from '../../_helpers/schemaError'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const AddModal = ({
  title,
  itemProperties,
  itemSchema,
  definitionSchema,
  endpoint,
  defaultData = {},
  setModalResponse = null,
  classes = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
    if (setModalResponse) {
      setModalResponse(false)
    }
  }, [setModalResponse])

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const defaultClasses = useStyles()

  const schemaDefinitions = useSelector(state => state.schema)

  if (!schemaDefinitions) {
    throw new SchemaError()
  }

  const handleSuccess = resource => {
    if (setModalResponse) {
      setModalResponse(resource)
      handleDialogToggle()
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={handleDialogToggle}
      >
        {title}
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          <Form
            definitionSchema={definitionSchema}
            properties={itemProperties}
            method="POST"
            url={endpoint}
            defaultData={defaultData}
            storeCollectionId={itemSchema.endpoint}
            showSubmitAndStayButton={false}
            showCancelButton={false}
            buttonContainerRef={buttonRef}
            handleSuccess={handleSuccess}
            width={600}
          />
        </DialogContent>
        <DialogActions ref={onButtonRef}></DialogActions>
      </Dialog>
    </>
  )
}

AddModal.propTypes = {
  title: PropTypes.string.isRequired,
  itemProperties: PropTypes.object.isRequired,
  itemSchema: PropTypes.object.isRequired,
  setModalResponse: PropTypes.func,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
}
