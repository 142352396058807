import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { MailOutline, Send } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import { TinymceType } from '../../../components/form/fields/TinymceType'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const NotifyModal = ({
  title,
  itemProperties,
  itemSchema,
  definitionSchema,
  endpoint,
  defaultData = {},
  classes = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [config, setConfig] = useState(false)

  const [isConfigFetching, setIsConfigFetching] = useState(false)

  const defaultContent = `<p><b>Szanowni Państwo, </b><br />
    z bieżącym numerem <b>(87)</b> „Tatr” zakończyła się wykupiona przez Państwa prenumerata naszego kwartalnika. </p>

    <p>Przypominamy, że obejmuje ona cztery kolejne numery „Tatr”. W przypadku
      prenumeraty dwuletniej - osiem numerów. Prenumeratorzy oprócz niższej ceny od
        czasu do czasu otrzymują ciekawe i zazwyczaj niedostępne w sprzedaży
          upominki w postaci reprintów, książek, broszur, wydawnictw multimedialnych itp.</p>

    <p><b>Prenumeratę najłatwiej przedłużyć przez stronę naszego
      <a href="http://sklep.tpn.pl/index.php?route=product/product&product_id=131">sklepu</a> lub w razie
      problemów mailowo -
        Łukasz Janczy, <a href="mailto:ljanczy@tpn.pl">ljanczy@tpn.pl</a>.</b><br />
    Zachęcamy do pozostania z nami!</p>`

  useEffect(() => {
    if (!isConfigFetching && !config) {
      setIsConfigFetching(true)
      fetch(`${getApiUrl()}/api/shop_config`, {
        method: 'GET',
        headers: {
          ...authHeader(),
        },
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Error')
          }
          return response.json()
        })
        .then(data => {
          setConfig(data)
          setIsConfigFetching(false)
          setSelectValue(
            data?.subscriptionLastNotificationInfo?.content
              ? data?.subscriptionLastNotificationInfo?.content
              : defaultContent
          )
        })
        .catch(e => {
          setIsConfigFetching(false)
        })
    }
  }, [config, defaultContent, isConfigFetching])

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [inputError, setInputError] = useState(false)

  const [isFetching, setIsFetching] = useState(false)

  const [selectValue, setSelectValue] = useState(null)

  const numberRef = useRef(null)

  const defaultClasses = useStyles()

  let responseError = null

  const handleExportClick = () => {
    if (!Number(numberRef.current.value)) {
      setInputError(true)
      return
    }
    setInputError(false)

    setIsFetching(true)
    fetch(`${getApiUrl()}/api/send_subscription_expire_notification`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        number: Number(numberRef.current.value),
        content: selectValue,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          responseError = response.json()
          throw new Error('Error')
        }
        notification('success', 'Email został wysłany')
        setConfig(false)
        setIsFetching(false)
        setIsDialogOpen(true)
      })
      .catch(e => {
        notification(
          'error',
          'Nie można znaleźć adresów dla wskazanego numeru prenumeraty'
        )
        setIsFetching(false)

        responseError.then(data => {
          notification('error', data.error)
        })
      })
  }

  const setError = useCallback((name, error) => {}, [])

  const setValue = useCallback((name, value, setRenderError = true) => {
    setSelectValue(value)
  }, [])

  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }

  return (
    <>
      {isConfigFetching && <div>Ładowanie...</div>}
      {!isConfigFetching && (
        <>
          <div style={{ display: 'inline-block' }}>
            <span>
              Ostatnio wysłano:{' '}
              {config?.subscriptionLastNotificationInfo?.time
                ? new Date(
                    config?.subscriptionLastNotificationInfo?.time.date
                  ).toLocaleString('pl-PL', dateOptions)
                : 'nigdy'}
              <br />
              Ostatni numer:{' '}
              {config?.subscriptionLastNotificationInfo?.number
                ? config?.subscriptionLastNotificationInfo?.number
                : 'nigdy'}
            </span>
          </div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<MailOutline />}
            onClick={handleDialogToggle}
            disabled={isFetching}
          >
            Wyślij email
          </Button>
          <Dialog
            maxWidth="md"
            onClose={handleDialogToggle}
            open={isDialogOpen}
            disableEnforceFocus={true}
          >
            <DialogTitleWithClose onClose={handleDialogToggle}>
              {title}
            </DialogTitleWithClose>
            <DialogContent
              className={clsx(defaultClasses.content, classes.content)}
            >
              <div style={{ marginTop: '10px' }}>
                Wysyła wiadomość email to klientów, których prenumerata wygasła
              </div>
              <div style={{ marginTop: '20px' }}>
                <TextField
                  id="standard-basic"
                  label="Numer prenumeraty"
                  inputRef={numberRef}
                  error={inputError}
                  style={{ width: '100%' }}
                  helperText={
                    'Np. 87 wyśle email do osóbm których numer końcowy prenumeraty wynosi 87'
                  }
                />
                <TinymceType
                  setError={setError}
                  setValue={setValue}
                  name={'status'}
                  disabled={false}
                  renderError={false}
                  id="standard-basic"
                  label="Treść emaila"
                  inputRef={numberRef}
                  error={inputError}
                  style={{ width: '100%' }}
                  value={selectValue}
                  helperText={
                    'Np. 87 wyśle email do osóbm których numer końcowy prenumeraty wynosi 87'
                  }
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Send />}
                onClick={handleExportClick}
                style={{ marginTop: '20px', width: '100%' }}
                disabled={isFetching}
              >
                Wyślij powiadomienie
              </Button>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

NotifyModal.propTypes = {
  title: PropTypes.string,
  itemProperties: PropTypes.object,
  itemSchema: PropTypes.object,
  setModalResponse: PropTypes.func,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
}
