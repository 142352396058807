const schema = {
  endpoint: '/api/seo_redirects',
  access: 'seoRedirect',
  resource: {
    definition: '#SeoRedirect',
    properties: {
      oldPath: {
        description: 'T_SEO_REDIRECT_OLD_PATH',
        hint: 'np. /pl/catalog',
        validate: ['required'],
        fullWidth: true,
      },
      newPath: {
        description: 'T_SEO_REDIRECT_NEW_PATH',
        hint: 'Pełny adres URL, np. https://google.com/',
        validate: ['required'],
        fullWidth: true,
      },
      redirectType: {
        description: 'T_SEO_REDIRECT_TYPE',
        hint: 'Typ przekierowania, np. 301',
        type: 'choice',
        choices: {
          301: '301 - przekierowanie stałe',
          302: '302 - przekierowanie tymczasowe',
        },
        validate: ['required'],
        fullWidth: true,
        isNumber: true,
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
