import React from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import FolderIcon from '@material-ui/icons/FolderOpenOutlined'
import { DeleteDirModal } from './DeleteDirModal'

export const TreeLeaf = ({
  leaf,
  path,
  depth,
  expandedLeaves,
  handleToggleLeaf,
  handleDirChoose,
  handleTreeRefresh,
  handleCurrentDir,
  classes,
}) => {
  const handleDirDelete = () => {
    handleTreeRefresh()
    handleCurrentDir('/')
  }

  return Object.keys(leaf).map((dir, index) => {
    const currentPath = `${getCurrentDir(path)}${dir}`
    const dirName = getDirName(currentPath)

    return (
      <div key={`tree-dir-${depth}-${index}`} className={classes.nested}>
        <ListItem button key={`dir-wrapper-${depth}-${index}`}>
          {Object.keys(leaf[dir]).length === 0 ? (
            <ExpandLess style={{ opacity: 0 }} />
          ) : expandedLeaves.includes(currentPath) ? (
            <ExpandLess path={currentPath} onClick={handleToggleLeaf} />
          ) : (
            <ExpandMore path={currentPath} onClick={handleToggleLeaf} />
          )}
          <ListItemIcon
            style={{ minWidth: 32 }}
            key={`dir-icon-${depth}-${index}`}
          >
            <FolderIcon />
          </ListItemIcon>
          <ListItemText
            primary={dirName}
            key={`dir-${depth}-${index}`}
            path={currentPath}
            onClick={handleDirChoose}
          />
          <DeleteDirModal dir={currentPath} callback={handleDirDelete} />
        </ListItem>

        {hasChildren(leaf[dir]) && (
          <Collapse
            in={expandedLeaves.includes(currentPath)}
            timeout="auto"
            unmountOnExit
            key={`dir-elements-wrapper-${depth}-${index}`}
          >
            <List
              component="div"
              disablePadding
              key={`dir-elements-${depth}-${index}`}
            >
              <TreeLeaf
                leaf={leaf[dir]}
                path={currentPath}
                depth={depth + 1}
                expandedLeaves={expandedLeaves}
                handleToggleLeaf={handleToggleLeaf}
                handleDirChoose={handleDirChoose}
                handleTreeRefresh={handleTreeRefresh}
                handleCurrentDir={handleCurrentDir}
                classes={classes}
              />
            </List>
          </Collapse>
        )}
      </div>
    )
  })
}

const getCurrentDir = currentDir =>
  currentDir === '/' ? currentDir : `${currentDir}/`
const getDirName = dir => dir.slice(dir.lastIndexOf('/') + 1)

const hasChildren = dir => Object.keys(dir).length > 0
