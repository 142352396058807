import React from 'react'
import { Form } from 'pages'
import schema from '_schema/accommodationNotice'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width={800}
      ></Form>
      <br />
      <br />
    </>
  )
}
