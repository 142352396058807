const schema = {
  endpoint: '/api/shop_shipping_type_personal_collection_points',
  resource: {
    definition: '#ShopShippingTypePersonalCollectionPoint',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required', 'length(1)'],
          },
          address: {
            validate: ['required', 'length(1)'],
          },
        },
      },
      /*
      map:{
        type: 'streetmap',
        label: 'Mapa',
        validate: ['required'],
      },*/
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
