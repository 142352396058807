import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { useStyles } from 'components/resource/sidebar/Sidebar.styles'

export const Sidebar = ({
  resource,
  titleAccessor = 'title',
  statAccessor = 'stat',
  collectionPath,
  disabled = false,
  handleSyncUpdatedAt,
}) => {
  const history = useHistory()
  const defaultClasses = useStyles()
  const onDeleteSuccess = () => {
    history.push(collectionPath)
  }

  const profile = useSelector(state => state.profile)

  return (
    <List className={defaultClasses.list}>
      <ListItem className={defaultClasses.item}>
        <Status
          resource={resource}
          accessor={statAccessor}
          disabled={disabled || profile.group?.uuid === resource.uuid}
          translations={{
            publish: 'T_GENERAL_ENABLE',
            unpublish: 'T_GENERAL_DISABLE',
          }}
          isSwitch={false}
          handleSyncUpdatedAt={handleSyncUpdatedAt}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Delete
          resource={resource}
          accessor={titleAccessor}
          disabled={disabled || profile.group?.uuid === resource.uuid}
          onSuccess={onDeleteSuccess}
        />
      </ListItem>
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleSyncUpdatedAt: PropTypes.func.isRequired,
}
