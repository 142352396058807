import React from 'react'
import PropTypes from 'prop-types'
import { Clone } from 'components/resource/Clone'
import { constants } from 'components/table/_state'

export const CloneCell = ({
  resource,
  endpoint,
  disabled = false,
  onSuccess: customOnSuccess = null,
  tableStateDispatch,
}) => {
  const onSuccess = () => {
    customOnSuccess && customOnSuccess()

    tableStateDispatch({ type: constants.RELOAD })
  }

  return (
    <Clone
      resource={resource}
      endpoint={endpoint}
      disabled={disabled}
      onSuccess={onSuccess}
    />
  )
}

CloneCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  tableStateDispatch: PropTypes.func.isRequired,
}
