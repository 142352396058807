import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoints: {
    media: '/api/manager_media',
    dir_create: '/api/manager_media/dir',
    dir_delete: '/api/manager_media/dir/delete',
    tree: '/api/manager_media/dir/tree',
  },
  forms: {
    create_dir: {
      properties: {
        dir: {
          type: FIELD_TYPES.STRING,
          description: 'Nazwa katalogu',
          validate: ['required'],
        },
      },
    },
    edit_media: {
      definition: '#ManagerMedia',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            description: { type: FIELD_TYPES.TINYMCE },
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
      },
    },
  },
}

export default schema
