const schema = {
  endpoint: '/api/shop_subscriptions',
  resource: {
    definition: '#ShopSubscription',
    properties: {
      firstName: {
        type: 'string',
        description: '[DANE KLIENTA] Imię',
        validate: ['required'],
      },
      lastName: {
        type: 'string',
        description: '[DANE KLIENTA] Nazwisko',
        validate: ['required'],
      },
      email: {
        type: 'string',
        description: '[DANE KLIENTA] E-mail',
        validate: ['required', 'email'],
      },
      phone: {
        type: 'string',
        description: '[DANE KLIENTA] Telefon',
        validate: ['required'],
      },
      fax: {
        type: 'string',
        description: '[DANE KLIENTA] Fax',
      },
      paymentFirstName: {
        type: 'string',
        description: '[DANE PŁATNIKA] Imię',
        validate: ['required'],
      },
      paymentLastName: {
        type: 'string',
        description: '[DANE PŁATNIKA] Nazwisko',
        validate: ['required'],
      },
      paymentCompany: {
        type: 'string',
        description: '[DANE PŁATNIKA] Firma',
      },
      paymentNip: {
        type: 'string',
        description: '[DANE PŁATNIKA] NIP',
      },
      paymentStreet: {
        type: 'string',
        description: '[DANE PŁATNIKA] Ulica',
        validate: ['required'],
      },
      paymentHouseNumber: {
        type: 'string',
        description: '[DANE PŁATNIKA] Numer domu',
        validate: ['required'],
      },
      paymentApartmentNumber: {
        type: 'string',
        description: '[DANE PŁATNIKA] Numer mieszkania',
      },
      paymentCity: {
        type: 'string',
        description: '[DANE PŁATNIKA] Miasto',
        validate: ['required'],
      },
      paymentPostalCode: {
        type: 'string',
        description: '[DANE PŁATNIKA] Kod pocztowy',
        validate: ['required'],
      },
      paymentCountry: {
        type: 'string',
        description: '[DANE PŁATNIKA] Kraj',
        validate: ['required'],
      },
      shippingFirstName: {
        type: 'string',
        description: '[DANE WYSYŁKI] Imię',
        validate: ['required'],
      },
      shippingLastName: {
        type: 'string',
        description: '[DANE WYSYŁKI] Nazwisko',
        validate: ['required'],
      },
      shippingCompany: {
        type: 'string',
        description: '[DANE WYSYŁKI] Firma',
      },
      shippingStreet: {
        type: 'string',
        description: '[DANE WYSYŁKI] Ulica',
        validate: ['required'],
      },
      shippingHouseNumber: {
        type: 'string',
        description: '[DANE WYSYŁKI] Numer domu',
        validate: ['required'],
      },
      shippingApartmentNumber: {
        type: 'string',
        description: '[DANE WYSYŁKI] Numer mieszkania',
      },
      shippingCity: {
        type: 'string',
        description: '[DANE WYSYŁKI] Miasto',
        validate: ['required'],
      },
      shippingPostalCode: {
        type: 'string',
        description: '[DANE WYSYŁKI] Kod pocztowy',
        validate: ['required'],
      },
      shippingCountry: {
        type: 'string',
        description: '[DANE WYSYŁKI] Kraj',
        validate: ['required'],
      },
      shippingMethod: {
        description: 'Metoda wysyłki',
      },
      legacyStatus: {
        type: 'resource',
        endpoint: '/api/shop_legacy_order_statuses',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_MODULE_SHOP_SUBSCRIPTION_LEGACY_STATUS',
        disabled: true,
      },
      status: {
        type: 'resource',
        endpoint: '/api/shop_subscription_statuses',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_MODULE_SHOP_SUBSCRIPTION_STATUS',
      },
      subscriptionFrom: {
        type: 'number',
        description: 'T_MODULE_SHOP_SUBSCRIPTION_FROM',
        validate: ['required'],
      },
      subscriptionTo: {
        type: 'number',
        description: 'T_MODULE_SHOP_SUBSCRIPTION_TO',
        validate: ['required'],
      },
      sender: {
        type: 'resource',
        endpoint: '/api/shop_subscription_senders',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_MODULE_SHOP_SUBSCRIPTION_SENDER',
      },
      importTotal: {
        type: 'number',
        description: 'Egz.',
        validate: ['required'],
      },
      importSubscriptionPrice: {
        type: 'float',
        description: 'Cena zakupu',
        validate: ['required', 'money'],
        asString: true,
      },
      comment: {
        type: 'textarea',
        description: 'T_GENERAL_COMMENT',
      },
    },
  },
}

export default schema
