import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const heroBanner = (
  photoEndpoint,
  blockHeroBannerEndpoint,
  blockHeroBannerThumbEndpoint
) => ({
  name: BLOCK_TYPES_IRI.HERO_CAROUSEL,
  label: 'Sekcja hero z 4 banerami',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    banners: {
      type: 'collection',
      label: 'Banery',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#PageBlockBanner',
      endpoint: blockHeroBannerEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
              hint: 'widoczny na belce do rozwijania',
            },
            description: {
              type: 'string',
              description: 'Krótki opis',
            },
            shortLead: {
              type: 'string',
              description: 'Krótki opis na kafelku',
            },
            buttonTitle: {
              type: 'string',
              description: 'Nazwa przycisku',
              hint: 'opcjonalna',
            },
            link: {
              type: 'string',
              description: 'Link',
              hint: 'jesli wpisany, wybrana Strona/Produkt jest ignorowany',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoEndpoint,
          thumbs: blockHeroBannerThumbEndpoint && {
            endpoint: blockHeroBannerThumbEndpoint,
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        page: {
          type: 'resource',
          endpoint: `/api/pages?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          description: 'Strona',
        },
        product: {
          type: 'resource',
          endpoint: `/api/shop_products_form?pagination=false`,
          titleAccessor: `nativeTitle`,
          description: 'lub Produkt',
        },
      },
    },
  },
})
