import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import shopCategory from '../shopCategory'
import { LANGS_IRI } from '../../_lib/langs'

export const shopBanner2 = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.SHOP_BANNER2,
  label: 'T_GENERAL_BLOCK_TYPE_SHOP_BANNER2',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Tytuł - baner 1',
        },
        link2: {
          type: 'string',
          description: 'Tytuł - baner 2',
        },
        linkTitle: {
          type: 'string',
          description: 'Krótki opis - baner 1',
        },
        linkTitle2: {
          type: 'string',
          description: 'Krótki opis - baner 2',
        },
        alt: {
          type: 'string',
          description: 'T_GENERAL_ALT',
        },
        alt2: {
          type: 'string',
          description: 'T_GENERAL_ALT2',
        },
      },
    },
    shopCategory1: {
      type: 'resource',
      endpoint: `${shopCategory.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      description: 'Kategoria - baner 1',
    },
    shopIsNew: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_NEW',
    },
    shopIsBestseller: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_BESTSELLER',
    },
    shopIsSuggested: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_SUGGESTED',
    },
    shopCategory2: {
      type: 'resource',
      endpoint: `${shopCategory.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      description: 'Kategoria - baner 2',
    },
    shopIsNew2: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_NEW',
    },
    shopIsBestseller2: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_BESTSELLER',
    },
    shopIsSuggested2: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_BANNER_PROMOTION',
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=shop_banner2`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    media2: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=shop_banner2`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt2: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    showInProductDetail: {
      type: 'boolean',
      description: 'Wyświetl w detalu produktu',
    },
  },
})
