const schema = {
  endpoint: '/api/volunteering_accommodations',
  resource: {
    definition: '#VolunteeringAccommodation',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required'],
          },
        },
      },
      vacancies: {
        description: 'T_MODULE_VOLUNTEERING_VACANCIES',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
