import React from 'react'
import PropTypes from 'prop-types'
import { Delete } from 'components/resource/Delete'
import { constants } from 'components/table/_state'

export const DeleteCell = ({
  resource,
  accessor,
  disabled = false,
  onSuccess: customOnSuccess = null,
  tableState,
  tableStateDispatch,
}) => {
  const onSuccess = () => {
    customOnSuccess && customOnSuccess()

    if (tableState.data.page > 1 && tableState.data.items.length < 2) {
      tableStateDispatch({
        type: constants.SET_PAGE,
        payload: { page: tableState.data.page - 1 },
      })
    } else {
      tableStateDispatch({ type: constants.RELOAD })
    }
  }

  return (
    <Delete
      resource={resource}
      accessor={accessor}
      disabled={disabled}
      onSuccess={onSuccess}
    />
  )
}

DeleteCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }).isRequired,
    config: PropTypes.shape({
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
}
