import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'components/Paper'
import { translate } from '_helpers/translate'
import { Admins, AdminGroups, LoginEntries, RegistryEntries } from './boxes'
import { hasResourceAccess } from '_helpers/hasResourceAccess'
import adminRoutes from 'pages/Admins/routes'
import adminGroupRoutes from 'pages/AdminGroups/routes'
import loginEntryRoutes from 'pages/LoginEntries/routes'
import registryEntryRoutes from 'pages/RegistryEntries/routes'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main,
  },
}))

export const Home = () => {
  const profile = useSelector(state => state.profile)

  const classes = useStyles()

  return profile.isPasswordResetRequired ? (
    <Paper>
      <div className={classes.root}>
        {translate('T_GENERAL_PASSWORD_WAS_RESETTED')}
      </div>
    </Paper>
  ) : (
    <Grid container>
      {hasResourceAccess(adminRoutes().index.resourceAccess, profile) && (
        <Grid item xs={12} lg={6}>
          <Admins />
        </Grid>
      )}
      {hasResourceAccess(adminGroupRoutes().index.resourceAccess, profile) && (
        <Grid item xs={12} lg={6}>
          <AdminGroups />
        </Grid>
      )}
      {hasResourceAccess(loginEntryRoutes().index.resourceAccess, profile) && (
        <Grid item xs={12} lg={6}>
          <LoginEntries />
        </Grid>
      )}
      {hasResourceAccess(
        registryEntryRoutes().index.resourceAccess,
        profile
      ) && (
        <Grid item xs={12} lg={6}>
          <RegistryEntries />
        </Grid>
      )}
    </Grid>
  )
}
