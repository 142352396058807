import mediaSchema from './media'

const schema = {
  endpoint: '/api/sea_eye_inspections',
  access: 'seaEyeFiacre',
  resource: {
    definition: '#SeaEyeInspection',
    properties: {
      place: {
        validate: ['required'],
      },
      inspector: {
        validate: ['required'],
      },
      inspectedFrom: {
        type: 'datetime',
        validate: ['required'],
      },
      inspectedTo: {
        type: 'datetime',
        validate: ['required'],
      },
      comments: {
        type: 'tinymce',
      },
    },
  },
  subresources: {
    files: {
      endpoint: '/api/sea_eye_inspection_files',
      definition: '#SeaEyeInspectionFile',
      properties: {
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: mediaSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
      },
    },
  },
}

export default schema
