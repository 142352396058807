//import packageGallery from './packageGallery'
//import pageSchema from './page'
import { LANGS_IRI } from '../_lib/langs'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/parks',
  access: 'park',
  resource: {
    definition: '#Park',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'tinymce',
            description: 'T_GENERAL_DESCRIPTION_NORMAL',
            label: 'T_GENERAL_DESCRIPTION_NORMAL',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/parki-narodowe/:slug',
              [LANGS_IRI.EN]: '/en/national-parks/:slug',
            },
          },
        },
      },
      gallery: {
        type: 'resource',
        endpoint: `/api/package_galleries/park?pagination=false`,
        titleAccessor: 'title',
        description: 'Select package gallery',
        hostnameFilter: true,
      },
      address: {
        type: 'textarea',
      },
      phone: {
        description: 'T_MOBULE_PARK_PHONE_NUMBER',
      },
      email: {
        validate: ['email'],
      },
      googleMapUrl: {
        description: 'T_MOBULE_PARK_GOOGLE_MAPS_URL',
      },
      mapZoom: {
        type: 'number',
        description: 'T_MOBULE_PARK_MAP_ZOOM',
      },
      mapX: {
        description: 'T_MOBULE_PARK_MAP_CORD_X',
      },
      mapY: {
        description: 'T_MOBULE_PARK_MAP_CORD_Y',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    pages: {
      endpoint: '/api/park_pages',
      definition: '#ParkPage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
            },
          },
        },
        homepage: {
          type: 'boolean',
          description: 'T_MODULE_PARK_HOMEPAGE',
        },
        page: {
          type: 'resource',
          endpoint: `/api/pages/park?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          description: 'T_MODULE_PARK_PAGE_SELECT',
          hostnameFilter: true,
        },
        predefinedIcon: {
          type: 'resourceWithIcon',
          endpoint: '/api/park_page_predefined_icons',
          titleAccessor: 'trans',
          transTitle: true,
          description: 'T_GENERAL_PREDEFINED_ICON',
        },
      },
    },
    images: {
      endpoint: '/api/park_images',
      definition: '#ParkImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/park_image_thumbs',
          },
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
}

export default schema
