import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const shopPhotoVideo = (photoEndpoint, labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.SHOP_PHOTO_VIDEO,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_SHOP_PHOTO_VIDEO',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Title',
        },
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_VIDEO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      accept: 'video/*, audio/*',
      restrictManagerMimeType: 'video/*, audio/*',
    },
  },
})
