import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const list = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint = '#NewsBlockListItem' // tu chyba powinien być konkretny ref... pasuje do wszystkich encji np Page ?
) => ({
  name: BLOCK_TYPES_IRI.LIST,
  label: 'T_GENERAL_BLOCK_TYPE_LIST',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        shortLead: {
          description: 'T_GENERAL_LEAD',
          type: 'tinymce',
        },
      },
    },
    listItems: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_LIST_ITEMS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint,
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            text: {
              type: 'tinymce',
              description: 'T_GENERAL_BLOCK_TYPE_LIST_TEXT',
              label: 'T_GENERAL_BLOCK_TYPE_LIST_TEXT',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_PICTOGRAM',
          endpoint: photoEndpoint,
          thumbs: blockListItemEndpointThumbEndpoint && {
            endpoint: blockListItemEndpointThumbEndpoint,
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
    expandable: {
      description: 'T_BLOCK_EXPANDABLE_LABEL',
    },
    showPictograms: {
      description: 'T_BLOCK_SHOW_PICTOGRAMS_LABEL',
    },
  },
})
