export const initState = ({
  properties,
  resource,
  defaultData,
  url,
  method,
  fetchCompareResource,
}) => ({
  values:
    method === 'PUT' && resource
      ? {
          ...defaultData,
          ...resource,
        }
      : Object.assign(
          defaultData,
          ...Object.keys(properties).map(name => ({
            [name]:
              resource?.[name] !== undefined
                ? resource[name]
                : properties[name].defaultValue !== undefined
                ? properties[name].defaultValue
                : null,
          }))
        ),
  errors: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  renderError: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  additional: {},
  isInvalid: false,
  isProcessing: false,
  isSubmitted: false,
  fetchCompareResource,
  compareValues: null,
  url:
    resource?.uuid && url.includes(':uuid')
      ? url.replace(':uuid', resource.uuid)
      : url,
})
