import React from 'react'
import ReactNotification from 'react-notifications-component'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Router, Switch } from 'react-router-dom'
import { PrivateRoute } from 'components/route/PrivateRoute'
import { AnonymousRoute } from 'components/route/AnonymousRoute'
import { Login, ResetPasswordLink, ResetPasswordChange, Page } from 'pages'
import { hot } from 'react-hot-loader'
import { history } from '_helpers/history'
import { generateRoutes } from '_helpers/generateRoutes'
import { authActions } from '_actions'
import { useTokenRefresh } from '_helpers/useTokenRefresh'
import { boolean } from '_helpers/boolean'

const useStyles = makeStyles(theme => ({
  app: {
    backgroundColor: theme.elements.background,
  },
}))

const App = () => {
  const classes = useStyles()

  const { data } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useTokenRefresh(
    data?.token,
    dispatch,
    authActions.logout,
    authActions.refresh
  )

  return (
    <>
      <ReactNotification />
      <Router history={history}>
        <div className={classes.app}>
          <Switch>{generateRoutes(routes())}</Switch>
        </div>
      </Router>
    </>
  )
}

const routes = () =>
  [
    {
      type: AnonymousRoute,
      component: Login,
      path: '/login',
      exact: true,
    },
  ]
    .concat(
      boolean(process.env.REACT_APP_RESET_PASSWORD_SHOW)
        ? [
            {
              type: AnonymousRoute,
              component: ResetPasswordLink,
              path: '/reset-password',
              exact: true,
            },
            {
              type: AnonymousRoute,
              component: ResetPasswordChange,
              path: '/reset-password/:hash',
              exact: true,
            },
          ]
        : []
    )
    .concat([
      {
        type: PrivateRoute,
        component: Page,
        path: '/',
      },
    ])

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App
