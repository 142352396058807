import { take, call } from 'redux-saga/effects'
import { authConstants } from '_constants'
import { schemaActions, profileActions, apiConfigActions } from '_actions'

export function* authFlow() {
  while (true) {
    yield take([
      authConstants.LOGIN_SUCCESS,
      authConstants.LOGIN_RESTORE_SUCCESS,
    ])
    yield call(schemaActions.fetch)
    yield call(apiConfigActions.fetch)
    yield call(profileActions.fetch)
  }
}
