import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  // FormHelperText,
  Popper,
  // Chip,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
// import { validate } from 'core/_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'
// import Portal from '@material-ui/core/Portal'
// import { store } from 'core/_store'
// import { collectionConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
}))

export const ShopOrderShippingType = ({
  name,
  value,
  label1,
  label2 = null,
  label3 = null,
  endpoint1 = null,
  endpoint2 = null,
  endpoint3 = null,
  resources = null,
  error = false,
  renderError = false,
  disabled = false,
  fullWidth = false,
  validators,
  setValue,
  getValue,
  setError,
  titleAccessor1,
  titleAccessor2,
  titleAccessor3 = null,
  name1,
  name2,
  name3,
}) => {
  const [id1] = useState(randomHash())
  const [id2] = useState(randomHash())
  const [id3] = useState(randomHash())

  const [options, setOptions] = useState(getValue(name1))
  const [choices, setChoices] = useState(resources || [])

  const [options2, setOptions2] = useState([])
  const [choices2, setChoices2] = useState([])

  const [options3, setOptions3] = useState([])
  const [choices3, setChoices3] = useState(null)

  const [isFetching1, setFetching1] = useState(true)
  const [isFetching2, setFetching2] = useState(false)
  const [isFetching3, setFetching3] = useState(false)

  const [init, setInit] = useState(false)

  const handleChange = (e, option) => {
    setValue(name1, option ? option.value : 0)
    fetchShippingTypes(option ? option.uuid : null)
    setValue(name2, null)
  }
  const handleChange2 = (e, option) => {
    setValue(name2, option ? option.value : 0)
    setValue(name3, null)
  }

  const handleChange3 = (e, option) => {
    setValue(name3, option ? option.value : 0)
  }

  function Tcompare(a, b) {
    prop(a, titleAccessor1)

    if (prop(a, titleAccessor1) && prop(b, titleAccessor2)) {
      return new Intl.Collator().compare(
        prop(a, titleAccessor1),
        prop(b, titleAccessor2)
      )
    } else if (a.title && b.title) {
      return new Intl.Collator().compare(a.title, b.title)
    }
    return 0
    // if (a.translations.pl.title < b.translations.pl.title) { return -1 } if (a.translations.pl.title > b.translations.pl.title) { return 1  } return 0
  }

  useEffect(
    () => {
      if (!endpoint1 || resources) {
        return
      }

      const controller = new AbortController()
      const { signal } = controller

      if (choices.length === 0) {
        setFetching1(true)

        fetchDataHandleAuthError(
          endpoint1,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices(xsc)
            setFetching1(false)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
      }
      return () => controller.abort()
    },
    // eslint-disable-next-line
    [resources]
  )

  const fetchShippingTypes = uuid => {
    const controller = new AbortController()
    const { signal } = controller

    const endpointUrl = endpoint2.replace(':uuid', uuid)

    setFetching2(true)

    fetchDataHandleAuthError(
      endpointUrl,
      'GET',
      { signal },
      response => {
        if (response['availableShippingTypesCms']) {
          setChoices2(response['availableShippingTypesCms'])
        }
        setFetching2(false)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }

  const fetchShippingTypesRef = useRef(fetchShippingTypes)

  const fetchPaymentMethods = () => {
    setFetching3(true)
    const curOption2 = options2.find(option => option.value === getValue(name2))

    if (curOption2?.availablePaymentMethodsCMS) {
      setChoices3(curOption2?.availablePaymentMethodsCMS)
    } else {
      setChoices3([])
    }

    setFetching3(false)
  }

  if (getValue(name2) && choices3 === null) {
    fetchPaymentMethods()
  }

  const fetchPaymentMethodsRef = useRef(fetchPaymentMethods)

  useEffect(() => {
    if (init) {
      return
    }

    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor1),
      uuid: prop(resource, 'uuid'),
      shippingType: prop(resource, 'shippingType'),
    }))
    setOptions(opt)

    if (getValue(name1)) {
      if (typeof getValue(name1) === 'string') {
        fetchShippingTypesRef.current(getValue(name1))
      } else {
        fetchShippingTypesRef.current(getValue(name1)['@id'])
      }
    }

    if (getValue(name2)) {
      if (typeof getValue(name2) === 'string') {
        fetchPaymentMethodsRef.current(getValue(name2))
      } else {
        fetchPaymentMethodsRef.current(getValue(name2)['@id'])
      }
    }

    if (choices.length > 0) {
      setInit(true)
    }
  }, [
    choices,
    titleAccessor1,
    getValue,
    name1,
    init,
    fetchShippingTypesRef,
    fetchPaymentMethodsRef,
    name2,
  ])

  useEffect(() => {
    if (typeof choices2 === 'object' && choices2 !== null) {
      const newOptions = []
      Object.keys(choices2).forEach(key => {
        // Perform an operation on each property
        const shippingResource = choices2[key]
        newOptions.push({
          value: shippingResource.shippingType['@id'],
          title: prop(shippingResource?.shippingType, titleAccessor2),
          floatPrice: prop(shippingResource, 'floatPrice'),
          availablePaymentMethodsCMS: prop(
            shippingResource?.shippingType,
            'availablePaymentMethodsCMS'
          ),
        })
      })
      setOptions2(newOptions)
    }

    /*
    const opt2 = choices2?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor2),
    }))
     */

    //setOptions2(opt2)
  }, [choices2, titleAccessor2])

  useEffect(() => {

    if (typeof choices3 === 'object' && choices3 !== null) {
      const newOptions = []
      Object.keys(choices3).forEach(key => {
        // Perform an operation on each property
        const paymentResource = choices3[key]
        newOptions.push({
          value: paymentResource['@id'],
          title: prop(paymentResource, titleAccessor3),
          operator: prop(paymentResource, 'operator'),
        })
      })
      setOptions3(newOptions)
    }
  }, [choices3, titleAccessor3])

  const classes = useStyles()

  /*
  const value2Change = value2 =>
    getValue(value2)
      ? {
          value: getValue(value2),
          title:
            options2.find(option => option.value === getValue(value2))?.title ||
            '',
        }
      : null

   */

  const value2Change = value => {
    if (!getValue(value)) {
      return null
    }

    if (typeof getValue(value) === 'string') {
      const option = options2.find(option => option.value === getValue(value))

      return {
        value: getValue(value),
        title: option?.title || '',
      }
    }

    const iri = getValue(value)['@id']
    if (!iri) {
      return iri
    }

    const option = options2.find(option => option.value === iri)

    return {
      value: iri,
      title: option?.title || '',
    }
  }

  const value3Change = value => {
    if (!getValue(value)) {
      return null
    }

    if (typeof getValue(value) === 'string') {
      const option = options3.find(option => option.value === getValue(value))

      return {
        value: getValue(value),
        title: option?.title || '',
      }
    }

    const iri = getValue(value)['@id']
    if (!iri) {
      return iri
    }

    const option = options3.find(option => option.value === iri)

    return {
      value: iri,
      title: option?.title || '',
    }
  }

  const value1Change = value => {
    if (!getValue(value)) {
      return null
    }

    if (typeof getValue(value) === 'string') {
      const option = options.find(option => option.value === getValue(value))

      return {
        value: getValue(value),
        title: option?.title || '',
      }
    }

    const iri = getValue(value)['@id']
    if (!iri) {
      return iri
    }

    const option = options.find(option => option.value === iri)

    return {
      value: iri,
      title: option?.title || '',
    }
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isFetching1 ? (
            <Loader style={{ paddingTop: '10px' }} />
          ) : (
            <FormControl
              className={clsx(
                classes.formControl,
                fullWidth && classes.formControllFullWidth
              )}
              error={renderError && !!error}
            >
              <Autocomplete
                id={id1}
                name={name1}
                options={options}
                // multiple={false}
                getOptionLabel={option =>
                  option?.title ? option.title : '<Błąd>'
                }
                getOptionSelected={(option, value) => {
                  return option.value === value?.value
                }}
                onChange={handleChange}
                value={value1Change(name1)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      translate(label1.text || label1) +
                      (validators && validators.includes('required')
                        ? ' *'
                        : '')
                    }
                    variant="outlined"
                  />
                )}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    style={{ width: 'auto' }}
                    placement="bottom-start"
                  />
                )}
                disabled={disabled || !choices.length}
                classes={{ root: classes.autocomplete }}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={1}>
          <p>
            {/* <br /> */}
            {/* lub */}
          </p>
        </Grid>

        <Grid item xs={12}>
          {isFetching2 ? (
            <Loader style={{ paddingTop: '10px' }} />
          ) : (
            <FormControl
              className={clsx(
                classes.formControl,
                fullWidth && classes.formControllFullWidth
              )}
              error={renderError && !!error}
              disabled={disabled}
            >
              <Autocomplete
                id={id2}
                name={name2}
                options={options2}
                // multiple={false}
                getOptionLabel={option => {
                  return option.title
                }}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value
                }}
                onChange={handleChange2}
                value={value2Change(name2)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      translate(label2.text || label2) +
                      (validators && validators.includes('required')
                        ? ' *'
                        : '')
                    }
                    variant="outlined"
                  />
                )}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    style={{ width: 'auto' }}
                    placement="bottom-start"
                  />
                )}
                disabled={disabled || !options2.length}
                classes={{ root: classes.autocomplete }}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          {isFetching3 ? (
            <Loader style={{ paddingTop: '10px' }} />
          ) : (
            <FormControl
              className={clsx(
                classes.formControl,
                fullWidth && classes.formControllFullWidth
              )}
              error={renderError && !!error}
              disabled={disabled}
            >
              <Autocomplete
                id={id3}
                name={name3}
                options={options3}
                // multiple={false}
                getOptionLabel={option => {
                  return option.title
                }}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value
                }}
                onChange={handleChange3}
                value={value3Change(name3)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      translate(label3.text || label3)
                    }
                    variant="outlined"
                  />
                )}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    style={{ width: 'auto' }}
                    placement="bottom-start"
                  />
                )}
                disabled={disabled || !options3.length}
                classes={{ root: classes.autocomplete }}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
