import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const hydrochart = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.HYDROCHART,
  label: labelTrans ?? 'Blok z wykresem poziomu wody',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },

    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Title',
        },
      },
    },
  },
})
