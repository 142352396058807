import { put, call } from 'redux-saga/effects'
import { apiConfigConstants } from '_constants'
import { apiConfigService } from '_services'
import { handleAuthError } from '_helpers/handleAuthError'
import { prop } from '_helpers/prop'

const accessProperties = ['tinymce_key', 'google_key', 'hostname']

function* fetch() {
  yield put({ type: apiConfigConstants.REQUEST })

  const { response, error } = yield call(apiConfigService.get)

  if (response) {
    yield put({
      type: apiConfigConstants.SUCCESS,
      payload: {
        ...[
          ...accessProperties,
          //...Object.keys(schema.profile.properties),
        ].reduce(
          (obj, key) =>
            Object.assign(obj, { [key.split('.')[0]]: prop(response, key) }),
          {}
        ),
      },
    })

    return
  }

  yield call(handleAuthError.saga, error)
}

export const apiConfigActions = {
  fetch,
}
