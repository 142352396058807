import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import buildUrl from 'build-url'
import schema from '_schema/managerMedia'

export const fetchMedia = (
  dir,
  page,
  perPage,
  order,
  setMedia,
  setIsMediaFetching,
  restrictMimeTypes
) => {
  setIsMediaFetching(true)

  const controller = new AbortController()
  const { signal } = controller

  const url = buildUrl(schema.endpoints.media, {
    queryParams: {
      dir,
      page,
      perPage,
      [`order[${order.property}]`]: order.direction,
      ...(restrictMimeTypes ? { mimeType: restrictMimeTypes } : {}),
    },
  })

  fetchDataHandleAuthError(
    url,
    'GET',
    { signal },
    response => {
      setIsMediaFetching(false)

      setMedia({
        items: response['hydra:member'],
        count: response['hydra:totalItems'],
      })
    },
    error => {
      setIsMediaFetching(false)

      if (error.response?.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
    }
  )

  return () => controller.abort()
}
