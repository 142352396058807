import React from 'react'
import { Route } from 'react-router-dom'
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'

import { Collection as TourneyPackageCollection } from './TourneyPackage/Collection'
import { Edit as TourneyPackageEdit } from './TourneyPackage/Edit'
import { New as TourneyPackageNew } from './TourneyPackage/New'

import { Collection as TourneyRankingCollection } from './TourneyRanking/Collection'
import { Collection as TourneyScoreCollection } from './TourneyScore/Collection'
import { Edit as TourneyScoreEdit } from './TourneyScore/Edit'

import schema from '_schema/tourney'

const basePath = '/tourneys'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: LocalLibraryOutlinedIcon,
        allowedParks: ['TATRZANSKI'],
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      // /*
      packageIndex: {
        subtitle: 'Lista pakietów',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyPackageCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/packages`,
        exact: true,
      },
      packageNew: {
        subtitle: 'Nowe pole',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyPackageNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/packages/new`,
        exact: true,
      },
      packageEdit: {
        subtitle: 'Edycja pola',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyPackageEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/packages/:id`,
        exact: true,
      },

      // */

      rankingIndex: {
        subtitle: 'Ranking',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyRankingCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/ranking`,
        exact: true,
      },

      scoreIndex: {
        subtitle: 'Wyniki',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyScoreCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/score/:uid`,
        exact: true,
      },
      scoreEdit: {
        subtitle: 'Edycja',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={TourneyScoreEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/score/:uid/edit/:id`,
        exact: true,
      },
    },
    {
      title: 'Turnieje',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
