import React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { getResourceLangs } from '_helpers/getResourceLangs'
import { constants } from 'components/embedded/_state'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.elements.embedded_collection.color,
  },
}))

export const Add = ({
  endpoint,
  parent,
  dispatch,
  title = '',
  smallButton = false,
  disabled = false,
  order = 'asc',
}) => {
  const handleSubmit = () => {
    dispatch({ type: constants.FETCH_START })

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          parent,
          translations: Object.assign(
            {},
            ...getResourceLangs().map(lang => ({ [lang]: { lang } }))
          ),
        }),
      },
      response => {
        dispatch({
          type: constants.ADD_ITEM,
          payload: { value: response, order: order },
        })

        notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  if (smallButton) {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {translate(title)}
      </Button>
    )
  } else {
    return (
      <IconButton
        size="small"
        onClick={handleSubmit}
        disabled={disabled}
        className={classes.button}
      >
        <AddCircleOutline />
      </IconButton>
    )
  }
}

Add.propTypes = {
  endpoint: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  smallButton: PropTypes.bool,
  disabled: PropTypes.bool,
}
