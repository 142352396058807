import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginBottom: 15,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  confirmButton: {
    color: theme.palette.success.main,
  },
}))

export const RemoveDialog = ({
  type = 'file',
  setValue,
  disabled = false,
  isOpen,
  handleToggle,
  classes = {},
}) => {
  const handleRemove = () => {
    setValue(null)
    handleToggle()
  }

  const defaultClasses = useStyles()

  return (
    <Dialog open={isOpen} onClose={handleToggle}>
      <DialogTitle>
        {translate(`T_GENERAL_${type.toUpperCase()}_REMOVE`)}
      </DialogTitle>
      <DialogContent
        className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
      >
        <DialogContentText>
          {translate(`T_GENERAL_${type.toUpperCase()}_REMOVE_CONFIRM`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleToggle}
          className={clsx(defaultClasses.cancelButton, classes.cancelButton)}
          disabled={disabled}
          autoFocus
        >
          {translate('T_GENERAL_CANCEL')}
        </Button>
        <Button
          onClick={handleRemove}
          className={clsx(defaultClasses.confirmButton, classes.confirmButton)}
          disabled={disabled}
        >
          {translate('T_GENERAL_CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveDialog.propTypes = {
  type: PropTypes.oneOf(['file', 'image']),
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogContent: PropTypes.string,
    cancelButton: PropTypes.string,
    confirmButton: PropTypes.string,
  }),
}
