import shippingCountrySchema from './shopShippingCountry'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/users',
  access: 'user',
  resource: {
    definition: '#User',
    properties: {
      generalHeader: {
        type: 'header',
        label: 'Dane ogólne',
      },
      email: {},
      rawPassword: {
        type: 'passwordRepeat',
        name2: 'rawPasswordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['password'],
      },
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      shopHeader: {
        type: 'header',
        label: 'Dane w sklepie',
      },
      companyName: {
        description: 'Nazwa firmy',
      },
      street: {
        description: 'Ulica',
      },
      houseNumber: {
        description: 'Numer domu',
      },
      apartmentNumber: {
        description: 'Numer mieszkania',
      },
      postalCode: {
        description: 'Kod pocztowy',
      },
      city: {
        description: 'Miasto',
      },
      country: {
        type: 'resource',
        endpoint: `${shippingCountrySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Kraj',
      },
      fvCompanyName: {
        description: '[FAKTURA] Nazwa firmy',
      },
      fvNip: {
        description: '[FAKTURA] Numer NIP',
      },
      fvAddress: {
        type: 'textarea',
        description: '[FAKTURA] Adres',
      },
      volunteerHeader: {
        type: 'header',
        label: 'Dane wolontariusza',
      },
      pesel: {
        description: 'T_GENERAL_PESEL',
      },
      education: {
        type: 'resource',
        endpoint: '/api/education_levels',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Poziom wykształcenia',
      },
      fieldOfStudy: {
        description: 'Kierunek studiów',
      },
      nameOfTheUniversity: {
        description: 'Nazwa uczelni',
      },
      universityAddress: {
        description: 'Adres uczelni',
      },
      yearOfGraduation: {
        description: 'Rok ukończenia',
      },
      stillStudying: {
        description: 'Czy nadal studiuje?',
      },
      yearStarted: {
        description: 'Rok rozpoczęcia',
      },
      notWorking: {
        description: 'Nie pracuje',
      },
      workPosition: {
        description: 'Stanowisko',
      },
      workContract: {
        type: 'resource',
        endpoint: '/api/employment_contract_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Typ umowy',
      },
      fte: {
        type: 'resource',
        endpoint: '/api/employment_ftes',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Etat',
      },
      workName: {
        description: 'Nazwa firmy',
      },
      workAddress: {
        description: 'Adres firmy',
      },
      accommodationType: {
        label: 'Zakwaterowanie',
        type: 'resource',
        endpoint: '/api/volunteering_accommodation_types',
        titleAccessor: 'trans',
        transTitle: true,
      },
      consentHealth: {
        description: 'Oświadczenie o zdrowiu',
      },
      consentInformationDisclaimer: {
        description: 'Zgoda na klauzulę informacyjną',
      },
      consentDataPrivacy: {
        description: 'Zgoda na przetwarzanie danych',
      },
      consentNewsletter: {
        description: 'Zgoda na newsletter',
      },
      volunteerStatus: {
        label: 'Status',
        type: 'resource',
        endpoint: '/api/volunteer_account_status_types',
        titleAccessor: 'trans',
        transTitle: true,
      },
      globalHeader: {
        type: 'header',
        label: 'Dane globalne',
      },
      isConfirmed: {
        description: 'Konto potwierdzone linkiem aktywacyjnym',
      },
      deleted: {
        description: 'Konto usunięte',
        hint:
          'konto poprawnie usunięte przez użytkownika, operacja potwierdzona linkiem',
      },
      stat: {
        description: 'Aktywność',
        hint: 'Jeśli wyłączona, użytkownik nie będzie się mógł zalogować',
      },
    },
  },
}

export default schema
