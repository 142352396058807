import PropTypes from 'prop-types'

export const collectionTablePropTypes = {
  parameters: PropTypes.array,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      sortable: PropTypes.bool,
      filterable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Filter: PropTypes.elementType,
      Cell: PropTypes.elementType,
    })
  ),
  defaultSorters: PropTypes.objectOf(PropTypes.oneOf(['asc', 'desc'])),
  defaultFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  autoWidth: PropTypes.bool,
  isRowLinkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  perPage: PropTypes.number,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetable: PropTypes.bool,
  paginable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  orderable: PropTypes.bool,
  numerable: PropTypes.bool,
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      title: PropTypes.string,
      execute: PropTypes.func,
    })
  ),
  isManualOrderSorterVisible: PropTypes.bool,
  isPaginationVisible: PropTypes.bool,
  isRowSelectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRowKeyTimestamped: PropTypes.bool,
  prepareResources: PropTypes.func,
  storeCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.func,
  editPath: PropTypes.string,
  transparent: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string,
    toolbar: PropTypes.string,
    row: PropTypes.string,
    rowAsLink: PropTypes.string,
    cell: PropTypes.string,
    emptyResults: PropTypes.string,
    manualOrderContainer: PropTypes.string,
    manualOrderContainerTitle: PropTypes.string,
    draggedRow: PropTypes.string,
    massSelectContainer: PropTypes.string,
    massSelectContainerTitle: PropTypes.string,
    massHeadColumn: PropTypes.string,
    massColumn: PropTypes.string,
  }),
}
