import React, { useEffect, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/tourneyConfig'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { currentHostnameIri } from '../../_helpers/currentHostnameIri'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`
  const profile = useSelector(state => state.profile)

  const [hostnameIri, setHostnameIri] = useState(false)
  const [isHostnameLoading, setIsHostnameLoading] = useState(false)

  useEffect(() => {
    if (!isHostnameLoading && hostnameIri === false) {
      setIsHostnameLoading(true)
      currentHostnameIri(setHostnameIri)
    }

    if (hostnameIri !== false) {
      setIsHostnameLoading(false)
    }
  }, [hostnameIri, setHostnameIri, isHostnameLoading, setIsHostnameLoading])

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        modifyProperties={properties =>
          addOpenformProperties(
            profile?.openform,
            properties,
            null,
            null,
            hostnameIri
          )
        }
        sidebar={false}
        width='100%'
      ></Form>
    </div>
  )
}
