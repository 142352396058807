import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const modifyBlockByPage = (blockTypes, resource) => {
  let modifiedBlockTypes = []
  switch (resource.idName) {
    // case 'park_tourist_announcement':
    // break
    case 'park_education_competitions':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.LINKS ||
          elem.name === BLOCK_TYPES_IRI.TEXT ||
          elem.name === BLOCK_TYPES_IRI.BANNER2
      )
      break

    case 'park_shop':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.NEWSLETTER ||
          elem.name === BLOCK_TYPES_IRI.HERO_CAROUSEL ||
          elem.name === BLOCK_TYPES_IRI.SHOP_CAROUSEL ||
          elem.name === BLOCK_TYPES_IRI.SHOP_BENEFITS ||
          elem.name === BLOCK_TYPES_IRI.SHOP_BANNER1 ||
          elem.name === BLOCK_TYPES_IRI.SHOP_BANNER2 ||
          elem.name === BLOCK_TYPES_IRI.SHOP_PHOTO_VIDEO
      )
      break

    case 'park_accommodation':
      let blocks = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.LINKS ||
          elem.name === BLOCK_TYPES_IRI.BANNER2 ||
          elem.name === BLOCK_TYPES_IRI.CONTESTFORM
      )
      // eslint-disable-next-line
      blocks.map(elem => {
        if (elem.name === BLOCK_TYPES_IRI.CONTESTFORM) {
          delete elem.properties.formset
        }
      })
      modifiedBlockTypes = blocks
      break

    case 'park_contact_make_case':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.LINKS ||
          elem.name === BLOCK_TYPES_IRI.BANNER2
      )
      break

    case 'park_search':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.LINKS ||
          elem.name === BLOCK_TYPES_IRI.BANNER2
      )
      break

    case 'park_hydro_level':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name !== BLOCK_TYPES_IRI.HERO_CAROUSEL &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_CAROUSEL &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BENEFITS &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BANNER1 &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BANNER2 &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_PHOTO_VIDEO &&
          elem.name !== BLOCK_TYPES_IRI.EMBED &&
          elem.name !== BLOCK_TYPES_IRI.CONTEST &&
          elem.name !== BLOCK_TYPES_IRI.CONTESTFORM &&
          elem.name !== BLOCK_TYPES_IRI.STOPS &&
          elem.name !== BLOCK_TYPES_IRI.CREW &&
          elem.name !== BLOCK_TYPES_IRI.NEWSLETTER &&
          elem.name !== BLOCK_TYPES_IRI.FORMSETBLOCK &&
          elem.name !== BLOCK_TYPES_IRI.A11_STRING &&
          elem.name !== BLOCK_TYPES_IRI.A11_TEXT
      )
      break

    case 'page_declaration_a11y':
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name === BLOCK_TYPES_IRI.A11_STRING ||
          elem.name === BLOCK_TYPES_IRI.A11_TEXT
      )
      break

    case 'home':
      //case 'park_tourist_announcement':
      modifiedBlockTypes = []
      break

    default:
      modifiedBlockTypes = blockTypes.filter(
        elem =>
          elem.name !== BLOCK_TYPES_IRI.HERO_CAROUSEL &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_CAROUSEL &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BENEFITS &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BANNER1 &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_BANNER2 &&
          elem.name !== BLOCK_TYPES_IRI.SHOP_PHOTO_VIDEO &&
          elem.name !== BLOCK_TYPES_IRI.HYDROCHART &&
          elem.name !== BLOCK_TYPES_IRI.A11_STRING &&
          elem.name !== BLOCK_TYPES_IRI.A11_TEXT
      )
  }

  modifiedBlockTypes.forEach(elem => {
    if (
      elem.name === BLOCK_TYPES_IRI.NEWSLETTER &&
      resource?.idName === 'park_shop'
    ) {
      elem.properties.showInProductDetail = {
        type: 'boolean',
        description: 'Wyświetl w detalu produktu',
      }
    }
  })

  return modifiedBlockTypes
}
