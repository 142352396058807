import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { StatusCell as OriginalStatusCell } from 'components/table/cells/StatusCell'

export const StatusCell = ({
  resource,
  accessor = 'stat',
  tableState,
  tableStateDispatch,
}) => {
  const profile = useSelector(state => state.profile)

  return (
    <OriginalStatusCell
      resource={resource}
      accessor={accessor}
      disabled={profile.uuid === resource.uuid}
      tableState={tableState}
      tableStateDispatch={tableStateDispatch}
    />
  )
}

StatusCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  tableState: PropTypes.object,
  tableStateDispatch: PropTypes.func.isRequired,
}
