import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageGallery from '../packageGallery'

export const gallery = () => ({
  name: BLOCK_TYPES_IRI.GALLERY,
  label: 'T_GENERAL_BLOCK_TYPE_GALLERY',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
      },
    },
    gallery: {
      type: 'resource',
      endpoint: `${packageGallery.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package gallery',
      hostnameFilter: true,
    },
  },
})
