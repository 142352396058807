import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/shopProduct'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { status } from '../../components/table/_columns/status'
import { MainCell } from './table/cells/MainCell'
import { MultiStatusCell } from '../../components/table/cells/MultiStatusCell'
import { MultiResourceFilter } from '../../components/table/filters/MultiResourceFilter'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'
import { RangeFilter } from '../../components/table/filters/RangeFilter'
import { MultiChoiceFilter } from '../../components/table/filters/MultiChoiceFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'nativeTitle',
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'nativeTitle',
        sorterName: 'nativeTitle',
        filterable: true,
        sortable: true,
        width: '50%',
        Cell: MainCell,
      },
      {
        columnId: 'translations.model',
        header: 'Model',
        accessor: `translations.${LANGS_IRI.PL}.model`,
        filterName: 'translations.model',
        sorterName: 'translations.model',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        filtersList: ['oneOfIri[category.uuid]'],
        columnId: 'category',
        header: 'Kategoria',
        accessor: `category.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        filtersList: ['floatPrice[gte]'],
        columnId: 'floatPrice',
        header: translate('Cena'),
        accessor: `floatPriceString`,
        filterable: true,
        sortable: true,
        sorterName: 'price',
        width: '15%',
      },
      {
        filtersList: ['variantsTotalAmount[gte]'],
        columnId: 'variantsStockAvailable',
        header: translate('Łączne stany magazynowe (dostępne)'),
        accessor: `variantsStockAvailable`,
        filterable: true,
        sortable: false,
        width: '10%',
      },
      {
        columnId: 'suggested',
        ...status('suggested'),
        Cell: MultiStatusCell,
        accessors: {
          new: 'Nowość',
          bestseller: 'Bestseller',
          suggested: 'Polecany',
          subscription: 'Prenumerata',
        },
        header: translate('Statusy'),
        filterable: true,
        filterName: 'suggested',
        Filter: MultiChoiceFilter,
        width: '5%',
      },
      {
        columnId: 'stat',
        ...status('stat'),
        header: translate('Publikacja'),
        filterable: true,
        Filter: ChoiceFilter,
        choices: {
          true: 'Opublikowane',
          false: 'Nie opublikowane',
        },
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          false,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHOP_PRODUCT_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_SHOP_PRODUCT')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpointCollection}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'nativeTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.model',
            in: 'query',
            type: 'string',
          },
          {
            name: 'oneOfIri[categories.uuid]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'oneOfIri[category.uuid]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'stat',
            in: 'query',
            type: 'string',
          },
          {
            name: 'floatPrice[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'floatPrice[lte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'floatPrice[between]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'variantsTotalAmount[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'variantsTotalAmount[lte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'variantsTotalAmount[between]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'suggested',
            in: 'query',
            type: 'string',
          },
          {
            name: 'new',
            in: 'query',
            type: 'string',
          },
          {
            name: 'bestseller',
            in: 'query',
            type: 'string',
          },
          {
            name: 'subscription',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[translations.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.model]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[nativeTitle]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[price]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'oneOfIri[categories.uuid]',
            title: 'kategorie',
            Filter: MultiResourceFilter,
            endpoint: '/api/shop_categories?pagination=false',
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          },
          {
            name: 'oneOfIri[category.uuid]',
            title: 'kategorie',
            Filter: MultiResourceFilter,
            endpoint: '/api/shop_categories?pagination=false',
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          },
          {
            name: 'floatPrice[gte]',
            title: 'Cena',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'floatPrice[lte]',
            title: 'Cena',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'floatPrice[between]',
            title: 'Cena',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'variantsTotalAmount[gte]',
            title: 'Stany magazynowe',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'variantsTotalAmount[lte]',
            title: 'Stany magazynowe',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'variantsTotalAmount[between]',
            title: 'Stany magazynowe',
            titles: ['od', 'do'],
            Filter: RangeFilter,
          },
          {
            name: 'suggested',
            title: 'Statusy',
            Filter: MultiChoiceFilter,
            choices: [
              {
                accessor: 'new',
                filterName: 'new',
                value: true,
                title: 'Nowość',
              },
              {
                accessor: 'bestseller',
                filterName: 'bestseller',
                value: true,
                title: 'Bestseller',
              },
              {
                accessor: 'suggested',
                filterName: 'suggested',
                value: true,
                title: 'Polecany',
              },
              {
                accessor: 'subscription',
                filterName: 'subscription',
                value: true,
                title: 'Prenumerata',
              },
            ],
          },
          {
            name: 'new',
            title: 'Stany magazynowe',
          },
          {
            name: 'bestseller',
            title: 'Bestseller',
          },
          {
            name: 'subscription',
            title: 'Prenumerata',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
