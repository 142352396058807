import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  // FormHelperText,
  Popper,
  // Chip,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
// import { validate } from 'core/_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'
// import Portal from '@material-ui/core/Portal'
// import { store } from 'core/_store'
// import { collectionConstants } from 'core/_constants'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
}))

export const DoubleResourceType = ({
  name,
  value,
  label1,
  label2 = null,
  endpoint1 = null,
  endpoint2,
  resources = null,
  error = false,
  renderError = false,
  disabled = false,
  fullWidth = false,
  validators,
  setValue,
  getValue,
  setError,
  titleAccessor1,
  titleAccessor2,
  name1,
  name2 = null,
}) => {
  const [id1] = useState(randomHash())
  const [id2] = useState(randomHash())

  const [options, setOptions] = useState(getValue(name1))
  const [choices, setChoices] = useState(resources || [])

  const [options2, setOptions2] = useState([])
  const [choices2, setChoices2] = useState([])

  const [isFetching1, setFetching1] = useState(true)
  const [isFetching2, setFetching2] = useState(false)

  const [init, setInit] = useState(false)

  const handleChange = (e, option) => {
    setValue(name1, option ? option.value : 0)
    getAjaxValuesWhenChange(option ? option.value : null, 'lesson')
    setValue(name2, null)
  }
  const handleChange2 = (e, option) => {
    setValue(name2, option ? option.value : 0)
  }

  function Tcompare(a, b) {
    prop(a, titleAccessor1)

    if (prop(a, titleAccessor1) && prop(b, titleAccessor2)) {
      return new Intl.Collator().compare(
        prop(a, titleAccessor1),
        prop(b, titleAccessor2)
      )
    } else if (a.title && b.title) {
      return new Intl.Collator().compare(a.title, b.title)
    }
    return 0
    // if (a.translations.pl.title < b.translations.pl.title) { return -1 } if (a.translations.pl.title > b.translations.pl.title) { return 1  } return 0
  }

  useEffect(
    () => {
      if (!endpoint1 || resources) {
        return
      }

      const controller = new AbortController()
      const { signal } = controller

      if (choices.length === 0) {
        setFetching1(true)

        fetchDataHandleAuthError(
          endpoint1,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices(xsc)
            setFetching1(false)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
      }
      return () => controller.abort()
    },
    // eslint-disable-next-line
    [resources]
  )

  const getAjaxValuesWhenChange = (valueSel, parentSel) => {
    const controller = new AbortController()
    const { signal } = controller

    const urls = endpoint2.replace(':iri', valueSel)

    fetchDataHandleAuthError(
      urls,
      'GET',
      { signal },
      response => {
        if (response['hydra:member']) {
          setChoices2(response['hydra:member'])
        }
        setFetching2(false)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }

  const getAjaxValuesWhenChangeRef = useRef(getAjaxValuesWhenChange)

  useEffect(() => {
    if (init) {
      return
    }

    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor1),
    }))
    setOptions(opt)

    if (getValue(name1)) {
      if (typeof getValue(name1) === 'string') {
        getAjaxValuesWhenChangeRef.current(getValue(name1), 'lesson')
      } else {
        getAjaxValuesWhenChangeRef.current(getValue(name1)['@id'], 'lesson')
      }
    }

    if (choices.length > 0) {
      setInit(true)
    }
  }, [
    choices,
    titleAccessor1,
    getValue,
    name1,
    init,
    getAjaxValuesWhenChangeRef,
  ])

  useEffect(() => {
    const opt2 = choices2?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor2),
    }))

    setOptions2(opt2)
  }, [choices2, titleAccessor2])

  const classes = useStyles()

  /*
  const value2Change = value2 =>
    getValue(value2)
      ? {
          value: getValue(value2),
          title:
            options2.find(option => option.value === getValue(value2))?.title ||
            '',
        }
      : null

   */

  const value2Change = value2 => {
    if (!getValue(value2)) {
      return null
    }

    if (typeof getValue(value2) === 'string') {
      const option = options2.find(option => option.value === getValue(value2))

      return {
        value: getValue(value2),
        title: option?.title || '',
      }
    }

    const iri = getValue(value2)['@id']
    if (!iri) {
      return iri
    }

    const option = options2.find(option => option.value === iri)

    return {
      value: iri,
      title: option?.title || '',
    }
  }

  const value1Change = value => {
    if (!getValue(value)) {
      return null
    }

    if (typeof getValue(value) === 'string') {
      const option = options.find(option => option.value === getValue(value))

      return {
        value: getValue(value),
        title: option?.title || '',
      }
    }

    const iri = getValue(value)['@id']
    if (!iri) {
      return iri
    }

    const option = options.find(option => option.value === iri)

    return {
      value: iri,
      title: option?.title || '',
    }
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isFetching1 ? (
            <Loader style={{ paddingTop: '10px' }} />
          ) : (
            <FormControl
              className={clsx(
                classes.formControl,
                fullWidth && classes.formControllFullWidth
              )}
              error={renderError && !!error}
            >
              <Autocomplete
                id={id1}
                name={name1}
                options={options}
                // multiple={false}
                getOptionLabel={option => option.title}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value
                }}
                onChange={handleChange}
                value={value1Change(name1)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      translate(label1.text || label1) +
                      (validators && validators.includes('required')
                        ? ' *'
                        : '')
                    }
                    variant="outlined"
                  />
                )}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    style={{ width: 'auto' }}
                    placement="bottom-start"
                  />
                )}
                disabled={disabled || !choices.length}
                classes={{ root: classes.autocomplete }}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={1}>
          <p>
            {/* <br /> */}
            {/* lub */}
          </p>
        </Grid>

        <Grid item xs={12}>
          {isFetching2 ? (
            <Loader style={{ paddingTop: '10px' }} />
          ) : (
            <FormControl
              className={clsx(
                classes.formControl,
                fullWidth && classes.formControllFullWidth
              )}
              error={renderError && !!error}
              disabled={disabled}
            >
              <Autocomplete
                id={id2}
                name={name2}
                options={options2}
                // multiple={false}
                getOptionLabel={option => {
                  return option.title
                }}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value
                }}
                onChange={handleChange2}
                value={value2Change(name2)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      translate(label2.text || label2) +
                      (validators && validators.includes('required')
                        ? ' *'
                        : '')
                    }
                    variant="outlined"
                  />
                )}
                PopperComponent={props => (
                  <Popper
                    {...props}
                    style={{ width: 'auto' }}
                    placement="bottom-start"
                  />
                )}
                disabled={disabled || !choices2.length}
                classes={{ root: classes.autocomplete }}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
