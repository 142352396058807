import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'
import { LANGS_IRI } from '../../_lib/langs'

export const gallery = (photoEndpoint, blockImageEndpoint) => ({
  name: BLOCK_TYPES_IRI.GALLERY,
  label: 'T_GENERAL_BLOCK_TYPE_GALLERY',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
          description: 'Opis',
        },
      },
    },
    images: {
      type: 'collection',
      label: 'Obrazki',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#BipPageBlockImage',
      endpoint: blockImageEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            alt: {},
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoEndpoint,
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        publishDate: {
          type: 'datetime',
          label: 'Czas publikacji zdjęcia',
        },
        creatorName: {
          description: 'Informację wytworzył',
        },
        personResponsible: {
          description: 'Informację opublikował',
        },
        createdDate: {
          type: 'date',
          description: 'Data wytworzenia informacji',
        },
      },
    },
  },
})
