import React from 'react'
import { Form } from 'pages'
import schema from '_schema/guideLanguage'
import routes from './routes'

export const New = ({ schema: definitions }) => (
  <Form
    definitionSchema={definitions[schema.resource.definition]}
    definitions={definitions}
    customResourceSchema={schema.resource}
    method="POST"
    url={schema.endpoint}
    collectionPath={routes().index.path}
    editPath={routes().edit.path}
    storeCollectionId={schema.endpoint}
    width={600}
  />
)
