import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { styles as translationTabStyles } from 'components/form/fields/translation/TranslationTab.styles'
import { useSelector } from 'react-redux'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    width: '100%',
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const Tinymce3Type = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = '100%',
  translationLang = null,
  formHeight = 300,
  tinymceParentUuid = null,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const apiConfig = useSelector(state => state.apiConfig)

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()
  const langUrl = `${window.location.origin.replace('admin.', '')}/tinymce/${
    process.env.REACT_APP_LOCALE
  }.js`

  let isFetching = false

  const defaultSettings = {
    menubar: '', //'file edit view insert',
    force_br_newlines: true,
    force_p_newlines: false,
    forced_root_block: '',
    entities: '160,nbsp', //default is full list
    entity_encoding: 'named',
    language: process.env.REACT_APP_LOCALE,
    language_url: langUrl,
    directionality: translationLang === 'he' ? 'rtl' : 'ltr',
    paste_as_text: true,
    image_advtab: true,
    nonbreaking_wrap: false,
    plugins: [
      'autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime paste nonbreaking',
    ],
    contextmenu: 'link | copy paste',
    toolbar: 'insertfile undo redo | bold italic | link unlink nonbreaking',
    extended_valid_elements:
      'span[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
      'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
      'onkeydown|onkeyup],script[charset|defer|language|src|type]',
    style_formats: [],
    paste_text_sticky: true,
    paste_text_sticky_default: true,
    browser_spellcheck: true,
    width: `calc(${formWidth} - ${
      translationLang
        ? translationTabStyles.root?.paddingLeft +
          translationTabStyles.root?.paddingRight
        : 0
    }px)`,
    height: formHeight,
    skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
    content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
    file_picker_types: 'file',
    file_picker_callback: function(callback, value, meta) {
      if (isFetching) {
        notification(
          'warning',
          'Wgrywanie pliku jeszcze się nie zakończyło',
          'Błąd'
        )
      }

      if (!tinymceParentUuid) {
        notification(
          'warning',
          'Funkcja będzie dostępna po zapisaniu formularza',
          'Błąd'
        )
        return
      }

      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*, .pdf')

      input.onchange = function() {
        const file = this.files[0]
        const reader = new FileReader()

        reader.onload = function() {
          const id = 'blobid' + new Date().getTime()
          const blobCache = window.tinymce.activeEditor.editorUpload.blobCache
          const base64 = reader.result.split(',')[1]
          const blobInfo = blobCache.create(id, file, base64)
          blobCache.add(blobInfo)

          const formData = new FormData()
          formData.append('file', blobInfo.blob())
          formData.append('related', tinymceParentUuid)

          const headers = new Headers({
            ...authHeader(),
          })

          isFetching = true
          notification('default', 'Wgrywanie pliku w trakcie', 'Info')

          fetch(`${getApiUrl()}/api/media/wysiwyg`, {
            method: 'POST',
            body: formData,
            headers: headers,
          })
            .then(response => response.json()) // Assuming response is JSON
            .then(data => {
              // Callback function with blob URI and additional data
              callback(data.url, {
                text: data.originalName,
                title: data.originalName,
              })

              isFetching = false
              notification('success', 'Pomyślnie wgrano plik', 'Sukces')
            })
            .catch(error => {
              // Handle errors
              console.error('Error:', error)
            })
        }
        reader.readAsDataURL(file)
      }

      input.click()
    },
  }

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <InputLabel htmlFor={id} className={classes.label}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      {apiConfig.tinymce_key && (
        <Editor
          apiKey={apiConfig.tinymce_key}
          tinymceScriptSrc={
            'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js'
          }
          disabled={disabled}
          id={id}
          init={defaultSettings}
          textareaName={name}
          value={value}
          onEditorChange={handleChange}
        />
      )}
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

Tinymce3Type.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
  tinymceParentUuid: PropTypes.string,
}
