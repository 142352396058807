import photoSchema from './photo'

const reviewGradeChoices = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
}

const schema = {
  endpoint: '/api/shop_product_reviews',
  resource: {
    definition: '#ShopProductReview',
    properties: {
      name: {
        description: 'T_MODULE_TRAIL_REVIEW_AUTHOR',
        validate: ['required'],
      },
      text: {
        type: 'textarea',
        description: 'T_MODULE_TRAIL_REVIEW_TEXT',
        fullWidth: true,
        validate: ['required'],
      },
      gradeHelper: {
        type: 'choice',
        choices: reviewGradeChoices,
        description: 'T_GENERAL_GRADE',
        validate: ['required'],
        fullWidth: false,
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/shop_product_review_thumbs',
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
