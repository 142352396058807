import { makeStyles } from '@material-ui/styles'

export const styles = {
  root: {
    paddingTop: 14,
    paddingBottom: 10,
    paddingLeft: 18,
    paddingRight: 18,
    maxWidth: 800
  },
  field: {
    marginBottom: '1.5em',
    '& label': {
      fontSize: '1.0em',
    },
    '& p': {
      fontSize: '.9em',
    },
  },
}

export const useStyles = makeStyles(styles)
