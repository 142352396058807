import React from 'react'
import { Route } from 'react-router-dom'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/accommodationPdf'

const basePath = '/accommodation_pdfs'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: PictureAsPdfIcon,
        allowedParks: ['TATRZANSKI'],
      },
    },
    {
      title: 'PDFy',
      resourceAccess: schema.access,
      indexPath: basePath,
      // isOpenform: true,
    }
  )

export default routes
