import lessonSchema from './lesson'
import hostSchema from './lessonHost'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/lesson_schedules',
  resource: {
    definition: '#LessonSchedule',
    properties: {
      /*
      lesson: {
        type: 'resource',
        endpoint: `${lessonSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Zajęcia',
        validate: ['required'],
      },
       */
      lesson: {
        type: 'doubleResource',
        name1: 'lesson',
        endpoint1: `${lessonSchema.endpoint}?pagination=false`,
        titleAccessor1: `translations.${LANGS_IRI.PL}.title`,
        label1: 'Zajęcia',
        name2: 'educator',
        label2: 'Prowadzący',
        titleAccessor2: `fullName`,
        endpoint2: `${hostSchema.endpoint}?pagination=false&iri[lessons]=:iri`,
        validate: ['required'],
      },
      /*
      educator: {
        type: 'resource',
        endpoint: `${hostSchema.endpoint}?pagination=false`,
        titleAccessor: `fullName`,
        label: 'Prowadzący',
        validate: ['required'],
      },
     */
      status: {
        label: 'Status',
        type: 'resource',
        endpoint: '/api/lesson_schedule_statuses?pagination=false',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      date: {
        type: 'date',
        label: 'Data',
        validate: ['required'],
      },
    },
  },
}

export default schema
