const schema = {
  endpoint: '/api/shop_shipping_areas',
  resource: {
    definition: '#ShopShippingArea',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required', 'length(1)'],
          },
        },
      },
      shopFreeShippingThresholdFloat: {
        type: 'string',
        description: 'T_SHOP_CONFIG_FREE_SHIPPING_THRESHOLD',
        validate: ['money'],
        hint: '0 - bez darmowej dostawy',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
