import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Title } from 'components/Title'
import { CustomButton } from 'components/CustomButton'
import { AddCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ColumnSettings } from './table/ColumnSettings'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  buttonTitle: {
    marginLeft: '.7em',
  },
  columnSettingsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const CollectionHeader = ({
  children,
  buttonTitle = '',
  buttonPath,
  hideButton = false,
  additionalButtons = null,
  columns = null,
  setVisibleColumns = null,
  collectionId = null,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {columns && setVisibleColumns ? (
        <div className={defaultClasses.columnSettingsWrapper}>
          <Title>{children}</Title>
          <ColumnSettings
            columns={columns}
            setVisibleColumns={setVisibleColumns}
            collectionId={collectionId}
          />
        </div>
      ) : (
        <Title>{children}</Title>
      )}
      {!hideButton && (
        <div className={clsx(defaultClasses.buttons, classes.buttons)}>
          <CustomButton path={buttonPath}>
            <AddCircleOutline />
            <span
              className={clsx(defaultClasses.buttonTitle, classes.buttonTitle)}
            >
              {buttonTitle}
            </span>
          </CustomButton>
          {additionalButtons}
        </div>
      )}
    </div>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string,
  buttonPath: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  additionalButtons: PropTypes.node,
  columns: PropTypes.array,
  setVisibleColumns: PropTypes.func,
  collectionId: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
}
