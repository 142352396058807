import React, { useEffect, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'

import Leaflet from 'leaflet'
import {
  MapContainer,
  TileLayer,
  Marker,
  // Popup,
  useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import iconUrl from 'pages/../../public/img/pin.svg'

const INITIAL_ZOOM = 14
const INITIAL_POSITION = {
  LAT: 49.271441,
  LNG: 19.982744,
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
    position: 'relative',
    zIndex: 0,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginBottom: 10,
  },
  hint: {
    marginTop: 8,
  },
  map: {
    position: 'relative',
    height: 400,
    width: '100%',
  },
  mapDisabled: {
    position: 'relative',
    height: 400,
    width: '100%',
    '& img': {
      opacity: '0.5 !important',
      filter: 'grayscale(1)',
    },
  },
}))

export const StreetMapType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  validators,
  setValue,
}) => {
  const { zoom, lat, lng } = value?.lat
    ? value
    : {
        zoom: INITIAL_ZOOM,
        lat: INITIAL_POSITION.LAT,
        lng: INITIAL_POSITION.LNG,
      }

  const [currentZoom, setCurrentZoom] = useState(zoom)
  const [position, setPosition] = useState([lat, lng])
  const [wasdraggable, setWasDraggable] = useState(false)
  const markerRef = useRef(null)

  useEffect(() => {
    if (!initialValue && validators?.includes('required')) {
      setValue(name, {
        zoom: INITIAL_ZOOM,
        lat: INITIAL_POSITION.LAT,
        lng: INITIAL_POSITION.LNG,
      })
    }
  }, [setValue, name, initialValue, validators])

  const valueRef = useRef(value)

  useEffect(() => {
    setValue(name, {
      zoom: currentZoom ? currentZoom : INITIAL_ZOOM,
      lat: valueRef.current?.lat ? valueRef.current.lat : INITIAL_POSITION.LAT,
      lng: valueRef.current?.lng ? valueRef.current.lng : INITIAL_POSITION.LNG,
    })
  }, [currentZoom, name, setValue])

  const classes = useStyles()

  const newicon = new Leaflet.Icon({
    iconUrl,
    iconAnchor: [18, 31],
    popupAnchor: [18, 0],
    iconSize: [36, 36],
  })

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          const latLng = marker.getLatLng()
          setWasDraggable(true)
          setPosition(latLng)
          setValue(name, {
            zoom: currentZoom,
            lat: latLng.lat,
            lng: latLng.lng,
          })
        }
      },
    }),
    [name, setValue]
  )

  const LocationMarker = () => {
    // eslint-disable-next-line
    const map = useMapEvents({
      click(e) {
        if (!wasdraggable) {
          const latLng = e.latlng
          setPosition([latLng.lat, latLng.lng])
          setValue(name, {
            zoom: currentZoom,
            lat: latLng.lat,
            lng: latLng.lng,
          })
        }
        setWasDraggable(false)
      },
      zoomend: () => {
        setCurrentZoom(map.getZoom())
      },
    })

    return null
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.label}
        style={label.color && { color: label.color }}
      >
        {translate(label.text || label)}
      </div>
      <div className={classes.hint}>{translate(hint)}</div>

      <MapContainer
        className={disabled ? classes.mapDisabled : classes.map}
        center={position}
        zoom={zoom}
        zoomControl={!disabled}
        dragging={!disabled}
        scrollWheelZoom={!disabled}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
        />
        <LocationMarker />
        <Marker
          draggable={!disabled}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}
          icon={newicon}
        >
          {/* <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup> */}
        </Marker>
      </MapContainer>

      {compare && compareValue && (
        <MapContainer
          className={classes.map}
          center={[compareValue.lat, compareValue.lng]}
          zoom={compareValue.zoom}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
          />
          <Marker
            position={[compareValue.lat, compareValue.lng]}
            icon={newicon}
          />
        </MapContainer>
      )}
    </div>
  )
}

StreetMapType.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
}
