import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { editable } from 'components/table/_columns/editable'
//import { status } from 'components/table/_columns/status'
import { operations } from './table/columns/operations'
import { EditableCell } from './table/cells/EditableCell'
//import { StatusCell } from './table/cells/StatusCell'
import { translate } from '_helpers/translate'
import schema from '_schema/adminGroup'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'title',
        ...editable('title'),
        width: '53%',
        Cell: EditableCell,
      },
      /*
      {
        header: translate('T_GENERAL_SUPER_ADMIN'),
        ...status('isSuperAdmin'),
        width: '10%',
        Cell: StatusCell,
      },
      {
        header: translate('T_GENERAL_REDACTOR'),
        ...status('isRedactor'),
        width: '10%',
        Cell: StatusCell,
      },
       */
      { columnId: 'operations', ...operations('title'), width: '15%' },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ADMIN_GROUPS_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_ADMIN_GROUPS')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        editPath={routes().edit.path}
        autoWidth={false}
        orderable={true}
        parameters={[
          {
            name: 'title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'isSuperAdmin',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'order[title]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[isSuperAdmin]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
