import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import { LANGS_IRI } from '../../../../_lib/langs'
import { Chip } from '@material-ui/core'
import { translate } from '../../../../_helpers/translate'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: '1.0em',
  },
  button: {
    marginLeft: 10,
  },
})

export const MainCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  routes = [],
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource['uuid']))
    }
  }

  const classes = useStyles()

  const redirectToEdit = event => {
    event.stopPropagation()
    const shortClass = resource?.autoNewsClassShort?.toLowerCase()

    if (!shortClass) {
      return
    }

    /*
    const haveClass = (routeClass, entryClass) => {
      if (Array.isArray(routeClass)) {
        routeClass.forEach(route => {
          if (route?.resourceAccess?.toLowerCase() === entryClass) {
            return true
          }
        })

        return false
      } else {
        return entryClass === routeClass?.toLowerCase()
      }
    }
     */

    routes.forEach(route => {
      if (
        route?.routeKey === 'edit' &&
        route?.registryEntryDefinition &&
        route?.registryEntryDefinition === `#${resource?.autoNewsClassShort}`
        //haveClass(route?.registryEntryDefinition, shortClass)
      ) {
        history.push(route?.path.replace(':id', resource?.autoNewsUuid))
        return
      }
    })
  }

  return (
    <div onClick={onClick}>
      {resource?.autoNewsClass && resource?.autoNewsUuid ? (
        <div className={classes.container}>
          <div className={classes.title}>
            <div>{prop(resource, accessor)}</div>
          </div>
          <Chip
            label={translate(resource?.autoNewsClassShort)}
            clickable
            color="primary"
            className={classes.button}
            onClick={redirectToEdit}
          />
        </div>
      ) : (
        <div className={classes.title}>
          <div>{prop(resource, accessor)}</div>
        </div>
      )}
    </div>
  )
}

MainCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    translations: PropTypes.shape({
      [LANGS_IRI.PL]: PropTypes.shape({
        author: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
  routes: PropTypes.array,
}
