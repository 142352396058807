import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
// import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
// import { publish } from 'components/table/_mass'
import schema from '_schema/tourneyRanking'
import { TimeCell } from '../table/cells/TimeCell'
import { WynikiCell } from '../table/cells/WynikiCell'

export const Collection = ({ ...rest }) => {
  const { match } = rest
  const columns = useMemo(
    () => [
      {
        columnId: 'firstName',
        header: translate('Imię'),
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        columnId: 'lastName',
        header: translate('Nazwisko'),
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        columnId: 'email',
        header: translate('Email'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        columnId: 'suma',
        header: translate('Suma punktów'),
        accessor: `suma`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'timers',
        header: translate('Czas rozwiązania'),
        accessor: `timers`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: TimeCell,
      },
      {
        columnId: 'wyniki',
        header: translate('Operacje'),
        width: '5%',
        Cell: WynikiCell,
        accessor: match.params.pid,
      },
      // {
      //   ...operations(`email`, true, true, true, true, false),
      //   width: '10%',
      // },
    ],
    [match.params.pid]
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        hideButton={true}
        buttonPath={''}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Turniej - ranking')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={`${schema.endpoint}/${match.params.pid}`}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        // selectable={[publish.on(), publish.off()]}
        // isRowLinkable={true}
        // editPath={routes().packageEdit.path.replace(
        //   ':pid',
        //   match.params.pid
        // )}
        autoWidth={false}
      />
    </Paper>
  )
}
