import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { translate } from '_helpers/translate'
import schema from '_schema/matterFormRequest'
import { makeStyles } from '@material-ui/styles'
import routesTo from 'pages/MatterFormRequest/routes'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/pl'

// import 'react-big-calendar/lib/sass/styles.scss'
require('react-big-calendar/lib/css/react-big-calendar.css')

const useStyles = makeStyles(theme => ({
  legend_span: {
    display: 'inline-block',
    width: 12,
    height: 12,
    marginRight: 5,
    marginLeft: 10,
  },
  legend: {
    margin: '20px 0 0',
    border: '1px dashed gray',
    backgroundColor: 'rgba(255, 255, 255, .7)',
  },
}))

const localizer = momentLocalizer(moment)

export const Collection = () => {
  let history = useHistory()
  const colorState = {
    1: 'gray',
    2: 'lightgray',
    3: 'yellow',
    4: 'lightgreen',
    5: 'lightblue',
  }
  const colorDay = {
    1: 'lightyellow',
    2: 'salmon',
    3: 'red',
  }

  const classes = useStyles()
  const events = [
    // {
    //   id: 1,
    //   title: 'Long Event',
    //   start: new Date(2024, 3, 7),
    //   end: new Date(2024, 3, 7),
    //   color: 'red',
    // },
    // {
    //   id: 2,
    //   title: 'DTS STARTS',
    //   start: new Date(2024, 3, 13, 0, 0, 0),
    //   end: new Date(2024, 3, 13, 10, 0, 0),
    //   color: 'blue',
    // },
  ]

  const [eventsList, setEventsList] = useState(events)
  const [eventsBackgroundList, setEventsBackgroundList] = useState([])

  function onRangeChange({ start, end }) {
    if (start && end) {
      fetchDataHandleAuthError(
        `${schema.endpoint}?pagination=false&dateFrom[after]=${
          start.toISOString().split('T')[0]
        }&dateFrom[before]=${end.toISOString().split('T')[0]}`,
        'GET',
        {},
        response => {
          if (response['hydra:member']) {
            let out = []
            response['hydra:member'].map(el => {
              if (
                el.status.value !== 0 &&
                (el.type.value === 'car_dmo' || el.type.value === 'car_dw')
              ) {
                const dater = new Date(el.dateFrom)
                const hour = el.hours?.split('_') ?? ''
                dater.setHours(hour && hour[1] ? hour[1].split(':')[0] : 0)
                const title =
                  el.type.value === 'car_dmo' || el.type.value === 'car_dw'
                    ? (el.type.value === 'car_dmo'
                        ? '[Morskie oko]'
                        : '[Włośienica] ') +
                      'wjazd ' +
                      translate(hour[0]) +
                      ' ' +
                      hour[1] +
                      ':' +
                      el.car +
                      '\n' +
                      translate(el.carType?.trans)
                    : translate(el.type.trans)

                const newEvent = {
                  id: el.uuid,
                  title: title,
                  start: dater,
                  end: dater,
                  date: new Date(el.dateFrom),
                  color: colorState[el.status.value],
                  uuid: el.uuid,
                }
                out = [...out, newEvent]
              }
              return []
            })
            setEventsList([...out])
            setEventsBackgroundList([...out])
          }
          // setEventsList([...eventsList, newEvent])
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          notification('error', error.response.detail, error.response.title)
        }
      )
    }
  }

  useEffect(
    () => {
      var dt = new Date()
      var m = dt.getMonth()
      var y = dt.getFullYear()
      var sy = y
      var ey = y
      var sm = m - 1
      var em = m + 1
      if (m === 0) {
        sy = y - 1
        sm = 11
      }
      if (m === 11) {
        ey = y + 1
        em = 0
      }
      sm = sm + 1
      em = em + 1
      var start = new Date(`${sy}-${sm < 10 ? '0' + sm : sm}-21`)
      var end = new Date(`${ey}-${em < 10 ? '0' + em : em}-07`)
      onRangeChange({ start, end })
      return () => {}
    },
    // eslint-disable-next-line
    []
  )

  const eventPropGetter = event => {
    const backgroundColor = event.color
    return { style: { backgroundColor, color: 'black' } }
  }

  const dayPropGetter = date => {
    var item = eventsBackgroundList.filter(
      el => el.date.getTime() === date.getTime()
    )
    var color = null //'#90ee904d'
    var max = 4
    if (item.length === 0) {
    } else if (item.length < max) {
      color = colorDay[1]
    } else if (item.length === max) {
      color = colorDay[2]
    } else if (item.length > max) {
      color = colorDay[3]
    }
    if (color) {
      const backgroundColor = color
      return { style: { backgroundColor } }
    }
  }

  const onSelectEvent = calEvent => {
    var p = routesTo().edit.path.replace(':id', calEvent.uuid)
    // window.location = p
    history.push(p)
  }

  return (
    <div style={{ padding: 10 }}>
      <Calendar
        localizer={localizer}
        views={['month', 'day']}
        events={eventsList}
        eventPropGetter={eventPropGetter}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100vh' }}
        onRangeChange={onRangeChange}
        dayPropGetter={dayPropGetter}
        onSelectEvent={onSelectEvent}
        messages={{
          allDay: 'cały dzień',
          next: 'następny',
          previous: 'poprzedni',
          today: 'dziś',
          month: 'miesiąc',
          week: 'tydzień',
          day: 'dzień',
          agenda: 'agenda',
          date: 'Data',
          time: 'Godzina',
          event: 'Event',
        }}
      />

      <table className={classes.legend}>
        <tbody>
          <tr>
            <th rowSpan="2">Legenda:</th>
            <th>Wnioski:</th>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[5] }}
              ></span>{' '}
              Odrzucony
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[4] }}
              ></span>{' '}
              Zaakceptowany
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[3] }}
              ></span>{' '}
              Zwrócony
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[2] }}
              ></span>{' '}
              Jeszcze nie rozpatrzony
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorState[1] }}
              ></span>{' '}
              Niepotwierdzony przez użytkownika
            </td>
          </tr>
          <tr>
            <th>Kalendarz:</th>
            {/* <td>
              <span style="background-color: lightgreen"></span> Brak wniosku
            </td> */}
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[1] }}
              ></span>{' '}
              Istnieją wolne terminy
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[2] }}
              ></span>{' '}
              Brak wolnych terminów
            </td>
            <td>
              <span
                className={classes.legend_span}
                style={{ backgroundColor: colorDay[3] }}
              ></span>{' '}
              Limit przekroczony
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
