import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const newsletter = (
  blockListItemEndpoint,
  definitionRefblockListItemEndpoint = '#PageBlockListItem',
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.NEWSLETTER,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_NEWSLETTER',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
          type: 'textarea',
          hint: 'domyślnie trans "T_NEWSLETTER_TITLE"',
        },
        shortLead: {
          type: 'string',
          description: 'Subtitle',
          hint: 'domyślnie trans "T_NEWSLETTER_SUBTITLE"',
        },
      },
    },
    listItems: {
      type: 'collection',
      label: 'Korzyści',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint,
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            text: {
              type: 'string',
              label: 'Opis krótki',
            },
          },
        },
      },
    },
  },
})
