import formsetSchema from '_schema/formset'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const formsetContest = (photoEndpoint, thumbEndpoint, labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.CONTESTFORM,
  label: labelTrans ?? 'Blok zgłoszenie do konkursu',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł',
        },
        shortLead: {
          type: 'string',
          label: 'Nad tytuł',
        },
        text: {
          type: 'textarea',
          label: 'Opis krótki',
        },
        linkTitle:{
          type: 'string',
          label: 'Tytuł guzika'
        },
        alt:{
          type: 'string',
          label: 'Alt'
        }
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=banner`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    formset: {
      type: 'resource',
      endpoint: `${formsetSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]}.title`,
      hint: 'turniej nie wymaga formularza, guzik będzie przekierowywał do konta użytkownika'
    },
  },
})
