import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../../_helpers/translate'
import { Title } from '../../Title'
import { Container } from '@material-ui/core'

export const HeaderType = ({ label }) => (
  <Container maxWidth="xl" style={{ padding: 0, paddingTop: '20px' }}>
    <Title>{translate(label)}</Title>
  </Container>
)

HeaderType.propTypes = {
  label: PropTypes.string.isRequired,
}
