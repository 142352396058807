import page from './page'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_footers',
  resource: {
    definition: '#ShopFooter',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    items: {
      endpoint: '/api/shop_footer_pages',
      definition: '#ShopFooterPage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony',
            },
            link: {
              label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
              hint: 'jesli wpisany przekieruje do linku',
            },
          },
        },
        page: {
          type: 'resource',
          endpoint: `${page.endpoint}?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          label: 'T_GENERAL_PAGE',
        },
        targetBlank: {
          description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
          label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        },
      },
    },
  },
}

export default schema
