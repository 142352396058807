import React, { useMemo, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Table,
  TableRow,
  TableFooter,
  TablePagination,
  AccordionDetails,
  IconButton,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { TableLoader, PaginationActions, useCollectionTableStyles } from '.'
import PropTypes from 'prop-types'
import {
  prepareColumns,
  prepareFilters,
  prepareSorters,
  addCustomFilters,
  useCollectionFetch,
} from './_helpers'
import { constants, reducer } from './_state'
import { makeStyles, withStyles } from '@material-ui/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiTypography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '../../_actions'
import { authHeader } from '../../_helpers/authHeader'
import { getApiUrl } from '../../_services/apiUrlProvider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { StatusCell } from './cells/StatusCell'
import { Delete } from '../resource/Delete'
import { Edit } from '@material-ui/icons'

const useStyles = makeStyles({
  accordionTitle: {
    //borderBottom: '1px solid #ADADAD',
    paddingBottom: '30px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  description: {
    paddingLeft: '113px',
    fontSize: '14px',
    paddingTop: '20px',
  },
  dateContainer: {
    minWidth: '110px',
    maxWidth: '110px',
    backgroundColor: '#FFFFFF',
    marginBottom: '-7px',
  },
  pagination: {
    display: 'flex',
    paddingRight: '15px',
    justifyContent: 'end',
  },
  '& .MuiTableCell-root': {
    border: 0,
  },
})

export const CollectionAccordion = ({
  // schema,
  definitionSchema,
  endpoint,
  customResourceSchema = {},
  columns,
  defaultSorters,
  defaultFilters,
  customFilters = [],
  autoWidth = true,
  isRowLinkable = false,
  perPage = null,
  perPageOptions = null,
  resetable = true,
  paginable = true,
  filterable = true,
  sortable = true,
  orderable = false,
  numerable = true,
  selectable = [],
  selectable2 = [],
  isManualOrderSorterVisible = true,
  isPaginationVisible = true,
  isRowSelectable = true,
  isRowKeyTimestamped = true,
  prepareResources = null,
  storeCollectionId = null,
  editPath = null,
  transparent = false,
  children = null,
  classes = {},
  parameters = [],
  setItemCount = null,
  reloadCollection = false,
  setReloadCollection = null,
  schemaParameters = [
    {
      name: 'order[ord]',
      in: 'query',
      type: 'string',
    },
  ],
  parentResource = null,
  nestedUuid = null,
  reloadFilters = false,
  setReloadFilters = null,
  horizontalFilterBar = false,
  ...rest
}) => {
  const history = useHistory()

  const preparedColumns = useMemo(
    () => prepareColumns(columns, definitionSchema.properties),
    [columns, definitionSchema.properties]
  )

  const preparedFilters = useMemo(
    () => prepareFilters(defaultFilters, preparedColumns, parameters),
    [defaultFilters, preparedColumns, parameters]
  )

  const filters = useMemo(
    () => addCustomFilters(customFilters, preparedFilters, parameters),
    [customFilters, preparedFilters, parameters]
  )

  const sorters = useMemo(
    () =>
      prepareSorters(
        orderable,
        defaultSorters,
        preparedColumns,
        schemaParameters
      ),
    [orderable, defaultSorters, preparedColumns, schemaParameters]
  )

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    sorters,
    perPage,
    paginable,
    prepareResources,
    storeCollectionId
  )

  const setPage = useCallback(
    (e, page) =>
      dispatch({ type: constants.SET_PAGE, payload: { page: page + 1 } }),
    [dispatch]
  )

  const setPerPage = useCallback(
    e => {
      const perPage = e.target.value
      dispatch({ type: constants.SET_PER_PAGE, payload: { perPage } })
    },
    [dispatch]
  )

  const defaultClasses = useCollectionTableStyles()

  const Accordion = withStyles({
    root: {
      marginTop: '12px',
      boxShadow: 'none',
      '&:not(:last-child)': {
        //borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        marginTop: '12px',
      },
      //padding: '0 50px',
    },
    expanded: {},
  })(MuiAccordion)

  const AccordionSummary = withStyles({
    root: {
      minHeight: 56,
      borderBottom: '1px solid #ADADAD',
      padding: 0,
      margin: '0 15px',
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px 0',
      },
      marginBottom: '0px',
    },
    expanded: {},
  })(MuiAccordionSummary)

  const Typography = withStyles({
    root: {
      //borderBottom: '1px solid rgba(0, 0, 0, .125)',
      fontSize: '24px',
      fontWeight: 700,
      width: '100%',
      paddingLeft: '3px',
    },
  })(MuiTypography)

  const accordionClasses = useStyles()

  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = panel => (event, newExpanded) => {
    if (!event?.target) {
      return
    }

    if (
      event.target.classList.contains('MuiTypography-root') ||
      event.target.getAttribute('data-toggle')
    ) {
      setExpanded(newExpanded ? panel : false)
    }

    /*
    if (
      strict &&
      ['MuiTypography-root'].some(className =>
        event.target.classList.contains(className)
      )
    ) {
      setExpanded(newExpanded ? panel : false)
    } else if (!strict) {
      setExpanded(newExpanded ? panel : false)
    }
     */
  }

  const [updateProfile, setUpdateProfile] = useState(true)

  const dispatchProfile = useDispatch()
  const profile = useSelector(state => state.profile)

  useEffect(() => {
    if (updateProfile) {
      fetch(`${getApiUrl()}/api/changelog_read`, {
        method: 'GET',
        headers: {
          ...authHeader(),
        },
      })
        .then(
          response => {
            if (!response.ok) {
              return ''
            }

            return response.json()
          },
          () => {}
        )
        .then(data => {
          profile.unreadChangelogEntries = data.unreadChangelogEntries
          dispatchProfile(profileActions.update(profile))
          setUpdateProfile(false)
        })
    }
  }, [dispatchProfile, profile, updateProfile])

  const redirectToEdit = resource => {
    return history.push(editPath.replace(':id', resource['uuid']))
  }

  /*
  const dispatchProfile = useDispatch()
  dispatchProfile(profileActions.fetch())
*/
  return (
    <div>
      <TableLoader show={!state.init && state.isFetching} />
      {!state.init &&
        !state.isFetching &&
        state.data.items.map((item, i) => {
          const date = new Date(item?.date)
          const formattedDate = `${String(date.getDate()).padStart(
            2,
            '0'
          )}.${String(date.getMonth() + 1).padStart(
            2,
            '0'
          )}.${date.getFullYear()}`
          return (
            <Accordion
              square
              expanded={expanded === `panel${i}`}
              onChange={handleChange(`panel${i}`)}
              key={`accordion-${i}`}
            >
              <AccordionSummary
                aria-controls={`panel${i}d-content`}
                id={`panel${i}d-header`}
                expandIcon={
                  <div>
                    <ExpandMoreIcon data-toggle={true} />
                  </div>
                }
              >
                <div className={clsx(accordionClasses.dateContainer)} data-toggle={true}>
                  {formattedDate}
                </div>
                <Typography
                  className={clsx(
                    defaultClasses.input,
                    classes.input,
                    accordionClasses.accordionTitle
                  )}
                  style={{ fontWeight: 700 }}
                >
                  {item?.title}
                </Typography>
                {profile?.openform && (
                  <div className={clsx(accordionClasses.buttons)}>
                    <StatusCell resource={item} tableStateDispatch={dispatch} />
                    <Delete resource={item} isIcon={true} />
                    <IconButton
                      onClick={() => {
                        redirectToEdit(item)
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className={clsx(accordionClasses.description)}
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </AccordionDetails>
            </Accordion>
          )
        })}
      {paginable && isPaginationVisible && (
        <Table>
          <TableFooter className={clsx(accordionClasses.pagination)}>
            <TableRow>
              <TablePagination
                style={{ border: 0 }}
                page={state.init ? 0 : state.data.page - 1}
                count={state.data.totalItems}
                onPageChange={setPage}
                onRowsPerPageChange={setPerPage}
                rowsPerPage={state.data.perPage}
                rowsPerPageOptions={
                  perPageOptions ||
                  process.env.REACT_APP_COLLECTION_PER_PAGE_OPTIONS.split(
                    ','
                  ).map(option => parseInt(option.trim()))
                }
                ActionsComponent={PaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </div>
  )
}

CollectionAccordion.propTypes = {
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      sortable: PropTypes.bool,
      filterable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Filter: PropTypes.elementType,
      Cell: PropTypes.elementType,
    })
  ),
  defaultSorters: PropTypes.objectOf(PropTypes.oneOf(['asc', 'desc'])),
  defaultFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  autoWidth: PropTypes.bool,
  isRowLinkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  perPage: PropTypes.number,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetable: PropTypes.bool,
  paginable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  orderable: PropTypes.bool,
  numerable: PropTypes.bool,
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      title: PropTypes.string,
      execute: PropTypes.func,
    })
  ),
  isManualOrderSorterVisible: PropTypes.bool,
  isPaginationVisible: PropTypes.bool,
  isRowSelectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRowKeyTimestamped: PropTypes.bool,
  prepareResources: PropTypes.func,
  storeCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.func,
  editPath: PropTypes.string,
  transparent: PropTypes.bool,
  setItemCount: PropTypes.func,
  reloadCollection: PropTypes.bool,
  setReloadCollection: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string,
    toolbar: PropTypes.string,
    row: PropTypes.string,
    rowAsLink: PropTypes.string,
    cell: PropTypes.string,
    emptyResults: PropTypes.string,
    manualOrderContainer: PropTypes.string,
    manualOrderContainerTitle: PropTypes.string,
    draggedRow: PropTypes.string,
    massSelectContainer: PropTypes.string,
    massSelectContainerTitle: PropTypes.string,
    massHeadColumn: PropTypes.string,
    massColumn: PropTypes.string,
  }),
  reloadFilters: PropTypes.bool,
  setReloadFilters: PropTypes.func,
  horizontalFilterBar: PropTypes.bool,
}
