import formsetSchema from '_schema/formset'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const formsetMatter = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.CONTESTFORM,
  label: labelTrans ?? 'Blok guzikiem formularza',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł formularza',
          hint: 'domyślnie trans T_MATTER_FORM_TITLE'
        },
        linkTitle:{
          type: 'string',
          label: 'Tytuł guzika',
          hint: 'guzik otwierający formularz'
        },
      },
    },
    formset: {
      type: 'resource',
      endpoint: `${formsetSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]}.title`
    },
  },
})
