import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/shopReturn'
import routes from './routes'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const [productModalResponse, setProductModalResponse] = useState(null)
  const defaultData = { parent: iri }

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width={600}
      >
        <div style={{ marginTop: '15px', marginBottom: '35px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.products.endpoint}?pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.products.properties}
            definitionSchema={
              definitions[schema.subresources.products.definition]
            }
            headerTitle={translate('Produkty')}
            panelTitle={translate('Nazwa produktu')}
            titleAccessor={`titleList`}
            statable={false}
            key={iri}
            paginable={false}
            orderable={false}
            modalResponse={productModalResponse}
            setModalResponse={setProductModalResponse}
            hideAddButton={true}
          />
          <AddModal
            title={translate('Dodaj produkt')}
            definitionSchema={
              definitions[schema.subresources.products.definition]
            }
            itemProperties={schema.subresources.products.properties}
            endpoint={schema.subresources.products.endpoint}
            itemSchema={schema}
            setModalResponse={setProductModalResponse}
            defaultData={defaultData}
          />
        </div>
      </Form>
    </div>
  )
}
