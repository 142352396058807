import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/packageLogotype'

const basePath = '/package_logotypes'

const routes = () =>
  addRoutesConfig(
    {
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/items/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/items/:id`,
        exact: true,
      },
    },
    {
      title: 'Logotype package items',
      resourceAccess: parentSchema.access,
      indexPath: `${basePath}/:id/show`,
      parent: basePath,
    }
  )

export default routes
