import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const contact2us = () => ({
  name: BLOCK_TYPES_IRI.CONTACT2US,
  label: 'Blok kontaktowy (email + telefon)',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
        },
        sectionTitle: {
          description: 'Podtytuł',
          type: 'string',
        },
        columnTitle1: {
          description: 'Email',
          type: 'string',
          validate: ['email']
        },
        text: {
          description: 'Opis krótki pod emailem',
          type: 'textarea',
        },
        columnTitle2: {
          description: 'Telefon',
          type: 'string',
        },
        text2: {
          description: 'Opis krótki pod telefonem',
          type: 'textarea',
        },
      },
    },
  },
})
