// import photoSchema from './photo'
// import { LANGS_IRI } from '../_lib/langs'
// import fileSchema from './media'

const schema = {
  endpoint: '/api/tourneys',
  clone_endpoint: '/api/tourney/clone',
  access: 'tourney',
  resource: {
    definition: '#Tourney',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          // shortLead: {
          //   type: 'textarea',
          //   description: 'Adres/opis kótki na kafelku',
          //   label: 'Adres/opis kótki na kafelku',
          // },
          // description: {
          //   description: 'Opis',
          //   label: 'Description',
          //   type: 'tinymce',
          // },
          // metaTitle: {},
          // metaDescription: {
          //   type: 'textarea',
          // },
          // metaKeywords: {
          //   type: 'textarea',
          // },
          // link: {
          //   label:'Link do strony www'
          // },
        },
      },

      dateFrom: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'datetime',
        validate: ['required'],
      },

      // regulamin: {
      //   type: 'file',
      //   description: 'Regulamin',
      //   endpoint: fileSchema.endpoint,
      //   accept: 'application/pdf',
      //   validate: ['maxSize'],
      // },

      // adminCanEdit: {},

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },

}

export default schema
