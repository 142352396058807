import { prepareColumn } from './prepareColumn'

export const prepareColumns = (columns, properties) =>
  columns
    .filter(
      column =>
        column.hidden === false ||
        (column.hidden === undefined &&
          (column.excluded === false || column.excluded === undefined))
    )
    .map(column => prepareColumn(column, properties))
