import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/tourneyUser'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'fullName',
        header: translate('Imię i nazwisko'),
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'email',
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '45%',
      },
      {
        columnId: 'phone',
        header: translate('Telefon'),
        accessor: `phone`,
        filterable: true,
        sortable: true,
        width: '45%',
      },
      {
        columnId: 'phone',
        ...operations(`email`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj użytkownika')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Lista użytkowników')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'companyName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[companyName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[phone]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
