import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/lesson'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'translations.title',
        header: translate('Temat'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        filterName: 'translations.title',
        sortable: true,
        sorterName: 'translations.title',
        width: '15%',
      },
      {
        columnId: 'translations.type',
        header: translate('Forma zajęć'),
        accessor: `translations.${LANGS_IRI.PL}.type`,
        filterable: true,
        filterName: 'translations.type',
        sortable: true,
        sorterName: 'translations.type',
        width: '10%',
      },
      {
        columnId: 'translations.place',
        header: translate('Miejsce'),
        accessor: `translations.${LANGS_IRI.PL}.place`,
        filterable: true,
        filterName: 'translations.place',
        sortable: true,
        sorterName: 'translations.place',
        width: '10%',
      },
      {
        columnId: 'translations.time',
        header: translate('Czas'),
        accessor: `translations.${LANGS_IRI.PL}.time`,
        filterable: true,
        filterName: 'translations.time',
        sortable: true,
        sorterName: 'translations.time',
        width: '10%',
      },
      {
        columnId: 'translations.timeOfStart',
        header: translate('Rozpoczęcie'),
        accessor: `translations.${LANGS_IRI.PL}.timeOfStart`,
        filterable: true,
        filterName: 'translations.timeOfStart',
        sortable: true,
        sorterName: 'translations.timeOfStart',
        width: '10%',
      },
      {
        columnId: 'operations',
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_LESSON_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
      >
        {translate('T_MODULE_LESSON')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.place',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.type',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.timeOfStart',
            in: 'query',
            type: 'string',
          },
          {
            name: 'translations.time',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[translations.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.place]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.type]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.timeOfStart]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.time]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
