import React from 'react'
import { Form } from 'pages'
import schema from '_schema/tourneyPackage'
import routes from '../routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().packageIndex.path.replace(
        ':pid',
        match.params.pid
      )}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`nativeTitle`}
      // SidebarComponent={SidebarNotStatable}
      width="100%"
    />
  )
}
