import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'pages'
import schema from '_schema/seaEyeHistoryEntry'
import routes from './routes'
import { commonConstants } from '_constants'

export const New = ({ schema: definitions }) => {
  const form = useSelector(state => state.common.seaEyeHistoryEntryForm)

  const [resourceSchema, setResourceSchema] = useState({
    form: null,
    type: null,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (resourceSchema.form) {
      return
    }

    setResourceSchema({
      form: form
        ? {
            ...schema.resource,
            properties: {
              ...schema.resource.properties,
              fiacres: {
                ...schema.resource.properties.fiacres,
                defaultValue: form.fiacres.defaultValue,
              },
              horses: {
                ...schema.resource.properties.horses,
                defaultValue: form.horses.defaultValue,
              },
              occuredAt: {
                ...schema.resource.properties.occuredAt,
                defaultValue: form.occuredAt.defaultValue,
              },
              description: {
                ...schema.resource.properties.description,
                defaultValue: form.description.defaultValue,
              },
              ...(form.type === '/api/sea_eye_history_entry_types/SELL'
                ? {
                    newOwners: {
                      ...schema.resource.properties.fiacres,
                      description: 'Nowi właściciele',
                      validate: ['required'],
                    },
                  }
                : {}),
            },
          }
        : schema.resource,
      type: form?.type || null,
    })

    dispatch({
      type: commonConstants.RESET_CURRENT_SEA_EYE_HISTORY_ENTRY_FORM,
    })
  }, [resourceSchema, form, setResourceSchema, dispatch])

  return resourceSchema.form ? (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={resourceSchema.form}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      defaultData={{
        type: resourceSchema.type,
      }}
      width={600}
    />
  ) : null
}
