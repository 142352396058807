import React from 'react'
import { Form } from 'pages'
import { translate } from '_helpers/translate'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/packageFaq'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.item.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.item.properties}
        definitionSchema={definitions[schema.subresources.item.definition]}
        headerTitle={translate('T_MODULE_PACKAGE_FAQ_ITEMS_HEADER_TITLE')}
        expandTitle={translate('T_MODULE_PACKAGE_FAQ_ITEMS_EXPAND_TITLE')}
        panelTitle={translate('T_GENERAL_FAQ')}
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        statable={true}
      />
    </Form>
  )
}
