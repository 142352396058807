import React from 'react'
import { Form } from 'pages'
import { translate } from '_helpers/translate'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/packageFile'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getApiUrl } from '../../_services/apiUrlProvider'
import { notification } from '../../_helpers/notification'
import { authHeader } from '../../_helpers/authHeader'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  table: {
    minWidth: 400,
  },
}))

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [open, setOpen] = React.useState(false)
  const [isFetchingRelations, setIsFetchingRelations] = React.useState(false)
  const [relations, setRelations] = React.useState([])
  const classes = useStyles()

  const relationTypes = {
    'App\\Entity\\Matter': 'Załatw sprawę',
    'App\\Entity\\TourismNoticeArticle': 'Komunikat turystyczny',
    'App\\Entity\\Trail': 'Trasa',
    'App\\Entity\\Page': 'Struktura stron',
    'App\\Entity\\News': 'Aktualność',
    'App\\Entity\\Event': 'Wydarzenie',
    'App\\Entity\\Contest': 'Konkurs',
    'App\\Entity\\Volunteering': 'Wolontariat',
    'App\\Entity\\VolunteeringEvent': 'Wydarzenie wolontariatu',
  }

  const handleClickOpen = () => {
    setOpen(true)
    if (relations.length === 0) {
      fetchRelations()
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const fetchRelations = () => {
    if (isFetchingRelations) {
      return
    }
    setIsFetchingRelations(true)
    fetch(
      `${getApiUrl()}/api/package_files_relations?uuid=${match.params.id}`,
      {
        method: 'GET',
        headers: {
          ...authHeader(),
        },
      }
    )
      .then(
        response => {
          if (!response.ok) {
            notification(
              'error',
              'T_FRONT_PREVIEW_ERROR_TEXT',
              'T_FRONT_PREVIEW_ERROR_TITLE'
            )
            setIsFetchingRelations(false)
            return []
          }

          return response.json()
        },
        () => {}
      )
      .then(data => {
        setIsFetchingRelations(false)
        if (data.length > 0) {
          setRelations(data)
        }
      })
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginTop: 20, marginLeft: 32 }}
      >
        Miejsca użycia
      </Button>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        width={600}
      >
        <EmbeddedCollection
          endpoint={`${schema.subresources.item.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.item.properties}
          definitionSchema={definitions[schema.subresources.item.definition]}
          headerTitle={translate('T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE')}
          expandTitle={translate('T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE')}
          panelTitle={translate('T_GENERAL_FILE')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
        />
      </Form>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Miejsca użycia</DialogTitle>
        <DialogContent dividers={true}>
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {isFetchingRelations ? (
              <div className={classes.root}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {relations.length > 0 &&
                      relations.map(row => (
                        <TableRow key={row['@id']}>
                          <TableCell component="th" scope="row">
                            {row.title} [{relationTypes[row['class']]}]
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!isFetchingRelations && relations.length === 0 && (
              <div className={classes.root}>Brak</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
