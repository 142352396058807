import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/shopConfig'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { LANGS_IRI } from '../../_lib/langs'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`

  const { match } = rest

  const [vatModalResponse, setVatModalResponse] = useState(null)
  const [alertModalResponse, setAlertModalResponse] = useState(null)
  const defaultData = { parent: iri }

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width="100%"
      >
        <EmbeddedCollection
          endpoint={`${schema.subresources.categories.endpoint}?pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.categories.properties}
          definitionSchema={
            definitions[schema.subresources.categories.definition]
          }
          headerTitle={translate('Podstrony filtrów')}
          panelTitle={translate('Podstrona')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={false}
          key={iri}
          paginable={false}
          orderable={false}
          addButtonTitle={'Dodaj podstronę'}
          addButtonSmall={true}
        />
        <div style={{ marginTop: '15px', marginBottom: '35px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.vat.endpoint}?pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.vat.properties}
            definitionSchema={definitions[schema.subresources.vat.definition]}
            headerTitle={translate('Stawki vat')}
            panelTitle={translate('Stawka')}
            titleAccessor={`title`}
            statable={false}
            key={iri}
            paginable={false}
            orderable={false}
            modalResponse={vatModalResponse}
            setModalResponse={setVatModalResponse}
            hideAddButton={true}
          />
          <AddModal
            title={translate('Dodaj stawkę vat')}
            definitionSchema={definitions[schema.subresources.vat.definition]}
            itemProperties={schema.subresources.vat.properties}
            endpoint={schema.subresources.vat.endpoint}
            itemSchema={schema}
            setModalResponse={setVatModalResponse}
            defaultData={defaultData}
          />
        </div>
        <div style={{ marginTop: '15px', marginBottom: '35px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.alerts.endpoint}?pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.alerts.properties}
            definitionSchema={
              definitions[schema.subresources.alerts.definition]
            }
            headerTitle={translate('Komunikaty')}
            panelTitle={translate('Komunikat')}
            titleAccessor={`cmsListTitle`}
            statable={true}
            key={iri}
            paginable={false}
            orderable={false}
            modalResponse={alertModalResponse}
            setModalResponse={setAlertModalResponse}
            hideAddButton={true}
          />
          <AddModal
            title={translate('Dodaj komunikat')}
            definitionSchema={
              definitions[schema.subresources.alerts.definition]
            }
            itemProperties={schema.subresources.alerts.properties}
            endpoint={schema.subresources.alerts.endpoint}
            itemSchema={schema}
            setModalResponse={setAlertModalResponse}
            defaultData={defaultData}
          />
        </div>
      </Form>
    </div>
  )
}
