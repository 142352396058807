import photoSchema from './photo'
import categories from './accommodationCategory'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/accommodations',
  clone_endpoint: '/api/accommodation/clone',
  access: 'accommodation',
  resource: {
    definition: '#Accommodation',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortLead: {
            type: 'textarea',
            description: 'Adres/opis kótki na kafelku',
            label: 'Adres/opis kótki na kafelku',
          },
          // description: {
          //   description: 'Opis',
          //   label: 'Description',
          //   type: 'tinymce',
          // },
          // metaTitle: {},
          // metaDescription: {
          //   type: 'textarea',
          // },
          // metaKeywords: {
          //   type: 'textarea',
          // },
          link: {
            label: 'Link do strony www',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      email: {
        type: 'string',
        description: 'Email',
      },
      phone: {
        type: 'string',
        description: 'Telefon',
      },
      onecategory: {
        type: 'resource',
        endpoint: `${categories.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_TRAIL_SUGGESTED_CATEGORY',
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/accommodation_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },

      adminCanEdit: {},
      popularCount: {
        type: 'number',
        description: 'Popularność',
        defaultValue: 0,
      },
      userUuid: {
        type: 'userLink',
        description: 'Przypisany użytkownik',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
