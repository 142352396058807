import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const tourismInformationNotices = () => ({
  name: BLOCK_TYPES_IRI.TOURISM_INFORMATION_NOTICES,
  label: 'T_GENERAL_BLOCK_TYPE_TOURISM_INFORMATION_NOTICES',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    tourismNoticeType: {
      type: 'resource',
      endpoint: '/api/tourism_notice_types',
      titleAccessor: 'trans',
      transTitle: true,
      description: 'T_GENERAL_BLOCK_TYPE_TOURISM_INFORMATION_NOTICES_TYPE',
    },
  },
})
