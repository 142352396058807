import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'T_GENERAL_ALT',
          type: 'string',
        },
        alt2: {
          description: 'T_GENERAL_ALT2',
          type: 'string',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?property[]=media`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    media2: {
      type: 'image',
      description: 'T_GENERAL_PHOTO2',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?property[]=media2`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt2: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
