import { tokenVerify } from './tokenVerify'
import { store } from '_store'

export const getToken = () => {
  const token = store.getState().auth.data?.token

  if (tokenVerify(token)) {
    return token
  }

  return null
}
