import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { Chip } from '@material-ui/core'

export const TitleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable && !resource?.defaultVariant) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const value = prop(resource, accessor)

  return (
    <div onClick={onClick}>
      {resource?.defaultVariant ? <Chip label={value} /> : value}
    </div>
  )
}

TitleCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
