export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema,
  currentHostnameIri
) => {
  let modifiedProperties = properties

  if (isOpenform) {
    modifiedProperties = {
      ...properties,
      maptouristKey: {
        type: 'string',
        description: {
          text: '[OF] Klucz do mapy turystycznej',
          color: '#cc0000',
        },
      },
      maptouristSlug: {
        type: 'string',
        description: {
          text: '[OF] Slug do mapy turystycznej',
          color: '#cc0000',
        },
      },
      emailSmtpAddress: {
        type: 'string',
        description: { text: '[OF][SMTP] Adres serwera', color: '#cc0000' },
      },
      emailSmtpPort: {
        type: 'number',
        description: { text: '[OF][SMTP] Port serwera', color: '#cc0000' },
      },
      emailSmtpUser: {
        type: 'string',
        description: { text: '[OF][SMTP] Użytkownik', color: '#cc0000' },
      },
      emailSmtpPassword: {
        type: 'string',
        description: { text: '[OF][SMTP] Hasło', color: '#cc0000' },
      },
    }
  }

  const newsletterAllowed = [
    '/api/hostname_types/PPN_GATE',
    '/api/hostname_types/TATRZANSKI',
  ]

  if (!newsletterAllowed.includes(currentHostnameIri)) {
    delete modifiedProperties.freshApiKey
    delete modifiedProperties.freshApiSecret
    delete modifiedProperties.freshApiList
  }

  return modifiedProperties
}
