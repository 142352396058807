import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const MediaType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  setValue,
  setAdditional,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value
    setValue(name, value)
    setAdditional({ media: value })
  }

  useEffect(() => {
    if (!initialValue) {
      setValue(name, 'photo')
    }

    setAdditional({ media: initialValue || 'photo' })
  }, [setValue, name, initialValue, setAdditional])

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      disabled={disabled}
    >
      <InputLabel htmlFor={id}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Select
        id={id}
        name={name}
        onChange={handleChange}
        value={value || 'photo'}
      >
        <MenuItem value="photo" key="photo">
          {translate('T_GENERAL_MEDIA_PHOTO')}
        </MenuItem>
        <MenuItem value="youtube" key="youtube">
          {translate('T_GENERAL_MEDIA_YOUTUBE')}
        </MenuItem>
      </Select>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue &&
            translate(`T_GENERAL_MEDIA_${compareValue.toUpperCase()}`)}
        </div>
      )}
      {hint && <FormHelperText>{translate(hint)}</FormHelperText>}
    </FormControl>
  )
}

MediaType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
