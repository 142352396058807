import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/volunteeringSubmission'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { DateCell } from '../../components/table/cells/DateCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'parent.translations.title',
        header: translate('T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION'),
        accessor: `parent.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        sortable: true,
        filterName: 'parent.translations.title',
        width: '15%',
      },
      {
        columnId: 'email',
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'firstName',
        header: translate('T_GENERAL_FIRST_NAME'),
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'lastName',
        header: translate('T_GENERAL_SURNAME'),
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'phone',
        header: translate('T_GENERAL_PHONE_NUMBER'),
        accessor: `phone`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'parent.dateFrom',
        header: translate(
          'T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION_DATE_FROM'
        ),
        accessor: `parent.dateFrom`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DateCell,
      },
      {
        columnId: 'parent.dateTo',
        header: translate(
          'T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION_DATE_TO'
        ),
        accessor: `parent.dateTo`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DateCell,
      },
      {
        columnId: 'status',
        header: translate('Status'),
        accessor: `status.trans`,
        translateAccessor: true,
        transTitle: true,
        width: '10%',
      },
      {
        columnId: 'operations',
        ...operations(`email`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_VOLUNTEERING_SUBMISSION_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        {translate('T_MODULE_VOLUNTEERING_SUBMISSION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'parent.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'pesel',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.dateFrom',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.dateTo',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[firstName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[lastName]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        isManualOrderSorterVisible={false}
      />
    </Paper>
  )
}
