import React from 'react'
import { Form } from 'pages'
import { EmbeddedCollection } from 'components/embedded'
import { translate } from '_helpers/translate'
import schema from '_schema/seaEyeHistoryEntry'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={{
        ...schema.resource,
        properties: {
          ...schema.resource.properties,
          fiacres: {
            ...schema.resource.properties.fiacres,
            disabled: true,
          },
          horses: {
            ...schema.resource.properties.horses,
            disabled: true,
          },
        },
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.files.endpoint}?pagination=false&iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.files.properties}
        definitionSchema={definitions[schema.subresources.files.definition]}
        headerTitle={translate('Files')}
        titleAccessor="title"
        statable={false}
        key={iri}
        paginable={false}
      />
      <br />
      <br />
      <br />
    </Form>
  )
}
