import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const miginfo = () => ({
  name: BLOCK_TYPES_IRI.MIGINFO,
  label: 'Blok z językiem migowym',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
          hint: 'Domyślnie tytuł jak w konfiguracji'
        },
        text: {
          type: 'textarea',
          label: 'Krótki tekst',
          hint: 'Domyślnie tekst jak w konfiguracji'
        },

      },
    },

  },
})
