import React from 'react'
import PropTypes from 'prop-types'
import { Status } from 'components/resource/Status'
import { constants } from 'components/table/_state'

export const StatusCell = ({
  resource,
  accessor = 'stat',
  disabled = false,
  tableStateDispatch,
  customEndpoint = '',
}) => {
  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  return (
    <Status
      resource={resource}
      accessor={accessor}
      disabled={disabled}
      onSuccess={onSuccess}
      customEndpoint={customEndpoint}
    />
  )
}

StatusCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  disabled: PropTypes.bool,
  tableStateDispatch: PropTypes.func.isRequired,
  customEndpoint: PropTypes.string,
}
