import adminGroupSchema from './adminGroup'

const schema = {
  endpoint: '/api/admins',
  access: 'admin',
  resource: {
    definition: '#Admin',
    properties: {
      fullName: {
        validate: ['required', 'length(3)'],
      },
      email: {
        validate: ['required', 'email'],
      },
      rawPassword: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['required', 'password'],
      },
      group: {
        type: 'resource',
        endpoint: `${adminGroupSchema.endpoint}?order[createdAt]=desc&pagination=false`,
        titleAccessor: 'title',
      },
      /*
      allowedHostnames: {
        type: 'multipleResource',
        endpoint: `/api/hostname_types?pagination=false`,
        titleAccessor: 'trans',
        label: 'T_MODULE_ADMIN_HOSTNAMES_SELECT',
        transTitle: true,
      },
       */
    },
  },
}

export default schema
