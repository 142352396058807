import discountCodeSchema from './shopDiscountCode'

const schema = {
  endpoint: '/api/shop_orders',
  endpointCustomer: '/api/shop_orders_customer',
  resource: {
    definition: '#ShopOrder',
    properties: {
      status: {
        type: 'resource',
        endpoint: `/api/shop_order_statuses?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        initialValue: {
          value: '/api/shop_order_statuses/WORKING',
          title: 'Roboczy',
        },
        description: 'Status zamówienia',
        disabled: true,
      },
      adminNotes: {
        type: 'textarea',
        description:
          'Uwagi administratora (widoczne tylko przez administratora)',
      },
      firstName: {
        description: 'Imię',
      },
      lastName: {
        description: 'Nazwisko',
      },
      companyName: {
        description: 'Nazwa firmy',
      },
      email: {
        description: 'Email',
      },
      phone: {
        description: 'Numer telefonu',
      },
      street: {
        description: 'Ulica',
      },
      houseNumber: {
        description: 'Numer domu',
      },
      apartmentNumber: {
        description: 'Numer mieszkania',
      },
      postalCode: {
        description: 'Kod pocztowy',
      },
      city: {
        description: 'Miasto',
      },
      province: {
        type: 'resource',
        endpoint: `/api/provinces?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        description: 'Województwo',
        disabled: true,
      },
      userNotes: {
        type: 'textarea',
        description: 'Uwagi kupującego',
      },
      fvCompanyName: {
        description: '[FAKTURA] Nazwa firmy',
      },
      fvNip: {
        description: '[FAKTURA] Numer NIP',
      },
      fvAddress: {
        type: 'textarea',
        description: '[FAKTURA] Adres',
      },
      discountCodeHeader: {
        type: 'header',
        description: 'Kod promocyjny',
      },
      discountCode: {
        type: 'resource',
        endpoint: `${discountCodeSchema.endpoint}?pagination=false`,
        titleAccessor: `code`,
        description: 'Kod promocyjny',
      },
    },
  },
}

export default schema
