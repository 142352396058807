import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Accordion,
  TableBody,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'components/table'
import { Form } from 'components/form'
import { ContextualHint } from 'components/ContextualHint'
import { BodySkeleton, TableLoader } from 'components/table'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { Add } from './components/Add'
import { useEmbeddedCollectionFetch } from 'components/embedded/_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from 'components/embedded/_helpers/performOrderChange'
import { applyProperties } from '_helpers/applyProperties'
import { prop } from '_helpers/prop'
import {
  useBlockCollectionStyles,
  // blockCollectionPropTypes,
} from 'components/block'
import { translate } from '_helpers/translate'
import { constants, reducer } from 'components/embedded/_state'
import { boolean } from '_helpers/boolean'
import { LANGS_IRI } from '_lib/langs'
import { useSelector } from 'react-redux'

export const HBlockCollection = ({
  endpoint,
  pid,
  parentIri,
  definitionSchema,
  types,
  headerTitle = null,
  expandTitle = translate('sekcje'),
  titleAccessor = null,
  statable = false,
  statAccessor = 'stat',
  contextualHint = null,
  disabled = false,
  classes = { select: { color: '#cc0' } },
}) => {
  const reload = useSelector(state => state.common.reload)
  const profile = useSelector(state => state.profile)
  const [state, dispatch] = useEmbeddedCollectionFetch(
    reducer,
    `${endpoint}?order[ord]=asc`,
    pid,
    reload,
    parentIri
  )

  const defaultClasses = {
    ...useBlockCollectionStyles(),
    addContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 8,
      '& > *': {
        marginRight: 15,
      },
      color: '#000',
    },
  }

  const handleDelete = resource => {
    if (disabled) {
      return
    }

    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex || disabled) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch, disabled]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const TableComponent = profile.openform
    ? ManuallyOrderableTableBody
    : TableBody
  const TableRowComponent = profile.openform
    ? ManuallyOrderableTableRow
    : TableRow

  return (
    <>
      <div className={clsx(defaultClasses.header, classes.header)}>
        <div>{headerTitle}</div>
        <div
          className={clsx(defaultClasses.massExpand, classes.massExpand)}
          onClick={handleExpand}
        >
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer
        className={clsx(
          defaultClasses.collectionContainer,
          classes.collectionContainer
        )}
      >
        <TableLoader show={!state.init && state.isFetching} align="center" />

        <Table size="small">
          <TableComponent
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const type = types.find(type => type.name === item.type['@id'])

                const properties = applyProperties(
                  type.properties,
                  definitionSchema.properties,
                  'missing'
                )

                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                const handleSyncUpdatedAt = resource => {
                  dispatch({
                    type: constants.SYNC_UPDATED_AT,
                    payload: {
                      iri: resource['@id'],
                      updatedAt: resource.updatedAt,
                    },
                  })
                }

                return (
                  <TableRowComponent index={i} key={item.uuid}>
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell, classes.cell)}
                      style={{ width: '100%', border: 'none' }}
                    >
                      <Accordion
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <AccordionSummary
                          classes={{
                            content: clsx(
                              defaultClasses.expansionPanelSummary,
                              classes.expansionPanelSummary
                            ),
                          }}
                        >
                          {!disabled && profile.openform ? (
                            <><span style={{ color: '#c00' }}>[</span><ManualOrderDragHandle /><span style={{ color: '#c00' }}>]</span></>
                          ) : null}
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.blockTitle,
                              classes.blockTitle
                            )}
                          >
                            <span>{translate(type.label)}</span>
                            {titleAccessor
                              ? prop(item, titleAccessor)
                                ? `: ${prop(item, titleAccessor)}`
                                : `: ${translate('T_GENERAL_MISSING_TITLE')}`
                              : item.translations?.[LANGS_IRI.PL]?.title
                              ? `: ${item.translations?.[LANGS_IRI.PL]?.title}`
                              : `: ${translate('T_GENERAL_MISSING_TITLE')}`}
                          </Typography>
                          {type.contextualHint && (
                            <div
                              className={clsx(
                                defaultClasses.blockIcon,
                                classes.blockIcon
                              )}
                            >
                              <ContextualHint name={type.contextualHint} />
                            </div>
                          )}
                          {statable && (
                            <div
                              className={clsx(
                                defaultClasses.blockIcon,
                                classes.blockIcon
                              )}
                            >
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={disabled || state.isFetching}
                                handleSyncUpdatedAt={handleSyncUpdatedAt}
                              />
                            </div>
                          )}
                          <div
                            className={clsx(
                              defaultClasses.blockIcon,
                              classes.blockIcon
                            )}
                          >
                            {profile.openform && (
                              <>
                                <span style={{ color: '#c00' }}>[</span>
                                <Delete
                                  resource={item}
                                  accessor={
                                    titleAccessor ||
                                    `translations.${LANGS_IRI.PL}.title`
                                  }
                                  disabled={disabled || state.isFetching}
                                  isIcon={true}
                                  onSuccess={handleDelete}
                                />
                                <span style={{ color: '#c00' }}>]</span>
                              </>
                            )}
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.expandIcon,
                              classes.expandIcon
                            )}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{
                            root: clsx(
                              defaultClasses.itemContainer,
                              classes.itemContainer
                            ),
                          }}
                        >
                          <Form
                            readOnly={disabled}
                            url={item['@id']}
                            method="PUT"
                            properties={properties}
                            resource={item}
                            width={'100%'}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            key={`${item.uuid}-${i}`}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRowComponent>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </TableComponent>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer, classes.select)}>
        {profile.openform && (
          <Add
            types={types}
            endpoint={endpoint}
            parent={parentIri}
            dispatch={dispatch}
            disabled={disabled || state.isFetching}
          />
        )}
        {contextualHint && (
          <div
            className={clsx(
              defaultClasses.contextualHint,
              classes.contextualHint
            )}
          >
            <ContextualHint name={contextualHint} />
          </div>
        )}
      </div>
    </>
  )
}

// HBlockCollection.propTypes = blockCollectionPropTypes
