const schema = {
  endpoint: '/api/lesson_submissions',
  resource: {
    definition: '#LessonSubmission',
    properties: {
      school: {},
      contactName: {},
      contactEmail: {},
      contactPhone: {},
      accept: {},
      numberOfPeople: {},
    },
  },
}

export default schema
