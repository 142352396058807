import { fetchData } from '_helpers/fetchData'
import schema from '_schema/profile'

const get = () =>
  fetchData(schema.profile.get).then(
    response => ({ response }),
    error => ({ error })
  )

export const profileService = {
  get,
}
