const schema = {
  endpoint: '/api/changelogs',
  resource: {
    definition: '#Changelog',
    properties: {
      title: {
        validate: ['required'],
      },
      date: {
        type: 'date',
        validate: ['required'],
      },
      description: {
        type: 'tinymce',
        validate: ['required'],
      },
    },
  },
}

export default schema
