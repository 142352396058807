const schema = {
  endpoint: '/api/volunteering_categories',
  clone_endpoint: '/api/volunteering_categories/clone',
  resource: {
    definition: '#VolunteeringCategory',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
