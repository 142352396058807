import React from 'react'
import schema from '../../../_schema/bipPageArticle'
import routes from '../routes'
import { Form } from '../../Form'
import { LANGS_IRI } from '../../../_lib/langs'
import { SidebarNotStatable } from '../../../components/resource/sidebar'
import { BlockCollection } from '../../../components/block'

export const VersionEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const collectionPath = function(resource) {
    return routes().articles.path.replace(':id', resource.parent?.uuid)
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
