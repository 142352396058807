import React from 'react'
import { Form } from 'pages'
import schema from '_schema/guideFee'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      modifyProperties={properties => {
        delete properties.parent

        return properties
      }}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarDisableStat={() => true}
      width={600}
    />
  )
}
