import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const banner1 = (photoEndpoint, thumbEndpoint, labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.BANNER1,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_BANNER1',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
        },
        shortLead: {
          type: 'string',
          description: 'Subtitle',
        },
        text: {
          type: 'textarea',
          label: 'Krótki opis',
          description: 'Krótki opis',
        },
        link: {
          type: 'string',
          description: 'Link',
        },
        linkTitle: {
          type: 'string',
          description: 'T_GENERAL_LINK_TITLE',
        },
        alt: {
          type: 'string',
          description: 'T_GENERAL_ALT',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=banner`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            title: media.translations[LANGS_IRI.PL].title,
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_VIDEO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      accept: 'video/*',
      restrictManagerMimeType: 'video/',
    },
  },
})
