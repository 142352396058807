import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const banner2 = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.BANNER2,
  label: 'T_GENERAL_BLOCK_TYPE_BANNER2',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
        },
        link: {
          type: 'string',
          description: 'T_GENERAL_LINK',
        },
        link2: {
          type: 'string',
          description: 'T_GENERAL_LINK2',
        },
        linkTitle: {
          type: 'string',
          description: 'T_GENERAL_LINK_TITLE',
        },
        linkTitle2: {
          type: 'string',
          description: 'T_GENERAL_LINK_TITLE2',
        },
        alt: {
          type: 'string',
          description: 'T_GENERAL_ALT',
        },
        alt2: {
          type: 'string',
          description: 'T_GENERAL_ALT2',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?property[]=media`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
    media2: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?property[]=media2`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt2: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
