const schema = {
  endpoint: '/api/bip_entities',
  resource: {
    definition: '#BipEntity',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          address:{
            type: 'textarea',
            label: 'Adres/opis opcjonalny w dymku na mapie'
          }
        },
      },
      map: {
        type: 'streetmap',
        label: 'Mapa',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
