import React from 'react'

export const nl2br = text =>
  text
    ? text.split('\n').map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ))
    : null
