import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { SystemUpdateAlt } from '@material-ui/icons'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import { MultipleResourceType } from '../../../components/form/fields/MultipleResourceType'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const ExportModal = ({
  title,
  itemProperties,
  itemSchema,
  definitionSchema,
  endpoint,
  defaultData = {},
  classes = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [inputError, setInputError] = useState(false)

  const [isFetching, setIsFetching] = useState(false)

  const [selectValue, setSelectValue] = useState(null)

  const [selectValue2, setSelectValue2] = useState(null)

  const numberRef = useRef(null)


  const defaultClasses = useStyles()

  let xTitle = null
  let responseError = null

  const handleExportClick = () => {
    if (!Number(numberRef.current.value)) {
      setInputError(true)
      return
    }
    setInputError(false)

    setIsFetching(true)
    fetch(`${getApiUrl()}/api/shop_elektroniczny_nadawca_subscription_xls`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        number: Number(numberRef.current.value),
        status: selectValue,
        print: selectValue2,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          responseError = response.json()
          throw new Error('Error')
        }
        xTitle = response.headers.get('x-title')
        setIsFetching(false)
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xTitle ?? 'elektroniczny_nadawca.xls')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        setIsFetching(false)

        notification(
          'success',
          'T_ELEKTRONICZNY_NADAWCA_GENERATE_XLS_SUCCESS',
          'T_FORM_SUCCESS'
        )
      })
      .catch(e => {
        notification(
          'error',
          'Nie można znaleźć adresów dla wskazanego numeru prenumeraty'
        )
        setIsFetching(false)

        responseError.then(data => {
          notification('error', data.error)
        })
      })
  }

  const setError = useCallback((name, error) => {
  }, [])

  const setValue = useCallback((name, value, setRenderError = true) => {
    setSelectValue(value)
  }, [])

  const setValue2 = useCallback((name, value, setRenderError = true) => {
    setSelectValue2(value)
  }, [])

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<SystemUpdateAlt />}
        onClick={handleDialogToggle}
        disabled={isFetching}
      >
        Eksportuj
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          <div>
            <TextField
              id="standard-basic"
              label="Numer prenumeraty"
              inputRef={numberRef}
              error={inputError}
              style={{width: '100%'}}
            />
          </div>
          <div style={{marginTop: '20px'}}>
            <MultipleResourceType
              setError={setError}
              setValue={setValue}
              name={'status'}
              disabled={false}
              renderError={false}
              endpoint={'/api/shop_subscription_statuses?pagination=false'}
              label={'Wybierz statusy'}
              transTitle={true}
              titleAccessor={'trans'}
              fullWidth={true}
              value={selectValue}
              initialValue={[
                {
                  '@id': '/api/shop_subscription_statuses/REALIZED',
                  '@type': 'ShopSubscriptionStatus',
                  value: 14,
                  trans: 'T_SHOP_MODULE_ORDER_STATUS_REALIZED',
                },
                {
                  '@id':
                    '/api/shop_subscription_statuses/SUBSCRIPTION_IN_TRANSIT',
                  '@type': 'ShopSubscriptionStatus',
                  value: 7,
                  trans: 'T_SHOP_MODULE_ORDER_STATUS_SUBSCRIPTION_IN_TRANSIT',
                },
              ]}
            ></MultipleResourceType>
          </div>
          <div style={{ marginTop: '20px' }}>
            <MultipleResourceType
              setError={setError}
              setValue={setValue2}
              name={'print'}
              disabled={false}
              renderError={false}
              endpoint={'/api/shop_subscription_senders?pagination=false'}
              label={'Wybierz drukarnie'}
              transTitle={true}
              titleAccessor={'trans'}
              fullWidth={true}
              value={selectValue2}
            ></MultipleResourceType>
          </div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SystemUpdateAlt/>}
            onClick={handleExportClick}
            style={{marginTop: '20px', width: '100%'}}
            disabled={isFetching}
          >
            Eksportuj
          </Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  )
}

ExportModal.propTypes = {
  title: PropTypes.string,
  itemProperties: PropTypes.object,
  itemSchema: PropTypes.object,
  setModalResponse: PropTypes.func,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
}
