
import React, { useState, useEffect, useCallback } from 'react'
// import { BlankForm } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { NotFound } from 'pages'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Button from '@material-ui/core/Button'
import { getApiUrl } from '_services/apiUrlProvider'

import clsx from 'clsx'
import moment from 'moment'
import { authHeader } from '_helpers/authHeader'

// import { useDispatch } from 'react-redux'
// import { Redirect } from 'react-router-dom'
import { Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Title } from 'components/Title'
// import { Sidebar as OriginalSidebar } from 'components/resource/sidebar'
// import { useResourceState } from '_helpers/useResourceState'
// import { useResourceFetch } from '_helpers/useResourceFetch'
// import { commonConstants } from '_constants'
// import { translate } from '_helpers/translate'
// import { boolean } from '_helpers/boolean'
import { FieldError } from 'components/form/_helpers/fieldError'
import { fields } from 'components/form/fields'
import schema from '_schema/formsetSave'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const FormSaveEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
    values: null,
  })

  function renderHTML(xxs) {
    return { __html: xxs }
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      iri,
      'GET',
      { signal },
      response => {
        setState({
          isFetching: false,
          resource: response ? response : {},
          values: response ? response : {},
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [iri])

  // const handleSuccess = resource => {
  //   setState(state => ({
  //     ...state,
  //     resource,
  //   }))
  // }

  const { isFetching, fetchError, resource } = state
  const defaultClasses = useStyles()
  const classes = useStyles()
  const header = false
  const paddingBottom = 60
  const sidebar = false
  const fieldNodeRefs = {}
  const width = '100%'
  const fieldsFullWidth = true
  const readOnly = false
  const disabled = false

  const properties = {
    lang: {
      type: 'string',
      label: 'Język w jakim zostało wysłane zgłoszenie',
      readonly: true,
    },
  }

  const setValue = useCallback((name, value, setRenderError = true) => {
    // dispatch({
    //   type: constants.SET_VALUE,
    //   payload: { name, value, setRenderError },
    // })
  }, [])

  const getValue = useCallback(
    name => {
      return state.values[name]
    },
    [state.values]
  )

  const setError = useCallback((name, error) => {
    // dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  const setAdditional = useCallback(additional => {
    // dispatch({ type: constants.SET_ADDITIONAL, payload: { additional } })
  }, [])

  const Wrapper = ({ children, wrapWithPaper }) =>
    wrapWithPaper ? (
      <Paper withPadding={false}>{children}</Paper>
    ) : (
      <div>{children}</div>
    )

  function getfiledownload(url) {
    // const controller = new AbortController()
    // const { signal } = controller

    // fetchDataHandleAuthError(
    //   url,
    //   'GET',
    //   { signal },
    //   response => {
    //     return response.blob()
    //   },
    //   error => {
    //     if (error.response.title === 'AbortError') {
    //       return
    //     }

    //     notification('error', error.response.detail, error.response.title)
    //     setState({
    //       isFetching: false,
    //       fetchError: true,
    //     })
    //   }
    // )

    let xtitle = null
    fetch(url, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle??'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie mozna pobrać', e)
      })
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container} style={{ paddingBottom }}>
      {header ? (
        <div className={classes.header}>
          {typeof header === 'function' ? header(resource) : header}
        </div>
      ) : (
        <Title className={classes.header}>
          Data wysłania {moment(resource.createdAt).format('DD.MM.YYYY HH:mm')}
        </Title>
      )}
      <Grid container spacing={3}>
        <Grid
          item
          xs={sidebar ? 9 : 12}
          className={clsx(sidebar && classes.grid)}
        >
          <Wrapper wrapWithPaper={false}>
            <div
              className={clsx(defaultClasses.root, classes.root)}
              style={{
                width: 300,
              }}
            >
              {Object.keys(properties).map(name => {
                if (
                  typeof properties[name].type === 'string' &&
                  !fields[properties[name].type]
                ) {
                  throw new FieldError(properties[name].type)
                }

                const FieldComponent =
                  typeof properties[name].type === 'string'
                    ? fields[properties[name].type]
                    : properties[name].type

                const {
                  type,
                  description,
                  hint,
                  validate,
                  additionalProperties,
                  endAdornment,
                  disabled: fieldDisabled,
                  ...rest
                } = properties[name]

                return (
                  <div
                    key={name}
                    className={clsx(defaultClasses.field, classes.field)}
                  >
                    <FieldComponent
                      uuid={state.values[process.env.REACT_APP_RESOURCE_ID]}
                      formUrl={state.url}
                      formMethod={'GET'}
                      name={name}
                      type={type}
                      label={description}
                      hint={hint}
                      initialValue={
                        resource?.[name] !== undefined
                          ? resource[name]
                          : properties[name].defaultValue !== undefined
                          ? properties[name].defaultValue
                          : null
                      }
                      value={state.values[name]}
                      disabled={
                        readOnly ||
                        fieldDisabled ||
                        disabled ||
                        state.isProcessing
                      }
                      validators={validate}
                      setValue={setValue}
                      getValue={getValue}
                      setError={setError}
                      additional={state.additional}
                      setAdditional={setAdditional}
                      fullWidth={fieldsFullWidth}
                      formWidth={width}
                      definitionRef={additionalProperties?.$ref}
                      nodeRef={fieldNodeRefs[name] || null}
                      endAdornment={endAdornment}
                      {...rest}
                    />
                  </div>
                )
              })}
            </div>
          </Wrapper>

          <Wrapper wrapWithPaper={false}>
            <div
              className={clsx(defaultClasses.root, classes.root)}
              style={{
                width: '100%',
              }}
            >
              {/* <a
            className={clsx(defaultClasses.link, classes.link)}
            href={`${process.env.REACT_APP_API_ENTRYPOINT}/api/formset/download/4f91dccf-8dcf-4b6e-9e62-0bf1c144b748`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.originalName}
          </a> */}

              <div className={clsx(defaultClasses.field, classes.field)}>
                <br />
                <Title className={classes.header}>Formularz:</Title>
                {resource.dataForm.map(section => (
                  <div>
                    {section.items.map(item => (
                      <div style={{ padding: '5px' }}>
                        <span style={{ fontWeight: 800 }}>
                          {item.title.replace(/<[^>]+>/g, '')}
                        </span>
                        <span>
                          {item.uuid ? (
                            <div
                              style={{
                                marginLeft: '20px',
                                listStyleType: 'circle',
                              }}
                            >
                              {item.uuid.map(filename => (
                                <div>
                                  {' '}
                                  - plik ({filename[1] ?? ''}) -
                                  <Button
                                    startIcon={<CloudUploadIcon />}
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      getfiledownload(
                                        `${getApiUrl()}/api/formset/download/${
                                          Array.isArray(filename)
                                            ? filename[0]
                                            : filename
                                        }`
                                      )
                                    }}
                                  >
                                    pobierz
                                  </Button>
                                </div>
                              ))}
                            </div>
                          ) : Array.isArray(item.value) ? (
                            <div
                              style={{
                                marginLeft: '20px',
                                listStyleType: 'circle',
                              }}
                            >
                              {item.value.map(option => (
                                <div>
                                  -{' '}
                                  {option?.title ? (
                                    option?.type === 'consent' ? (
                                      <span
                                        dangerouslySetInnerHTML={renderHTML(
                                          option.title
                                        )}
                                      />
                                    ) : (
                                      option.title
                                    )
                                  ) : (
                                    '--'
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div style={{ marginLeft: 20 }}>
                              {' '}
                              {item.value ?? '--'}{' '}
                            </div>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Wrapper>
        </Grid>
      </Grid>
    </div>
  )
}
