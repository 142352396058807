import {
  text,
  textWithPhoto,
  textWithVideo,
  //textWithLink,
  gallery,
  files,
  //faq,
  //logotypes,
  anchors,
  alert,
  //banner1,
  banner1Volunteering,
  banner2,
  links,
  list,
  newsletter,
  product,
  trails,
  audio,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    audio(photoEndpoint),
    //textWithLink(),
    gallery(),
    files(),
    //faq(),
    //logotypes(),
    anchors(blockAnchorEndpoint),
    alert(),
    banner1Volunteering(photoEndpoint, blockThumbEndpoint),
    banner2(photoEndpoint, blockThumbEndpoint),
    links(photoEndpoint, blockLinkEndpoint, blockLinkThumbEndpoint),
    list(photoEndpoint, blockListItemEndpoint, blockListItemThumbEndpoint),
    newsletter(blockListItemEndpoint),
    product(photoEndpoint, blockThumbEndpoint),
    trails(photoEndpoint, blockThumbEndpoint, blockFileEndpoint),
  ],
})

export default schema
