import { authHeader } from './authHeader'
import { handleResponse as defaultHandleResponse } from './handleResponse'
import { getApiUrl } from '../_services/apiUrlProvider'

export const fetchData = (
  url,
  method = 'GET',
  headers = {},
  request = {},
  handleResponse = null,
  absoluteUrl = false
) => {
  const isFormData = headers['Content-Type'] === 'multipart/form-data'
  delete headers['Content-Type']

  return fetch(absoluteUrl ? url : `${getApiUrl()}${url}`, {
    method,
    headers: {
      accept: 'application/ld+json',
      ...(isFormData ? {} : { 'Content-Type': 'application/ld+json' }),
      ...headers,
      ...authHeader(),
    },
    ...request,
  }).then(handleResponse || defaultHandleResponse)
}
