import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { translate } from '_helpers/translate'
import schema from '_schema/churchiscoInspection'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'place',
        header: translate('Place'),
        accessor: 'place',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'inspector',
        header: translate('Inspector'),
        accessor: 'inspector',
        filterable: true,
        sortable: true,
        width: '45%',
      },
      {
        columnId: 'inspectedFrom',
        header: translate('Inspected from'),
        accessor: 'inspectedFrom',
        filterable: true,
        sortable: true,
        Cell: DatetimeCell,
        width: '15%',
      },
      {
        columnId: 'inspectedTo',
        header: translate('Inspected to'),
        accessor: 'inspectedTo',
        filterable: true,
        sortable: true,
        Cell: DatetimeCell,
        width: '15%',
      },
      {
        columnId: 'operations',
        ...operations('title', true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_CHURCHISCO_INSPECTION_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_CHURCHISCO_INSPECTION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'place',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'inspector',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'inspectedFrom',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'inspectedTo',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[place]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[inspector]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[inspectedFrom]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[inspectedTo]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
