import React from 'react'
import { Form } from 'pages'
import schema from '_schema/tourneyScore'
import routes from '../routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes()
          .scoreIndex.path.replace(':pid', match.params.pid)
          .replace(':uid', match.params.uid)}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        // sidebarTitleAccessor={`nativeTitle`}
        // SidebarComponent={SidebarNotStatable}
        width="100%"
      />
      <br />
      <br />
      <br />
      <br />
    </>
  )
}
