import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import moment from 'moment'

export const SendOrderFvType = ({ value, endpoint }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [lastSend, setLastSend] = useState(value?.lastDate)

  const sendEmail = () => {
    if (isFetching) {
      return
    }

    setIsFetching(true)
    fetch(`${getApiUrl()}${endpoint}`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ uuid: value }),
    })
      .then(
        response => {
          return response.json()
        },
        () => {}
      )
      .then(data => {
        setIsFetching(false)
        if (data?.error) {
          notification('error', data.error, 'Błąd')
        } else {
          setLastSend(moment().format('YYYY-MM-DD HH:mm:ss'))
          notification('success', 'Wiadomość email została wysłana', 'Sukces')
        }
      })
  }

  if (value) {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={sendEmail}
          disabled={isFetching}
        >
          Wyślij fakturę
        </Button>
        <div style={{ marginTop: '5px' }}>
          Data ostatniej wysyłki:{' '}
          {lastSend
            ? moment(new Date(lastSend)).format('YYYY-MM-DD HH:mm:ss')
            : 'brak'}
        </div>
      </div>
    )
  }

  return null
}

SendOrderFvType.propTypes = {
  value: PropTypes.object,
  endpoint: PropTypes.string.isRequired,
}
