import React from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../../_helpers/translate'
import { Alert } from '@material-ui/lab'

export const AlertType = ({ name, variant, severity, value, label }) => {
  if (value) {
    return (
      <Alert name={name} variant={variant} severity={severity}>
        {translate(label)}
      </Alert>
    )
  }

  return null
}

AlertType.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
}
