import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/tourismNoticeArticle'
import tourismNoticeSchema from '_schema/tourismNotice'
import { useSelector } from 'react-redux'
import { currentHostnameIri } from '../../_helpers/currentHostnameIri'
import { addOpenformProperties } from '../AccommodationConfig/_helpers/addOpenformProperties'
import { BlockCollection } from '../../components/block'
import { Paper } from '../../components/Paper'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { AddModal } from '../../components/embedded/AddModal'
import { applyProperties } from '../../_helpers/applyProperties'
import { LANGS_IRI } from '../../_lib/langs'
import { Sidebar } from './sidebar'
import { getApiUrl } from '../../_services/apiUrlProvider'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
  slug: {
    marginTop: 15,
    marginBottom: 20,
  },
  link: {
    color: '#555',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkAdmin: {
    color: 'red',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginTop: 10,
    height: 28,
    fontSize: 14,
    marginBottom: 20,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`
  const profile = useSelector(state => state.profile)

  const [hostnameIri, setHostnameIri] = useState(false)
  const [isHostnameLoading, setIsHostnameLoading] = useState(false)

  useEffect(() => {
    if (!isHostnameLoading && hostnameIri === false) {
      setIsHostnameLoading(true)
      currentHostnameIri(setHostnameIri)
    }

    if (hostnameIri !== false) {
      setIsHostnameLoading(false)
    }
  }, [hostnameIri, setHostnameIri, isHostnameLoading, setIsHostnameLoading])

  const { match } = rest

  const [parentResource, setParentResource] = useState(null)

  const reviewProperties = useMemo(() => {
    return applyProperties(
      tourismNoticeSchema.resource.properties,
      definitions[tourismNoticeSchema.resource.definition].properties,
      'missing'
    )
  }, [definitions])

  const [reviewModalResponse, setReviewModalResponse] = useState(null)

  const parentIri = schema.getEndpoint.replace(':uuid', parentResource?.uuid)

  const defaultData = { parent: parentIri }

  const getTourismNoticeTitle = item => {
    //if (!item) return translate('T_GENERAL_MISSING_TITLE')
    const type = translate(item?.type.trans)
    const title = item.translations[LANGS_IRI.PL].title

    return `${type} - ${title}`
  }

  const classes = useStyles()

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        modifyProperties={properties =>
          addOpenformProperties(
            profile?.openform,
            properties,
            null,
            null,
            hostnameIri
          )
        }
        storeCollectionId={schema.endpoint}
        sidebar={true}
        SidebarComponent={Sidebar}
        sidebarStatAccessor={''}
        sidebarVersionEndpoint={schema.version_endpoint}
        width="100%"
        setParentResource={setParentResource}
      >
        <a
          href={`${getApiUrl()}/podglad-komunikatu-turystycznego/${
            parentResource?.uuid
          }?access_token=${profile?.contentAccessToken}`}
          className={classes.linkAdmin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<Visibility />}
          >
            Podgląd administratora
          </Button>
        </a>
        {parentResource?.uuid && (
          <BlockCollection
            endpoint={schema.subresources.block.endpoint}
            pid={match.params.id}
            parentIri={parentIri}
            definitionSchema={definitions[schema.subresources.block.definition]}
            types={schema.subresources.block.types}
            contextualHint={schema.subresources.block.contextualHint}
            statable={true}
            key={parentIri}
          />
        )}
        <Paper
          variant="outlined"
          header={translate('Komunikaty')}
          withPadding={false}
          style={{ marginTop: '30px' }}
        >
          <EmbeddedCollection
            endpoint={`${tourismNoticeSchema.endpoint}?pagination=false`}
            pid={parentIri}
            parentIri={iri}
            properties={tourismNoticeSchema.resource.properties}
            definitionSchema={
              definitions[tourismNoticeSchema.resource.definition]
            }
            headerTitle={translate('Komunikaty')}
            headerAccessor={`translations.${LANGS_IRI.PL}.title`}
            titleAccessor={getTourismNoticeTitle}
            statable={true}
            modalResponse={reviewModalResponse}
            setModalResponse={setReviewModalResponse}
            hideAddButton={true}
          />
          <AddModal
            title={translate('T_MODULE_TOURISM_NOTICE_NEW_BUTTON')}
            definitionSchema={
              definitions[tourismNoticeSchema.resource.definition]
            }
            itemProperties={reviewProperties}
            defaultData={defaultData}
            endpoint={tourismNoticeSchema.endpoint}
            itemSchema={tourismNoticeSchema}
            setModalResponse={setReviewModalResponse}
          />
        </Paper>
      </Form>
    </div>
  )
}
