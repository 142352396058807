import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Drawer, Divider, Tooltip } from '@material-ui/core'
import { Menu } from 'components/menu'
import { Logo } from 'components/Logo'
import { MenuSkeleton } from './MenuSkeleton'
import { UserBox } from 'components/UserBox'
import { translate } from '_helpers/translate'
import { useStyles } from './SideBar.styles'
import profileRoutes from 'pages/Profile/routes'

export const SideBar = ({ menu, isSidebarOpen, loading }) => {
  const classes = useStyles()

  const profileRoute = profileRoutes().index
  const ProfileIcon = profileRoute.icon

  return (
    <Drawer
      variant="permanent"
      classes={{
        docked: classes.docked,
        paper: clsx(
          classes.drawer,
          !isSidebarOpen && classes.drawerSidebarClosed
        ),
      }}
      open={isSidebarOpen}
      style={{overflowY:'auto', height:'100vh'}}
    >
      <Logo className={classes.logo} />
      {isSidebarOpen ? (
        <div className={clsx(classes.userBox)}>
          <UserBox />
        </div>
      ) : (
        <Tooltip title={translate(profileRoute.title)} placement="right">
          <Link to={profileRoute.path} className={classes.profileLink}>
            <ProfileIcon />
          </Link>
        </Tooltip>
      )}
      <Divider className={classes.divider} />
      {loading ? (
        <MenuSkeleton rows={4} />
      ) : (
        <Menu items={menu} isSidebarOpen={isSidebarOpen} />
      )}
    </Drawer>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

SideBar.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ),
  isSidebarOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
}
