import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const audio = photoEndpoint => ({
  name: BLOCK_TYPES_IRI.AUDIO,
  label: 'T_GENERAL_BLOCK_TYPE_AUDIO',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          description: 'T_GENERAL_TITLE',
        },
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_AUDIO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      accept: 'audio/*',
      restrictManagerMimeType: 'audio/*',
    },
  },
})
