import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import { getApiUrl } from '../../../../_services/apiUrlProvider'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const MainCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource['uuid']))
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>
      {resource.media ? (
        <div className={classes.imgContainer}>
          <img
            alt="preview"
            className={classes.img}
            src={`${getApiUrl()}${resource.media.url}`}
          />
        </div>
      ) : null}
      <div className={classes.title}>
        <div>{prop(resource, accessor)}</div>
      </div>
    </div>
  )
}

MainCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    translations: PropTypes.shape({}).isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
