const schema = {
  endpoint: '/api/shop_variants',
  clone_endpoint: '/api/shop_variant/clone',
  resource: {
    definition: '#ShopVariant',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
    },
  },
  subresources: {
    options: {
      endpoint: '/api/shop_variant_options',
      definition: '#ShopVariantOption',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        subscriptionFrom: {
          type: 'number',
          description: 'T_MODULE_SHOP_PRODUCT_SUBSCRIPTION_FROM',
        },
      },
    },
  },
}

export default schema
