import React from 'react'
import PropTypes from 'prop-types'
import { prop } from '_helpers/prop'
import { makeStyles } from '@material-ui/styles'
import { ShoppingCartOutlined } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import routes from '../../routes'

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export const OrderHistoryCell = ({ resource, accessor }) => {
  const history = useHistory()
  const classes = useStyles()

  const redirectToOrders = () => {
    history.push(routes().orderCollection.path.replace(':id', resource['uuid']))
  }

  return (
    <div className={classes.container}>
      <div>{prop(resource, accessor)}</div>
      <div>
        <IconButton onClick={redirectToOrders}>
          <ShoppingCartOutlined />
        </IconButton>
      </div>
    </div>
  )
}

OrderHistoryCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  setIsInput: PropTypes.func.isRequired,
}
