import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { translate } from '../../../_helpers/translate'

export const DefaultCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  translateAccessor = false,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  return (
    <div onClick={onClick}>
      {translateAccessor
        ? translate(prop(resource, accessor))
        : prop(resource, accessor)}
    </div>
  )
}

DefaultCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
  translateAccessor: PropTypes.bool,
}
