import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 20,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 10,
    verticalAlign: 'middle',
  },
}))

export const ReturnMessageType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  getValue,
}) => {
  const classes = useStyles()

  const createdAt = getValue('createdAt')
  const sentDate = getValue('sentDate')
  const toAdmin = getValue('toAdmin')
  const mailText = getValue('mailText')
  const recipients = getValue('recipients')
  const recipient = recipients ? recipients[0] : null

  return (
    <>
      <div style={{ marginTop: 50, fontSize: '1.5em' }}>Historia wątku: </div>

      {value.map(item => {
        return (
          <Typography className={classes.root}>
            {item.sentDate
              ? moment(item.sentDate).format('DD.MM.YYYY HH:mm')
              : moment(item.createdAt).format('DD.MM.YYYY HH:mm')}{' '}
            |{' '}
            {item.toAdmin && item.recipients[0]
              ? `${item.recipients[0].companyName}(${item.recipients[0].email})`
              : 'Administrator'}
            <hr />
            {item.mailText}
          </Typography>
        )
      })}

      <Typography className={classes.root}>
        {sentDate
          ? moment(sentDate).format('DD.MM.YYYY HH:mm')
          : moment(createdAt).format('DD.MM.YYYY HH:mm')}{' '}
        |{' '}
        {toAdmin && recipient
          ? `${recipient.companyName}(${recipient.email})`
          : 'Administrator'}
        <hr />
        {mailText}
      </Typography>
    </>
  )
}

ReturnMessageType.propTypes = {
  name: PropTypes.string.isRequired,
}
