import React from 'react'
import PropTypes from 'prop-types'
import { constants } from 'components/table/_state'
import { MultiStatus } from '../../resource/MultiStatus'

export const MultiStatusCell = ({
  resource,
  accessors,
  disabled = false,
  tableStateDispatch,
  customEndpoint = '',
}) => {
  const onSuccess = (accessor, value) => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  return (
    <MultiStatus
      resource={resource}
      accessors={accessors}
      disabled={disabled}
      onSuccess={onSuccess}
      customEndpoint={customEndpoint}
    />
  )
}

MultiStatusCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  tableStateDispatch: PropTypes.func.isRequired,
  customEndpoint: PropTypes.string,
}
