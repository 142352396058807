import { constants } from './constants'
import { reducer as originalReducer } from 'components/table/_state'
import { processNestedToFlatList } from '../../_helpers/processNestedToFlatList'
import { traverseNested } from '../../_helpers/traverseNested'

export const reducer = (state, action) => {
  let items, toggled

  switch (action.type) {
    case constants.SET_SINGLE_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) => ({
            value: i === action.payload.row ? !item.value : item.value,
            iri: item.iri,
          })),
        },
      }
    case constants.SET_MASS_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) => ({
            value: (typeof action.payload.isRowSelectable === 'boolean'
            ? action.payload.isRowSelectable
            : action.payload.isRowSelectable(state.data.items[i]))
              ? !state.data.selectAll
              : item.value,
            iri: item.iri,
          })),
          selectAll: !state.data.selectAll,
        },
      }
    case constants.FETCH_SUCCESS:
      items = processNestedToFlatList(
        action.payload.data.items,
        state.data.toggled
      )

      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          items,
          rawItems: action.payload.data.items,
          selected: items.map(item => ({ value: false, iri: item['@id'] })),
          selectAll: false,
          totalItems: items.length,
        },
        init: false,
        isFetching: false,
      }
    case constants.MANUAL_ORDER_CHANGE_SUCCESS:
      return {
        ...state,
        config: {
          ...state.config,
        },
      }
    case constants.TOGGLE:
      toggled = {
        ...state.data.toggled,
        [action.payload]: !state.data.toggled[action.payload],
      }

      items = processNestedToFlatList(state.data.rawItems, toggled)

      return {
        ...state,
        data: {
          ...state.data,
          items: items,
          selected: items.map(page => ({
            value:
              state.data.selected.find(item => item.iri === page['@id'])
                ?.value || false,
            iri: page['@id'],
          })),
          selectAll: false,
          toggled,
        },
      }
    case constants.TOGGLE_ALL:
      toggled = Object.assign(
        {},
        ...state.data.rawItems.map(item => ({
          [item['@id']]: !state.data.toggledAll,
        }))
      )

      items = processNestedToFlatList(state.data.rawItems, toggled)

      return {
        ...state,
        data: {
          ...state.data,
          items: items,
          selected: items.map(page => ({
            value:
              state.data.selected.find(item => item.iri === page['@id'])
                ?.value || false,
            iri: page['@id'],
          })),
          selectAll: false,
          toggled,
          toggledAll: !state.data.toggledAll,
        },
      }
    case constants.MASS_SELECT_ACTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: action.payload.items,
          rawItems: traverseNested(action.payload.items, [
            ...state.data.rawItems,
          ]),
          selected: new Array(action.payload.items.length).fill(false),
          selectAll: false,
        },
        isFetching: false,
      }
    case constants.UPDATE_ITEM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map(item =>
            item['@id'] === action.payload['@id']
              ? {
                  ...item,
                  [action.payload.accessor]: action.payload.value,
                }
              : item
          ),
          rawItems: traverseNested(
            [
              {
                '@id': action.payload['@id'],
                [action.payload.accessor]: action.payload.value,
              },
            ],
            [...state.data.rawItems]
          ),
        },
      }
    default:
      return originalReducer(state, action)
  }
}
