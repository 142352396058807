import React from 'react'
import { Tooltip } from '@material-ui/core'
import { DragIndicatorOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { sortableHandle } from 'react-sortable-hoc'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  handle: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export const ManualOrderDragHandle = sortableHandle(() => {
  const classes = useStyles()

  return (
    <Tooltip title={translate('T_GENERAL_DRAG_HANDLE')}>
      <DragIndicatorOutlined className={classes.handle} />
    </Tooltip>
  )
})
