import PropTypes from 'prop-types'

export const embeddedCollectionPropTypes = {
  endpoint: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
      properties: PropTypes.object,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }),
      ]),
      hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      validate: PropTypes.arrayOf(PropTypes.string),
      endpoint: PropTypes.string,
      thumbs: PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
      }),
      choices: PropTypes.object,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string.isRequired,
        })
      ),
      titleAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func,
      ]),
    })
  ).isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  headerTitle: PropTypes.string,
  expandTitle: PropTypes.string,
  panelTitle: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  orderable: PropTypes.bool,
  statable: PropTypes.bool,
  statDisabled: PropTypes.bool,
  statAccessor: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  contextualHint: PropTypes.string,
  disabled: PropTypes.bool,
  disableTableBorder: PropTypes.bool,
  modalResponse: PropTypes.object,
  setModalResponse: PropTypes.func,
  addButtonTitle: PropTypes.string,
  hideAddButton: PropTypes.bool,
  addButtonSmall: PropTypes.bool,
  classes: PropTypes.shape({
    header: PropTypes.string,
    massExpand: PropTypes.string,
    addContainer: PropTypes.string,
    collectionContainer: PropTypes.string,
    cell: PropTypes.string,
    expansionPanelDetails: PropTypes.string,
    emptyResults: PropTypes.string,
    draggedRow: PropTypes.string,
    contextualHint: PropTypes.string,
    expansionPanelSummary: PropTypes.string,
    itemTitle: PropTypes.string,
    itemIcon: PropTypes.string,
    expandIcon: PropTypes.string,
  }),
  hidePanelTitle: PropTypes.bool,
}
