//import React, { useEffect, useMemo, useState } from 'react'
import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { editable } from 'components/table/_columns/editable'
import { operations } from './table/columns/operations'
import { EditableCell } from './table/cells/EditableCell'
import { translate } from '_helpers/translate'
import schema from '_schema/admin'
import routes from './routes'
import { status } from '../../components/table/_columns/status'
import { useSelector } from 'react-redux'
import { StatusCell } from './table/cells/StatusCell'
//import { isPPNCheck } from '../../_helpers/isPPNCheck'

export const Collection = () => {
  const profile = useSelector(state => state.profile)

  /*
  const [isPPN, setIsPPN] = useState(false)
  const [isPPNLoading, setIsPPNLoading] = useState(false)

  useEffect(() => {
    if (!isPPNLoading) {
      setIsPPNLoading(true)
      isPPNCheck(setIsPPN)
    }
  }, [isPPN, setIsPPN, isPPNLoading, setIsPPNLoading])
   */

  const columns = useMemo(
    () => [
      {
        columnId: 'fullName',
        ...editable('fullName'),
        Cell: EditableCell,
        width: '28%',
      },
      {
        columnId: 'email',
        ...editable('email'),
        Cell: EditableCell,
        width: '26%',
      },
      {
        columnId: 'group.title',
        ...standard('group.title'),
        sortable: false,
        width: '24%',
      },
      {
        columnId: 'redactor',
        ...status('redactor'),
        Cell: StatusCell,
        header: translate('T_GENERAL_REDACTOR'),
        width: '3%',
      },
      {
        columnId: 'superAdmin',
        ...status('superAdmin'),
        Cell: StatusCell,
        header: 'Super admin',
        width: '3%',
        excluded: !profile?.openform,
      },
      {
        columnId: 'canEditSuperAdmin',
        ...status('canEditSuperAdmin'),
        Cell: StatusCell,
        header: 'Zarządzanie super adminami',
        width: '5%',
        excluded: !profile?.openform,
      },
      {
        columnId: 'openform',
        ...status('openform'),
        Cell: StatusCell,
        header: 'Openform',
        width: '3%',
        excluded: !profile?.openform,
      },
      {
        columnId: 'operations',
        ...operations('fullName'),
        width: '15%',
      },
    ],
    [profile]
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ADMINS_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
      >
        {translate('T_MODULE_ADMINS')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'email',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'fullName',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'group.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[email]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[fullName]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
