import guideSchema from './guide'

const schema = {
  endpoint: '/api/guide_licences',
  access: 'guide',
  resource: {
    definition: '#GuideLicence',
    properties: {
      parent: {
        description: 'Przewodnik',
        type: 'resource',
        endpoint: `${guideSchema.endpoint}?pagination=false&order[surname]=asc&order[name]=asc`,
        titleAccessor: 'fullName',
        validate: ['required'],
      },
      number: {
        description: 'Number',
        type: 'number',
        // validate: ['required'],
        hint:'zostaw puste, by nadać numer automatycznie'
      },
      year: {
        type: 'number',
        validate: ['required', 'length(4)'],
      },
      issuedAt: {
        type: 'date',
        validate: ['required'],
      },
    },
  },
}

export default schema
