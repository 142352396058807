import React, { useCallback, useEffect, useState } from 'react'
import schema from '../../../_schema/bipPageArticle'
import routes from '../routes'
import { Form } from '../../Form'
import { LANGS_IRI } from '../../../_lib/langs'
import { BlockCollection } from '../../../components/block'
import { useDispatch } from 'react-redux'
import { commonConstants } from '../../../_constants'
import { Sidebar } from '../sidebar'
import { Alert } from '@material-ui/lab'
import { translate } from '../../../_helpers/translate'
import {fetchDataHandleAuthError} from "../../../_helpers/fetchDataHandleAuthError";

export const ArticleEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const collectionPath = function(resource) {
    return routes().articles.path.replace(':id', resource.parent?.uuid)
  }

  const dispatch = useDispatch()
  const [parentResource, setParentResource] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  const modifiedCallback = resource => {
    if (resource) {
      fetchDataHandleAuthError(
        schema.is_modified_endpoint,
        'POST',
        {
          body: JSON.stringify({
            iri: iri,
          }),
        },
        response => {
          if (response?.modified === true) {
            setShowAlert(true)
          } else {
            setShowAlert(false)
          }
        },
        error => {
          console.error(error?.response?.detail)
        }
      )
    }
  }

  const handleSuccessAndStay = useCallback(
    resource => {
      if (resource?.modified) {
        setShowAlert(true)
      } else {
        setShowAlert(false)
      }

      setParentResource(resource)
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })

      modifiedCallback(resource)
    },
    [dispatch, setShowAlert, setParentResource]
  )

  useEffect(() => {
    modifiedCallback(true)
  }, [parentResource, setShowAlert])

  const onVersionSuccess = useCallback(() => {
    setShowAlert(false)
    window.location.reload()
  }, [setShowAlert])

  return (
    <div>
      {showAlert && (
        <Alert variant="filled" severity="info" style={{ marginTop: '30px' }}>
          {translate('T_MODULE_BIP_PAGE_ALERT_UNPUBLISHED_CHANGES')}
        </Alert>
      )}
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={collectionPath}
        storeCollectionId={schema.endpoint}
        handleSuccessAndStay={handleSuccessAndStay}
        sidebar={true}
        onVersionSuccess={onVersionSuccess}
        sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
        sidebarVersionEndpoint={schema.version_endpoint}
        sidebarStatAccessor={''}
        SidebarComponent={Sidebar}
        setParentResource={setParentResource}
        width="100%"
        modifiedCallback={modifiedCallback}
      >
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={schema.subresources.block.types}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={iri}
          modifiedCallback={modifiedCallback}
        />
      </Form>
    </div>
  )
}
