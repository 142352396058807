import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Box, TextField } from '@material-ui/core'
import { Logo } from 'components/Logo'
import { Copyright } from 'components/Copyright'
import { nl2br } from '_helpers/nl2br'
import { validators } from '_helpers/validators'
import { translate } from '_helpers/translate'
import { useStyles } from '.'
import { getApiUrl } from '../../_services/apiUrlProvider'

export const ResetPasswordChange = ({ match }) => {
  const [state, setState] = useState({
    password: null,
    passwordRepeat: null,
    passwordErrorMessage: translate('T_VALIDATION_PASSWORD'),
    passwordRepeatErrorMessage: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = {
    password: e => {
      const password = getValue(e.target.value)
      setState(state => ({
        ...state,
        password,
      }))
      validate(password, state.passwordRepeat)
    },
    passwordRepeat: e => {
      const passwordRepeat = getValue(e.target.value)
      setState(state => ({
        ...state,
        passwordRepeat,
      }))
      validate(state.password, passwordRepeat)
    },
  }

  const validate = (password, passwordRepeat) => {
    const isPasswordValid =
      validators.required(password).result &&
      validators.password(password).result

    setState(state => ({
      ...state,
      passwordErrorMessage: !isPasswordValid
        ? translate('T_VALIDATION_PASSWORD')
        : null,
    }))

    const isPasswordRepeatValid = password === passwordRepeat

    setState(state => ({
      ...state,
      passwordRepeatErrorMessage: !isPasswordRepeatValid
        ? translate('T_VALIDATION_PASSWORDS_DO_NOT_MATCH')
        : null,
      isInvalid: !isPasswordValid || !isPasswordRepeatValid,
    }))
  }

  const url = `${getApiUrl()}/api/reset_password/change`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/ld+json' },
      body: JSON.stringify({
        hash: match.params.hash,
        rawPassword: password,
        rawPasswordRepeat: passwordRepeat,
      }),
    })
      .then(response =>
        response.text().then(text => {
          const data = text ? JSON.parse(text) : {}

          return {
            code: response.status,
            message: translate(
              data?.['hydra:description']
                ?.replace('rawPassword: ', '')
                ?.replace('hash: ', '')
            ),
            ...data,
          }
        })
      )
      .then(data => {
        if (data.code !== 201) {
          setState(state => ({
            ...state,
            errorMessage: data.message || translate('Error'),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const {
    password,
    passwordRepeat,
    passwordErrorMessage,
    passwordRepeatErrorMessage,
    errorMessage,
    isSubmitted,
    isFetching,
    isCompleted,
  } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Logo className={classes.logo} />
        <div className={classes.avatar}>
          {translate('T_GENERAL_NEW_PASSWORD_TITLE')}
        </div>
        {isCompleted ? (
          <div className={classes.reset_message}>
            {translate('T_GENERAL_NEW_PASSWORD_RESETTED')}
          </div>
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {errorMessage && (
              <div className={classes.new_password_error}>
                {nl2br(errorMessage)}
              </div>
            )}
            <div className={classes.form_inner}>
              <TextField
                className={classes.input}
                error={isSubmitted && !!passwordErrorMessage}
                helperText={
                  isSubmitted && passwordErrorMessage
                    ? passwordErrorMessage
                    : null
                }
                value={password || ''}
                onChange={handleChange.password}
                required={true}
                label={translate('T_GENERAL_NEW_PASSWORD_TYPE_NEW')}
                type="password"
                margin="normal"
                fullWidth={true}
              />
              <TextField
                className={classes.input}
                error={isSubmitted && !!passwordRepeatErrorMessage}
                helperText={
                  isSubmitted && passwordRepeatErrorMessage
                    ? passwordRepeatErrorMessage
                    : null
                }
                value={passwordRepeat || ''}
                onChange={handleChange.passwordRepeat}
                required={true}
                label={translate('T_GENERAL_NEW_PASSWORD_RETYPE_NEW')}
                type="password"
                margin="normal"
                fullWidth={true}
              />
            </div>
            <Button
              disabled={isFetching}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isFetching ? (
                <img
                  className={classes.spinner}
                  src="/img/react-logo.svg"
                  alt="loading"
                />
              ) : (
                translate('T_GENERAL_NEW_PASSWORD_CHANGE')
              )}
            </Button>
          </form>
        )}
      </Card>
      <Link to="/login" className={classes.lower_link}>
        {translate('T_GENERAL_BACK_TO_LOGIN')}
      </Link>
      <Box mt={4}>
        <Copyright />
      </Box>
    </div>
  )
}

const getValue = value => (value === '' ? null : value)
