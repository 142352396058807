import { fetchFileHandleAuthError } from './fetchFileHandleAuthError'
import { notification } from '_helpers/notification'

export const downloadFile = (url, fileName) => {
  fetchFileHandleAuthError(
    url,
    'GET',
    {},
    response => response.blob(),
    error => {
      if (error.response.title === 'AbortError') {
        return error
      }

      notification('error', error.response.detail, error.response.title)

      return error
    }
  ).then(blob => {
    if (!blob || !(blob instanceof Blob)) {
      return
    }

    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.download = fileName

    document.body.appendChild(link)
    link.click()
    link.remove()
  })
}
