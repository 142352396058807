import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { PowerSettingsNewOutlined } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { authActions } from '_actions'
import { isObjectEmpty } from '_helpers/isObjectEmpty'
import { translate } from '_helpers/translate'
import profileRoutes from 'pages/Profile/routes'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  username: {
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 170,
  },
  skeleton: {
    width: 140,
    backgroundColor: alpha(theme.elements.sideBar.text, 0.15),
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  button: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    color: theme.elements.sideBar.text,
    '&:hover': {
      color: theme.elements.sideBar.hover,
    },
  },
}))

export const UserBox = props => {
  const profile = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const handleLogout = () => dispatch(authActions.logout())

  const profileRoute = profileRoutes().index
  const ProfileIcon = profileRoute.icon

  const classes = useStyles()

  return (
    <div className={classes.root} {...props}>
      <span className={classes.username}>
        {isObjectEmpty(profile) ? (
          <Skeleton className={classes.skeleton} />
        ) : (
          profile.fullName
        )}
      </span>
      <span className={classes.controls}>
        <div>
          <Link to={profileRoute.path} className={classes.button}>
            <Tooltip title={translate(profileRoute.title)}>
              <ProfileIcon fontSize="small" />
            </Tooltip>
          </Link>
        </div>
        <Tooltip title={translate('T_GENERAL_LOGOUT')}>
          <PowerSettingsNewOutlined
            fontSize="small"
            className={classes.button}
            onClick={handleLogout}
          />
        </Tooltip>
      </span>
    </div>
  )
}
