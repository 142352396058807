import matterFormConfigGroup from './matterFormConfigGroup'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/matter_form_permissions',
  endpointGenerate: '/api/matter_form_permission/generate',
  endpointPdf: '/api/matter_form_permission/pdf',
  resource: {
    definition: '#MatterFormPermission',
    properties: {
      signature: {
        description: 'Znak sprawy',
        validate: ['required'],
      },
      symbol: {
        description: '[Znak sprawy] Symbol',
        validate: ['required'],
      },
      /*
      numberOld: {
        description: 'Stary numer',
        disabled: true,
      },
       */
      number: {
        description: '[Znak sprawy] Numer',
        validate: ['required'],
      },
      year: {
        description: '[Znak sprawy] Rok',
        validate: ['required'],
      },
      date: {
        type: 'date',
        description: 'Zakopane, dnia',
        validate: ['required'],
      },
      inHour: {
        description: 'Wjazd do godziny',
      },
      outHour: {
        description: 'Wyjazd po godzinie',
      },
      /*
      fee1: {
        description: 'Opłata #1',
        validate: ['required'],
      },
      fee2: {
        description: 'Opłata #2',
        validate: ['required'],
      },*/
      fee1Item: {
        type: 'resource',
        endpoint: `${matterFormConfigGroup.subresources.items.endpointFee}?pagination=false&parent.idName=fees`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Opłata #1',
      },
      fee2Item: {
        type: 'resource',
        endpoint: `${matterFormConfigGroup.subresources.items.endpointFee}?pagination=false&parent.idName=fees`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Opłata #2',
      },
      ticket: {
        description: 'Bilet',
      },
      signatureBox: {
        description: 'Miejsce na podpis',
      },
    },
  },
}

export default schema
