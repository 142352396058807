import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const stops = (blockLinkEndpoint, definitionRefblockLinkEndpoint) => ({
  name: BLOCK_TYPES_IRI.STOPS,
  label: 'Blok z przystankami',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Title',
          description: 'Title',
        },
        text2: {
          label: 'Informacje pod mapą (i)',
          type: 'tinymce3',
          formHeight: 150
        },
        columnTitle1: {
          description: 'Trasa z',
          type: 'string',
        },
        columnTitle2: {
          description: 'Trasa do',
          type: 'string',
        },
      },
    },

    links: {
      type: 'collection',
      label: 'Punkty na trasie',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockLinkEndpoint,
      endpoint: blockLinkEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
          },
        },
        map: {
          type: 'streetmap',
          label: 'Mapa',
          validate: ['required'],
          disabled: false,
        },
      },
    },
  },
})
