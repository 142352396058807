import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  InputLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { CreateOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { EmbeddedCollection } from 'components/embedded'
import { StaticCollection } from 'components/static'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    marginBottom: 15,
  },
}))

export const CollectionType = ({
  uuid,
  label,
  value,
  properties,
  definitionRef = null,
  endpoint,
  formUrl,
  titleAccessor = null,
  disabled = false,
  classes = {},
  statable = false,
  definitions,
  sort = '',
  modifiedCallback = null,
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? schema.definitions[definitionRef] : schema),
    [schema, definitionRef]
  )

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <InputLabel className={clsx(defaultClasses.label, classes.label)}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Tooltip
        title={translate(
          uuid ? 'T_GENERAL_EDIT' : 'T_GENERAL_SAVE_FORM_TO_EDIT'
        )}
      >
        <span>
          <IconButton color="primary" onClick={handleToggle} disabled={!uuid}>
            <CreateOutlined
              className={clsx(defaultClasses.labelButton, classes.labelButton)}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        onClose={handleToggle}
        open={isOpen}
        fullWidth={true}
        maxWidth="lg"
        disableEnforceFocus
      >
        <DialogTitleWithClose onClose={handleToggle}>
          {translate(label.text || label)}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
        >
          {disabled ? (
            <StaticCollection
              items={value || []}
              properties={properties}
              definitionSchema={definitionSchema}
            />
          ) : (
            <EmbeddedCollection
              endpoint={
                sort === ''
                  ? `${endpoint}?iri[parent]=${formUrl}`
                  : `${endpoint}?iri[parent]=${formUrl}&${sort}`
              }
              pid={uuid}
              parentIri={formUrl}
              properties={properties}
              definitionSchema={definitionSchema}
              alignButtonEvenly={true}
              titleAccessor={titleAccessor}
              disabled={!uuid || disabled}
              definitions={definitions}
              statable={statable}
              modifiedCallback={modifiedCallback}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

CollectionType.propTypes = {
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ),
  properties: PropTypes.object.isRequired,
  definitionRef: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    labelButton: PropTypes.string,
    dialogContent: PropTypes.string,
  }),
  statable: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  modifiedCallback: PropTypes.func,
}
