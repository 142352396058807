import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const column = (
  blockListItemEndpoint
) => ({
  name: BLOCK_TYPES_IRI.COLUMNS,
  label: 'Blok 3 kolumny',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    listItems: {
      type: 'collection',
      label: 'Kolumny',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#PageBlockListItem',
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              label: 'Tytuł kolumny',
            },
            text: {
              type: 'tinymce',
              label: 'Opis kolumny',
              description: 'Opis kolumny',
            },
            infoTitle: {
              type: 'string',
              label: 'Uwaga - tytuł',
            },
            info: {
              type: 'tinymce',
              label: 'Uwaga - opis',
              description: 'Uwaga - opis',
            },

          },
        },
      },
    },

  },
})
