import PropTypes from 'prop-types'

export const collectionTablePropTypes = {
  parameters: PropTypes.array,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object,
  }),
  defaultSorters: PropTypes.objectOf(PropTypes.oneOf(['asc', 'desc'])),
  defaultFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  resetable: PropTypes.bool,
  filterable: PropTypes.bool,
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      title: PropTypes.string,
      execute: PropTypes.func,
    })
  ),
  isRowSelectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  storeCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editPath: PropTypes.string,
  children: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string,
    toolbar: PropTypes.string,
    row: PropTypes.string,
    rowAsLink: PropTypes.string,
    cell: PropTypes.string,
    emptyResults: PropTypes.string,
    manualOrderContainer: PropTypes.string,
    manualOrderContainerTitle: PropTypes.string,
    draggedRow: PropTypes.string,
    massSelectContainer: PropTypes.string,
    massSelectContainerTitle: PropTypes.string,
    massHeadColumn: PropTypes.string,
    massColumn: PropTypes.string,
  }),
  setHomepageAlert: PropTypes.func,
}
