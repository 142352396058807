import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, FormHelperText, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
  slug: {
    marginTop: 15,
  },
  link: {
    color: '#555',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkAdmin: {
    color: 'red',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginTop: 10,
    height: 28,
    fontSize: 14,
  },
})

export const SlugType = ({
  name,
  routes,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  translationLang,
  fullWidth = false,
}) => {
  const handleChange = e => {
    const value = e.target.value
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const profile = useSelector(state => state.profile)

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <TextField
        name={name}
        type="text"
        label={
          translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label.color && {
            style: {
              color: label.color,
            },
          }
        }
        fullWidth={fullWidth}
        variant="standard"
      />
      {value && (
        <div>
          <FormHelperText className={classes.slug}>
            {`${translate('T_GENERAL_FRONT_ROUTE')}: `}
            <a
              href={
                getApiUrl() + routes[translationLang].replace(':slug', value)
              }
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getApiUrl() + routes[translationLang].replace(':slug', value)}
            </a>
          </FormHelperText>
          <a
            href={
              getApiUrl() +
              routes[translationLang].replace(':slug', value) +
              `?access_token=${profile?.contentAccessToken}`
            }
            className={classes.linkAdmin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Visibility />}
            >
              Podgląd administratora
            </Button>
          </a>
        </div>
      )}
    </>
  )
}

SlugType.propTypes = {
  name: PropTypes.string.isRequired,
  routes: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  translationLang: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}
