import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/weatherLocation'
import routes from './routes'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        columnId: 'title',
        header: translate('Nazwa punktu'),
        accessor: `title`,
        filterable: true,
        sortable: true,
        width: '70%',
      },
      {
        columnId: 'operations',
        ...operations(`title`, true, profile.openform, true, true, false),
        width: '10%',
      },
    ],
    // eslint-disable-next-line
    [profile]
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj punkt pogodowy')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Punkty pogodowe')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'asc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        sortable={false}
        numerable={false}
        isManualOrderSorterVisible={true}
      />
    </Paper>
  )
}
