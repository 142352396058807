import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'
import fileSchema from '../media'

export const pricelist = blockPricelistItemEndpoint => ({
  name: BLOCK_TYPES_IRI.PRICELIST,
  label: 'Blok z cennikiem',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          label: 'Opis',
          description: 'Opis',
          type: 'tinymce',
        },
      },
    },

    pricelistItems: {
      type: 'collection',
      label: 'Sekcje rozwijane',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#PageBlockPricelistItem',
      endpoint: blockPricelistItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
              hint: 'widoczny na belce do rozwijania',
            },

            linkTitle: {
              type: 'string',
              label: 'Tytuł buttona z linkiem',
            },
            link: {
              type: 'string',
              description: 'Link do buttona',
            },

            text: {
              type: 'tinymce',
              label: 'Tekst pod elementami cennika',
              description: 'Tekst pod elementami cennika',
            },
            infoTitle: {
              type: 'tinymce3',
              label: 'Info, komunikta tekstowy (i)',
              description: 'Info, komunikta tekstowy',
              formHeight: 150
            },
            alertTitle: {
              type: 'tinymce3',
              label: 'Alert, uwaga tekstowa /!\\',
              description: 'Alert, uwaga tekstowa',
              formHeight: 150
            },

            mapboxText: {
              type: 'tinymce3',
              label: 'Tekst mapy',
              hint: 'tekst pojawiający się w boksie na mapie po kliknięciu w pointer',
            },
            tableHeadKey: {
              type: 'string',
              label: 'Nazwa kolumny 1 cennika',
              hint: 'np. rodzaj biletu'
            },
            tableHeadValue: {
              type: 'string',
              label: 'Nazwa kolumny 2 cennika',
              hint: 'np. cena'
            },

            fileTitle: {
              hint: 'jeśli brak, guzik się nie wyświetli',
              description: 'Tytuł guzika do pobrania cennika/pliku'
            },
            fileSubTitle: {
              description: 'Podtytuł guzika do pobrania cennika/pliku'
            }
          },
        },
        expanded: {
          label: 'Czy może się rozwijać',
          description: 'Czy może się rozwijać',
          hint: 'jesli tak, tytuł będzie pokazywany',
        },
        showTitle: {
          label: 'Ukryj tytuł na belce do rozwijania',
          hint: 'jeśli sekcja rozwijana mozna ukryć',
        },
        file: {
          type: 'file',
          description: 'Plik pdf/cennik',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
          accept: 'application/pdf',
          restrictManagerMimeType: 'application/pdf',
        },

        showMap: {
          label: 'Pokazuj na mapie',
          hint: 'jeśli włączone, bedzie użyta pozycja z mapki poniżej',
        },
        map: {
          type: 'streetmap',
          label: 'Mapa',
          validate: ['required'],
          disabled: false,
        },

        options: {
          type: 'embeddedCollection',
          endpoint: '/api/page_block_pricelist_options',
          definitionRef: '#PageBlockPricelistOption',
          description: 'Elementy cennika',
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,

          properties: {
            translations: {
              type: 'translation',
              properties: {
                title: {
                  label: 'Title',
                  type: 'string',
                },
                price: {
                  type: 'string',
                  label: 'Ceny/godziny',
                },
                shortLead: {
                  type: 'string',
                  label: 'Informacja pod ceną',
                },
              },
            },
            stat: {},
          },
        },
      },
    },
  },
})
