import React from 'react'
import { Paper } from 'components/Paper'
import { FileManager } from 'components/file_manager/FileManager'

export const Manager = ({ schema: definitions }) => {
  return (
    <Paper>
      <FileManager definitions={definitions} />
    </Paper>
  )
}
