import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
// import { publish } from 'components/table/_mass'
import schema from '_schema/tourneyScore'
import routes from '../routes'
import { OpenIsCell } from '../table/cells/OpenIsCell'
// import { LANGS_IRI } from '_lib/langs'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Link from '@material-ui/core/Link'
import { Link as LinkRoute } from 'react-router-dom'

export const Collection = ({ ...rest }) => {
  const { match } = rest
  const columns = useMemo(
    () => [
      {
        columnId: 'questionText',
        header: translate('Pytanie'),
        accessor: `questionText`,
        filterable: true,
        sortable: true,
        width: '35%',
      },
      {
        columnId: 'answerAny',
        header: translate('Odpowiedź'),
        accessor: `answerAny`,
        filterable: true,
        sortable: true,
        width: '35%',
      },
      {
        columnId: 'score',
        header: translate('Punkty'),
        accessor: `score`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'open',
        header: translate('Pytanie otwarte'),
        accessor: `open`,
        filterable: true,
        sortable: true,
        width: '5%',
        Cell: OpenIsCell,
      },
      {
        columnId: 'operations',
        ...operations(`questionText`, true, false, false, true, false),
        width: '5%',
      },
    ],
    []
  )

  const defaultFilters = {
    'tourney.uuid': match.params.pid,
    'user.uuid': match.params.uid,
  }

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonPath={''}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        {translate('Turniej - wyniki')}
      </CollectionHeader>
      <Link
        component={LinkRoute}
        to={routes().rankingIndex.path.replace(':pid', match.params.pid)}
      >
        <span color="primary">
          <ArrowBackIcon color="primary" style={{ verticalAlign: 'middle' }} />
          Ranking
        </span>
      </Link>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ id: 'asc' }}
        orderable={false}
        // selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes()
          .scoreEdit.path.replace(':pid', match.params.pid)
          .replace(':uid', match.params.uid)}
        autoWidth={false}
        defaultFilters={defaultFilters}
        parameters={[
          {
            name: 'tourney.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'user.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        storeCollectionId={
          schema.endpoint + match.params.pid + match.params.uid
        }
      />
    </Paper>
  )
}
