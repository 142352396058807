import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/customer'
import { LoginLogCollection } from './LoginLogCollection'
import { LoginLogEdit } from './LoginLogEdit'
import { Collection as ShopOrderCollection } from '../ShopOrder/Collection'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'

const basePath = '/customers_deleted'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: PersonAddDisabledIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      loginLogCollection: {
        subtitle: 'T_MODULE_CUSTOMER_LOGIN_LOG',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={LoginLogCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/login-log`,
        exact: true,
      },
      loginLogEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={LoginLogEdit}
            {...props}
          />
        ),
        path: `${basePath}/login-log/:id`,
        exact: true,
      },
      orderCollection: {
        subtitle: 'T_MODULE_CUSTOMER_ORDERS',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ShopOrderCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/orders`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_CUSTOMER_DEL',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
