import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { MassUpload } from 'components/MassUpload'
import { Form } from 'components/form'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { commonConstants } from '_constants'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  secondStepContainer: {
    textAlign: 'left',
  },
  chosenFiles: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  applyToAll: {
    fontSize: '1.1em',
    marginBottom: 10,
  },
}))

export const ResourceMediaAddMultiple = ({
  parentIri,
  fileEndpoint,
  itemEndpoint,
  itemProperties,
  fileProperty = 'file',
  fileType = 'file',
  validators = ['maxSize'],
  accept = null,
  hint = null,
  disabled = false,
  onUploadSuccess = null,
  onResourceSubmit = null,
  onResourceCancel = null,
  formWidth = 600,
  buttonNodeRef = null,
  classes = {},
}) => {
  const [files, setFiles] = useState([])

  const [state, setState] = useState({
    current: null,
    isFetching: false,
  })

  const dispatch = useDispatch()

  const onMassUploadSuccess = response => {
    onUploadSuccess && onUploadSuccess(response)

    setFiles(response['hydra:member'])
  }

  const onMassResourceSubmit = resource => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    uploadItem({ ...resource, parent: parentIri }, state.current || 0)
  }

  const uploadItem = (resource, index) => {
    setState({
      ...state,
      isFetching: true,
      current: index,
    })

    const file = files[index]

    fetchDataHandleAuthError(
      itemEndpoint,
      'POST',
      {
        body: JSON.stringify({
          ...resource,
          [fileProperty]: file,
        }),
      },
      () => {
        if (index + 1 < files.length) {
          uploadItem(resource, index + 1)
        } else {
          setState(state => ({
            ...state,
            isFetching: false,
          }))

          onResourceSubmit && onResourceSubmit(resource)

          dispatch({ type: commonConstants.INIT_RELOAD })

          notification(
            'success',
            'T_FORM_RECORD_MULTIPLE_CREATED',
            'T_FORM_SUCCESS'
          )
        }
      },
      error => {
        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const onMassResourceCancel = e => {
    onResourceCancel && onResourceCancel(e)
  }

  const defaultClasses = useStyles()

  return !files.length ? (
    <MassUpload
      type={fileType}
      hint={hint}
      endpoint={fileEndpoint}
      disabled={disabled}
      validators={validators}
      accept={accept}
      onSuccess={onMassUploadSuccess}
      buttonNodeRef={buttonNodeRef}
    />
  ) : (
    <div
      className={clsx(
        defaultClasses.secondStepContainer,
        classes.secondStepContainer
      )}
    >
      <div className={clsx(defaultClasses.chosenFiles, classes.chosenFiles)}>
        {state.current === null
          ? translate('T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN').replace(
              '%',
              files.length
            )
          : `${translate(
              'T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING'
            )} ${state.current + 1} ${translate(
              'T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM'
            )} ${files.length}`}
      </div>
      <div className={clsx(defaultClasses.applyToAll, classes.applyToAll)}>
        {translate('T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO')}
      </div>
      <Form
        url={itemEndpoint}
        method="POST"
        disabled={disabled || state.isFetching}
        width={formWidth}
        properties={itemProperties}
        handleSubmit={onMassResourceSubmit}
        handleCancel={onMassResourceCancel}
        showSubmitAndStayButton={false}
        buttonContainerRef={buttonNodeRef}
      />
    </div>
  )
}

ResourceMediaAddMultiple.propTypes = {
  parentIri: PropTypes.string.isRequired,
  fileEndpoint: PropTypes.string.isRequired,
  itemEndpoint: PropTypes.string.isRequired,
  itemProperties: PropTypes.object.isRequired,
  fileProperty: PropTypes.string,
  fileType: PropTypes.oneOf(['file', 'image']),
  validators: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  onResourceSubmit: PropTypes.func,
  onResourceCancel: PropTypes.func,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  buttonNodeRef: PropTypes.object,
  classes: PropTypes.shape({
    secondStepContainer: PropTypes.string,
    chosenFiles: PropTypes.string,
    applyToAll: PropTypes.string,
  }),
}
