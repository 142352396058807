import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'
import { LANGS_IRI } from '../../_lib/langs'
import {translate} from "../../_helpers/translate";

export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: {
          type: 'string',
          description: 'Subtitle',
        },
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'T_GENERAL_ALT',
          type: 'string',
        },
      },
    },
    thumbType: {
      label: 'Rodzaj kadru',
      type: 'resource',
      endpoint: '/api/thumb_types?pagination=false',
      titleAccessor: 'trans',
      transTitle: true,
      validate: ['required'],
      initialValue: {
        value: '/api/thumb_types/CROPPED',
        title: translate('T_THUMB_TYPE_CROPPED'),
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
