import React from 'react'
import { IconButton } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { prop } from '../../../../_helpers/prop'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    minWidth: 32,
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  sendButton: {},
})

export const contracts = (
  accessor = `startDate`,
  header,
  url = null,
  urlTitle = '<Unknown>'
) => ({
  header: translate(header),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({ resource, accessor }) => {
    const classes = useStyles()

    const contractsUrl = url.replace(':id', prop(resource, 'uuid'))

    return (
      <div className={classes.root}>
        <span>{prop(resource, accessor)}</span>
        {url && (
          <IconButton
            component={Link}
            to={contractsUrl}
            color="primary"
            className={clsx(classes.sendButton)}
            variant="contained"
          >
            <Assignment />
          </IconButton>
        )}
      </div>
    )
  },
})
