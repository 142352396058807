import React from 'react'
import { Route } from 'react-router-dom'
import { Group } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { AccommodationCollection } from './AccommodationCollection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/accommodationUser'
import { AccommodationEdit } from './AccommodationEdit'
import { AccommodationNew } from './AccommodationNew'

const basePath = '/accommodation_users'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: Group,
        allowedParks: ['TATRZANSKI'],
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },

      // /*
      accommodationIndex: {
        subtitle: 'Lista kwater użytkownika',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={AccommodationCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/accommodations`,
        exact: true,
      },
      accommodationNew: {
        subtitle: 'Nowe pole',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={AccommodationNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/accommodations/new`,
        exact: true,
      },
      accommodationEdit: {
        subtitle: 'Edycja pola',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={AccommodationEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/accommodations/:id`,
        exact: true,
      },

      // */
    },
    {
      title: 'Użytkownicy',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
