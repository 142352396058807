import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/guide_languages',
  resource: {
    definition: '#GuideLanguage',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
        },
      },
      isoCode: {
        type: FIELD_TYPES.STRING,
        description: '2 znakowy kod ISO języka',
        hint:
          'https://pl.wiktionary.org/wiki/Wikis%C5%82ownik:Kody_j%C4%99zyk%C3%B3w',
        validate: ['required'],
      },
      stat: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
