import React from 'react'
import { Form } from 'pages'
import schema from '_schema/shopProduct'
import routes from './routes'

export const New = ({ schema: definitions }) => (
  <Form
    definitionSchema={definitions[schema.resource.definition]}
    definitions={definitions}
    customResourceSchema={schema.resource}
    method="POST"
    url={schema.endpoint}
    collectionPath={routes().index.path}
    editPath={routes().edit.path}
    storeCollectionId={schema.endpoint}
    modifyProperties={(properties, resource) => {
      if (!resource?.subscription) {
        delete properties.subscriptionFrom
        delete properties.sender
      }

      return properties
    }}
    width={600}
  />
)
