import seaEyeFiacreSchema from './seaEyeFiacre'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/sea_eye_horses',
  access: 'seaEyeFiacre',
  resource: {
    definition: '#SeaEyeHorse',
    properties: {
      fiacres: {
        type: 'multipleResource',
        endpoint: `${seaEyeFiacreSchema.endpoint}?stat=true&pagination=false`,
        titleAccessor: 'title',
        validate: ['required'],
      },
      name: {
        description: 'T_GENERAL_GUIDE_NAME',
        validate: ['required'],
      },
      passportNumber: {
        validate: ['required'],
      },
      chipNumber: {
        validate: ['required'],
      },
      registeredAt: {
        type: 'date',
        validate: ['required'],
      },
      withdrawnAt: {
        type: 'date',
      },
      isLicenceExpired: {},
    },
  },
  subresources: {
    files: {
      endpoint: '/api/sea_eye_horse_files',
      definition: '#SeaEyeHorseFile',
      properties: {
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: mediaSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
      },
    },
  },
}

export default schema
