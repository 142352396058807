const schema = {
  endpoint: '/api/volunteer_user_contracts',
  resource: {
    definition: '#VolunteerUserContract',
    properties: {
      startDate: {
        type: 'datetime',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_DATE_FROM',
        validate: ['required'],
      },
      endDate: {
        type: 'datetime',
        description: 'T_MODULE_VOLUNTEERING_TIMESHEET_DATE_TO',
        validate: ['required'],
      },
      /*
      contractNumber: {
        type: 'string',
        description: 'Numer kontraktu',
        validate: ['required'],
      },*/
      contractIdcard: {
        type: 'string',
        description: 'Numer umowy',
        validate: ['required'],
      },
    },
  },
}

export default schema
