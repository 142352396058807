import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'
import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/shop_categories',
  collectionEndpoint: '/api/shop_categories/select',
  clone_endpoint: '/api/shop_category/clone',
  resource: {
    definition: '#ShopCategory',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
            description: 'T_GENERAL_DESCRIPTION',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      defaultSorter: {
        label: 'Domyślne sortowanie',
        type: 'resource',
        endpoint: '/api/shop_category_sorters',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/shop_category_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
