import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/lesson_config',
  resource: {
    definition: '#LessonConfig',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          description: {
            description: 'Opis pod formularzem',
            label: 'Opis pod formularzem',
            type: 'tinymce',
          },
        },
      },
      rules: {
        type: 'resource',
        endpoint: `/api/pages?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Regulamin',
      },
      notificationEmail: {
        description: 'Adres email na który bedą wysyłane powiadomienia',
        validate: ['email'],
      },
    },
  },
}

export default schema
