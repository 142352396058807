import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'components/root'
import { isLogged } from '_helpers/isLogged'
import { store } from '_store'

isLogged(store.dispatch)

const render = Component =>
  ReactDOM.render(<Component />, document.querySelector('#root'))

render(Root)

if (module.hot) {
  module.hot.accept('./components/root', () => {
    const NextRoot = require('./components/root').default
    render(NextRoot)
  })
}
