import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const embed = () => ({
  name: BLOCK_TYPES_IRI.EMBED,
  label: 'T_GENERAL_BLOCK_TYPE_EMBED',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'textarea',
          description: 'Opis',
        },
        text2: {
          description: 'T_GENERAL_BLOCK_TYPE_EMBED_TEXT',
          type: 'textarea',
        },
      },
    },
  },
})
