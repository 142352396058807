import React, { useMemo } from 'react'
import { HomeBox } from 'pages/Home/HomeBox'
import { standard } from 'components/table/_columns/standard'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/adminGroup'
import routes from 'pages/AdminGroups/routes'

export const AdminGroups = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('title'),
        width: '90%',
      },
      {
        ...operations(null, false, false, false, true),
        header: translate('T_GENERAL_DATE'),
        width: '10%',
      },
    ],
    []
  )

  return (
    <HomeBox
      columns={columns}
      route={routes().index}
      endpoint={schema.endpoint}
      isRowLinkable={true}
      editPath={routes().edit.path}
      definition={schema.resource.definition}
    />
  )
}
