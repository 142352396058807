import _block from 'pages/TourismNoticeArticle/_blocks'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/tourism_notice_article',
  getEndpoint: '/api/tourism_notice_articles/:uuid',
  getCollectionEndpoint: '/api/tourism_notice_articles',
  version_endpoint: '/api/tourism_notice_article/version',
  resource: {
    definition: '#TourismNoticeArticle',
    properties: {},
  },
  subresources: {
    block: {
      endpoint: '/api/tourism_notice_article_blocks',
      definition: '#TourismNoticeArticleBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/tourism_notice_article_block_thumbs',
        '/api/tourism_notice_article_block_anchors',
        '/api/tourism_notice_article_block_files',
        '/api/tourism_notice_article_block_links',
        '/api/tourism_notice_article_block_link_thumbs',
        '/api/tourism_notice_article_block_list_items',
        '/api/tourism_notice_article_block_list_item_thumbs',
        '/api/tourism_notice_article_block_pricelist_items',
        '/api/tourism_notice_article_block_banners',
        '/api/tourism_notice_article_block_banner_thumbs',
        '#TourismNoticeArticleBlockListItem',
        '#TourismNoticeArticleBlockLink'
      ).types,
    },
  },
}

export default schema
