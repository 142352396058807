import React from 'react'
import { useHistory } from 'react-router-dom'

export const IsLicenceExpiredCell = ({
  resource,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  return (
    <div onClick={onClick}>
      {resource.withdrawnAt
        ? resource.isLicenceExpired
          ? 'TAK'
          : 'NIE'
        : 'N/D'}
    </div>
  )
}
