import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const linkshome = (
  blockLinkEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.LINKSHOME,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_LINKS',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'T_GENERAL_TITLE',
          type: 'string',
        },
      },
    },
    links: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINKS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockLink',
      endpoint: blockLinkEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            link: {
              type: 'string',
              description: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
              label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
              fullWidth: true,
            },
          },
        },
        targetBlank: {
          description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
          label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        },
      },
    },
    // blockStyle: {
    //   label: 'T_BLOCK_STYLE_LABEL',
    //   type: 'resource',
    //   endpoint: '/api/block_styles',
    //   titleAccessor: 'trans',
    //   transTitle: true,
    // },
    // twoColumns: {
    //   description: 'T_BLOCK_TWO_COLUMNS_LABEL',
    // },
  },
})
