import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const shopBenefits = () => ({
  name: BLOCK_TYPES_IRI.SHOP_BENEFITS,
  label: 'T_GENERAL_BLOCK_TYPE_SHOP_BENEFITS',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
  },
})
