import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/seoRedirect'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'oldPath',
        header: translate('T_SEO_REDIRECT_OLD_PATH'),
        accessor: `oldPath`,
        filterName: 'oldPath',
        filterable: true,
        width: '15%',
      },
      {
        columnId: 'newPath',
        header: translate('T_SEO_REDIRECT_NEW_PATH'),
        accessor: `newPath`,
        filterName: 'newPath',
        filterable: true,
        width: '20%',
      },
      {
        columnId: 'redirectType',
        header: translate('T_SEO_REDIRECT_TYPE'),
        accessor: `redirectType`,
        filterName: 'redirectType',
        filterable: true,
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(`oldPath`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_SEO_REDIRECT_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_SEO_REDIRECT')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        numerable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'oldPath',
            in: 'query',
            type: 'string',
          },
          {
            name: 'newPath',
            in: 'query',
            type: 'string',
          },
          {
            name: 'redirectType',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
