import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'

const FORMAT = 'YYYY-MM-DD'

export const DateCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource?.uuid))
    }
  }

  const value = prop(resource, accessor)
  const warsawTime = moment
    .utc(value)
    .utcOffset('+0200') //TODO czas leni +2 zimowy chyba ma +1
    .format(FORMAT)

  return <div onClick={onClick}>{value && warsawTime}</div>
}

DateCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
