import React from 'react'
import { Route } from 'react-router-dom'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/trail'
import { ReviewCollection } from './Review/ReviewCollection'
import { ReviewNew } from './Review/ReviewNew'
import { ReviewEdit } from './Review/ReviewEdit'

const basePath = '/trails'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: AssignmentOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
        registryEntryDefinition: schema.resource.definition,
      },
      reviews: {
        subtitle: 'T_MODULE_TRAIL_REVIEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ReviewCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/reviews`,
        exact: true,
      },
      reviewNew: {
        subtitle: 'T_MODULE_TRAIL_REVIEW_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ReviewNew}
            {...props}
          />
        ),
        path: `${basePath}/reviews/new`,
        exact: true,
      },
      reviewEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ReviewEdit}
            {...props}
          />
        ),
        path: `${basePath}/reviews/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_TRAIL',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
