import { LANGS_IRI } from '../_lib/langs'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/main_menus',
  endpointProvider: '/api/main_menus/provider',
  access: 'mainMenu',
  resource: {
    definition: '#MainMenu',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Title',
            hint: 'jeśli wpisany, będzie użyty zamiast nazwy strony',
          },
          link: {
            hint: 'wpisz link lub wybierz stronę poniżej',
          },

          bannerLink: {},
          bannerText: {
            type: 'textarea',
          },
          bannerAlt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },

      page: {
        type: 'resource',
        endpoint: `/api/pages?pagination=false`,
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,
      },

      media: {
        type: 'image',
        description: '[BANNER] Zdjęcie',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/main_menu_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              title: media.translations[LANGS_IRI.PL].title,
              bannerAlt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },

      targetBlank: {},
      stat: {},
    },
  },
}

export default schema
