import React, { useState, useEffect } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import FolderIcon from '@material-ui/icons/FolderOpenOutlined'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchTree } from './_helpers/fetchTree'
import { TreeLeaf } from './TreeLeaf'

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
}))

export const Tree = ({ treeRefresh, handleTreeRefresh, handleCurrentDir }) => {
  const [tree, setTree] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const [expandedLeaves, setExpandedLeaves] = useState([])

  const handleToggleLeaf = e => {
    const target = e.target.getAttribute('[path]')
      ? e.target
      : e.target.closest('[path]')

    const path = target.getAttribute('path')

    let modified = JSON.parse(JSON.stringify(expandedLeaves))

    if (expandedLeaves.includes(path)) {
      modified = modified.filter(dir => dir !== path)
    } else {
      modified = [...modified, path]
    }

    setExpandedLeaves(modified)
  }

  const handleDirChoose = e => {
    const target = e.target.getAttribute('[path]')
      ? e.target
      : e.target.closest('[path]')

    const path = target.getAttribute('path')

    handleCurrentDir(path)
  }

  useEffect(() => {
    const aborter = fetchTree(setTree, setIsFetching)

    return () => aborter()
  }, [treeRefresh])

  const classes = useStyles()

  return (
    <Grid item xs={3}>
      {isFetching ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ padding: 20 }}
        >
          <Loader />
        </Grid>
      ) : (
        <List component="nav">
          <ListItem button key="main-dir-wrapper">
            <ListItemIcon style={{ minWidth: 32 }} key="main-dir-icon">
              <FolderIcon />
            </ListItemIcon>
            <ListItemText
              primary="/"
              key="main-dir"
              path="/"
              onClick={handleDirChoose}
            />
          </ListItem>

          <TreeLeaf
            leaf={tree}
            path="/"
            depth={1}
            expandedLeaves={expandedLeaves}
            handleToggleLeaf={handleToggleLeaf}
            handleDirChoose={handleDirChoose}
            handleTreeRefresh={handleTreeRefresh}
            handleCurrentDir={handleCurrentDir}
            classes={classes}
          />
        </List>
      )}
    </Grid>
  )
}
