import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/operations'
import { translate } from '_helpers/translate'
import { MainCellSave } from './table/cells/MainCellSave'
import { MainCellSendcount } from './table/cells/MainCellSendcount'
import schema from '_schema/accommodationMessage'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'mailTitle',
        header: translate('T_GENERAL_TITLE'),
        accessor: `mailTitle`,
        filterable: true,
        sortable: true,
        width: '30%',
      },
      {
        columnId: 'from',
        header: translate('Użytkownik'),
        accessor: `from`,
        filterable: true,
        sortable: true,
        width: '40%',
      },
      {
        columnId: 'adminReaded',
        header: translate('Nowa'),
        accessor: `adminReaded`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'sentCount',
        header: translate('Wysłanych'),
        accessor: `sentCount`,
        filterName: 'sentCount',
        filterable: true,
        Cell: MainCellSendcount,
        width: '5%',
      },
      {
        columnId: 'sentDate',
        header: translate('Data wysłania'),
        accessor: `sentDate`,
        filterName: 'sentDate',
        filterable: true,
        Cell: MainCellSave,
        width: '10%',
      },
      {
        columnId: 'operations',
        ...operations(`mailTitle`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Nowa wiadomość')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Wiadomości')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        defaultFilters={{
          'exists[parent]': false,
        }}
        parameters={[
          {
            name: 'exists[parent]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
      />
    </Paper>
  )
}
