import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { CreateNewFolderOutlined } from '@material-ui/icons'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { ResourceMediaAddMultiple } from './ResourceMediaAddMultiple'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const ResourceMediaAddMultipleDialog = ({
  title,
  parentIri,
  fileEndpoint,
  itemEndpoint,
  itemProperties,
  fileProperty = 'media',
  fileType = 'image',
  disabled = false,
  classes = {},
}) => {
  const [isMassUploadDialogOpen, setIsMassUploadDialogOpen] = useState(false)

  const handleMassUploadDialogToggle = useCallback(() => {
    setIsMassUploadDialogOpen(state => !state)
  }, [])

  const handleItemMassCancel = e => {
    e.preventDefault()
    handleMassUploadDialogToggle()
  }

  const handleResourceSubmit = () => {
    handleMassUploadDialogToggle()
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const defaultClasses = useStyles()

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<CreateNewFolderOutlined />}
        onClick={handleMassUploadDialogToggle}
      >
        {title}
      </Button>
      <Dialog
        maxWidth="md"
        onClose={handleMassUploadDialogToggle}
        open={isMassUploadDialogOpen}
      >
        <DialogTitleWithClose onClose={handleMassUploadDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          <ResourceMediaAddMultiple
            parentIri={parentIri}
            fileEndpoint={fileEndpoint}
            itemEndpoint={itemEndpoint}
            itemProperties={itemProperties}
            fileProperty={fileProperty}
            fileType={fileType}
            onResourceSubmit={handleResourceSubmit}
            onResourceCancel={handleItemMassCancel}
            buttonNodeRef={buttonRef}
            disabled={disabled}
          />
        </DialogContent>
        <DialogActions ref={onButtonRef}></DialogActions>
      </Dialog>
    </>
  )
}

ResourceMediaAddMultipleDialog.propTypes = {
  title: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  fileEndpoint: PropTypes.string.isRequired,
  itemEndpoint: PropTypes.string.isRequired,
  itemProperties: PropTypes.object.isRequired,
  fileProperty: PropTypes.string,
  fileType: PropTypes.oneOf(['file', 'image']),
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
}
