import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { Tooltip } from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { Link } from 'react-router-dom'
import routes from './../../routes'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#666', //theme.palette.success.main,
    height: 24,
  },
})

export const WynikiCell = ({ resource, accessor }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {resource.uuid ? (
        <>
          <Link
            to={routes()
              .scoreIndex.path.replace(':pid', accessor)
              .replace(':uid', resource.uid)}
            className={classes.icon}
          >
            <Tooltip title={translate('Wyniki')}>
              <ReceiptIcon />
            </Tooltip>
          </Link>
        </>
      ) : (
        <span style={{ width: 24 }}></span>
      )}
    </div>
  )
}
