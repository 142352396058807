import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const eventhome = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.EVENTHOME,
  label: labelTrans ?? 'Wydarzenia',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Title',
          type: 'string',
          hint: 'domyślnie trans "T_PAGE_SECTION_EVENTS_TITLE"',
        },
        shortLead: {
          label: 'Subtitle',
          type: 'textarea',
          hint: 'domyślnie trans "T_PAGE_SECTION_EVENTS_SUBTITLE"',
        },
        linkTitle: {
          type: 'string',
          label: 'Tytuł guzika',
          hint: 'domyślnie trans "T_PAGE_SECTION_EVENTS_SEE_ALL_BUTTON"',
        },
      },
    },
  },
})
