import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import CallMissedIcon from '@material-ui/icons/CallMissed'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
// import { CloneCell } from 'components/table/cells/CloneCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
// import { commonConstants } from '_constants'
import routes from 'pages/MainMenu/routes'
import clsx from 'clsx'
import red from '@material-ui/core/colors/red'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  icon: {
    color: '#666', //theme.palette.success.main,
    height: 24,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const operations = (
  accessor = `title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneable = true
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)

    // const dispatch = useDispatch()

    const onSuccess = () => {
      // dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    const maxNesting = 4

    const isSubPageable =
      profile?.openform || resource.nestingLevel < maxNesting

    const showMenu = editable || cloneable || deletable || isSubPageable

    return (
      <div className={classes.root}>
        {showMenu && (
          <>
            {resource.parent && (
              <Link
                to={
                  resource.parent.parent
                    ? routes().indexParent.path.replace(
                        ':id',
                        `${
                          resource.parent.parent[
                            process.env.REACT_APP_RESOURCE_ID
                          ]
                        }`
                      )
                    : routes().index.path
                }
                className={classes.icon}
              >
                <Tooltip title={translate('Powrót do menu wyżej')}>
                  <CallMissedIcon />
                </Tooltip>
              </Link>
            )}

            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx(
                classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (resource.nestingLevel > 2 || profile?.openform) && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    // disabled={!profile?.openform && !resource.isEditable}
                    editPath={editPath}
                  />
                  {resource.nestingLevel <= 2 && profile?.openform && (
                    <span>[OF]</span>
                  )}
                </MenuItem>
              )}
              {isSubPageable && (
                <>
                  <MenuItem className={classes.menuItem}>
                    <Link
                      to={routes().newParent.path.replace(
                        ':pid',
                        `${resource[process.env.REACT_APP_RESOURCE_ID]}`
                      )}
                      className={classes.link}
                    >
                      {translate('T_MODULE_PAGES_ADD_SUBPAGE')}
                      <span style={{ color: red[800] }}>
                        {profile?.openform &&
                        !(resource.nestingLevel < maxNesting)
                          ? ' [OF]'
                          : ''}
                      </span>
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.menuItem}>
                    <Link
                      to={routes().indexParent.path.replace(
                        ':id',
                        `${resource[process.env.REACT_APP_RESOURCE_ID]}`
                      )}
                      className={classes.link}
                    >
                      {translate('Podstrony')}
                      <span style={{ color: red[800] }}>
                        {profile?.openform &&
                        !(resource.nestingLevel < maxNesting)
                          ? ' [OF]'
                          : ''}
                      </span>
                    </Link>
                  </MenuItem>
                </>
              )}
              {/* {cloneable && (
                <MenuItem className={classes.menuItem}>
                  <CloneCell
                    resource={resource}
                    disabled={!!resource.idName}
                    onSuccess={onSuccess}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )} */}
              {(resource.nestingLevel > 2 || profile?.openform) && (
                <MenuItem className={classes.menuItem}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    // disabled={!profile?.openform && !resource.isDeletable}
                    onSuccess={onSuccess}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                  {resource.nestingLevel <= 2 && profile?.openform && (
                    <span>[OF]</span>
                  )}
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {1 && (
          <StatusCell
            resource={resource}
            // disabled={!profile?.openform && !resource.isStatable}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
