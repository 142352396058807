const schema = {
  endpoint: '/api/searched_words',
  access: 'searchedWord',
  resource: {
    definition: '#SearchedWord',
    properties: {
      word: {
        description: 'Szukane słowo'
      },
      num: {
        description: 'Ilość'
      },
    },
  },
}

export default schema
