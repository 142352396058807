import churchiscoFiacreSchema from './churchiscoFiacre'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/churchisco_history_entries',
  access: 'churchiscoFiacre',
  resource: {
    definition: '#ChurchiscoHistoryEntry',
    properties: {
      fiacres: {
        type: 'multipleResource',
        endpoint: `${churchiscoFiacreSchema.endpoint}?stat=true&pagination=false`,
        titleAccessor: 'title',
        validate: ['required'],
      },
      occuredAt: {
        type: 'date',
        validate: ['required'],
      },
      description: {
        type: 'tinymce',
        validate: ['required'],
      },
    },
  },
  subresources: {
    files: {
      endpoint: '/api/churchisco_history_entry_files',
      definition: '#ChurchiscoHistoryEntryFile',
      properties: {
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: mediaSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
      },
    },
  },
}

export default schema
