import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Button,
  Card,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { Logo } from 'components/Logo'
import { Copyright } from 'components/Copyright'
import { translate } from '_helpers/translate'
import { authActions } from '_actions'
import { validate } from 'email-validator'
import { boolean } from '_helpers/boolean'
import { useStyles } from '.'
//import { setCurrentTitle } from '../../_helpers/setCurrentTitle'

export const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    _remember_me: false,
    submitted: false,
  })
  const dispatch = useDispatch()

  //setCurrentTitle()

  const handleChange = e => {
    const { name, value } = e.target
    setState(state => ({
      ...state,
      [name]: name === '_remember_me' ? Boolean(value) : value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState(state => ({ ...state, submitted: true }))
    const { email, password, _remember_me } = state

    if (validate(email) && password) {
      dispatch(authActions.login(email, password, _remember_me))
    }
  }

  const isLogging = useSelector(state => state.auth.isLogging)
  const { email, password, _remember_me, submitted } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Logo className={classes.logo} />
        <div className={classes.avatar}>
          <Avatar className={classes.lock}>
            <LockOutlined />
          </Avatar>
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <div className={classes.form_inner}>
            <TextField
              className={classes.input}
              error={submitted && !validate(email)}
              helperText={
                submitted && !validate(email)
                  ? translate('T_VALIDATION_EMAIL')
                  : null
              }
              value={email}
              onChange={handleChange}
              {...emailInputConfig()}
            />
            <TextField
              className={classes.input}
              error={submitted && !password}
              helperText={
                submitted && !password
                  ? translate('T_VALIDATION_REQUIRED')
                  : null
              }
              value={password}
              onChange={handleChange}
              {...passwordInputConfig()}
            />
            {boolean(process.env.REACT_APP_REMEMBER_ME_SHOW) ? (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.remember_me_checkbox}
                    disableRipple
                    size="small"
                    name="_remember_me"
                    value={_remember_me}
                    onChange={handleChange}
                  />
                }
                label={translate('T_GENERAL_REMEMBER_ME')}
                className={classes.remember_me}
              />
            ) : null}
          </div>
          <Button
            disabled={isLogging}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLogging ? (
              <img
                className={classes.spinner}
                src="/img/react-logo.svg"
                alt="loading"
              />
            ) : (
              translate('T_GENERAL_LOGIN')
            )}
          </Button>
        </form>
      </Card>
      {boolean(process.env.REACT_APP_RESET_PASSWORD_SHOW) ? (
        <Link to="/reset-password" className={classes.lower_link}>
          {translate('T_GENERAL_FORGOT_PASSWORD')}
        </Link>
      ) : null}
      <Box mt={4}>
        <Copyright />
      </Box>
    </div>
  )
}

const emailInputConfig = () => ({
  required: true,
  id: 'email',
  name: 'email',
  label: translate('T_FORM_FIELD_EMAIL'),
  margin: 'normal',
  fullWidth: true,
  autoComplete: 'email',
  autoFocus: true,
})

const passwordInputConfig = () => ({
  required: true,
  id: 'password',
  name: 'password',
  type: 'password',
  label: translate('T_FORM_FIELD_PASSWORD'),
  margin: 'normal',
  fullWidth: true,
  autoComplete: 'current-password',
})
