import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const finalist = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint
) => ({
  name: BLOCK_TYPES_IRI.FINALIST,
  label: 'Blok z laureatami',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    listItems: {
      type: 'collection',
      label: 'Laureaci',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint, //'#XXXXBlockListItem', // tu chyba powinien być konkretny ref... pasuje do wszystkich encji np Page ?
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              label: 'Imię i nazwisko laureata',
            },
            text: {
              type: 'string',
              label: 'Miejsce/informacja',
              hint: 'np cyfry 1,2,3 lub "wyróżnienie"'
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        puchar: {
          label: 'Pokazuj ikonę pucharu',
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: photoEndpoint,
          thumbs: blockListItemEndpointThumbEndpoint && {
            endpoint: `${blockListItemEndpointThumbEndpoint}?name[]=finalist`,
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
})
