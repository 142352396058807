export const traverseNested = (processed, nested) => {
  const list = []

  for (const page of nested) {
    let current = { ...page }

    const fresh = processed.find(element => element['@id'] === page['@id'])

    if (fresh) {
      current = {
        ...current,
        ...fresh,
        children: page.children,
      }

      processed.filter(element => element['@id'] === fresh['@id'])
    }

    current.children = traverseNested(processed, page.children)

    list.push(current)
  }

  return list
}
