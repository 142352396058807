import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const crew = () => ({
  name: BLOCK_TYPES_IRI.CREW,
  label: 'Blok z zespołem',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
          hint: 'Domyślnie trans T_CONTACT_CREW'
        },
      },
    },

  },
})
