import React, { useMemo, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/shopVariant'
import routes from './routes'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { LANGS_IRI } from '../../_lib/langs'
import { applyProperties } from '../../_helpers/applyProperties'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const defaultData = { parent: iri }

  const itemProperties = useMemo(
    () =>
      applyProperties(
        schema.subresources.options.properties,
        definitions[schema.subresources.options.definition].properties,
        'missing'
      ),
    [definitions]
  )

  const [modalResponse, setModalResponse] = useState(null)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        if (resource?.defaultVariant) {
          delete properties.stat
        }
        return properties
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
      sidebarDisableStat={resource => {
        if (resource?.defaultVariant) {
          return true
        }
        return false
      }}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.options.endpoint}?iri[parent]=${iri}&order[ord]=asc&pagination=false`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.options.properties}
        definitionSchema={definitions[schema.subresources.options.definition]}
        headerTitle={translate('T_MODULE_SHOP_VARIANT_OPTIONS_HEADER_TITLE')}
        expandTitle={translate('T_MODULE_SHOP_VARIANT_OPTIONS_EXPAND_TITLE')}
        panelTitle={translate('T_MODULE_SHOP_VARIANT_OPTION_TITLE')}
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        statable={true}
        modalResponse={modalResponse}
        setModalResponse={setModalResponse}
        hideAddButton={true}
      />
      <AddModal
        title={translate('T_MODULE_SHOP_VARIANT_OPTIONS_NEW_BUTTON')}
        definitionSchema={definitions[schema.subresources.options.definition]}
        itemProperties={itemProperties}
        defaultData={defaultData}
        endpoint={schema.subresources.options.endpoint}
        itemSchema={schema}
        setModalResponse={setModalResponse}
      />
    </Form>
  )
}
