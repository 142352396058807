import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const textWithPhotoFloat = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO_FLOAT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO_FLOAT',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        sectionTitle: {
          description: 'Tekst górny',
          type: 'tinymce',
        },
        text: {
          description: 'Tekst boczny',
          type: 'tinymce',
        },
        text2: {
          description: 'Tekst dolny',
          type: 'tinymce',
        },
        columnTitle1: {
          description: 'Tytuł zdjęcia',
          type: 'string',
        },
        columnTitle2: {
          description: 'Autor zdjęcia',
          type: 'string',
        },
        alt: {
          description: 'T_GENERAL_ALT',
          type: 'string',
        },
      },
    },
    imageFloat: {
      type: 'resource',
      endpoint: '/api/image_float_types',
      titleAccessor: 'trans',
      transTitle: true,
      validate: ['required'],
      description: 'Położenie obrazka',
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
