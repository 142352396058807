import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/lessonSchedule'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { DateCell } from '../../components/table/cells/DateCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'lesson.translations.title',
        header: translate('Lekcja'),
        accessor: `lesson.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        filterName: 'lesson.translations.title',
        sortable: true,
        sorterName: 'lesson.translations.title',
        width: '30%',
      },
      {
        columnId: 'educator.fullName',
        header: translate('Edukator'),
        accessor: `educator.fullName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'date',
        header: translate('Data'),
        accessor: 'date',
        filterable: true,
        sortable: true,
        Cell: DateCell,
        width: '15%',
      },
      {
        columnId: 'status',
        header: translate('Status'),
        accessor: `status.trans`,
        translateAccessor: true,
        filterable: true,
        sortable: true,
        sorterName: 'status',
        width: '7%',
      },
      {
        columnId: 'operations',
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          false,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_LESSON_SCHEDULE_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_LESSON_SCHEDULE')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'educator.fullName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lesson.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'date',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[lesson.translations.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[educator.fullName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[date]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[status]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
