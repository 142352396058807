import React from 'react'
import { Form } from 'pages'
import schema from '_schema/matterFormConfigGroup'
import routes from './routes'
import { useSelector } from 'react-redux'

export const New = ({ schema: definitions }) => {
  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={600}
      modifyProperties={properties => {
        if (profile?.openform) {
          properties.idName = {
            type: 'string',
            description: {
              text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
              color: '#cc0000',
            },
          }
        }

        return properties
      }}
    />
  )
}
