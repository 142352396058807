import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/volunteering_config',
  resource: {
    definition: '#VolunteeringConfig',
    properties: {
      clausePage: {
        type: 'resource',
        endpoint: `/api/pages?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Strona klauzuli informacyjnej',
        hint:
          'Gdy akcja wolontariacka nie ma uzupełnionego linku do klauzuli informacyjnej, to zostanie użyta ta strona.',
      },
      email: {
        type: 'string',
        label: 'Adres email',
        hint: 'Wyświetlany w wiadomości email',
      },
      phone: {
        type: 'string',
        label: 'Numer telefonu',
        hint: 'Wyświetlany w wiadomości email',
      },
      normalTemplate: {
        label: 'Wyświetlaj szablon artykułu',
        hint:
          'Wyświetlaj artykuł zamiast listy akcji wolontariackich na podstronie "wolontariat"',
      },
    },
  },
}

export default schema
