import districtSchema from './lessonDistrict'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/lesson_communes',
  resource: {
    definition: '#LessonCommune',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required'],
          },
        },
      },
      parent: {
        type: 'resource',
        endpoint: `${districtSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Powiat',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
