import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { MenuOutlined, MenuOpen, RefreshOutlined } from '@material-ui/icons'
import { UserMenu } from 'components/menu'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { ContextualHintSwitch } from 'components/ContextualHintSwitch'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import { useStyles } from './styles'
import schema from '_schema/profile'

export const TopBar = ({ breadcrumbs, isSidebarOpen, isMobile, loading }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [reloadIcon, setReloadIcon] = useState('refresh')

  const handleInitReload = () => {
    setReloadIcon('loader')
    dispatch({ type: commonConstants.INIT_RELOAD })
    setTimeout(() => setReloadIcon('refresh'), 700)
  }

  const handleSidebarToggle = () => {
    dispatch({ type: commonConstants.SIDEBAR_TOGGLE })
  }

  return (
    <AppBar
      position="absolute"
      className={clsx(
        classes.appBar,
        isSidebarOpen && classes.appBarSidebarOpened
      )}
    >
      <Toolbar className={classes.toolbar}>
        {!isMobile && (
          <Tooltip title={translate('T_GENERAL_TOGGLE_MENU')}>
            <IconButton
              edge="start"
              onClick={handleSidebarToggle}
              className={classes.sidebarClose}
            >
              {isSidebarOpen ? <MenuOpen /> : <MenuOutlined />}
            </IconButton>
          </Tooltip>
        )}
        {loading ? (
          <Skeleton animation="wave" className={classes.skeleton} />
        ) : (
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            className={classes.breadcrumbs}
          />
        )}
        <ContextualHintSwitch profileEndpoint={schema.endpoint} />
        {reloadIcon === 'refresh' ? (
          <Tooltip title={translate('T_GENERAL_REFRESH')}>
            <IconButton onClick={handleInitReload}>
              <RefreshOutlined color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
          <CircularProgress
            color="primary"
            size="1.2rem"
            className={classes.reloadLoader}
          />
        )}
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  breadcrumbs: PropTypes.shape({
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
      })
    ),
    custom: PropTypes.bool,
  }),
  isSidebarOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
}
