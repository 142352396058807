const schema = {
  endpoint: '/api/shop_elektroniczny_nadawca_locations',
  resource: {
    definition: '#ShopElektronicznyNadawcaLocation',
    properties: {
      title: {
        type: 'string',
        description: 'Nazwa',
        validate: ['required'],
      },
      pni: {
        type: 'number',
        description: 'PNI',
        validate: ['required'],
      },
    },
  },
}

export default schema
