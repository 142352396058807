import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  delete: {
    color: theme.palette.error.main,
  },
  update: {
    color: theme.palette.info.main,
  },
  create: {
    color: theme.palette.success.main,
  },

  link: {
    color: theme.palette.common.black,
  },
}))

export const TitleCell = ({ resource, accessor, routes = [] }) => {
  let title = prop(resource, accessor)

  const classes = useStyles()

  let path = null

  const entityClass = prop(resource, 'parentClass')
    ? prop(resource, 'parentClass')
    : prop(resource, 'resourceClass')

  const uuid = prop(resource, 'parentUuid')
    ? prop(resource, 'parentUuid')
    : prop(resource, 'resourceUuid')

  routes.forEach(route => {
    if (route?.registryEntryDefinition === `#${entityClass}`) {
      path = route.path.replace(':id', uuid)
    }
  })

  if (!title) {
    title = '<brak tytułu>'
  }

  if (prop(resource, 'method') === 'DELETE') {
    return <div>{title}</div>
  }

  if (!path) {
    return <div>{title}</div>
  }

  return (
    <div>
      <a className={classes.link} href={path}>
        {title}
      </a>
    </div>
  )
}

TitleCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.string,
  routes: PropTypes.array,
}
