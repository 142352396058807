import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const alert = () => ({
  name: BLOCK_TYPES_IRI.ALERT,
  label: 'T_GENERAL_BLOCK_TYPE_ALERT',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        shortLead: {
          type: 'tinymce',
          description: 'T_GENERAL_TITLE',
          label: 'T_GENERAL_TITLE',
        },
      },
    },
    alertStyle: {
      label: 'T_ALERT_STYLE_LABEL',
      type: 'resource',
      endpoint: '/api/alert_styles',
      titleAccessor: 'trans',
      transTitle: true,
    },
    alertIconType: {
      label: 'T_ALERT_ICON_LABEL',
      type: 'resource',
      endpoint: '/api/alert_icon_types',
      titleAccessor: 'trans',
      transTitle: true,
    },
  },
})
