import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const goodtoknown = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.GOODTOKNOWN,
  label: labelTrans ?? 'Blok dobrze wiedziec',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    listItems: {
      type: 'collection',
      label: 'Taby',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint, //'#XXXXBlockListItem', // tu chyba powinien być konkretny ref... pasuje do wszystkich encji np Page ?
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              label: 'Title',
            },
            infoTitle: {
              label: 'Subtitle',
              type: 'string',
            },
            text: {
              type: 'tinymce',
              label: 'Opis',
            },
            linkTitle: {
              label: 'Tytuł buttona',
              type: 'string',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },

        // targetBlank: {
        //   description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        //   label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        // },

        page: {
          label: 'Strona ze struktury',
          type: 'resource',
          endpoint: `/api/pages?pagination=false`,
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,
        },

        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: photoEndpoint,
          thumbs: blockListItemEndpointThumbEndpoint && {
            endpoint: `${blockListItemEndpointThumbEndpoint}?name[]=banner`,
          },
          validate: ['maxSize'],
          // minDimensions: '683 x 537'
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
})
