const schema = {
  endpoint: '/api/tourney_package_scores',
  access: 'tourneys',
  resource: {
    definition: '#TourneyPackageScore',
    properties: {
      questionText: {
        description: 'Pytanie',
        disabled: true,
      },
      answerText: {
        description: 'Odpowiedz udzielona',
        disabled: true,
      },
      open: {
        description: 'Czy pytanie było otwarte',
        disabled: true,
      },
      openText: {
        type: 'textarea',
        description: 'Odpowiedz wpisana na pytanie otwarte',
        disabled: true,
      },
      score: {
        description: 'Punkty',
        hint: 'można przyznać punkt gdy pytanie było otwarte',
        max: 1,
        min: 0,
      },
    },
  },
}

export default schema
