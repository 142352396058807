import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const text = () => ({
  name: BLOCK_TYPES_IRI.TEXT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        shortLead: {
          description: 'T_GENERAL_LEAD',
          type: 'tinymce',
        },
        text: {
          description: 'T_GENERAL_BLOCK_TYPE_TEXT_COLUMN1',
          type: 'tinymce',
        },
        text2: {
          description: 'T_GENERAL_BLOCK_TYPE_TEXT_COLUMN2',
          type: 'tinymce',
        },
        link: {
          type: 'string',
          description: 'Link',
          hint: 'np. http://example.com'
        },
        linkTitle: {
          type: 'string',
          description: 'T_GENERAL_LINK_TITLE',
        },
      },
    },
  },
})
