import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { Loader } from 'components/Loader'
import { translate } from '_helpers/translate'

const useStyles = makeStyles({
  input: {
    textAlign: 'left',
    width: 160,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const MatterStatusFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  error = false
}) => {
  const [open, setOpen] = React.useState(false)

  const handleChange = e => {
    const value = e.target.value
    setFilter(name, value || null)
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleEnter && handleEnter()
        break
      default:
        break
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const defaultClasses = useStyles()

  const [isFetching, setIsFetching] = useState(false)
  const [resource, setResource] = useState([])

  useEffect(
    () => {
      let controller

      if (controller) {
        controller.abort()
      }

      controller = new AbortController()
      const { signal } = controller

      if (isFetching === true) {
        return () => controller.abort()
      }

      setIsFetching(true)

      fetchDataHandleAuthError(
        `/api/matter_form_statuses`,
        'GET',
        { signal },
        response => {
          if (response['hydra:member']) {
            setResource(response['hydra:member'])
            setIsFetching(false)
          } else {
            notification('error', 'Brak typów')
          }
        },
        error => {
          if (error.response.title === 'AbortError') {
            setIsFetching(true)
          } else {
            setIsFetching(false)
            notification('error', error.response.detail, error.response.title)
          }
        }
      )
      return () => controller.abort()
    },
    // eslint-disable-next-line
    []
  )

  return (
    <div>
      <FormControl className={clsx(defaultClasses.input, classes.input)} error={error}>
        {isFetching ? (
          <Loader />
        ) : (
          <Select
            labelId={`demo-controlled-open-select-label-${name}`}
            id={`demo-controlled-open-select-id-${name}`}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value || ''}
            onChange={handleChange}
            name={`filter-text-${name}`}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            // input={<InputBase style={{color:'red'}} />}
          >
            <MenuItem value="" key={`null_nothing-${name}`}>
              <em>wybierz</em>
            </MenuItem>
            {resource.map(r => {
              return ( r.value > 0 &&
                <MenuItem key={`${name}-${r.value}`} value={r.value}>
                  {translate(r.trans)}
                </MenuItem>
              )
            })}
          </Select>
        )}
        <FormHelperText>{title}</FormHelperText>
      </FormControl>
    </div>
  )
}
