const schema = {
  endpoint: '/api/package_faqs',
  access: 'packageFaq',
  resource: {
    definition: '#PackageFaq',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_faq_items',
      definition: '#PackageFaqItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            description: { type: 'textarea' },
          },
        },
      },
    },
  },
}

export default schema
