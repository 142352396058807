import { authHeader } from './authHeader'
import { handleAuthError } from './handleAuthError'
import { processError } from './processError'
import { AuthError } from './authError'
import { getApiUrl } from '../_services/apiUrlProvider'

export const fetchFileHandleAuthError = (
  url,
  method = 'GET',
  request = {},
  handleSuccess = () => {},
  handleError = () => {},
  headers = {},
  absoluteUrl = false
) =>
  fetch(absoluteUrl ? url : `${getApiUrl()}${url}`, {
    method,
    headers: {
      'Content-Type': 'application/ld+json',
      ...headers,
      ...authHeader(),
    },
    ...request,
  })
    .then(response => {
      switch (response.status) {
        case 200:
          return response
        case 401:
          return Promise.reject(new AuthError())
        default:
          return Promise.reject({
            response: {
              title: response.status,
              detail: response.statusText,
              violations: [],
            },
          })
      }
    })
    .then(
      response => handleSuccess(response),
      error => {
        handleAuthError.default(error)

        return handleError(processError(error))
      }
    )
