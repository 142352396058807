import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
  slug: {
    marginTop: 15,
  },
  link: {
    color: '#555',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkAdmin: {
    color: 'red',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginTop: 10,
    height: 28,
    fontSize: 14,
  },
})

export const ArticlePreviewUuidType = ({
  name,
  routes,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  translationLang,
  fullWidth = false,
}) => {
  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const profile = useSelector(state => state.profile)

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      {value && (
        <div>
          <a
            href={
              getApiUrl() +
              routes[translationLang].replace(':uuid', value) +
              `?access_token=${profile?.contentAccessToken}`
            }
            className={classes.linkAdmin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Visibility />}
            >
              Podgląd administratora
            </Button>
          </a>
        </div>
      )}
    </>
  )
}

ArticlePreviewUuidType.propTypes = {
  name: PropTypes.string.isRequired,
  routes: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  translationLang: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}
