import shopPaymentMethod from './shopPaymentMethod'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_shipping_types',
  resource: {
    definition: '#ShopShippingType',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required', 'length(1)'],
          },
          description: {
            type: 'tinymce',
            label: 'Opis dostawy na podsumowaniu zamówienia',
          },
        },
      },
      cashOnDelivery: {
        description: 'Przesyłka pobraniowa',
      },
      operator: {
        label: 'T_MODULE_SHOP_SHIPPING_TYPE_OPERATOR',
        type: 'resource',
        endpoint: '/api/shop_shipping_operators',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      paymentMethods: {
        type: 'multipleResource',
        endpoint: `${shopPaymentMethod.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_SHOP_SHIPPING_TYPE_PAYMENT_TYPES',
      },
      showPersonalCollectionAlert: {
        type: 'alert',
        description: 'T_SHOP_SHIPPING_OPERATOR_IN_PERSON_ALERT',
        variant: 'filled',
        severity: 'warning',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
