import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    margin: '-16px 0'
  },
  item: {
    borderBottom: `1px solid ${theme.palette.disabled}`,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    padding: '14px 20px',
    '&:last-of-type' : {
      borderBottom: 0
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
}))
