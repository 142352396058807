export const modifyPropertiesByPage = (properties, resource, schema) => {
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  if (resource && resource.nestingLevel === 1) {
    return modifiedProperties
  } else {
    delete modifiedProperties.translations.properties.bannerLink
    delete modifiedProperties.translations.properties.bannerAlt
    delete modifiedProperties.translations.properties.bannerText
    delete modifiedProperties.media
  }
  return modifiedProperties
}
