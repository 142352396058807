import React, { useMemo } from 'react'
import { HomeBox } from 'pages/Home/HomeBox'
import { standard } from 'components/table/_columns/standard'
import { operations } from 'components/table/_columns/operations'
import { ResultCell } from 'pages/LoginEntries/table/cells/ResultCell'
import { translate } from '_helpers/translate'
import schema from '_schema/loginEntry'
import routes from 'pages/LoginEntries/routes'

export const LoginEntries = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('username'),
        width: '55%',
      },
      {
        ...standard('result'),
        width: '35%',
        Cell: ResultCell,
      },
      {
        ...operations(null, false, false, false, true),
        header: translate('T_GENERAL_DATE'),
        width: '10%',
      },
    ],
    []
  )

  return (
    <HomeBox
      columns={columns}
      route={routes().index}
      endpoint={schema.endpoint}
      definition={schema.resource.definition}
    />
  )
}
