import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const links_trail = (
  photoEndpoint,
  blockLinkEndpoint,
  blockLinkEndpointThumbEndpoint
) => ({
  name: BLOCK_TYPES_IRI.LINKS,
  label: 'T_GENERAL_BLOCK_TYPE_LINKS',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        shortLead: {
          description: 'Title',
          type: 'string',
        },
      },
    },
    links: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINKS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#TrailBlockLink',
      endpoint: blockLinkEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            link: {
              type: 'string',
              description: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
              label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
              fullWidth: true,
            },
            alt: {
              description: 'T_GENERAL_ALT_PICTOGRAM',
            },
          },
        },
        targetBlank: {
          description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
          label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        },
        // media: {
        //   type: 'image',
        //   description: 'T_GENERAL_PICTOGRAM',
        //   endpoint: photoEndpoint,
        //   thumbs: blockLinkEndpointThumbEndpoint && {
        //     endpoint: blockLinkEndpointThumbEndpoint,
        //   },
        //   validate: ['maxSize'],
        // },
      },
    },
    // blockStyle: {
    //   label: 'T_BLOCK_STYLE_LABEL',
    //   type: 'resource',
    //   endpoint: '/api/block_styles',
    //   titleAccessor: 'trans',
    //   transTitle: true,
    // },
    // twoColumns: {
    //   description: 'T_BLOCK_TWO_COLUMNS_LABEL',
    // },
    // showPictograms: {
    //   description: 'T_BLOCK_SHOW_PICTOGRAMS_LABEL',
    // },
  },
})
