import React, {useCallback} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {Form, NotFound} from 'pages'
import {Paper} from 'components/Paper'
import {Title} from 'components/Title'
import {Loader} from 'components/Loader'
import {useResourceState} from '_helpers/useResourceState'
import {useResourceFetch} from '_helpers/useResourceFetch'
import {modifyPropertiesByPage} from './_helpers/modifyPropertiesByPage'
import {translate} from '_helpers/translate'
import schema from '_schema/mainMenu'
import routes from './routes'

export const New = ({ schema: definitions, ...rest }) => {

  const pid = rest.match.params.pid
  const defaultData = pid
    ? { parent: `${schema.endpoint}/${pid}` }
    : {  }

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  useResourceFetch(
    pid && `${schema.endpoint}/${pid}`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const isNotEditable = false

  const history = useHistory()

  const handleSuccess = useCallback(() => {
    history.push(
      pid ? routes().indexParent.path.replace(':id', pid) : routes().index.path
    )
  }, [history, pid])

  const handleSuccessAndStay = useCallback(
    resource => {
      history.push(
        pid
          ? routes().indexParent.path.replace(':id', pid)
          : routes().index.path
      )
      history.push(
        routes().edit.path.replace(
          ':id',
          resource[process.env.REACT_APP_RESOURCE_ID]
        )
      )
    },
    [history, pid]
  )
  const handleCancel = () => {
    history.goBack()
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : pid && fetchError ? (
    <NotFound />
  ) : isNotEditable ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        return modifyPropertiesByPage(properties, resource, schema)
      }}
      method="POST"
      url={schema.endpoint}
      storeCollectionId={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      handleCancel={handleCancel}
      header={
        <Title>
          {translate(pid ? 'T_MODULE_PAGES_ADD_SUBPAGE' : 'T_GENERAL_NEW')}
        </Title>
      }
      fieldsFullWidth={false}
      width={'100%'}
    />
  )
}
