import React from 'react'
import { Route } from 'react-router-dom'
import { Email } from '@material-ui/icons'
import { BuforCollection } from './BuforCollection'
import { SchemableComponent } from 'components/SchemableComponent'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/shopProduct'
import { BuforEdit } from './BuforEdit'
import { BuforNew } from './BuforNew'
import { LocationCollection } from './LocationCollection'
import { LocationNew } from './LocationNew'
import { LocationEdit } from './LocationEdit'

const basePath = '/elektroniczny-nadawca/bufor'
const locationPath = '/elektroniczny-nadawca/location'

const routes = () =>
  addRoutesConfig(
    {
      buforIndex: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <BuforCollection {...props} />,
        path: basePath,
        exact: true,
        icon: Email,
      },
      buforNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={BuforNew}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      buforEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={BuforEdit}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      locationIndex: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <LocationCollection {...props} />,
        path: locationPath,
        exact: true,
      },
      locationNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={LocationNew}
            {...props}
          />
        ),
        exact: true,
        path: `${locationPath}/new`,
      },
      locationEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={LocationEdit}
            {...props}
          />
        ),
        path: `${locationPath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Elektroniczny nadawca',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
