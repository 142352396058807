const schema = {
  endpoint: '/api/volunteer_users',
  resource: {
    definition: '#VolunteerUser',
    properties: {
      email: {
        validate: ['required', 'email'],
      },
      rawPassword: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['password'],
      },
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
        validate: ['required'],
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
        validate: ['required'],
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      pesel: {
        description: 'T_GENERAL_PESEL',
      },
      education: {
        type: 'resource',
        endpoint: '/api/education_levels',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Poziom wykształcenia',
      },
      fieldOfStudy: {
        description: 'Kierunek studiów',
      },
      nameOfTheUniversity: {
        description: 'Nazwa uczelni',
      },
      universityAddress: {
        description: 'Adres uczelni',
      },
      yearOfGraduation: {
        description: 'Rok ukończenia',
      },
      stillStudying: {
        description: 'Czy nadal studiuje?',
      },
      yearStarted: {
        description: 'Rok rozpoczęcia',
      },
      notWorking: {
        description: 'Nie pracuje',
      },
      workPosition: {
        description: 'Stanowisko',
      },
      workContract: {
        type: 'resource',
        endpoint: '/api/employment_contract_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Typ umowy',
      },
      fte: {
        type: 'resource',
        endpoint: '/api/employment_ftes',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Etat',
      },
      workName: {
        description: 'Nazwa firmy',
      },
      workAddress: {
        description: 'Adres firmy',
      },
      accommodationType: {
        label: 'Zakwaterowanie',
        type: 'resource',
        endpoint: '/api/volunteering_accommodation_types',
        titleAccessor: 'trans',
        transTitle: true,
      },
      consentHealth: {
        description: 'Oświadczenie o zdrowiu',
      },
      consentInformationDisclaimer: {
        description: 'Zgoda na klauzulę informacyjną',
      },
      consentDataPrivacy: {
        description: 'Zgoda na przetwarzanie danych',
      },
      consentNewsletter: {
        description: 'Zgoda na newsletter',
      },
      deleted: {
        description: 'Konto usunięte',
        hint:
          'konto poprawnie usunięte przez użytkownika, operacja potwierdzona linkiem',
      },
      status: {
        label: 'Status',
        type: 'resource',
        endpoint: '/api/volunteer_account_status_types',
        titleAccessor: 'trans',
        transTitle: true,
      },
    },
  },
}

export default schema
