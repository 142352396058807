import { BLOCK_TYPES_IRI } from '_lib/blockTypesBip'

export const entitiesMap = () => ({
  name: BLOCK_TYPES_IRI.ENTITIES_MAP,
  label: 'T_GENERAL_BLOCK_TYPE_ENTITIES_MAP',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
  },
})
