const schema = {
  endpoint: '/api/tourney_config',
  access: 'tourneyConfig',
  resource: {
    definition: '#TourneyConfig',
    properties: {


      emailTourney: {
        description: 'Adresy email na które bedą wysyłane powiadomienia',
        hint: 'można wpisać kilka rozdzielonych przecinkami',
      },


    },
  },
}

export default schema
