import _media from './_media'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/package_logotypes',
  access: 'packageLogotype',
  resource: {
    definition: '#PackageLogotype',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_logotype_items',
      definition: '#PackageLogotypeItem',
      types: _media(
        photoSchema.endpoint.single,
        '/package_logotype_item_thumbs'
      ).types,
    },
  },
}

export default schema
