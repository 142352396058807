import React from 'react'
import { Form } from 'pages'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import schema from '_schema/weatherLocation'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const profile = useSelector(state => state.profile)
  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
      modifyProperties={properties =>
        addOpenformProperties(profile?.openform, properties, null, null, null)
      }
    ></Form>
  )
}
