import photoSchema from './photo'
import fileSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/home_banners',
  access: 'homeBanner',
  resource: {
    definition: '#HomeBanner',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          videoDescription: {
            type: 'string',
            label: 'Opis filmu',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/home_banner_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      mediaMobile: {
        type: 'image',
        description: 'Zdjęcie na stronie mobile',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/home_banner_thumbs',
        },
        validate: ['maxSize'],
      },
      file: {
        type: 'file',
        description: 'T_GENERAL_FILE_VIDEO',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize(20971520)'],
      },
      hasSound: {
        type: 'boolean',
        description: 'Zawiera dźwięk',
      },
      transcription: {
        type: 'file',
        description: 'Plik video z transkrypcją',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize(20971520)'],
      },
      audioDescription: {
        type: 'file',
        description: 'Plik audio z audiodeskrypcją',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize(20971520)'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
