const schema = {
  endpoint: '/api/accommodation_categories',
  clone_endpoint: '/api/accommodation_categories/clone',
  access: 'accommodationCategory',
  resource: {
    definition: '#AccommodationCategory',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      listPriority: {
        description: 'Priorytet wyświetlania na liście',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
