import locationSchema from './shopElektronicznyNadawcaLocation'

const schema = {
  endpoint: '/api/shop_elektroniczny_nadawca_bufors',
  resource: {
    definition: '#ShopElektronicznyNadawcaBufor',
    properties: {
      sendDate: {
        type: 'date',
        description: 'Data nadania',
        validate: ['required'],
      },
      location: {
        type: 'resource',
        endpoint: `${locationSchema.endpoint}?pagination=false`,
        titleAccessor: 'title',
        description: 'Miejsce nadania',
        validate: ['required'],
      },
      /*
      sendLocation: {
        type: 'number',
        description: 'Miejsce nadania',
        validate: ['required'],
      },*/
    },
  },
}

export default schema
