import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const homegetmore = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.HOMEGETMORE,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },

    listItems: {
      type: 'collection',
      label: 'Kafle',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint,
      endpoint: blockListItemEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              label: 'Title',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },

        page: {
          label: 'Strona ze struktury',
          type: 'resource',
          endpoint: `/api/pages?pagination=false`,
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,
        },

        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: photoEndpoint,
          thumbs: blockListItemEndpointThumbEndpoint && {
            endpoint: `${blockListItemEndpointThumbEndpoint}?name[]=banner&name[]=slim`,
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
})
