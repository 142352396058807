import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    // marginLeft: '1.0em',
  },
})

export const TimeCell = ({ resource }) => {
  const classes = useStyles()

  var days = Math.floor(resource.timers / (1.0 * 60 * 60 * 24))
  var hours = Math.floor(
    (resource.timers % (1.0 * 60 * 60 * 24)) / (1.0 * 60 * 60)
  )
  var minutes = Math.floor((resource.timers % (1.0 * 60 * 60)) / (1.0 * 60))
  var seconds = Math.floor((resource.timers % (1.0 * 60)) / 1.0)

  const timecontainer = `${days ? days + 'd ' : ''}${
    hours ? hours + ':' : ''
  }${minutes}:${seconds < 10 ? '0' + seconds : seconds}`

  return (
    <div className={classes.image}>
      <div className={classes.title}>
        {timecontainer}
        <br />
        <span style={{ fontSize: '0.8em' }}>{resource.timers} sek</span>
      </div>
    </div>
  )
}
