import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const trails = (photoEndpoint, thumbEndpoint, blockFileEndpoint) => ({
  name: BLOCK_TYPES_IRI.TRAILS,
  label: 'T_GENERAL_BLOCK_TYPE_TRAILS',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        columnTitle1: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_TITLE1',
        },
        columnTitle2: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_TITLE2',
        },
        columnHint1: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_HINT1',
        },
        columnHint2: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_HINT2',
        },
        text: {
          description: 'T_BLOCK_TRAILS_TEXT1',
          type: 'tinymce',
        },
        text2: {
          description: 'T_BLOCK_TRAILS_TEXT2',
          type: 'tinymce',
        },
        link: {
          type: 'string',
          description: 'Link',
        },
        linkTitle: {
          type: 'string',
          description: 'T_GENERAL_LINK_TITLE',
        },
      },
    },
    files: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_TRAILS_FILES',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockFile',
      endpoint: blockFileEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
          },
        },
        file: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: photoEndpoint,
          validate: ['maxSize'],
        },
      },
    },
    embed: {
      type: 'textarea',
      description: 'T_GENERAL_BLOCK_TYPE_TRAILS_EMBED',
      label: 'T_GENERAL_BLOCK_TYPE_TRAILS_EMBED',
      fullWidth: true,
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=single_photo`,
      },
      minDimensions: '960 x 340',
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
