import _block from './_blockVolunteering'
import photoSchema from './photo'
import mediaSchema from './media'
import categorySchema from './volunteeringCategory'
import locationSchema from './volunteeringLocation'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/volunteerings',
  endpoinT_SELECT_INFO: '/api/volunteering/select',
  clone_endpoint: '/api/volunteering/clone',
  access: 'volunteering',
  resource: {
    definition: '#Volunteering',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          formDescription: {
            description: 'T_MODULE_VOLUNTEERING_FORM_DESCRIPTION',
            label: 'T_MODULE_VOLUNTEERING_FORM_DESCRIPTION',
            type: 'textarea',
          },
          clauseLink: {
            description: 'Link do klauzuli informacyjnej',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/wolontariat/:slug',
              [LANGS_IRI.EN]: '/en/volunteering/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/volunteering_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      dateFrom: {
        type: 'date',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'date',
        description: 'T_GENERAL_DATE_TO',
        validate: ['required'],
      },
      vacancies: {
        description: 'T_MODULE_VOLUNTEERING_VACANCIES',
        validate: ['required'],
        initialValue: 0,
      },
      guardian: {
        description: 'T_MODULE_VOLUNTEERING_GUARDIAN',
      },
      guardianEmail: {
        description: 'T_MODULE_VOLUNTEERING_GUARDIAN_EMAIL',
        hint: 'T_MODULE_VOLUNTEERING_GUARDIAN_EMAIL_HINT',
      },
      address: {},
      category: {
        type: 'resource',
        endpoint: `${categorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORY',
      },
      location: {
        type: 'resource',
        endpoint: `${locationSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_VOLUNTEERING_LOCATION_SELECT',
      },
      suggested: {
        type: 'boolean',
        description: 'T_GENERAL_SUGGESTED',
      },
      adminCanEdit: {},
      status: {
        type: 'resource',
        endpoint: '/api/volunteering_status_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_GENERAL_STATUS',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/volunteering_blocks',
      definition: '#VolunteeringBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/volunteering_block_thumbs',
        '/api/volunteering_block_anchors',
        '/api/volunteering_block_files',
        '/api/volunteering_block_links',
        '/api/volunteering_block_link_thumbs',
        '/api/volunteering_block_list_items',
        '/api/volunteering_block_list_item_thumbs'
      ).types,
    },
  },
}

export default schema
