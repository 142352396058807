import React from 'react'
import { Route } from 'react-router-dom'
import { Reorder } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/shopProduct'

const basePath = '/shop_products'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: Reorder,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
        registryEntryDefinition: parentSchema.resource.definition,
      },
    },
    {
      title: 'T_MODULE_SHOP_PRODUCT',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
