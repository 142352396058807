import React from 'react'
import { Form } from 'pages'
import schema from '_schema/volunteeringConfig'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width="100%"
      ></Form>
    </div>
  )
}
