import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const newsCategories = () => ({
  name: BLOCK_TYPES_IRI.NEWS_CATEGORIES,
  label: 'T_GENERAL_BLOCK_TYPE_NEWS_CATEGORIES',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint:
            'Gdy tytuł jest pusty zostanie użyty trans "T_SECTION_FEATURED_NEWS_TITLE"',
        },
      },
    },
    newsCategories: {
      type: 'multipleResource',
      endpoint: `/api/news_categories?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      description: 'Kategorie',
      hint: 'zostaw puste jeśli chcesz użyć wszystkich kategorii',
    },
  },
})
