import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

const useStyles = makeStyles({
  input: {
    width: '300px',
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
  input_horizontal: {
    width: 200,
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      padding: 5,
    },
  },
  autocomplete_input: {
    height: 40,
    padding: '5px !important',
  },
  label: {
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
  },
  row_input: {
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 9,
    marginBottom: 10,
    '& .MuiInputBase-input': {
      paddingTop: '4px !important',
    },
    '& fieldset': {
      border: 0,
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: 46,
    },
  },
  wrapper: {
    padding: '0px 4px',
  },
  select: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    height: 46,
    marginTop: 10,
    color: '#5A6169',
  },
  checkbox: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    maxHeight: 300,
  },
  mainButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export const MultiChoiceFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  choices = [],
  titleAccessor = 'title',
}) => {
  const handleChange = newSelected => {
    // choices for each key, value
    choices.forEach(choice => {
      if (newSelected.includes(choice.accessor)) {
        setFilter(choice.filterName, choice.value)
      } else {
        setFilter(choice.filterName, null)
      }
    })
  }

  const defaultClasses = useStyles()
  const [selected, setSelected] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const handleToggle = (accessor, value) => {
    // Toggle for "All"
    if (value === null) {
      setSelected([])
      handleChange([])
      return
    }

    // Uncheck "All" when selecting other options
    let newSelected = selected.filter(item => item !== null)
    const currentIndex = newSelected.indexOf(accessor)

    if (currentIndex === -1) {
      newSelected.push(accessor)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setSelected(newSelected)
    handleChange(newSelected)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getSelectedText = () => {
    if (selected.length === 0) {
      return 'Wszystko'
    }

    let currentTitles = []
    selected.forEach(accessor => {
      const option = choices.find(option => option.accessor === accessor)
      currentTitles.push(option?.title)
    })

    return currentTitles.join(', ')
  }

  return (
    <div>
      <List style={{ paddingTop: 0 }}>
        <ListItem
          button
          onClick={handleClick}
          className={defaultClasses.mainButton}
        >
          <ListItemText primary={getSelectedText()} />
          <IconButton edge="end" className={defaultClasses.mainButton}>
            <KeyboardArrowDown />
          </IconButton>
        </ListItem>
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={defaultClasses.list}
      >
        <MenuItem className={defaultClasses.listItem}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.length === 0}
                onChange={() => handleToggle('all', null)}
                className={defaultClasses.checkbox}
              />
            }
            label="Wszystko"
          />
        </MenuItem>
        {Object.keys(choices).map(key => (
          <MenuItem key={key} className={defaultClasses.listItem}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.includes(choices[key]?.accessor)}
                  onChange={() =>
                    handleToggle(choices[key]?.accessor, choices[key]?.value)
                  }
                  className={defaultClasses.checkbox}
                />
              }
              label={choices[key]?.title ?? 'Brak tytułu'}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

MultiChoiceFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  choices: PropTypes.array,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  titleAccessor: PropTypes.string,
}
