import { authHeader } from './authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '../_services/apiUrlProvider'

export const currentHostnameIri = setHostnameIri =>
  fetch(`${getApiUrl()}/api/current_hostname_iri`, {
    method: 'GET',
    headers: {
      ...authHeader(),
    },
  })
    .then(
      response => {
        if (!response.ok) {
          notification(
            'error',
            'T_API_HOSTNAME_IRI_ERROR_TITLE',
            'T_API_HOSTNAME_IRI_ERROR_TEXT'
          )

          return ''
        }

        return response.json()
      },
      () => {}
    )
    .then(data => {
      setHostnameIri(data?.iri)
    })
