// import _block from './_block'
import _block from '../pages/Trail/_blocks'
import photoSchema from './photo'
import mediaSchema from './media'
import subcategorySchema from './trailSubcategory'
import categorySchema from './trailCategory'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/trails',
  clone_endpoint: '/api/trail/clone',
  access: 'trail',
  resource: {
    definition: '#Trail',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'textarea',
            description: 'Krótki opis szlaku',
          },
          /*
          beginning: {
            description: 'T_MODULE_TRAIL_BEGINNING',
          },
          end: {
            description: 'T_MODULE_TRAIL_END',
          },
          surface: {
            description: 'T_MODULE_TRAIL_SURFACE',
          },
          averageUpDescription: {
            description: 'T_MODULE_TRAIL_TIME_UP_DESCRIPTION',
          },
          averageDownDescription: {
            description: 'T_MODULE_TRAIL_TIME_DOWN_DESCRIPTION',
          },*/
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/szlaki-turystyczne/:slug',
              [LANGS_IRI.EN]: '/en/trails/:slug',
            },
          },
        },
      },
      /*
      slope: {
        description: 'T_MODULE_TRAIL_SLOPE',
      },
      distance: {
        description: 'T_MODULE_TRAIL_DISTANCE',
      },
      averageUp: {
        description: 'T_MODULE_TRAIL_TIME_UP',
      },
      averageDown: {
        description: 'T_MODULE_TRAIL_TIME_DOWN',
      },
       */
      file: {
        type: 'file',
        description: 'Plik ze śladem gpx',
        label: 'Plik ze śladem gpx',
        endpoint: mediaSchema.endpoint,
        // validate: ['maxSize(20971520)'],
        accept: '.xml, .gpx',
        restrictManagerMimeType: '.xml, .gpx',
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/trail_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      categories: {
        type: 'multipleResource',
        endpoint: `${categorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_TRAIL_CATEGORY',
      },
      subcategories: {
        type: 'multipleResource',
        endpoint: `${subcategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_TRAIL_SUBCATEGORY',
      },
      suggested: {
        type: 'boolean',
        description: 'T_GENERAL_SUGGESTED',
      },
      adminCanEdit: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/trail_blocks',
      definition: '#TrailBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/trail_block_thumbs',
        '/api/trail_block_anchors',
        '/api/trail_block_files',
        '/api/trail_block_links',
        '/api/trail_block_link_thumbs',
        '/api/trail_block_list_items',
        '/api/trail_block_list_item_thumbs'
      ).types,
    },
    parameters: {
      endpoint: '/api/trail_parameters',
      definition: '#TrailParameter',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'Tytuł',
              hint:
                'Predefiniowane parametry mają zdefiniowany tytuł w tłumaczeniach!',
              type: 'string',
            },
            value: {
              description: 'Wartość',
              type: 'string',
              fullWidth: true,
            },
            hoverDescription: {
              description: 'Opis hovera',
            },
          },
        },
        value: {
          description: 'Wartość',
          type: 'number',
          fullWidth: true,
        },
        predefinedType: {
          type: 'resource',
          endpoint: '/api/trail_parameter_types',
          titleAccessor: 'trans',
          transTitle: true,
          description: 'Predefiniowany parametr',
          disabled: true,
          fullWidth: true,
        },
      },
    },
    information: {
      endpoint: '/api/trail_informations',
      definition: '#TrailInformation',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            text: {
              description: 'T_GENERAL_DESCRIPTION',
              label: 'T_GENERAL_DESCRIPTION',
              type: 'tinymce',
            },
            alt: {
              description: 'T_GENERAL_PREDEFINED_ICON_ALT',
            },
          },
        },
        predefinedIcon: {
          type: 'resourceWithIcon',
          endpoint: '/api/trail_information_predefined_icons',
          titleAccessor: 'trans',
          transTitle: true,
          description: 'T_GENERAL_PREDEFINED_ICON',
          iconBackground: 'none',
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_ICON',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/trail_information_thumbs',
          },
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
}

export default schema
