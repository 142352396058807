import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import schema from '_schema/managerMedia'

export const fetchTree = (setTree, setIsTreeFetching) => {
  setIsTreeFetching(true)

  const controller = new AbortController()
  const { signal } = controller

  fetchDataHandleAuthError(
    schema.endpoints.tree,
    'GET',
    { signal },
    response => {
      setIsTreeFetching(false)
      setTree(response['hydra:member'][0])
    },
    error => {
      setIsTreeFetching(false)

      if (error.response?.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
    }
  )

  return () => controller.abort()
}
