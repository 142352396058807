const schema = {
  endpoint: '/api/formset_saves',
  resource: {
    definition: '#FormsetSave',
    properties: {
      dataForm: {
        type: 'datetime',
      },

      stat: {},
    },
  },
}

export default schema
