import React from 'react'
import { Route } from 'react-router-dom'
import { AccountTreeOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/page'

export const PATH = '/pages'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: AccountTreeOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${PATH}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Edit}
            uniqueKey={true}
            {...props}
          />
        ),
        path: `${PATH}/:id`,
        exact: true,
        registryEntryDefinition: schema.resource.definition,
      },
    },
    {
      title: 'Pages',
      resourceAccess: schema.access,
      indexPath: PATH,
      // parent: `${schema.endpoint}/:id`,
    }
  )

export default routes
