import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { CustomSwitch } from './CustomSwitch'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { profileConstants } from '_constants'
import { notification } from '_helpers/notification'

export const ContextualHintSwitch = ({ profileEndpoint }) => {
  const value = useSelector(state => state.profile?.isContextualHintsVisible)
  const dispatch = useDispatch()

  const onChange = e => {
    const isContextualHintsVisible = e.target.checked

    fetchDataHandleAuthError(
      profileEndpoint,
      'PUT',
      { body: JSON.stringify({ isContextualHintsVisible }) },
      response => {
        const isContextualHintsVisible = response.isContextualHintsVisible

        dispatch({
          type: profileConstants.UPDATE,
          payload: { isContextualHintsVisible },
        })

        notification(
          'success',
          `T_FORM_CONTEXTUAL_HINTS_${isContextualHintsVisible ? 'ON' : 'OFF'}`,
          'T_FORM_SUCCESS'
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return value === undefined ? null : (
    <CustomSwitch
      name="contextual_hints_switch"
      checked={value}
      onChange={onChange}
    />
  )
}

ContextualHintSwitch.propTypes = {
  profileEndpoint: PropTypes.string.isRequired,
}
