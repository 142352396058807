import React, { useState } from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import { MoreVertOutlined, Print } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import clsx from 'clsx'
import { notification } from '../../../../_helpers/notification'
import { constants } from '../../../../components/table/_state'
import { fetchDataHandleAuthError } from '../../../../_helpers/fetchDataHandleAuthError'
import { getApiUrl } from '../../../../_services/apiUrlProvider'
import schipmentSchema from '../../../../_schema/shopElektronicznyNadawcaAddShipment'
import { authHeader } from '../../../../_helpers/authHeader'
import { Loader } from '../../../../components/Loader'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const buforOperations = (
  accessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneEndpoint = null
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)

    const dispatch = useDispatch()

    const onSuccess = () => {
      dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    const showMenu = editable || !!cloneEndpoint || deletable

    const [sendEnvelopeDisabled, setSendEnvelopeDisabled] = useState(false)

    const [sendEnvelopeModalOpen, setSendEnvelopeModalOpen] = useState(false)

    const handleChange = () => {
      setSendEnvelopeDisabled(true)
      fetchDataHandleAuthError(
        '/api/shop_elektroniczny_nadawca_send_envelope',
        'POST',
        {
          body: JSON.stringify({
            bufor: resource['@id'],
          }),
        },
        resource => {
          notification('success', 'Wysłano pakiet', 'T_FORM_SUCCESS')

          tableStateDispatch({ type: constants.RELOAD })
        },
        error => {
          setSendEnvelopeDisabled(false)
          notification('error', error.response.detail, error.response.title)
        }
      )
    }

    const handleOpenModal = () => {
      setSendEnvelopeModalOpen(true)
    }

    const handleCloseModal = () => {
      setSendEnvelopeModalOpen(false)
    }

    function download() {
      let xtitle = null
      fetch(`${getApiUrl()}${schipmentSchema.endpointMultiPdf}`, {
        method: 'POST',
        headers: {
          ...authHeader(),
        },
        body: JSON.stringify({ bufor: resource['@id'] }),
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Error')
          }
          xtitle = response.headers.get('x-title')
          return response.blob()
        })
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', xtitle ?? 'plik')

          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })
        .catch(e => {
          notification('error', 'Error, nie można pobrać', e)
        })
    }

    return (
      <div className={classes.root}>
        {resource?.ordersCount > 0 && (
          <IconButton
            onClick={() => {
              download()
            }}
          >
            <Print color={'secondary'} />
          </IconButton>
        )}
        {(resource?.finishedDate === null ||
          resource?.finishedDate === undefined) && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOpenModal}
            disabled={sendEnvelopeDisabled}
            style={{ minWidth: '100px' }}
          >
            {translate('Nadaj paczki')}
          </Button>
        )}
        {sendEnvelopeModalOpen && (
          <Dialog open={sendEnvelopeModalOpen} fullWidth={true} maxWidth="sm">
            <DialogTitle onClose={handleCloseModal}>
              {translate('Nadaj paczki')}
            </DialogTitle>
            <DialogContent dividers>
              {sendEnvelopeDisabled && (
                <Loader align="center" marginBottom={15} />
              )}
              <DialogContentText className={classes.confirmText}>
                {translate(
                  'Czy jesteś pewien? Cofnięcie operacji nie będzie możliwe'
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModal}
                disabled={sendEnvelopeDisabled}
                autoFocus
                variant="outlined"
              >
                {translate('T_GENERAL_CANCEL')}
              </Button>
              <Button
                onClick={handleChange}
                disabled={sendEnvelopeDisabled}
                variant="contained"
                color="primary"
              >
                {translate('Nadaj paczki')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {showMenu && (
          <>
            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx(
                classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isEditable}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {deletable && (
                <MenuItem className={classes.menuItem}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isDeletable}
                    onSuccess={onSuccess}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {statable && (
          <StatusCell
            resource={resource}
            disabled={!profile?.openform && !resource.isStatable}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
