import React from 'react'
import { Form } from 'pages'
import schema from '_schema/accommodationMessage'
import { addProperties } from './_helpers/addProperties'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const hostnameIri = null
  const profile = {}

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
      showSubmitAndStayButton={false}
      modifyProperties={(properties, resource) =>
        addProperties(
          profile?.openform,
          properties,
          resource,
          null,
          hostnameIri
        )
      }
    ></Form>
  )
}
