import { LANGS_IRI } from '../_lib/langs'

const schema = (photoEndpoint, thumbsEndpoint = null) => ({
  types: [
    {
      name: 'photo',
      label: 'T_GENERAL_MEDIA_PHOTO',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoEndpoint,
          thumbs: thumbsEndpoint && {
            endpoint: thumbsEndpoint,
          },
          validate: ['maxSize'],
        },
        stat: {
          description: 'T_GENERAL_PUBLICATION',
        },
      },
    },
    {
      name: 'youtube',
      label: 'T_GENERAL_MEDIA_YOUTUBE',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'T_GENERAL_VIDEO_MASK',
          endpoint: photoEndpoint,
          thumbs: thumbsEndpoint && {
            endpoint: thumbsEndpoint,
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        youtube: {
          type: 'youtube',
          description: 'T_GENERAL_YOUTUBE_CODE',
        },
        stat: {
          description: 'T_GENERAL_PUBLICATION',
        },
      },
    },
  ],
})

export default schema
