export const modifyPropertiesByResource = (properties, resource, schema) => {
  //example
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  switch (resource.operator?.name) {
    // Modify by page here
    case 'PRZELEWY24':
      delete modifiedProperties.accountNumber
      return modifiedProperties

    case 'WIRE_TRANSFER':
      delete modifiedProperties.merchantId
      delete modifiedProperties.developerMode
      delete modifiedProperties.crcKey
      delete modifiedProperties.reportsKey
      return modifiedProperties
    default:
      delete modifiedProperties.merchantId
      delete modifiedProperties.developerMode
      delete modifiedProperties.accountNumber
      delete modifiedProperties.crcKey
      delete modifiedProperties.reportsKey
      return modifiedProperties
  }
}
