import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import { StatusCell } from 'components/table/cells/StatusCell'
import schema from '_schema/guide'
import routes from './routes'
import { ControlList } from './components/ControlList'
import { exportselected } from './components/exportselected'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'name',
        header: translate('T_GENERAL_GUIDE_NAME'),
        accessor: 'name',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'surname',
        header: translate('Surname'),
        accessor: 'surname',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'email',
        header: translate('Email'),
        accessor: 'email',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'phone',
        header: translate('Phone'),
        accessor: 'phone',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'isGuide',
        header: translate('Is guide'),
        accessor: 'isGuide',
        filterable: true,
        sortable: true,
        Cell: StatusCell,
        width: '8%',
      },
      {
        columnId: 'isInstructor',
        header: translate('Is instructor'),
        accessor: 'isInstructor',
        filterable: true,
        sortable: true,
        Cell: StatusCell,
        width: '8%',
      },
      {
        columnId: 'isTPNEmployee',
        header: translate('Is TPN employee'),
        accessor: 'isTPNEmployee',
        filterable: true,
        sortable: true,
        Cell: StatusCell,
        width: '8%',
      },
      {
        columnId: 'operations',
        ...operations('title', true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_GUIDE_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
      >
        <div style={{ width: 400 }}>
          {translate('T_MODULE_GUIDE')}
          <ControlList />
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off(), exportselected.go()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'name',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'surname',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'isGuide',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'isInstructor',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'isTPNEmployee',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[name]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[surname]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[email]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[phone]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[isGuide]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[isInstructor]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[isTPNEmployee]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
