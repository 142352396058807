import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/trailSubcategory'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { status } from '../../components/table/_columns/status'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'translations.title',
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        sorterName: 'translations.title',
        filterable: true,
        sortable: true,
        width: '70%',
      },
      {
        columnId: 'isWarning',
        header: 'Ostrzeżenie',
        ...status('isWarning'),
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRAIL_SUBCATEGORY_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_TRAIL_SUBCATEGORY')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'translations.title',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[translations.title]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
