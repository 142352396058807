import React from 'react'
import schema from '../../../_schema/tourismNoticeArticle'
import routes from '../routes'
import { Form } from '../../Form'
import { LANGS_IRI } from '../../../_lib/langs'
import { SidebarNotStatable } from '../../../components/resource/sidebar'
import { BlockCollection } from '../../../components/block'
import { translate } from '../../../_helpers/translate'
import { EmbeddedCollection } from '../../../components/embedded'
import tourismNoticeArticleNoticeSchema from '../../../_schema/tourismNoticeArticleNotice'
import { Paper } from '../../../components/Paper'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
  slug: {
    marginTop: 15,
    marginBottom: 20,
  },
  link: {
    color: '#555',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkAdmin: {
    color: 'red',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginTop: 10,
    height: 28,
    fontSize: 14,
    marginBottom: 20,
  },
})

export const ArticleVersionEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const profile = useSelector(state => state.profile)
  const iri = schema.getEndpoint.replace(':uuid', match.params.id)

  const collectionPath = function(resource) {
    return routes().versions.path.replace(':id', resource.parent?.uuid)
  }

  const getTourismNoticeTitle = item => {
    //if (!item) return translate('T_GENERAL_MISSING_TITLE')
    const type = translate(item?.type.trans)
    const title = item.translations[LANGS_IRI.PL].title

    return `${type} - ${title}`
  }

  const history = useHistory()
  const handleCancel = () => {
    history.goBack()
  }

  const classes = useStyles()

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      handleCancel={handleCancel}
      showSubmitAndStayButton={false}
      showSubmitButton={false}
    >
      <a
        href={`${getApiUrl()}/podglad-komunikatu-turystycznego/${
          match.params.id
        }?access_token=${profile?.contentAccessToken}`}
        className={classes.linkAdmin}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<Visibility />}
        >
          Podgląd administratora
        </Button>
      </a>
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        disabled={true}
        key={iri}
      />
      <Paper
        variant="outlined"
        header={translate('Komunikaty')}
        withPadding={false}
        style={{ marginTop: '30px' }}
      >
        <EmbeddedCollection
          endpoint={`${tourismNoticeArticleNoticeSchema.endpoint}?iri[parent]=${iri}&pagination=false`}
          pid={iri}
          parentIri={iri}
          properties={tourismNoticeArticleNoticeSchema.resource.properties}
          definitionSchema={
            definitions[tourismNoticeArticleNoticeSchema.resource.definition]
          }
          headerTitle={translate('Komunikaty')}
          headerAccessor={`translations.${LANGS_IRI.PL}.title`}
          titleAccessor={getTourismNoticeTitle}
          statable={true}
          hideAddButton={false}
          disabled={true}
          statDisabled={true}
          deleteDisabled={true}
        />
      </Paper>
    </Form>
  )
}
