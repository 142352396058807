import React from 'react'
import { Form } from 'pages'
import schema from '_schema/matterFormConfigGroup'
import routes from './routes'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { LANGS_IRI } from '../../_lib/langs'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
      modifyProperties={properties => {
        if (profile?.openform) {
          properties.idName = {
            type: 'string',
            description: {
              text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
              color: '#cc0000',
            },
          }
        }

        return properties
      }}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.items.endpoint}?pagination=false&iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.items.properties}
        definitionSchema={definitions[schema.subresources.items.definition]}
        headerTitle={translate('Items')}
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        statable={false}
        key={iri}
        paginable={false}
      />
    </Form>
  )
}
