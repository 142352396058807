export const getApiUrl = () => {
  /*
  const config = {
    'admin.tpn.docker': 'dev.tpn.docker',
    'admin.tpn-test1.docker': 'tpn-test1.docker',
    'admin.tpn-test2.docker': 'tpn-test2.docker',
  }
   */

  /*
  const getApiUrl = () => {
    if (config.hasOwnProperty(window.location.hostname)) {
      if (window.location.protocol === 'https:') {
        return 'https://' + config[window.location.hostname]
      } else {
        return 'http://' + config[window.location.hostname]
      }
    } else {
      console.log('No match found for the current domain.')
      return process.env.REACT_APP_BACKEND_ENTRYPOINT
    }
  }
  */

  const getApiUrl = () => {
    if (window.location.hostname === 'admin.ppn.openform.pl') {
      return 'https://ppn.gov.pl'
    }

    if (
      window.location.hostname === 'localhost'
      // || window.location.hostname.endsWith('.docker')
    ) {
      return process.env.REACT_APP_BACKEND_ENTRYPOINT
    }

    if (window.location.protocol === 'https:') {
      return 'https://' + window.location.hostname.replace('admin.', '')
    } else {
      return 'http://' + window.location.hostname.replace('admin.', '')
    }
  }

  return getApiUrl()
}
