import React from 'react'
import { Form } from 'pages'
import schema from '_schema/trail'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { LANGS_IRI } from '../../_lib/langs'
import { ParametersEmbeddedCollection } from './component/ParametersEmbeddedCollection'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <div style={{ marginTop: '30px' }}>
        <ParametersEmbeddedCollection
          endpoint={`${schema.subresources.parameters.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.parameters.properties}
          definitionSchema={
            definitions[schema.subresources.parameters.definition]
          }
          headerTitle={translate('Parametry')}
          panelTitle={translate('Tytuł')}
          hidePanelTitle={true}
          titleAccessor={`cmsTitle`}
          statable={true}
        />
      </div>
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
      <div style={{ marginTop: '30px' }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.information.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.information.properties}
          definitionSchema={
            definitions[schema.subresources.information.definition]
          }
          headerTitle={translate('T_TRAIL_INFORMATION_TITLE')}
          panelTitle={translate('T_TRAIL_INFORMATION_TITLE')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
        />
      </div>
    </Form>
  )
}
