import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const a11text = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.A11_TEXT,
  label: labelTrans ?? '',
  properties: {
    // blockAnchor: {
    //   type: 'string',
    //   description: 'T_GENERAL_BLOCK_ANCHOR_ID',
    //   fullWidth: true,
    // },
    headerType: {
      type: 'resource',
      endpoint: '/api/header_types',
      titleAccessor: 'trans',
      description: 'Typ nagłówka',
      validate: ['required'],
    },
    translations: {
      type: 'translation',
      properties: {
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
      },
    },
  },
})
