import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { translate } from '../../../_helpers/translate'
import { KeyboardArrowDown } from '@material-ui/icons'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../_helpers/notification'
import { prop } from '../../../_helpers/prop'

const useStyles = makeStyles({
  input: {
    width: '300px',
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
  input_horizontal: {
    width: 200,
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      padding: 5,
    },
  },
  autocomplete_input: {
    height: 40,
    padding: '5px !important',
  },
  label: {
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
  },
  row_input: {
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 9,
    marginBottom: 10,
    '& .MuiInputBase-input': {
      paddingTop: '4px !important',
    },
    '& fieldset': {
      border: 0,
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: 46,
    },
  },
  wrapper: {
    padding: '0px 4px',
  },
  select: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    height: 46,
    marginTop: 10,
    color: '#5A6169',
  },
  checkbox: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    maxHeight: 300,
  },
  mainButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export const MultiResourceFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  endpoint,
  classes = {},
  titleAccessor = 'title',
  valueAccessor = '@id',
  values = [],
}) => {
  // todo api platform filter
  const handleChange = newSelected => {
    // convert array to string separated by comma
    const value = newSelected.join(',')
    setFilter(name, value || null)
  }

  const defaultClasses = useStyles()

  const [choices, setChoices] = useState([])
  const [selected, setSelected] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (!endpoint) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, setChoices])

  useEffect(() => {
    // value contains comma separated values
    const selectedValues = value ? value.split(',') : []
    setSelected(selectedValues)
  }, [value])

  const options = choices.map(resource => ({
    value: prop(resource, valueAccessor),
    title: translate(prop(resource, titleAccessor)),
  }))

  const handleToggle = (value, title) => {
    // Toggle for "All"
    if (value === null) {
      setSelected([])
      handleChange([])
      return
    }

    // Uncheck "All" when selecting other options
    let newSelected = selected.filter(item => item !== null)
    const currentIndex = newSelected.indexOf(value)

    if (currentIndex === -1) {
      newSelected.push(value)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setSelected(newSelected)
    handleChange(newSelected)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getSelectedText = () => {
    if (selected.length === 0) {
      return 'Wszystko'
    }

    let currentTitles = []
    selected.forEach(value => {
      const option = options.find(option => option.value === value)
      currentTitles.push(option.title)
    })

    return currentTitles.join(', ')
  }

  return (
    <div>
      <List style={{ paddingTop: 0 }}>
        <ListItem
          button
          onClick={handleClick}
          className={defaultClasses.mainButton}
        >
          <ListItemText primary={getSelectedText()} />
          <IconButton edge="end" className={defaultClasses.mainButton}>
            <KeyboardArrowDown />
          </IconButton>
        </ListItem>
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={defaultClasses.list}
      >
        <MenuItem className={defaultClasses.listItem}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.length === 0}
                onChange={() => handleToggle(null)}
                className={defaultClasses.checkbox}
              />
            }
            label="Wszystko"
          />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} className={defaultClasses.listItem}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.includes(option.value)}
                  onChange={() => handleToggle(option.value)}
                  className={defaultClasses.checkbox}
                />
              }
              label={option.title}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

MultiResourceFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  endpoint: PropTypes.string,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  titleAccessor: PropTypes.string,
  values: PropTypes.array,
}
