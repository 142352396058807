const schema = {
  endpoint: '/api/accommodation_users',
  access: 'accommodationUser',
  resource: {
    definition: '#AccommodationUser',
    properties: {
      email: {
        validate: ['required', 'email'],
      },
      // rawPassword: {
      //   type: 'passwordRepeat',
      //   description: 'T_FORM_FIELD_NEW_PASSWORD',
      //   hint: {
      //     origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
      //     repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
      //   },
      //   validate: ['required', 'password'],
      // },
      // firstName: {
      //   description: 'T_GENERAL_FIRST_NAME',
      //   // validate: ['required'],
      // },
      // lastName: {
      //   description: 'T_GENERAL_SURNAME',
      //   // validate: ['required'],
      // },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },

      companyName: {
        description: 'Nazwa firmy',
      },
      companyNip: {
        description: 'Nip firmy',
      },
      companyAddress: {
        description: 'Adres firmy',
        type: 'textarea'
      },

      stat: {
        description: 'Konto aktywne',
        hint: 'zostało potwierdzone z linka aktywacyjnego'
      },
      deleted: {
        description: 'Konto usunięte',
        hint: 'konto poprawnie usunięte przez użytkownika, operacja potwierdzona linkiem'
      },
    },
  },
}

export default schema
