import React from 'react'
import schema from '../../../_schema/shopShippingTypePoint'
import routes from '../routes'
import { Form } from '../../Form'
import { LANGS_IRI } from '../../../_lib/langs'
import { SidebarNotStatable } from '../../../components/resource/sidebar'

export const PointEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const collectionPath = function(resource) {
    return routes().points.path.replace(':id', resource.parent?.uuid)
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
    ></Form>
  )
}
