import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const inlinelogo = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint
) => ({
  name: BLOCK_TYPES_IRI.INLINELOGO,
  label: 'Blok z logotypami',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint: 'tylko widoczny w PA',
        },
      },
    },
    listItems: {
      type: 'collection',
      label: 'Logotypy',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint,
      endpoint: blockListItemEndpoint,
      statable: true,
      sort: 'order[ord]=ASC',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            text: {
              label: 'Link',
              type: 'string',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'Zdjęcie logo',
          endpoint: photoEndpoint,
          minDimensions: '100 x 100',
          // thumbs: blockListItemEndpointThumbEndpoint && {
          //   endpoint: blockListItemEndpointThumbEndpoint,
          // },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        targetBlank: {
          description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
          label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        },
      },
    },
  },
})
