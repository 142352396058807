import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageFile from '../packageFile'

export const files = () => ({
  name: BLOCK_TYPES_IRI.FILES,
  label: 'T_GENERAL_BLOCK_TYPE_FILES',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
        /*
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
         */
      },
    },
    packageFile: {
      type: 'resource',
      endpoint: `${packageFile.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package files',
    },
    blockStyle: {
      label: 'T_BLOCK_STYLE_LABEL',
      type: 'resource',
      endpoint: '/api/block_styles',
      titleAccessor: 'trans',
      transTitle: true,
    },
    showMoreButton: {
      description: 'T_GENERAL_SHOW_MORE_BUTTON',
    },
  },
})
