import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/shopElektronicznyNadawcaBufor'
import routes from './routes'
import { DateCell } from '../../components/table/cells/DateCell'
import { CollectionHeader } from '../../components/CollectionHeader'
import { Button } from '@material-ui/core'
import { AccountBalance } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { buforOperations } from './table/columns/buforOperations'

export const BuforCollection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'idBufor',
        header: translate('Identyfikator'),
        accessor: `idBufor`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'sendDate',
        header: translate('Data nadania'),
        accessor: `sendDate`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        columnId: 'locationTitle',
        header: translate('Urząd nadania'),
        accessor: `locationTitle`,
        filterable: true,
        sortable: true,
        width: '30%',
      },
      {
        columnId: 'ordersCount',
        header: translate('Liczba przesyłekk'),
        accessor: `ordersCount`,
        width: '10%',
      },
      {
        columnId: 'operations',
        ...buforOperations(`sendDate`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const useStyles = makeStyles(theme => ({
    container: {
      padding: theme.spacing(3),
      backgroundColor: 'transparent',
    },
    button: {
      margin: theme.spacing(1),
    },
  }))

  const classes = useStyles()
  const history = useHistory()

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(routes().locationIndex.path)
          }}
          className={classes.button}
          startIcon={<AccountBalance />}
        >
          Miejsca nadania
        </Button>
      </div>
      <Paper>
        <CollectionHeader
          buttonTitle={translate('Dodaj zbiór')}
          buttonPath={routes().buforNew.path}
          columns={columns}
          setVisibleColumns={setVisibleColumns}
          collectionId={schema.endpoint}
        >
          {translate('Zbiory')}
        </CollectionHeader>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={schema.endpoint}
          definition={schema.resource.definition}
          columns={visibleColumns}
          customResourceSchema={schema.resource}
          defaultSorters={{ sendDate: 'desc' }}
          orderable={false}
          //selectable={[publish.on(), publish.off()]}
          isRowLinkable={true}
          editPath={routes().buforEdit.path}
          autoWidth={false}
          numerable={false}
          parameters={[
            {
              name: 'sendDate',
              in: 'query',
              type: 'string',
            },
          ]}
          schemaParameters={[
            {
              name: 'order[sendDate]',
              in: 'query',
              type: 'string',
            },
            {
              name: 'order[idBufor]',
              in: 'query',
              type: 'string',
            },
          ]}
        />
      </Paper>
    </>
  )
}
