import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { DateCell } from 'components/table/cells/DateCell'
import schema from '_schema/guideFee'
import routes from './routes'
import { StringFilter } from '../../components/table/filters/StringFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        filtersList: ['parent.name', 'parent.surname'],
        columnId: 'parent.fullName',
        header: translate('Full name'),
        accessor: 'parent.fullName',
        width: '35%',
      },
      {
        columnId: 'parent.type',
        header: translate('Type'),
        accessor: 'parent.type',
        width: '20%',
      },
      {
        filtersList: ['amount[gte]', 'amount[lte]'],
        columnId: 'amountReal',
        header: translate('Amount'),
        accessor: 'amountReal',
        width: '15%',
      },
      {
        columnId: 'paidAt',
        header: translate('Paid at'),
        accessor: 'paidAt',
        filterable: true,
        Cell: DateCell,
        Filter: StringFilter,
        width: '15%',
      },
      {
        columnId: 'operations',
        ...operations('title', true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_GUIDE_FEE_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_GUIDE_FEE')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        parameters={[
          {
            name: 'parent.name',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'parent.surname',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'amountReal',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'paidAt',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'amount[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'amount[lte]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'parent.name',
            title: 'Imię',
            Filter: StringFilter,
          },
          {
            name: 'parent.surname',
            title: 'Nazwisko',
            Filter: StringFilter,
          },
          {
            name: 'amount[gte]',
            title: 'Cena od',
            Filter: StringFilter,
          },
          {
            name: 'amount[lte]',
            title: 'Cena do',
            Filter: StringFilter,
          },
        ]}
        defaultSorters={{ paidAt: 'desc' }}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
