import hostSchema from './lessonHost'
import { LANGS_IRI } from '../_lib/langs'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/lessons',
  access: 'lesson',
  resource: {
    definition: '#Lesson',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required'],
          },
          description: {
            type: 'textarea',
          },
          type: {
            description: 'Forma zajęć',
          },
          place: {
            description: 'Miejsce',
          },
          time: {
            description: 'Czas',
          },
          timeOfStart: {
            type: 'tinymce',
            description: 'Godzina rozpoczęcia',
          },
          duration: {
            description: 'Czas trwania',
          },
          alt: {
            type: 'string',
            description: 'T_GENERAL_ALT',
          },
        },
      },
      classes: {
        type: 'multipleResource',
        endpoint: `/api/lesson_class_types?pagination=false`,
        titleAccessor: 'trans',
        label: 'Klasy',
        transTitle: true,
      },
      hosts: {
        type: 'multipleResource',
        endpoint: `${hostSchema.endpoint}?pagination=false`,
        titleAccessor: `fullName`,
        label: 'Prowadzący',
      },
      collisions: {
        type: 'multipleResource',
        endpoint: `/api/lessons?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Zajęcia kolidujące (np. ze względu na wspólną salę)',
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
