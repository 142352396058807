import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Close } from '@material-ui/icons'
import SendIcon from '@material-ui/icons/Send'
import { makeStyles, withStyles } from '@material-ui/core/styles'
// import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
// import { prop } from 'core/_helpers/prop'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirmText: {
    marginTop: 12,
  },
}))

const styles = () => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const SendButton = ({
  resource,
  accessor = 'title',
  disabled = false,
  onSuccess = null,
  isIcon = false,
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const [progress, setProgress] = React.useState(
    resource.recipientsCount
      ? parseInt((resource.sentCount / resource.recipientsCount) * 100, 10)
      : 0
  )
  const [progressTxt, setProgressTxt] = React.useState(
    `${resource.sentCount}/${resource.recipientsCount}`
  )
  const [isFetching, setIsFetching] = React.useState(0)

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
      isFetching: false,
    }))
    setIsFetching(0)
  }

  const handleStart = () => {
    setIsFetching(isFetching + 1)
  }

  useEffect(
    () => {
      let controller

      if (controller) {
        controller.abort()
      }

      controller = new AbortController()
      const { signal } = controller

      if (isFetching === 0) {
        return () => controller.abort()
      }

      setState(state => ({
        ...state,
        isFetching: true,
      }))

      fetchDataHandleAuthError(
        `/api/accommodation_message/send/${resource.uuid}`,
        'GET',
        { signal },
        data => {
          if (data.init ?? false) {
            setProgress(0)
            setProgressTxt(`0/${data.recipientsCount}`)
            setIsFetching(isFetching + 1)
          } else if (data.finish ?? false) {
            setProgress(
              data.sent
                ? parseInt((data.sent / data.recipientsCount) * 100, 10)
                : 0
            )
            setProgressTxt(`${data.sent}/${data.recipientsCount}`)
            notification('success', 'Wysyłka widomości', 'Zakończona')
            setState({
              isConfirmOpen: true,
              isFetching: false,
            })
            onSuccess && onSuccess(resource)
          } else {
            setProgress(
              data.sent
                ? parseInt((data.sent / data.recipientsCount) * 100, 10)
                : 0
            )
            setProgressTxt(`${data.sent}/${data.recipientsCount}`)
            setIsFetching(isFetching + 1)
          }
        },
        error => {
          if (error.response.title === 'AbortError') {
            setState(state => ({
              ...state,
              isFetching: true,
            }))
          } else {
            setState({
              isConfirmOpen: false,
              isFetching: false,
            })
            notification('error', error.response.detail, error.response.title)
          }
        }
      )
      return () => controller.abort()
    },
    // eslint-disable-next-line
    [isFetching]
  )

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        isIcon ? (
          <SendIcon className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate(resource.sentCount ? 'Kontynuuj' : 'Rozpocznij wysyłkę')}
          </span>
        )
      ) : (
        <>
          {isIcon ? (
            <Tooltip
              title={translate(
                resource.finished
                  ? 'Zakończono'
                  : resource.sentCount
                  ? 'Kontynuuj'
                  : 'Rozpocznij wysyłkę'
              )}
            >
              {resource.finished ? (
                <SendIcon className={classes.disabled} disabled />
              ) : (
                <SendIcon
                  onClick={handleConfirmToggle}
                  className={classes.delete}
                />
              )}
            </Tooltip>
          ) : (
            <div className={classes.text} onClick={handleConfirmToggle}>
              {translate(
                resource.finished
                  ? 'Zakończono'
                  : resource.sentCount
                  ? 'Kontynuuj'
                  : 'Rozpocznij wysyłkę'
              )}
            </div>
          )}

          <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
            <DialogTitle onClose={handleConfirmToggle}>
              {translate('Wysyłka wiadomości')}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText className={classes.confirmText}>
                {progressTxt}
              </DialogContentText>
              <LinearProgress variant="determinate" value={progress} />

              {/* {state.isFetching && <Loader align="center" marginBottom={15} />}

              <DialogContentText className={classes.confirmText}>
                {translate('T_GENERAL_DELETE_CONFIRM')}
                {` "${
                  accessor
                    ? typeof accessor === 'function'
                      ? accessor(resource)
                      : prop(resource, accessor)
                    : translate('T_GENERAL_MISSING_TITLE')
                }"?`}
              </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmToggle}
                disabled={false}
                // autoFocus
                variant="outlined"
              >
                {translate('T_GENERAL_CANCEL')}
              </Button>
              <Button
                onClick={handleStart}
                disabled={state.isFetching}
                variant="contained"
                color="primary"
              >
                {translate('Rozpocznij')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

SendButton.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
}
