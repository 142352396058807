const schema = {
  endpoint: '/api/formsets',
  access: 'formset',
  resource: {
    definition: '#Formset',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł formularza',
          },
        },
      },

      email: {
        hint: 'jesli puste, użyty będzie domyślny adres z konfiguracji',
      },
      publishingDateFrom: {
        type: 'datetime',
      },
      publishingDateTo: {
        type: 'datetime',
      },

      stat: {},
    },
  },
}

export default schema
