import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  delete: {
    color: theme.palette.error.main,
  },
  update: {
    color: theme.palette.info.main,
  },
  create: {
    color: theme.palette.success.main,
  },
}))

export const MethodCell = ({ resource, accessor }) => {
  const method = prop(resource, accessor)

  const classes = useStyles()

  return (
    <div
      className={
        method === 'DELETE'
          ? classes.delete
          : method === 'PUT'
          ? classes.update
          : method === 'POST'
          ? classes.create
          : classes.view
      }
    >
      {translate(`T_REQUEST_METHOD_${method.toUpperCase()}`)}
    </div>
  )
}

MethodCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.string,
}
