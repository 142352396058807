import React from 'react'
import schema from '../../_schema/customer'
import routes from './routes'
import { Form } from '../Form'

export const LoginLogEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.subresources.loginLog.endpoint}/${match.params.id}`

  const collectionPath = function(resource) {
    return routes().loginLogEdit.path.replace(':id', resource.parent?.uuid)
  }

  return (
    <Form
      definitionSchema={definitions[schema.subresources.loginLog.definition]}
      definitions={definitions}
      customResourceSchema={schema.subresources.loginLog}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.subresources.loginLog.endpoint}
      sidebar={false}
      disabled={true}
      width="100%"
    ></Form>
  )
}
