import { fetchData } from '_helpers/fetchData'

const get = () =>
  fetchData('/api/api_config').then(
    response => ({ response }),
    error => ({ error })
  )

export const apiConfigService = {
  get,
}
