import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { useDropzone } from 'react-dropzone'
import { Button, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'

const dropzoneStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 30,
  marginBottom: 15,
  color: '#bdbdbd',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eee',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}

const useStyles = makeStyles(theme => ({
  chosenFiles: {
    color: theme.palette.text.secondary,
    marginLeft: 3,
    marginBottom: 10,
  },
  error: {
    textAlign: 'center',
  },
  sendButton: {

  },
}))

export const MassUpload = ({
  type = 'file',
  hint = null,
  endpoint,
  disabled = false,
  validators = ['maxSize'],
  accept = null,
  onSuccess = null,
  buttonNodeRef = null,
  classes = {},
}) => {
  const [state, setState] = useState({
    files: [],
    error: false,
    isFetching: false,
  })

  const handleSend = () => {
    if (!state.files.length) {
      return
    }

    const formData = new FormData()
    state.files.map(file => formData.append('file[]', file))

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: formData },
      response => {
        uploadSuccess()

        onSuccess && onSuccess(response)
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = () => {
    setState({
      files: [],
      error: false,
      isFetching: false,
    })

    notification('success', 'T_FORM_RECORD_UPLOADED', 'T_FORM_SUCCESS')
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setState(state => ({
          ...state,
          error: false,
        }))

        return
      }

      const valid = validate(validators, value)

      setState(state => ({
        ...state,
        error: !valid.result && valid.message,
      }))
    },
    [validators]
  )

  const onDrop = useCallback(
    files => {
      setState(state => ({
        ...state,
        files: [...files],
      }))

      for (let i = 0; i < files.length; i++) {
        validateField(files[i])
      }

      validateField([...files])
    },
    [setState, validateField]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      accept || (type === 'image' ? 'image/jpg,image/jpeg,image/png' : '*'),
    disabled: state.isFetching || disabled,
    onDrop,
  })

  const defaultClasses = useStyles()

  return (
    <>
      {state.isFetching && <Loader align="center" marginBottom={15} />}
      <div {...getRootProps({ style: dropzoneStyles })}>
        <input {...getInputProps()} />
        {translate('T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP')}
      </div>
      <div>
        {translate('T_GENERAL_CHOSEN_FILES')}:
        <ul className={clsx(defaultClasses.chosenFiles, classes.chosenFiles)}>
          {state.files.length
            ? state.files.map((file, index) => (
                <li key={`file-${index}`}>{file.name}</li>
              ))
            : translate('T_GENERAL_NONE')}
        </ul>
      </div>
      <FormHelperText
        error={!!state.error}
        disabled={disabled}
        className={clsx(defaultClasses.error, classes.error)}
      >
        {translate(state.error || hint)}
      </FormHelperText>
      <SubmitButton
        nodeRef={buttonNodeRef}
        disabled={
          !state.files.length || !!state.error || state.isFetching || disabled
        }
        onClick={handleSend}
        classes={{ root: clsx(defaultClasses.sendButton, classes.sendButton) }}
      />
    </>
  )
}

const SubmitButton = ({ nodeRef, disabled, onClick, classes }) => {
  const button = (
    <Button
      color="primary"
      className={clsx(classes.root)}
      disabled={disabled}
      onClick={onClick}
      variant="contained"
    >
      {translate('T_GENERAL_SEND')}
    </Button>
  )

  return nodeRef ? createPortal(button, nodeRef) : button
}

MassUpload.propTypes = {
  type: PropTypes.oneOf(['file', 'image']),
  hint: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
  onSuccess: PropTypes.func,
  buttonNodeRef: PropTypes.object,
  classes: PropTypes.shape({
    chosenFiles: PropTypes.string,
    error: PropTypes.string,
    sendButton: PropTypes.string,
  }),
}
