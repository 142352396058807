import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PowerSettingsNewOutlined, AccountCircleOutlined } from '@material-ui/icons'
import { Menu, MenuItem, ListItemIcon, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import { authActions } from '_actions'
import { translate } from '_helpers/translate'
import profileRoutes from 'pages/Profile/routes'

const items = () => [profileRoutes().index]

const useStyles = makeStyles({
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
})

export const UserMenu = props => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)
  const dispatch = useDispatch()

  const handleMenu = e => {
    setMenuAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchor(null)
  }

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    <div {...props}>
      <IconButton
        aria-label="user menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <AccountCircleOutlined color="primary" />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!menuAnchor}
        onClose={handleClose}
      >
        {items().map((item, i) => {
          const IconComponent = item.icon
          return (
            <MenuItem
              onClick={handleClose}
              key={`user-menu-link-${i}`}
              component={Link}
              to={item.path}
              className={classes.menuItem}
            >
              <ListItemIcon className={classes.listItem}>
                <IconComponent />
              </ListItemIcon>
              {translate(item.title)}
            </MenuItem>
          )
        })}
        <MenuItem onClick={handleLogout} className={classes.menuItem}>
          <ListItemIcon className={classes.listItem}>
            <PowerSettingsNewOutlined />
          </ListItemIcon>
          {translate('T_GENERAL_LOGOUT')}
        </MenuItem>
      </Menu>
    </div>
  )
}
