import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/changelog'
import routes from './routes'
import { CollectionAccordion } from '../../components/table/CollectionAccordion'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...operations('title', true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const profile = useSelector(state => state.profile)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj release')}
        buttonPath={routes().new.path}
        hideButton={!profile?.openform}
      >
        {translate('Releases')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionAccordion}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ date: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
