import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/volunteerUser'
import routes from './routes'
import { submissions } from '../Volunteering/table/_columns/submissions'
import { contracts } from './table/_columns/contracts'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'email',
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'firstName',
        header: translate('T_GENERAL_FIRST_NAME'),
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'lastName',
        header: translate('T_GENERAL_SURNAME'),
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'pesel',
        header: translate('T_GENERAL_PESEL'),
        accessor: `pesel`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'phone',
        header: translate('T_GENERAL_PHONE_NUMBER'),
        accessor: `phone`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'accommodationType',
        header: translate('Zakwaterowanie'),
        accessor: `accommodationType.trans`,
        translateAccessor: true,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'status',
        header: translate('Status'),
        accessor: `status.trans`,
        translateAccessor: true,
        filterName: 'iri[status]',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'submissionsCount',
        ...submissions(
          `submissionsCount`,
          'T_MODULE_VOLUNTEERING_SUBMISSION_COUNT',
          routes().submissions.path
        ),
        width: '5%',
      },
      {
        columnId: 'contractsCount',
        ...contracts(`contractsCount`, 'Umowy', routes().contracts.path),
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(`email`, true, false, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_VOLUNTEERING_USER_LIST_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_VOLUNTEERING_USER_LIST')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        defaultFilters={{
          deleted: false,
        }}
        parameters={[
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'pesel',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'deleted',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[firstName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[lastName]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'iri[status]',
            title: 'Status',
            Filter: ResourceFilter,
            endpoint: '/api/volunteer_account_status_types?pagination=false',
            titleAccessor: 'trans',
          },
        ]}
        orderable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
