import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/formsetItem'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'

export const FormitemCollection = ({ ...rest }) => {
  const { match } = rest

  const defaultFilters = {
    'parent.uuid': match.params.pid,
  }

  const columns = useMemo(
    () => [
      {
        columnId: 'translations.titleRaw',
        header: translate('Name'),
        accessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.titleRaw`,
        filterName: 'translations.title',
        filterable: true,
        width: '73%',
      },
      {
        columnId: 'operations',
        ...operations(
          `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.titleRaw`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '15%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj pole')}
        buttonPath={routes().formitemNew.path.replace(':pid', match.params.pid)}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Pola formularza')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().formitemEdit.path.replace(':pid', match.params.pid)}
        autoWidth={false}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
        parameters={[
          {
            name: 'parent.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
