import React, { useState } from 'react'
import { translate } from '_helpers/translate'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

export const DeleteMediaModal = ({ callback, media, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const handleConfirm = () => {
    fetchDataHandleAuthError(
      media['@id'],
      'DELETE',
      {},
      () => {
        handleToggle()
        callback()
      },
      error => {
        if (error.response?.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Button {...rest} onClick={handleToggle}>
        <DeleteForeverOutlinedIcon />
      </Button>
      <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleToggle}>
          {translate('T_GENERAL_DELETE')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Usunąć plik {media.originalName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} autoFocus variant="outlined">
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
