import guideSchema from './guide'

const schema = {
  endpoint: '/api/guide_fees',
  access: 'guide',
  resource: {
    definition: '#GuideFee',
    properties: {
      parent: {
        description: 'Przewodnik',
        type: 'resource',
        endpoint: `${guideSchema.endpoint}?pagination=false&order[surname]=asc&order[name]=asc`,
        titleAccessor: 'fullName',
        validate: ['required'],
      },
      amountReal: {
        description: 'Amount',
        type: 'number',
        validate: ['required'],
      },
      paidAt: {
        type: 'date',
        validate: ['required'],
      },
    },
  },
}

export default schema
