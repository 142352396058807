import formsetSchema from '_schema/formset'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const formsetBlock = () => ({
  name: BLOCK_TYPES_IRI.FORMSETBLOCK,
  label: 'Blok z formularzem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          type: 'string',
          label: 'Tytuł formularza',
        },
        sectionTitle: {
          type: 'string',
          description: 'Tytuł sekcji "Dodatkowe informacje',
          label: 'Tytuł sekcji "Dodatkowe informacje"',
        },
        text: {
          type: 'tinymce',
          label: 'Opis sekcji "Dodatkowe informacje"',
          description: 'Opis sekcji "Dodatkowe informacje"',
        },
      },
    },
    formset: {
      type: 'resource',
      endpoint: `${formsetSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`,
    },
  },
})
