const schema = {
  endpoint: '/api/shop_payment_methods',
  resource: {
    definition: '#ShopPaymentMethod',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required', 'length(1)'],
          },
          description: {
            type: 'tinymce',
            label: 'Opis płatności na podsumowaniu zamówienia',
          },
        },
      },
      operator: {
        label: 'T_MODULE_SHOP_PAYMENT_METHOD_OPERATOR',
        type: 'resource',
        endpoint: '/api/shop_payment_operators',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      floatPrice: {
        type: 'float',
        description: 'Modyfikator ceny',
        hint: 'Np. 2.0 doda 2 zł do ceny wysyłki',
        validate: ['money'],
      },
      accountNumber: {
        description: 'Numer konta bankowego',
      },
      merchantId: {
        description: 'Identyfikator sprzedawcy',
      },
      crcKey: {
        description: 'Klucz CRC',
      },
      reportsKey: {
        description: 'Klucz raportów',
      },
      developerMode: {
        description: 'Tryb developerski',
        hint:
          'Tryb developerski służy jedynie do testowania integracji płatności!!!',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
