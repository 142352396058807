import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'

const perform = (action, accessor, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let requests = []

    const items = tableState.data.selected.map((isSelected, index) => {
      if (
        !isSelected ||
        tableState.data.items[index][accessor] ===
          (action === 'publish' ? true : false)
      ) {
        return tableState.data.items[index]
      }

      requests.push({
        url: tableState.data.items[index]['@id'],
        method: 'PUT',
        body: {
          [accessor]: action === 'publish' ? true : false,
        },
      })

      return {
        ...tableState.data.items[index],
        [accessor]: action === 'publish' ? true : false,
      }
    })

    setTimeout(() => {
      Promise.all([
        requests.map(request =>
          fetchDataHandleAuthError(request.url, request.method, {
            body: JSON.stringify(request.body),
          })
        ),
      ]).then(
        () => {
          notification(
            'success',
            'T_FORM_RECORD_MULTIPLE_UPDATED',
            'T_FORM_SUCCESS'
          )
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_SUCCESS,
            payload: { action, items },
          })
        },
        error => {
          notification('error', error.response.detail, error.response.title)
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        }
      )
    }, 500)
  },
})

export const publish = {
  on: (
    action = 'publish',
    accessor = 'stat',
    title = 'T_GENERAL_MASS_SELECT_PUBLISH'
  ) => perform(action, accessor, title),
  off: (
    action = 'unpublish',
    accessor = 'stat',
    title = 'T_GENERAL_MASS_SELECT_UNPUBLISH'
  ) => perform(action, accessor, title),
}
