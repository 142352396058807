import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { translate } from '../../../../_helpers/translate'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  title: {
    paddingLeft: 10,
  },
  operatorChip: {
    height: 25,
    marginTop: 5,
  },
  cashOnDelivery: {
    height: 25,
    marginTop: 5,
    backgroundColor: '#ff9d63',
  },
}))

export const ShippingCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  translateAccessor = false,
}) => {
  const history = useHistory()
  const defaultClasses = useStyles()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  return (
    <div onClick={onClick}>
      <span className={defaultClasses.title}>
        {translateAccessor
          ? translate(prop(resource, accessor))
          : prop(resource, accessor)}
      </span>
      {resource?.shippingOperator && (
        <Chip
          label={translate(resource?.shippingOperator?.trans)}
          className={defaultClasses.operatorChip}
        />
      )}
      {resource?.cashOnDelivery && (
        <Chip
          label={translate('Płatność za pobraniem')}
          className={defaultClasses.cashOnDelivery}
        />
      )}
    </div>
  )
}

ShippingCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
  translateAccessor: PropTypes.bool,
}
