import _block from 'pages/HomeBlocks/_blocks'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/pages',
  access: 'page',
  resource: {
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/page_block_thumbs',
        '/api/page_block_anchors',
        '/api/page_block_files',
        '/api/page_block_links',
        '/api/page_block_link_thumbs',
        '/api/page_block_list_items',
        '/api/page_block_list_item_thumbs'
      ).types,
    },
  },
}

export default schema
