import React from 'react'
import {
  Button,
  Table,
  TableBody,
  Grid,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import { Loader } from 'components/Loader'
import { formatFileSize } from '_helpers/formatFileSize'
import { DeleteMediaModal } from './DeleteMediaModal'
import { downloadFile } from '_helpers/downloadFile'

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    minHeight: 100,
  },
  title: {
    lineBreak: 'anywhere',
  },
  size: {
    fontSize: '0.8em',
  },
})

export const ListView = ({
  medias,
  isFetching,
  handleDelete,
  handleManagerChoice = null,
}) => {
  const handleDownload = (e) => {
    const target = e.target.getAttribute('index')
      ? e.target
      : e.target.closest('[index]')

    const index = parseInt(target.getAttribute('index'))

    const url = medias[index]?.url
    const originalName = medias[index]?.originalName

    if (!url) {
      return
    }

    downloadFile(url, originalName)
  }

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Table>
        <TableBody style={{ height: 'auto' }}>
          {isFetching ? (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{ padding: 20 }}
            >
              <Loader />
            </Grid>
          ) : medias.map((media, index) => (
            <TableRow key={media.uuid}>
              <TableCell component="th" scope="row">
                <span className={classes.title}>
                  {media.originalName}
                </span>
              </TableCell>
              <TableCell
                style={{ width: 160 }}
                align="right"
              >
                <>
                  <span className={classes.size}>
                    {media.size > 0 && formatFileSize(media.size)}
                  </span>
                  <Button
                    style={{ minWidth: 10 }}
                    component="a"
                    index={index}
                    onClick={handleDownload}
                  >
                    <GetAppIcon />
                  </Button>
                </>
              </TableCell>
              <TableCell
                style={{
                  width:
                    handleManagerChoice
                      ? 114
                      : 74,
                }}
              >
                <Grid container>
                  {handleManagerChoice && (
                    <Button
                      style={{ minWidth: 10 }}
                      onClick={() => handleManagerChoice(media)}
                    >
                      <CheckOutlinedIcon />
                    </Button>
                  )}
                  <DeleteMediaModal
                    style={{ minWidth: 10 }}
                    variant="contained"
                    media={media}
                    size="small"
                    callback={handleDelete}
                  />
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
