export const constants = {
  FETCH_START: 'EMBEDDED_COLLECTION_FETCH_START',
  FETCH_SUCCESS: 'EMBEDDED_COLLECTION_FETCH_SUCCESS',
  FETCH_FAILURE: 'EMBEDDED_COLLECTION_FETCH_FAILURE',
  ORDER_CHANGE_START: 'EMBEDDED_COLLECTION_ORDER_CHANGE_START',
  ORDER_CHANGE_SUCCESS: 'EMBEDDED_COLLECTION_ORDER_CHANGE_SUCCESS',
  ORDER_CHANGE_FAILURE: 'EMBEDDED_COLLECTION_ORDER_CHANGE_FAILURE',
  ADD_ITEM: 'EMBEDDED_COLLECTION_ADD_ITEM',
  REMOVE_ITEM: 'EMBEDDED_COLLECTION_REMOVE_ITEM',
  UPDATE_ITEM: 'EMBEDDED_COLLECTION_UPDATE_ITEM',
  SYNC_UPDATED_AT: 'EMBEDDED_COLLECTION_SYNC_UPDATED_AT',
}
