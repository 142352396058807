const schema = {
  endpoint: '/api/accommodation_config',
  access: 'accommodationConfig',
  resource: {
    definition: '#AccommodationConfig',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          mailNotificationTitle: {
            type: 'string',
            description: 'Domyślny tytuł maila z powiadomieniami',
          },
          mailNotificationText: {
            type: 'tinymce',
            description: 'Domyślna treść maila z powiadomieniami',
            hint: '{TODAY} zamienia w momencie wysyłki na dzisiejszą datę'
          },

          mailAcceptTitle: {
            type: 'string',
            description: 'Domyślny tytuł maila z akceptacją kwatery użytkownika',
          },
          mailAcceptText: {
            type: 'tinymce',
            description: 'Domyślna treść maila z akceptacją kwatery użytkownika',
          },

          mailDenyTitle: {
            type: 'string',
            description: 'Domyślny tytuł maila z odrzuceniem kwatery użytkownika',
          },
          mailDenyText: {
            type: 'tinymce',
            description: 'Domyślna treść maila z odrzuceniem kwatery użytkownika',
          },

          mailAcceptOrderTitle: {
            type: 'string',
            description: 'Domyślny tytuł maila z akceptacją zamówienia',
          },
          mailAcceptOrderText: {
            type: 'tinymce',
            description: 'Domyślna treść maila z akceptacją zamówienia',
          },

          mailDenyOrderTitle: {
            type: 'string',
            description: 'Domyślny tytuł maila z odrzuceniem zamówienia',
          },
          mailDenyOrderText: {
            type: 'tinymce',
            description: 'Domyślna treść maila z odrzuceniem zamówienia',
          },
        },
      },

      emailAccommodation: {
        description: 'Adresy email na które bedą wysyłane powiadomienia',
        hint: 'można wpisać kilka rozdzielonych przecinkami',
      },


    },
  },
}

export default schema
