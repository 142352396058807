import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import { Form } from 'components/form'
import { applyProperties } from '_helpers/applyProperties'
import { prop } from '_helpers/prop'
import { useStaticCollectionStyles } from './StaticCollection.styles'
import { translate } from '_helpers/translate'

export const StaticCollection = ({
  items,
  properties: customProperties,
  definitionSchema,
  headerTitle = null,
  expandTitle = null,
  panelTitle = null,
  titleAccessor = null,
}) => {
  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = uuid => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [uuid]:
          state.items[uuid] !== undefined ? !state.items[uuid] : !state.switch,
      },
    }))
  }

  const properties = applyProperties(
    customProperties,
    definitionSchema.properties,
    'missing'
  )

  const classes = useStaticCollectionStyles()

  return (
    <>
      <div className={classes.header}>
        <div>{headerTitle}</div>
        <div className={classes.massExpand} onClick={handleExpand}>
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer className={classes.collectionContainer}>
        <Table size="small">
          <TableBody>
            {items.map((item, i) => (
              <TableRow key={`${item.uuid}`}>
                <TableCell key="item" className={classes.cell}>
                  <Accordion
                    expanded={
                      expanded.items[item.uuid] !== undefined
                        ? expanded.items[item.uuid]
                        : expanded.switch
                    }
                    onChange={handleSingleExpand(item.uuid)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <Typography>
                        {panelTitle ? `${panelTitle}: ` : ''}
                        {titleAccessor
                          ? prop(item, titleAccessor)
                            ? prop(item, titleAccessor)
                            : translate('T_GENERAL_MISSING_TITLE')
                          : item.translations?.[process.env.REACT_APP_LOCALE]
                              ?.title
                          ? item.translations?.[process.env.REACT_APP_LOCALE]
                              ?.title
                          : translate('T_GENERAL_MISSING_TITLE')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={classes.itemContainer}>
                      <Form
                        readOnly={true}
                        url={item.uuid}
                        method="PUT"
                        properties={properties}
                        resource={item}
                        width={'100%'}
                        fieldsFullWidth={false}
                        key={`${item.uuid}-${i}`}
                      />
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

StaticCollection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
      properties: PropTypes.object,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }),
      ]),
      hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      validate: PropTypes.arrayOf(PropTypes.string),
      endpoint: PropTypes.string,
      thumbs: PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
      }),
      choices: PropTypes.object,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string.isRequired,
        })
      ),
      titleAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ).isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  headerTitle: PropTypes.string,
  expandTitle: PropTypes.string,
  panelTitle: PropTypes.string,
  titleAccessor: PropTypes.string,
}
