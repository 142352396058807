import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const shophome = (labelTrans = null) => ({
  name: BLOCK_TYPES_IRI.SHOPHOME,
  label: labelTrans ?? 'Bestselery',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Title',
          type: 'string',
          hint: 'domyślnie trans "T_BESTSELLERS"',
        },
        linkTitle: {
          type: 'string',
          label: 'Tytuł guzika',
          hint: 'domyślnie trans "T_PRODUCT_SEE_ALL_BUTTON"',
        },
      },
    },
  },
})
