import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RestoreLoader } from 'components/RestoreLoader'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogged, isRestoring } = useSelector(state => state.auth)
  return (
    <Route
      {...rest}
      render={props =>
        isLogged ? (
          <Component {...props} />
        ) : isRestoring ? (
          <RestoreLoader />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
