import React from 'react'
import { Route } from 'react-router-dom'
import { ExploreOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/shopProduct'
import { CostCollection } from './Cost/CostCollection'
import { CostNew } from './Cost/CostNew'
import { CostEdit } from './Cost/CostEdit'

const basePath = '/shop_shipping_areas'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: ExploreOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      costs: {
        subtitle: 'T_MODULE_SHOP_SHIPPING_AREA_COST',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/costs`,
        exact: true,
      },
      costNew: {
        subtitle: 'T_MODULE_SHOP_SHIPPING_AREA_COST_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostNew}
            {...props}
          />
        ),
        path: `${basePath}/costs/new`,
        exact: true,
      },
      costEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CostEdit}
            {...props}
          />
        ),
        path: `${basePath}/costs/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_SHOP_SHIPPING_AREA',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
