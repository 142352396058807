export const addProperties = (
  isOpenform,
  properties,
  resource,
  schema,
  currentHostnameIri
) => {
  let modifiedProperties = properties

  //wysyłka zakończona lub w trakcie
  if (resource.finished || resource.sentCount > 0) {
    modifiedProperties = {
      ...properties,
      mailTitle: {
        ...properties.mailTitle,
        disabled: true,
      },
      mailText: {
        ...properties.mailText,
        disabled: true,
      },
      recipientsChoosen: {
        ...properties.recipientsChoosen,
        disabled: true,
      },
    }
  }

  if (resource.toAdmin) {
    delete modifiedProperties.restart
    delete modifiedProperties.recipients
    delete modifiedProperties.mailText
  }

  if (resource.recipients.length > 1) {
    //nie moze odpowiedziec do wszystkich, tylko do jednego
    delete modifiedProperties.newMailText
    delete modifiedProperties.children
  } else {
    delete modifiedProperties.restart
    delete modifiedProperties.recipients
    delete modifiedProperties.mailText
  }

  return modifiedProperties
}
