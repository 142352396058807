import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core'
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined'
import { Form } from 'components/form'
import schema from '_schema/managerMedia'

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '&>*': {
      marginLeft: 10,
    },
  },
})

export const CreateDirModal = ({ callback, currentDir, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const [buttonsRef, setButtonsRef] = useState(null)

  const onButtonsRef = useCallback(
    element => {
      setButtonsRef(element)
    },
    [setButtonsRef]
  )

  const handlePresubmitModify = values => {
    return {
      dir: `${getCurrentDir(currentDir)}${values.dir}`,
    }
  }

  const handleSuccess = () => {
    callback()
    handleToggle()
  }

  const classes = useStyles()

  return (
    <>
      <Button {...rest} onClick={handleToggle}>
        <CreateNewFolderOutlinedIcon />
        <Typography variant="h6" noWrap>
          Dodaj folder
        </Typography>
      </Button>
      <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleToggle}>Nowy folder</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Dodawanie folderu w katalogu <strong>{currentDir}</strong>
          </DialogContentText>
          <Form
            url={schema.endpoints.dir_create}
            method="POST"
            properties={schema.forms.create_dir.properties}
            showSubmitAndStayButton={false}
            handleSuccess={handleSuccess}
            handleCancel={handleToggle}
            handlePresubmitModify={handlePresubmitModify}
            buttonContainerRef={buttonsRef}
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons} ref={onButtonsRef} />
        </DialogActions>
      </Dialog>
    </>
  )
}

const getCurrentDir = currentDir =>
  currentDir === '/' ? currentDir : `${currentDir}/`
