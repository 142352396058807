import { makeStyles } from '@material-ui/styles'

export const useCollectionTableStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  toggle: {
    marginLeft: 8,
    marginRight: 8,
  },
  toggleActive: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  toggleDisabled: {
    color: theme.palette.disabled,
    cursor: 'default',
  },
}))
