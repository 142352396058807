import React, { useEffect, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/config'
import { useSelector } from 'react-redux'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { currentHostnameIri } from '../../_helpers/currentHostnameIri'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`
  const profile = useSelector(state => state.profile)
  const { match } = rest
  const defaultData = { parent: iri }

  const [hostnameIri, setHostnameIri] = useState(false)
  const [isHostnameLoading, setIsHostnameLoading] = useState(false)
  const [airlyModalResponse, setAirlyModalResponse] = useState(null)

  useEffect(() => {
    if (!isHostnameLoading && hostnameIri === false) {
      setIsHostnameLoading(true)
      currentHostnameIri(setHostnameIri)
    }

    if (hostnameIri !== false) {
      setIsHostnameLoading(false)
    }
  }, [hostnameIri, setHostnameIri, isHostnameLoading, setIsHostnameLoading])

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        modifyProperties={properties =>
          addOpenformProperties(
            profile?.openform,
            properties,
            null,
            null,
            hostnameIri
          )
        }
        sidebar={false}
        width={600}
      >
        <div style={{ marginTop: '15px', marginBottom: '35px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.airlyEntries.endpoint}?pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.airlyEntries.properties}
            definitionSchema={
              definitions[schema.subresources.airlyEntries.definition]
            }
            headerTitle={translate('Instalacje airly')}
            panelTitle={translate('Instalacja')}
            titleAccessor={`nativeTitle`}
            statable={true}
            key={iri}
            paginable={false}
            orderable={false}
            modalResponse={airlyModalResponse}
            setModalResponse={setAirlyModalResponse}
            hideAddButton={true}
          />
          <AddModal
            title={translate('Dodaj')}
            definitionSchema={
              definitions[schema.subresources.airlyEntries.definition]
            }
            itemProperties={schema.subresources.airlyEntries.properties}
            endpoint={schema.subresources.airlyEntries.endpoint}
            itemSchema={schema}
            setModalResponse={setAirlyModalResponse}
            defaultData={defaultData}
          />
        </div>
      </Form>
    </div>
  )
}
