import { LANGS_IRI } from '_lib/langs'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/tourney_packages',
  access: 'tourneyPackage',
  resource: {
    definition: '#TourneyPackage',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'Nazwa zestawu pytań',
          },
        },
      },

      dateFrom: {
        type: 'datetime',
        description: 'Czas rozpoczęcia zestawu',
        validate: ['required'],
      },
      dateTo: {
        type: 'datetime',
        validate: ['required'],
        description: 'Czas zakończenia zestawu',
      },

      items: {
        type: 'embeddedCollection',
        endpoint: '/api/tourney_package_items',
        definitionRef: '#TourneyPackageItem',
        description: 'Pytania',
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,
        statable: true,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                type: 'string',
                description: 'Tytuł/numer pytania',
              },
              description: {
                type: 'textarea',
                description: 'Pytanie',
              },
            },
          },
          open: {
            description: 'Pytanie otwarte',
            hint: 'uczestnik musi na nie odpowiedzieć pisemnie',
          },
          media: {
            type: 'image',
            description: 'T_GENERAL_PHOTO',
            endpoint: photoSchema.endpoint.single,
            validate: ['maxSize'],
          },

          answers: {
            type: 'collection',
            label: 'Odpowiedzi',
            titleAccessor: `translations.${LANGS_IRI.PL}.title`,
            definitionRef: '#TourneyPackageItemAnswer',
            endpoint: '/api/tourney_package_item_answers',
            statable: true,
            properties: {
              translations: {
                type: 'translation',
                properties: {
                  title: {
                    description: 'T_GENERAL_TITLE',
                    label: 'T_GENERAL_TITLE',
                  },
                },
              },
              //default
              good: {
                description: 'Poprawna odpowiedź',
                hint: 'jeśli to jest poprawna odpowiedź, zaznacz ją',
              },
            },
          },

          // stat: {},
        },
      },

      stat: {},
    },
  },
}

export default schema
