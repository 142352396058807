import { apiConfigConstants } from '_constants'

export const apiConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case apiConfigConstants.SUCCESS:
    case apiConfigConstants.UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    case apiConfigConstants.FAILURE:
      return {}
    default:
      return state
  }
}
