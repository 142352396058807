import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Schemable } from 'components/Schemable'
import { SchemaError } from '_helpers/schemaError'

export const SchemableCollection = ({
  component,
  path,
  definition = null,
  ...rest
}) => {
  return (
    <Schemable>
      <SchemaAssigner
        component={component}
        path={path}
        definition={definition}
        {...rest}
      />
    </Schemable>
  )
}

const SchemaAssigner = ({ component: Component, definition, ...rest }) => {
  const schema = useSelector(state => state.schema)

  if (!schema) {
    throw new SchemaError()
  }

  const definitionSchema = schema.definitions[definition]

  if (!definitionSchema) {
    throw new SchemaError(`Schema by def - ${definition} - was not found`)
  }

  return <Component definitionSchema={definitionSchema} {...rest} />
}

SchemableCollection.propTypes = {
  component: PropTypes.elementType.isRequired,
  definition: PropTypes.string.isRequired,
}
