import React from 'react'
import { Route } from 'react-router-dom'
import { LocalShipping } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/shopProduct'
import { PointCollection } from './PersonalCollectionPoint/PointCollection'
import { PointNew } from './PersonalCollectionPoint/PointNew'
import { PointEdit } from './PersonalCollectionPoint/PointEdit'

const basePath = '/shop_shipping_types'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: LocalShipping,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      points: {
        subtitle: 'T_MODULE_SHOP_SHIPPING_POINT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/points`,
        exact: true,
      },
      pointNew: {
        subtitle: 'T_MODULE_SHOP_SHIPPING_POINT_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointNew}
            {...props}
          />
        ),
        path: `${basePath}/points/new`,
        exact: true,
      },
      pointEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PointEdit}
            {...props}
          />
        ),
        path: `${basePath}/points/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_SHOP_SHIPPING_TYPE',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
