import shippingTypeSchema from './shopShippingType'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_shipping_area_costs',
  resource: {
    definition: '#ShopShippingAreaCost',
    properties: {
      floatPrice: {
        type: 'float',
        description: 'T_MODULE_SHOP_SHIPPING_AREA_COST_PRICE',
        errorAlertWhenNullOrEmpty: 'Cena nie uzupełniona',
        validate: ['money'],
      },
      shippingType: {
        label: 'T_MODULE_SHOP_SHIPPING_AREA_COST_SHIPPING_TYPE',
        type: 'resource',
        endpoint: shippingTypeSchema.endpoint,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        validate: ['required'],
      },
    },
  },
}

export default schema
