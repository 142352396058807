import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'

const performexportselected = (action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let itemsUuidToSend = []

    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return tableState.data.items[index]
      }

      itemsUuidToSend.push(tableState.data.items[index].uuid)

      return tableState.data.items[index]
    })

    let url = `${getApiUrl()}/api/guide/pdf/download`
    let xtitle = null

    setTimeout(() => {
      fetch(url, {
        method: 'POST',
        headers: {
          ...authHeader(),
        },
        body: JSON.stringify({
          items: itemsUuidToSend,
        }),
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Error')
          }
          xtitle = response.headers.get('x-title')
          return response.blob()
        })
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', xtitle ?? 'lista.csv')

          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)

          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_SUCCESS,
            payload: { action, items },
          })
        })
        .catch(e => {
          notification('error', 'Error, nie mozna pobrać', e)
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        })
    }, 500)
  },
})

export const exportselected = {
  go: (action = 'exportselected', title = 'Eksport do listy') =>
    performexportselected(action, title),
}
