export const hasResourceAccess = (resourceAccess, user, isPPN = null) => {
  if (!resourceAccess) {
    return true
  }

  if (user.isPasswordResetRequired) {
    return false
  }

  if (
    user?.openform ||
    user?.superAdmin ||
    (user.group?.resourceAccess &&
      ((typeof resourceAccess === 'string' &&
        user.group.resourceAccess.includes(resourceAccess)) ||
        (Array.isArray(resourceAccess) &&
          resourceAccess.some(access =>
            user.group.resourceAccess.includes(access)
          ))))
  ) {
    return true
  }

  return false
}
