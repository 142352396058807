import React from 'react'
import { Form } from 'pages'
import schema from '_schema/contest'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { addOpenformProperties } from './_helper/addOpenformProperties'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const hostnameIri = useSelector(state => state.apiConfig.hostname)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width="100%"
      modifyProperties={properties =>
        addOpenformProperties(
          null,
          properties,
          null,
          null,
          hostnameIri.name
        )
      }
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
