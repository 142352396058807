import React from 'react'
import { useHistory } from 'react-router-dom'

export const DoubletCell = ({
  resource,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const doublets = [
    ...(resource.passportNumberCount > 1 ? ['Paszport'] : []),
    ...(resource.chipNumberCount > 1 ? ['Chip'] : []),
  ]

  return (
    <div onClick={onClick}>{doublets.length ? doublets.join(', ') : 'Nie'}</div>
  )
}
