import React from 'react'
import { Form } from 'pages'
import schema from '_schema/footer'
import routes from './routes'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { LANGS_IRI } from '../../_lib/langs'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const gettitle = item => {
    if (!item) return translate('T_GENERAL_MISSING_TITLE')
    const title =
      item.page && item.page.translations[LANGS_IRI.PL].title
        ? item.page.translations[LANGS_IRI.PL].title
        : item.translations[LANGS_IRI.PL].title
    if (!title) {
      return translate('T_GENERAL_MISSING_TITLE')
    }
    return title
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.items.endpoint}?pagination=false&iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.items.properties}
        definitionSchema={definitions[schema.subresources.items.definition]}
        headerTitle={translate('T_MODULE_FOOTER_ITEMS')}
        panelTitle={translate('T_GENERAL_PAGE')}
        titleAccessor={gettitle}
        statable={true}
        maxItems={12}
        key={iri}
        paginable={false}
      />
    </Form>
  )
}
