import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

const stateChoices = {
  ticket: 'bilety',
  feature: 'aktualności',
  trail: 'trasy',
  info: 'informacja',
  edu: 'edukacja',
  parking: 'parking',
  map: 'mapa',
  accommodation: 'schroniska i noclegi',
  culturalevents: 'wydarzenia kulturalne',
}

export const shortcuts = (
  blockLinkEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.SHORTCUTS,
  label: labelTrans ?? 'Blok "Na skróty"',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'T_GENERAL_TITLE',
          type: 'string',
        },
      },
    },
    links: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINKS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockLink',
      endpoint: blockLinkEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            // link: {
            //   type: 'string',
            //   description: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
            //   label: 'T_GENERAL_BLOCK_TYPE_LINKS_LINK',
            //   fullWidth: true,
            // },
          },
        },

        // targetBlank: {
        //   description: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        //   label: 'T_GENERAL_BLOCK_TYPE_LINKS_TARGET',
        // },

        page: {
          label: 'Strona ze struktury',
          type: 'resource',
          endpoint: `/api/pages?pagination=false`,
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,
        },

        iconType: {
          type: 'choice',
          choices: stateChoices,
          label: 'Ikona',
          validate: ['required'],
        },

      },
    },

  },
})
