import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'components/table'
import { Form } from 'components/form'
import { ContextualHint } from 'components/ContextualHint'
import { BodySkeleton, TableLoader } from 'components/table'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { Add } from '../../../components/embedded/Add'
import { useEmbeddedCollectionFetch } from '../../../components/embedded/_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from '../../../components/embedded/_helpers/performOrderChange'
import { applyProperties } from '_helpers/applyProperties'
import { prop } from '_helpers/prop'
import { parametersEmbeddedCollectionPropTypes } from './ParametersEmbeddedCollection.propTypes'
import { translate } from '_helpers/translate'
import { constants, reducer } from '../../../components/embedded/_state'
import { boolean } from '_helpers/boolean'
import { LANGS_IRI } from '../../../_lib/langs'
import { useEmbeddedCollectionStyles } from '../../../components/embedded'

export const ParametersEmbeddedCollection = ({
  endpoint,
  pid,
  parentIri,
  properties: customProperties,
  definitionSchema,
  definitions,
  headerTitle = null,
  expandTitle = null,
  panelTitle = null,
  titleAccessor = null,
  statable = false,
  statDisabled = false,
  statAccessor = 'stat',
  deleteDisabled = false,
  contextualHint = null,
  disabled = false,
  maxItems = null,
  classes = {},
  additionalTitleVarTitle = null,
  additionalTitleVar = null,
  disableTableBorder = false,
  modalResponse = null,
  setModalResponse = null,
  addButtonTitle = '',
  hideAddButton = false,
  addButtonSmall = false,
  orderable = true,
  order = 'asc',
  hidePanelTitle = false,
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(reducer, endpoint, pid)

  const defaultClasses = useEmbeddedCollectionStyles()

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const properties = applyProperties(
    customProperties,
    definitionSchema.properties,
    'missing'
  )

  const canShowAddButton = () => {
    if (!maxItems) {
      return true
    }

    return !(maxItems && state.items.length >= maxItems)
  }

  const getNewProperties = item => {
    let newProperties = JSON.parse(JSON.stringify(properties))
    if (!item?.predefinedType?.name === undefined) {
      delete newProperties?.value
    }

    if (
      ['BEGINNING', 'END', 'SURFACE', 'GENERAL_TIME'].includes(
        item?.predefinedType?.name
      ) === true
    ) {
      delete newProperties?.value
    }

    if (
      item?.predefinedType?.name !== undefined &&
      ['BEGINNING', 'END', 'SURFACE', 'GENERAL_TIME'].includes(
        item?.predefinedType?.name
      ) === false
    ) {
      delete newProperties?.translations?.properties?.value
    }

    if (item?.predefinedType?.name === 'TILT' && newProperties.value) {
      newProperties.value.description = translate('T_MODULE_TRAIL_SLOPE')
    }

    if (item?.predefinedType?.name === 'TIME_UP' && newProperties.value) {
      newProperties.value.description = translate('T_MODULE_TRAIL_TIME_UP')
    }

    if (item?.predefinedType?.name === 'TIME_DOWN' && newProperties.value) {
      newProperties.value.description = translate('T_MODULE_TRAIL_TIME_DOWN')
    }

    if (item?.predefinedType?.name === 'LENGTH' && newProperties.value) {
      newProperties.value.description = translate('T_MODULE_TRAIL_DISTANCE')
    }

    return newProperties
  }

  useEffect(() => {
    if (modalResponse && setModalResponse) {
      dispatch({ type: constants.ADD_ITEM, payload: { value: modalResponse } })
      setModalResponse(null)
    }
  }, [dispatch, modalResponse, setModalResponse])

  return (
    <>
      <div className={clsx(defaultClasses.header, classes.header)}>
        <div>{headerTitle}</div>
        <div
          className={clsx(defaultClasses.massExpand, classes.massExpand)}
          onClick={handleExpand}
        >
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer
        className={clsx(
          defaultClasses.collectionContainer,
          classes.collectionContainer
        )}
      >
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                const handleSyncUpdatedAt = resource => {
                  dispatch({
                    type: constants.SYNC_UPDATED_AT,
                    payload: {
                      iri: resource['@id'],
                      updatedAt: resource.updatedAt,
                    },
                  })
                }

                const newProperties = getNewProperties(item)

                return (
                  <ManuallyOrderableTableRow index={i} key={`${item.uuid}`}>
                    <TableCell
                      key="item"
                      className={clsx(defaultClasses.cell, classes.cell)}
                      style={{ width: '100%' }}
                    >
                      <Accordion
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <AccordionSummary
                          classes={{
                            content: clsx(
                              defaultClasses.expansionPanelSummary,
                              classes.expansionPanelSummary
                            ),
                          }}
                        >
                          {orderable ? <ManualOrderDragHandle /> : null}
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.itemTitle,
                              classes.itemTitle
                            )}
                          >
                            <span>
                              {!hidePanelTitle && panelTitle
                                ? `${panelTitle}: `
                                : ''}
                            </span>
                            {titleAccessor
                              ? typeof titleAccessor === 'function'
                                ? titleAccessor(item)
                                : prop(item, titleAccessor)
                                ? prop(item, titleAccessor)
                                : translate('T_GENERAL_MISSING_TITLE')
                              : item.translations?.[
                                  LANGS_IRI[
                                    process.env.REACT_APP_LOCALE.toUpperCase()
                                  ]
                                ]?.title
                              ? item.translations?.[
                                  LANGS_IRI[
                                    process.env.REACT_APP_LOCALE.toUpperCase()
                                  ]
                                ]?.title
                              : translate('T_GENERAL_MISSING_TITLE')}
                            {additionalTitleVar && additionalTitleVarTitle && (
                              <span>
                                {additionalTitleVarTitle}:{' '}
                                {item[additionalTitleVar]}
                              </span>
                            )}
                          </Typography>
                          {statable && (
                            <div
                              className={clsx(
                                defaultClasses.itemIcon,
                                classes.itemIcon
                              )}
                            >
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={
                                  statDisabled ? statDisabled : state.isFetching
                                }
                                handleSyncUpdatedAt={handleSyncUpdatedAt}
                              />
                            </div>
                          )}
                          <div
                            className={clsx(
                              defaultClasses.itemIcon,
                              classes.itemIcon
                            )}
                          >
                            <Delete
                              resource={item}
                              accessor={
                                titleAccessor ||
                                `translations.${
                                  LANGS_IRI[
                                    process.env.REACT_APP_LOCALE.toUpperCase()
                                  ]
                                }.title`
                              }
                              disabled={
                                deleteDisabled
                                  ? deleteDisabled
                                  : state.isFetching
                              }
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.expandIcon,
                              classes.expandIcon
                            )}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{
                            root: clsx(
                              defaultClasses.itemContainer,
                              classes.itemContainer
                            ),
                          }}
                        >
                          <Form
                            url={item['@id']}
                            method="PUT"
                            properties={newProperties}
                            resource={item}
                            width={'100%'}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            definitions={definitions}
                            disabled={disabled}
                            key={`${item.uuid}-${i}`}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      {canShowAddButton() === true && !hideAddButton && (
        <div
          className={clsx(
            defaultClasses.addContainer,
            classes.addContainer,
            !addButtonSmall && defaultClasses.addContainerColor
          )}
        >
          <Add
            endpoint={endpoint}
            parent={parentIri}
            dispatch={dispatch}
            title={addButtonTitle}
            smallButton={addButtonSmall}
            disabled={disabled || state.isFetching}
            order={order}
          />
          {contextualHint && (
            <div
              className={clsx(
                defaultClasses.contextualHint,
                classes.contextualHint
              )}
            >
              <ContextualHint name={contextualHint} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

ParametersEmbeddedCollection.propTypes = parametersEmbeddedCollectionPropTypes
