import React, { useState } from 'react'
import moment from 'moment'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import seaEyeHistoryEntryRoutes from 'pages/SeaEyeHistoryEntries/routes'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const operations = (accessor, dispatch, history) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const handleHistoryEntry = (description, type) => () => {
      dispatch({
        type: commonConstants.SET_CURRENT_SEA_EYE_HISTORY_ENTRY_FORM,
        payload: {
          fiacres: {
            defaultValue: resource.fiacres,
          },
          horses: {
            defaultValue: [resource],
          },
          occuredAt: {
            defaultValue: moment().format('YYYY-MM-DD'),
          },
          description: {
            defaultValue: description,
          },
          type,
        },
      })

      history.push(seaEyeHistoryEntryRoutes().new.path)
    }

    return (
      <div className={classes.root}>
        <IconButton
          aria-label="record menu"
          aria-controls="record-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          className={clsx(
            classes.menuTrigger,
            menuAnchor && classes.menuTriggerOpen
          )}
        >
          <MoreVertOutlined color="inherit" />
        </IconButton>
        <StyledMenu
          id="record-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={handleClose}
        >
          <MenuItem className={classes.menuItem}>
            <EditLink
              resource={resource}
              accessor={accessor}
              editPath={editPath}
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <DeleteCell
              resource={resource}
              accessor={accessor}
              tableState={tableState}
              tableStateDispatch={tableStateDispatch}
            />
          </MenuItem>
          {!resource.withdrawnAt && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleHistoryEntry(
                'Badania okresowe',
                '/api/sea_eye_history_entry_types/EXAMINE'
              )}
            >
              Badania okresowe
            </MenuItem>
          )}
          {!resource.withdrawnAt && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleHistoryEntry(
                'Sprzedaż konia',
                '/api/sea_eye_history_entry_types/SELL'
              )}
            >
              Sprzedaż konia
            </MenuItem>
          )}
          {!resource.withdrawnAt && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleHistoryEntry(
                'Wycofanie konia',
                '/api/sea_eye_history_entry_types/WITHDRAW'
              )}
            >
              Wycofanie konia
            </MenuItem>
          )}
          {resource.withdrawnAt && (
            <MenuItem
              className={classes.menuItem}
              onClick={handleHistoryEntry(
                'Przywrócenie konia',
                '/api/sea_eye_history_entry_types/RESTORE'
              )}
            >
              Przywrócenie konia
            </MenuItem>
          )}
        </StyledMenu>
        <Timestamp resource={resource} />
      </div>
    )
  },
})
