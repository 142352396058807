import PropTypes from 'prop-types'

export const thumbCollectionPropTypes = {
  endpoint: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  originalPhotoUrl: PropTypes.string.isRequired,
  photoProperty: PropTypes.string,
  photoEndpoint: PropTypes.string,
  uploadValidators: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  twoColLayout: PropTypes.bool,
  cropperNodeRef: PropTypes.object,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  classes: PropTypes.shape({
    root: PropTypes.string,
    twoColLayout: PropTypes.string,
    thumbsContainer: PropTypes.string,
    thumbImageContainer: PropTypes.string,
    thumbImage: PropTypes.string,
    thumbTitle: PropTypes.string,
    emptyResults: PropTypes.string,
    cropContainer: PropTypes.string,
    cropContainerFullScreen: PropTypes.string,
    cropContainerAdditionalButtons: PropTypes.string,
    cropContainerFullScreenButton: PropTypes.string,
    cropContainerSubmitButtons: PropTypes.string,
    cropContainerResetButton: PropTypes.string,
    cropContainerCrop: PropTypes.string,
    cropContainerCropFullScreen: PropTypes.string,
  }),
  parentDimensions: PropTypes.object,
  modifiedCallback: PropTypes.func,
}
