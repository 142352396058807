import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
//import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { ModuleCell } from './table/cells/ModuleCell'
import { MethodCell } from './table/cells/MethodCell'
import { translate } from '_helpers/translate'
import schema from '_schema/registryEntry'
import { TitleCell } from './table/cells/TitleCell'
import { isObjectEmpty } from '../../_helpers/isObjectEmpty'
import globalRoutes from 'pages/routes'
import { useSelector } from 'react-redux'
import { NotFound } from '../NotFound'
import { hasResourceAccess } from '../../_helpers/hasResourceAccess'
import { Route } from 'react-router-dom'
import { CollectionHeader } from '../../components/CollectionHeader'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'

export const Collection = () => {
  const profile = useSelector(state => state.profile)

  const accessableRoutes = useMemo(
    () =>
      !isObjectEmpty(profile)
        ? [
            ...globalRoutes(),
            {
              type: Route,
              render: props => <NotFound {...props} />,
              path: '/*',
            },
          ].filter(route => hasResourceAccess(route.resourceAccess, profile))
        : [],
    [profile]
  )

  const columns = useMemo(
    () => [
      {
        columnId: 'createdAt',
        ...standard('createdAt'),
        header: 'Czas',
        width: '12%',
        filterable: true,
        Cell: DatetimeCell,
      },
      {
        columnId: 'username',
        ...standard('username'),
        width: '12%',
        header: 'E-mail',
      },
      {
        columnId: 'resourceClass',
        accessor: 'requestUri',
        header: translate('T_GENERAL_MODULE'),
        filterName: 'resourceClass',
        filterable: true,
        sortable: false,
        width: '12%',
        Cell: ModuleCell,
        Filter: ResourceFilter,
        routes: accessableRoutes,
      },
      /*
      {
        accessor: 'resourceUuid',
        header: translate('T_GENERAL_RECORD'),
        filterable: true,
        sortable: false,
        width: '10%',
      },*/
      {
        columnId: 'title',
        accessor: 'title',
        header: 'Wpis / strona',
        filterable: true,
        sortable: false,
        width: '26%',
        Cell: TitleCell,
        routes: accessableRoutes,
      },
      {
        columnId: 'type',
        accessor: 'type.trans',
        header: 'Pole / blok',
        filterable: true,
        filterName: 'iri[type]',
        sortable: false,
        width: '12%',
      },
      {
        columnId: 'requestMethod',
        accessor: 'requestMethod',
        header: translate('T_GENERAL_OPERATION'),
        filterable: true,
        sortable: false,
        width: '12%',
        Cell: MethodCell,
        Filter: ChoiceFilter,
        choices: {
          POST: 'Utworzenie',
          PUT: 'Edycja',
          DELETE: 'Usunięcie',
        },
      },
      {
        columnId: 'ip',
        header: 'IP',
        filterable: true,
        ...standard('ip'),
        width: '12%',
      },
    ],
    [accessableRoutes]
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  // <Title>{translate(routes().index.title)}</Title>

  return (
    <Paper>
      <CollectionHeader
        buttonPath={''}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        {translate('T_MODULE_PARK')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={visibleColumns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'username',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'resourceUuid',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'requestMethod',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'resourceClass',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'iri[type]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[username]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'iri[type]',
            title: 'Wszystkie',
            Filter: ResourceFilter,
            endpoint: '/api/registry_entry_types?pagination=false',
            titleAccessor: 'trans',
          },
          {
            name: 'resourceClass',
            title: 'Moduł',
            Filter: ResourceFilter,
            endpoint: '/api/registry_entry_modules?pagination=false',
            titleAccessor: 'title',
          },
        ]}
      />
    </Paper>
  )
}
