import React from 'react'
import { Route } from 'react-router-dom'
import { FileCopyOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'components/SchemableComponent'
import { Manager } from './Manager'
import { addRoutesConfig } from '_helpers/addRoutesConfig'

const PATH = '/manager_media'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_VIEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Manager}
            {...props}
          />
        ),
        path: PATH,
        exact: true,
        icon: FileCopyOutlined,
      },
    },
    {
      title: 'File manager',
    }
  )

export default routes
