import { translate } from './translate'
import { LANGS_IRI } from '_lib/langs'

const defaultSelectTitle = resource =>
  resource.translations?.[LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]]
    ?.title ||
  resource.title ||
  resource.name ||
  resource.fullName

export const modifyBreadcrumbs = (
  resource,
  parent,
  paths,
  length = 20,
  selectTitle = null
) => {
  const lastPath = paths.pop()

  if (!paths.length) {
    return [lastPath]
  }

  if (paths[paths.length - 1].self && parent) {
    paths.push({ ...paths[paths.length - 1] })
  }

  if (resource) {
    const title =
      (selectTitle ? selectTitle(resource) : defaultSelectTitle(resource)) ||
      translate('T_GENERAL_MISSING_TITLE')

    paths[paths.length - 1].title = `${translate(
      paths[paths.length - 1].title
    )} (${title.length > length ? `${title.slice(0, length)}..` : title})`
  }

  if (!parent) {
    return [...paths, lastPath]
  }

  const { path, self } = paths[paths.length - 1]

  paths[paths.length - 1].path = (self || path).includes(':id')
    ? (self || path).replace(':id', parent.uuid)
    : self || path

  return [...modifyBreadcrumbs(parent, parent.parent, [...paths]), lastPath]
}
