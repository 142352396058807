import React from 'react'
import { Form } from 'pages'
import schema from '_schema/shopElektronicznyNadawcaBufor'
import routes from './routes'

export const BuforEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().buforIndex.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
      modifyProperties={properties => {
        properties.sendDate.disabled = true
        properties.location.disabled = true
        return properties
      }}
    ></Form>
  )
}
