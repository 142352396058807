import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const homeawaa = labelTrans => ({
  name: BLOCK_TYPES_IRI.HOME_AWAA,
  label: labelTrans,
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },

    translations: {
      type: 'translation',

      properties: {
        title: {
          type: 'string',
          description: 'Tytuł sekcji',
          label: 'Tytuł sekcji',
          hint: 'domyślnie trans T_CURRENT_CONDITIONS',
        },
      },
    },

    // is off
    // showTourist: {
    //   type: 'boolean',
    //   description: 'Pokaż kolumnę Kominukaty'
    // },
    showWeather: {
      type: 'boolean',
      description: 'Pokaż kolumnę Pogoda'
    },
    showAvalanche: {
      type: 'boolean',
      description: 'Pokaż kolumnę Lawiny'
    },
    showAirly: {
      type: 'boolean',
      description: 'Pokaż kolumnę Airly stan'
    },
  },
})
