import {
  text,
  textWithPhoto,
  files,
  entitiesMap,
  articlesList,
  gallery,
} from '_schema/_blocksBip'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockFileEndpoint,
  blockImageEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    files(photoEndpoint, blockFileEndpoint),
    entitiesMap(),
    articlesList(),
    gallery(photoEndpoint, blockImageEndpoint),
  ],
})

export default schema
