import guideSchema from './guide'

const schema = {
  endpoint: '/api/guide_permissions',
  access: 'guide',
  resource: {
    definition: '#GuidePermission',
    properties: {
      parent: {
        description: 'Przewodnik',
        type: 'resource',
        endpoint: `${guideSchema.endpoint}?pagination=false&order[surname]=asc&order[name]=asc`,
        titleAccessor: 'fullName',
        validate: ['required'],
      },
      name: {
        validate: ['required'],
      },
      description: {
        type: 'textarea',
        validate: ['required'],
      },
    },
  },
}

export default schema
