import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/trail_categories',
  clone_endpoint: '/api/trail_categories/clone',
  resource: {
    definition: '#TrailCategory',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          alt: {
            description: 'T_GENERAL_PREDEFINED_ICON_ALT',
          },
        },
      },
      predefinedIcon: {
        type: 'resourceWithIcon',
        endpoint: '/api/trail_category_predefined_icons',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_GENERAL_PREDEFINED_ICON',
        fullWidth: true,
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_ICON',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/trail_category_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              title: media.translations[LANGS_IRI.PL].title,
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
