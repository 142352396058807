import shippingAreaSchema from './shopShippingArea'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_shipping_countries',
  endpointOrder: '/api/shop_shipping_countries/order/',
  endpointSelect: '/api/shop_shipping_countries/select',
  resource: {
    definition: '#ShopShippingCountry',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            validate: ['required', 'length(1)'],
          },
        },
      },
      countryCode: {
        description: 'T_MODULE_SHOP_SHIPPING_COUNTRY_CODE',
        validate: ['required', 'length(2)'],
      },
      area: {
        type: 'resource',
        endpoint: `${shippingAreaSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        validate: ['required'],
        description: 'T_MODULE_SHOP_SHIPPING_COUNTRY_AREA',
      },
      isPoland: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
