// import fileSchema from './media'

const stateChoices = {
  tiktok: 'Tiktok',
  youtube: 'Youtube',
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  spotify: 'Spotify',
  musicicon1: 'Podbean',
  musicicon2: 'Podcasty Google',
  musicicon3: 'Audioteka.pl',
  itunes: 'iTunes',
}

const schema = {
  endpoint: '/api/social_media_items',
  access: 'socialMediaItem',
  resource: {
    definition: '#SocialMediaItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'tytuł widoczny tylko w PA',
          },
          link: {},
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      targetBlank: {},

      //is off
      // media: {
      //   type: 'file',
      //   description: 'Ikona',
      //   endpoint: fileSchema.endpoint,
      //   validate: ['maxSize'],
      //   accept: 'image/*'
      // },

      iconName: {
        type: 'choice',
        choices: stateChoices,
        label: 'Ikona',
      },

      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
