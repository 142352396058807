import shippingCountrySchema from './shopShippingCountry'
import discountCodeSchema from './shopDiscountCode'
import { LANGS_IRI } from '../_lib/langs'
import shopProductSchema from './shopProduct'
import fileSchema from "./media";

const schema = {
  endpoint: '/api/shop_orders',
  endpointCustomer: '/api/shop_orders_customer',
  resource: {
    definition: '#ShopOrder',
    properties: {
      orderId: {
        disabled: true,
        description: 'Numer zamówienia',
      },
      totalPrice: {
        type: 'float',
        disabled: true,
        description: 'Cena całkowita (z przesyłką)',
      },
      status: {
        type: 'resource',
        endpoint: `/api/shop_order_statuses?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        description:
          'Status zamówienia (Zmiana statusu wysyła powiadomienie do klienta)',
      },
      legacyStatus: {
        type: 'resource',
        endpoint: '/api/shop_legacy_order_statuses?pagination=false',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Status (IMPORT)',
        disabled: true,
      },
      showLegacyAlert: {
        type: 'alert',
        description: 'To zamówienie pochodzi ze starego sklepu',
        variant: 'filled',
        severity: 'info',
      },
      priceRecalculationDisabled: {
        description: 'Wyłącz rekalkulację ceny',
        hint:
          'Przy modyfikacji zamówienia, cena zamówienia i dostawy nie zostanie przeliczona',
      },
      adminNotes: {
        type: 'textarea',
        description:
          'Uwagi administratora (widoczne tylko przez administratora)',
      },
      media: {
        type: 'file',
        description: 'Faktura VAT',
        endpoint: fileSchema.protectedEndpoint,
        validate: ['maxSize'],
        accept: 'application/pdf',
      },
      orderUuidToSendFv: {
        type: 'shopOrderSendFv',
        endpoint: '/api/send_order_fv',
      },
      firstName: {
        description: 'Imię',
      },
      lastName: {
        description: 'Nazwisko',
      },
      companyName: {
        description: 'Nazwa firmy',
      },
      email: {
        description: 'Email',
      },
      phone: {
        description: 'Numer telefonu',
      },
      street: {
        description: 'Ulica',
      },
      houseNumber: {
        description: 'Numer domu',
      },
      apartmentNumber: {
        description: 'Numer mieszkania',
      },
      postalCode: {
        description: 'Kod pocztowy',
      },
      city: {
        description: 'Miasto',
      },
      province: {
        type: 'resource',
        endpoint: `/api/provinces?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        description: 'Województwo',
        disabled: true,
      },
      userNotes: {
        type: 'textarea',
        description: 'Uwagi kupującego',
      },
      wantFv: {
        type: 'alert',
        description: 'Klient chce otrzymać fakturę!',
        variant: 'filled',
        severity: 'info',
      },
      fvCompanyName: {
        description: '[FAKTURA] Nazwa firmy',
      },
      fvNip: {
        description: '[FAKTURA] Numer NIP',
      },
      fvAddress: {
        type: 'textarea',
        description: '[FAKTURA] Adres',
      },
      shippingHeader: {
        type: 'header',
        description: 'Dane do dostawy',
      },
      pickupPoint: {
        description: 'Id punktu odbioru',
      },
      pickupPointName: {
        description: 'Nazwa punktu odbioru',
      },
      /*
      country: {
        type: 'resource',
        endpoint: `${shippingCountrySchema.endpointSelect}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Kraj',
        disabled: true,
      },
      shippingType: {
        type: 'resource',
        endpoint: `${shippingTypeSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Metoda dostawy',
        disabled: true,
      },*/
      shippingFirstName: {
        description: 'Imię',
      },
      shippingLastName: {
        description: 'Nazwisko',
      },
      shippingCompany: {
        description: 'Nazwa firmy',
      },
      shippingStreet: {
        description: 'Ulica',
      },
      shippingHouseNumber: {
        description: 'Numer domu',
      },
      shippingApartmentNumber: {
        description: 'Numer mieszkania',
      },
      shippingCity: {
        description: 'Miasto',
      },
      shippingPostalCode: {
        description: 'Kod pocztowy',
      },
      shipping: {
        type: 'shopOrderShipping',
        name1: 'country',
        endpoint1: `${shippingCountrySchema.endpointSelect}?pagination=false`,
        titleAccessor1: `translations.${LANGS_IRI.PL}.title`,
        label1: 'Kraj',
        name2: 'shippingType',
        label2: 'Metoda dostawy',
        titleAccessor2: `translations.${LANGS_IRI.PL}.title`,
        endpoint2: `${shippingCountrySchema.endpointOrder}:uuid`,
        name3: 'paymentMethod',
        label3: 'Metoda płatności',
        titleAccessor3: `translations.${LANGS_IRI.PL}.title`,
        validate: ['required'],
        fullWidth: true,
      },
      countryTitle: {
        description: 'Kraj',
        disabled: false,
      },
      areaTitle: {
        description: 'Obszar',
        disabled: true,
      },
      shippingTitle: {
        description: 'Metoda dostawy',
        disabled: true,
      },
      paymentMethodTitle: {
        description: 'Metoda płatności',
        disabled: true,
      },
      shippingCost: {
        type: 'number',
        description: 'Cena przesyłki',
        validate: ['money'],
      },
      freeShippingFromFloat: {
        type: 'string',
        description: 'Darmowa przesyłka od',
        validate: ['money'],
      },
      cashOnDelivery: {
        description: 'Pobraniowa',
      },
      paidAmountFloat: {
        type: 'string',
        description: 'Zapłacono',
        validate: ['money'],
        hint: 'Ilość pieniędzy zapłacona przez klienta',
      },
      trackingNumber: {
        type: 'string',
        description: 'Numer przesyłki',
        hint: 'Numer używany do śledzenia przesyłki',
      },
      trackingUrl: {
        type: 'string',
        description: 'Link do śledzenia',
        disabled: true,
        fullWidth: true,
      },
      trackingLinkSendTime: {
        type: 'datetime',
        description: 'Data wysłania linku śledzenia',
      },
      discountCodeHeader: {
        type: 'header',
        description: 'Kod promocyjny',
      },
      discountCode: {
        type: 'resource',
        endpoint: `${discountCodeSchema.endpoint}?pagination=false`,
        titleAccessor: `code`,
        description: 'Kod promocyjny',
      },
      discountCodeActive: {
        description: 'Aktywny',
      },
      discountCodeCode: {
        description: 'Kod promocyjny',
      },
      discountCodeRate: {
        description: 'Procent rabatu',
      },
      discountCodeMinimalPrice: {
        type: 'float',
        description: 'Minimalna kwota',
      },
      discountCodeStartDate: {
        description: 'Obowiązuje od',
      },
      discountCodeEndDate: {
        description: 'Obowiązuje do',
      },
    },
  },
  subresources: {
    variants: {
      endpoint: '/api/shop_order_variants',
      definition: '#ShopOrderVariant',
      properties: {
        importModel: {
          description: 'Model (stary sklep)',
          hideWhenEmpty: true,
        },
        mainVariantOptionTitle: {
          disabled: true,
          fullWidth: true,
          labelAccessor: 'mainVariantTitle',
        },
        secondaryVariantOptionTitle: {
          disabled: true,
          fullWidth: true,
          labelAccessor: 'secondaryVariantTitle',
          description: 'Domyślny',
        },
        ignoreDiscountCode: {
          description: 'Ignoruj kod promocyjny',
        },
        ignorePromotion: {
          description: 'Ignoruj promocje',
        },
        promotionStartDate: {
          type: 'datetime',
          disabled: true,
          fullWidth: true,
          description: 'Data rozpoczęcia promocji',
        },
        promotionEndDate: {
          type: 'datetime',
          disabled: true,
          fullWidth: true,
          description: 'Data zakończenia promocji',
        },
        promotionFreeShipping: {
          description: 'Darmowa przesyłka z promocji',
        },
        biggestPromotionRate: {
          fullWidth: true,
          description: 'Wysokość rabatu z promocji',
        },
        showDiscountAlert: {
          type: 'alert',
          description:
            'Gdy produkt posiada zniżkę kwotową, inne promocje procentowe będą ignorowane dla tego produktu',
          variant: 'filled',
          severity: 'info',
        },
        biggestFlatPromotionFloat: {
          type: 'string',
          fullWidth: true,
          description: 'Wysokość rabatu kwotowego z promocji',
          validate: ['money'],
        },
        price: {
          type: 'float',
          description: 'Łączna cena',
          hint: 'Uwzględnia rabaty i promocje',
          disabled: true,
          validate: ['money'],
        },
        originalUnitPriceFloat: {
          type: 'float',
          description: 'Cena jednostkowa',
          validate: ['money'],
        },
        newCount: {
          description: 'Ilość',
        },
        vatRate: {
          type: 'number',
          description: 'Stawka VAT (%)',
        },
        freeShipping: {
          description: 'Darmowa przesyłka',
        },
      },
    },
    addVariantModal: {
      properties: {
        product: {
          type: 'shopOrderVariant',
          name1: 'product',
          endpoint1: `${shopProductSchema.endpointSelect}?pagination=false`,
          titleAccessor1: `nativeTitle`,
          label1: 'Produkt',
          name2: 'variant',
          label2: 'Wariant',
          titleAccessor2: `collectionTitle`,
          endpoint2: `${shopProductSchema.subresources.options.endpoint}?pagination=false&iri[parent]=:iri`,
          validate: ['required'],
          fullWidth: true,
        },
        newCount: {
          type: 'number',
          label: 'Ilość',
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
