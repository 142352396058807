import React, { useMemo, useEffect, useCallback } from 'react'
import queryString from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { Form, NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { Loader } from 'components/Loader'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { collectionActions } from '_actions'
import { commonConstants } from '_constants'
import { translate } from '_helpers/translate'
import schema from '_schema/page'
import routes from './routes'

export const New = ({ schema: definitions, ...rest }) => {
  const profile = useSelector(state => state.profile)

  const { pid } = queryString.parse(rest.location.search)
  const defaultData = pid ? { parent: `${schema.endpoint}/${pid}` } : {}

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  useResourceFetch(
    pid && `${schema.endpoint}/${pid}`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const isNotEditable = useMemo(
    () =>
      !parent
        ? false
        : !profile?.openform &&
          (!parent.isSubPageable ||
            parent.depth >= process.env.REACT_APP_PAGE_MAX_DEPTH),
    [parent, profile]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isNotEditable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }
  }, [isNotEditable, dispatch])

  const history = useHistory()

  const handleSuccess = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    dispatch(collectionActions.resetConfig(schema.endpoint))

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(
    resource => {
      dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

      history.push(routes().edit.path.replace(':id', resource.uuid))
    },
    [history, dispatch]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : pid && fetchError ? (
    <NotFound />
  ) : isNotEditable ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      defaultData={defaultData}
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      modifyProperties={properties =>
        addOpenformProperties(profile?.openform, properties)
      }
      method="POST"
      url={schema.endpoint}
      storeCollectionId={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      header={
        <Title>
          {translate(pid ? 'T_MODULE_PAGES_ADD_SUBPAGE' : 'T_GENERAL_NEW')}
        </Title>
      }
      fieldsFullWidth={false}
      width={'100%'}
    />
  )
}
