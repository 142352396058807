import React from 'react'
import PropTypes from 'prop-types'
import { YoutubeType } from 'components/form/fields/YoutubeType'

export const MediaYoutubeType = ({ additional, setAdditional, ...rest }) =>
  additional.media === 'youtube' ? (
    <YoutubeType
      setAdditional={setAdditional}
      syncWithAdditional={true}
      {...rest}
    />
  ) : null

MediaYoutubeType.propTypes = {
  additional: PropTypes.object,
  setAdditional: PropTypes.func.isRequired,
}
