export const addOpenformProperties = (isOpenform, properties) => {
  if (!isOpenform) {
    return properties
  }

  return {
    ...properties,
    /*
    isOpenform: {
      description: {
        text: 'T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM',
        color: '#c20000',
      },
      type: 'boolean',
    },*/
  }
}
