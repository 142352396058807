const schema = {
  endpoint: '/api/trail_subcategories',
  resource: {
    definition: '#TrailSubcategory',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      isWarning: {
        description: 'T_MODULE_TRAIL_CATEGORY_IS_WARNING',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
