import _block from './_blockVolunteering'
import photoSchema from './photo'
import mediaSchema from './media'
import categorySchema from './volunteeringEventCategory'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/volunteering_events',
  clone_endpoint: '/api/volunteering_event/clone',
  resource: {
    definition: '#VolunteeringEvent',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/volunteering_event_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      dateFrom: {
        type: 'date',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'date',
        description: 'T_GENERAL_DATE_TO',
        validate: ['required'],
      },
      vacancies: {
        description: 'T_MODULE_VOLUNTEERING_VACANCIES',
        validate: ['required'],
      },
      address: {},
      category: {
        type: 'resource',
        endpoint: `${categorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORY',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/volunteering_event_blocks',
      definition: '#VolunteeringEventBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/volunteering_event_block_thumbs',
        '/api/volunteering_event_block_anchors',
        '/api/volunteering_event_block_files',
        '/api/volunteering_event_block_links',
        '/api/volunteering_event_block_link_thumbs',
        '/api/volunteering_event_block_list_items',
        '/api/volunteering_event_block_list_item_thumbs'
      ).types,
    },
  },
}

export default schema
