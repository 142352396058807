import React, { useState } from 'react'
import { Button, Grid, alpha } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import GetAppIcon from '@material-ui/icons/GetApp'
import SubjectIcon from '@material-ui/icons/Subject'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import { Loader } from 'components/Loader'
import { formatFileSize } from '_helpers/formatFileSize'
import { DeleteMediaModal } from './DeleteMediaModal'
import { downloadFile } from '_helpers/downloadFile'
import { MediaMetadataModal } from './MediaMetadataModal'
import { getToken } from '_helpers/getToken'
import { getApiUrl } from '../../_services/apiUrlProvider'

const useStyles = makeStyles(theme => ({
  title: {
    lineBreak: 'anywhere',
  },
  size: {
    fontSize: '0.8em',
  },
  grid_container: {
    flexGrow: 1,
    minHeight: 100,
    gap: 5,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
    alignContent: 'start',
  },
  media_container: {
    position: 'relative',
    padding: 10,
    '&:hover': {
      opacity: 1,
      '& $media_details': {
        display: 'flex',
      },
    },
  },
  media_thumb_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '3 / 2',
  },
  media_thumb_img: {
    width: '100%',
    height: 'auto',
    aspectRatio: '3 / 2',
    objectFit: 'contain',
    objectPosition: 'center',
    minWidth: 150,
  },
  media_details: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.common.black, 0.55),
    color: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& button': {
      margin: 10,
    },
  },
}))

export const GridView = ({
  medias,
  isFetching,
  handleDelete,
  definitions,
  handleModifyCurrentDirMedia,
  handleManagerChoice = null,
}) => {
  const [currentMedia, setCurrentMedia] = useState(null)

  const handleMetadata = e => {
    const target = e.target.getAttribute('index')
      ? e.target
      : e.target.closest('[index]')

    const index = parseInt(target.getAttribute('index'))

    if (!medias[index]) {
      return
    }

    setCurrentMedia({ ...medias[index] })
  }

  const handleDownload = e => {
    const target = e.target.getAttribute('index')
      ? e.target
      : e.target.closest('[index]')

    const index = parseInt(target.getAttribute('index'))

    const url = medias[index]?.url
    const originalName = medias[index]?.originalName

    if (!url) {
      return
    }

    downloadFile(url, originalName)
  }

  const classes = useStyles()

  return (
    <div
      style={{ paddingTop: 25, paddingBottom: 25 }}
      className={classes.grid_container}
    >
      {isFetching ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          style={{ padding: 20 }}
        >
          <Loader />
        </Grid>
      ) : (
        medias.map((media, index) => {
          const thumb = getThumbUrl(media)

          return (
            <div key={media.uuid} className={classes.media_container}>
              <div className={classes.media_thumb_wrapper}>
                <div
                  style={{
                    width: 150,
                    textAlign: 'center',
                  }}
                  className={classes.media_thumb_img}
                >
                  {thumb ? (
                    <img alt="" src={thumb} />
                  ) : (
                    <DescriptionOutlinedIcon
                      style={{
                        width: 100,
                        height: 'auto',
                      }}
                    />
                  )}
                </div>
              </div>
              <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                  <div className={classes.title}>{media.originalName}</div>
                  <div className={classes.size}>
                    {media.width &&
                      media.height &&
                      `${media.width} x ${media.height}`}
                  </div>
                  <div className={classes.size}>
                    {media.size > 0 && formatFileSize(media.size)}
                  </div>
                </Grid>
              </Grid>

              <div className={classes.media_details}>
                <Grid container justifyContent="center" alignItems="center">
                  {handleManagerChoice && (
                    <Grid
                      item
                      container
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Button
                        startIcon={<CheckOutlinedIcon />}
                        color="inherit"
                        size="small"
                        variant="contained"
                        onClick={() => handleManagerChoice(media)}
                      >
                        <span>wybierz</span>
                      </Button>
                    </Grid>
                  )}

                  <Grid
                    item
                    container
                    xs={12}
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                  >
                    <Button
                      startIcon={<SubjectIcon />}
                      color="inherit"
                      size="small"
                      variant="contained"
                      component="a"
                      style={{
                        width: 'calc(100% - 20px)',
                        margin: 10,
                      }}
                      index={index}
                      onClick={handleMetadata}
                    >
                      <span>metadane</span>
                    </Button>
                    <Button
                      startIcon={<GetAppIcon />}
                      color="inherit"
                      size="small"
                      variant="contained"
                      component="a"
                      style={{
                        width: 'calc(100% - 20px)',
                        margin: 10,
                      }}
                      index={index}
                      onClick={handleDownload}
                    >
                      <span>pobierz</span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DeleteMediaModal
                      variant="contained"
                      media={media}
                      size="small"
                      callback={handleDelete}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        })
      )}
      <MediaMetadataModal
        definitions={definitions}
        currentMedia={currentMedia}
        callback={handleModifyCurrentDirMedia}
      />
    </div>
  )
}

const getThumbUrl = media => {
  const thumb = media.thumbs.find(thumb => thumb.name === 'default')

  if (!thumb) {
    return null
  }

  return `${getApiUrl()}${thumb.url}?bearer=${getToken()}`
}
