import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/event'
import routes from './routes'
import { status } from '../../components/table/_columns/status'
import { MultipleDatetimeCell } from '../../components/table/cells/MultipleDatetimeCell'
import { useSelector } from 'react-redux'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { BooleanFilter } from '../../components/table/filters/BooleanFilter'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'

export const Collection = () => {
  const apiConfig = useSelector(state => state.apiConfig)
  const isPPN = apiConfig.hostname.name === 'PPN_GATE'

  const columns = useMemo(
    () => [
      {
        columnId: 'nativeTitle',
        header: translate('T_GENERAL_TITLE'),
        accessor: `nativeTitle`,
        filterable: true,
        sortable: true,
        width: '60%',
      },
      {
        columnId: 'dates',
        header: 'Daty wydarzenia',
        accessor: `datesCms`,
        filterName: 'dates.dateFrom',
        sorterName: 'dates.dateFrom',
        filterable: true,
        sortable: true,
        width: '12%',
        Cell: MultipleDatetimeCell,
      },
      {
        filtersList: ['iri[hostname]'],
        columnId: 'hostname',
        header: translate('T_GENERAL_PARK'),
        accessor: `hostnameTrans`,
        filterable: true,
        sortable: true,
        width: '10%',
        excluded: !isPPN,
        translateAccessor: true,
      },
      {
        columnId: 'visibleInPPN',
        ...status('visibleInPPN'),
        width: '5%',
        customEndpoint: '/api/events/visibility/@id',
        excluded: !isPPN,
        Filter: ChoiceFilter,
        choices: {
          true: 'Widoczne',
          false: 'Niewidoczne',
        },
      },
      {
        columnId: 'suggested',
        ...status('suggested'),
        header: translate('T_MODULE_EVENT_SUGGESTED'),
        filterable: true,
        Filter: BooleanFilter,
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(
          `nativeTitle`,
          true,
          !isPPN,
          true,
          true,
          !isPPN,
          schema.clone_endpoint
        ),
        width: '10%',
      },
    ],
    [isPPN]
  )

  let customFilters = isPPN
    ? [
        {
          name: 'iri[hostname]',
          title: 'Park',
          Filter: ResourceFilter,
          endpoint: '/api/hostname_types?pagination=false',
          titleAccessor: 'trans',
          horizontal: true,
        },
      ]
    : []

  let defaultSorters = isPPN ? { 'dates.dateFrom': 'desc' } : { ord: 'desc' }

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_EVENT_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
      >
        {translate('T_MODULE_EVENT')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={defaultSorters}
        parameters={[
          {
            name: 'nativeTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'dates.dateFrom',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[hostname]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'suggested',
            in: 'query',
            type: 'string',
          },
          {
            name: 'visibleInPPN',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[nativeTitle]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[dates.dateFrom]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[hostname]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={customFilters}
        orderable={!isPPN}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
