import { makeStyles } from '@material-ui/styles'

export const useThumbCollectionStyles = makeStyles(theme => ({
  twoColLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    '&> *:first-child': {
      marginRight: 5,
    },
    '&> *:last-child': {
      marginLeft: 5,
    },
  },
  thumbImageContainer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  thumbImageContainerCurrent: {
    backgroundColor: theme.palette.primary.main,
  },
  thumbImageContainerDisabled: {
    backgroundColor: theme.palette.disabled,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.disabled,
    },
  },
  thumbImage: {
    width: '100%',
    height: 150,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginBottom: '.5em',
  },
  thumbImageDisabled: {
    filter: 'grayscale(100%)',
  },
  thumbTitle: {
    marginBottom: '.5em',
    textAlign: 'center',
  },
  emptyResults: {
    margin: 10,
    textAlign: 'center',
  },
}))
