import React, { useState, useEffect, useMemo } from 'react'
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined'
import { TextField, Chip, Grid, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { debounce } from '@material-ui/core/utils'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../_helpers/notification'
import PropTypes from 'prop-types'
import { translate } from '../../../_helpers/translate'

export const AutoComplete = ({
  endpoint = null,
  titleAccessor = null,
  name,
  value,
  setValue,
  label = null,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const fetchResults = useMemo(
    () =>
      debounce((searchTerm, callback) => {
        const controller = new AbortController()
        const { signal } = controller
        fetchDataHandleAuthError(
          `${endpoint}?${titleAccessor}=${searchTerm}&perPage=50`,
          'GET',
          { signal },
          response => {
            const results = response['hydra:member']
            callback(results)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }
            callback([])
            notification('error', error.response.detail, error.response.title)
          }
        )
      }, 400),
    [endpoint, titleAccessor]
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value?.length ? value : [])
      return undefined
    }

    fetchResults(inputValue, results => {
      if (active) {
        setOptions(results)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetchResults])

  useEffect(() => {
    if (value === null) {
      setValue(name, [])
    }
  }, [name, setValue, value])

  return (
    <Autocomplete
      multiple
      id="autocomplete"
      getOptionLabel={option =>
        typeof option === 'string' ? option : option?.[titleAccessor]
      }
      //getOptionSelected={(option, value) => value.value === option.value}
      getOptionSelected={(option, value) => option.uuid === value.uuid}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value ? value : []}
      noOptionsText={translate('No results found')}
      onChange={(event, newValue) => {
        setOptions(newValue)
        setValue(
          name,
          newValue.map(option => option)
        )
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          variant="outlined"
          {...params}
          label={translate(label ? label : 'Search')}
          fullWidth
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={option?.[titleAccessor]}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => (
        <Grid container alignItems="center">
          <Grid item style={{ display: 'flex', width: 44 }}>
            <AccountBalanceOutlined />
          </Grid>
          <Grid
            item
            style={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
          >
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {option[titleAccessor]}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {translate(option['@type'])}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  )
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      })
    ),
  ]),
  endpoint: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  setValue: PropTypes.func.isRequired,
}
