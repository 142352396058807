import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Schemable } from 'components/Schemable'
import { SchemaError } from '_helpers/schemaError'
import { prop } from '_helpers/prop'

export const SchemableComponent = ({
  component,
  path,
  uniqueKey = false,
  ...rest
}) => {
  return (
    <Schemable>
      <SchemaAssigner
        component={component}
        path={path}
        uniqueKey={uniqueKey}
        {...rest}
      />
    </Schemable>
  )
}

const SchemaAssigner = ({ component: Component, path, uniqueKey, ...rest }) => {
  const schema = prop(
    useSelector(state => state.schema),
    path
  )

  if (!schema) {
    throw new SchemaError()
  }

  const { location } = rest

  return (
    <Component
      schema={schema}
      key={uniqueKey && location?.pathname}
      {...rest}
    />
  )
}

SchemableComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  uniqueKey: PropTypes.bool,
}
