import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const anchors = blockAnchorEndpoint => ({
  name: BLOCK_TYPES_IRI.ANCHORS,
  label: 'T_GENERAL_BLOCK_TYPE_ANCHOR',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    anchors: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_ANCHORS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockAnchor',
      endpoint: blockAnchorEndpoint,
      statable: true,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
          },
        },
        anchor: {
          description: 'T_GENERAL_ANCHOR',
          label: 'T_GENERAL_ANCHOR',
          fullWidth: true,
        },
      },
    },
  },
})
