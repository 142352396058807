import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { AppBar, Toolbar } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { UserMenu } from 'components/menu'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { CustomButton } from 'components/CustomButton'
import { translate } from '_helpers/translate'
import { useStyles } from './styles'

export const PreviewBar = ({ breadcrumbs, editPath, loading = false }) => {
  const classes = useStyles()

  const profile = useSelector(state => state.profile)

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {loading ? (
          <Skeleton animation="wave" className={classes.skeleton} />
        ) : (
          <>
            <div className={classes.loggedAs}>
              {`${translate('T_GENERAL_YOU_ARE_LOGGED_AS')} ${
                profile.fullName
              }`}
            </div>
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              className={classes.breadcrumbs}
            />
          </>
        )}
        <CustomButton
          title={translate('T_GENERAL_EDIT')}
          path={editPath}
          className={classes.editButton}
        />
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}

PreviewBar.propTypes = {
  breadcrumbs: PropTypes.shape({
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
      })
    ),
    custom: PropTypes.bool,
  }).isRequired,
  editPath: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}
