import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Chip, Avatar } from '@material-ui/core'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  chip: {
    color: '#eee',
  },
  avatar: {
    backgroundColor: '#eee',
  },
  icon: {
    color: '#333',
    fontSize: '1.3em',
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  failure: {
    backgroundColor: theme.palette.error.main,
  },
}))

export const ResultCell = ({ resource, accessor }) => {
  const classes = useStyles()
  const result = prop(resource, accessor)

  return (
    <Chip
      label={translate(
        result ? 'T_GENERAL_RESULT_SUCCESS' : 'T_GENERAL_RESULT_FAILURE'
      )}
      className={clsx(classes.chip, result ? classes.success : classes.failure)}
      avatar={
        <Avatar className={classes.avatar}>
          {result ? (
            <CheckOutlined className={classes.icon} />
          ) : (
            <CloseOutlined className={classes.icon} />
          )}
        </Avatar>
      }
    />
  )
}

ResultCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.string,
}
