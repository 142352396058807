import React from 'react'
import { Form } from 'components/form'
import schema from '_schema/profile'

export const ChangePasswordForm = () => (
  <Form
    title="T_FORM_CHANGE_PASSWORD_TITLE"
    url={schema.changePassword.set}
    method="PUT"
    properties={schema.changePassword.properties}
    showSubmitAndStayButton={false}
    showCancelButton={false}
  />
)
