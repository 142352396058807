export const isParkVisible = (item, hostnameConfig) => {
  if (typeof item?.parkVisible === 'boolean') {
    if (
      item.parkVisible === true &&
      hostnameConfig.hostname.name === 'PPN_GATE'
    ) {
      return false
    }

    if (
      item.parkVisible === false &&
      hostnameConfig.hostname.name !== 'PPN_GATE'
    ) {
      return false
    }
  }

  if (item?.allowedParks && typeof item.allowedParks === 'object') {
    if (
      item.allowedParks.length &&
      !item.allowedParks.includes(hostnameConfig.hostname.name)
    ) {
      return false
    }
  }

  return true
}
/*
export const isParkVisible = (item, isPPN = null) => {
  if (typeof item?.parkVisible === 'boolean' && typeof isPPN === 'boolean') {
    if (item.parkVisible === true && isPPN === true) {
      return false
    }

    if (item.parkVisible === false && isPPN === false) {
      return false
    }
  }
  return true
}
 */
