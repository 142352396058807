import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Tooltip } from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { prop } from '_helpers/prop'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  input: {
    '& > *': {
      fontSize: 'inherit',
    },
  },
  helperText: {
    fontSize: '.75em',
    color: theme.palette.primary.main,
  },
  submit: {
    marginLeft: 12,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&[disabled]': {
      cursor: 'default',
      color: theme.palette.disabled,
    },
  },
}))

export const InputCell = ({
  resource,
  accessor,
  propertySchema,
  onSuccess,
}) => {
  const [state, setState] = useState({
    value: prop(resource, accessor),
    changed: false,
    disabled: false,
    error: false,
  })

  const handleChange = e => {
    const value = e.target.value
    const isValid = validate(propertySchema.validate, value)

    setState(state => ({
      ...state,
      value,
      changed: true,
      error: !isValid.result && translate(isValid.message),
    }))
  }

  const handleSubmit = () => {
    if (state.error || state.disabled) {
      return
    }

    setState(state => ({ ...state, disabled: true }))

    fetchDataHandleAuthError(
      resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: state.value,
        }),
      },
      () => {
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
        onSuccess(state.value)
      },
      error => {
        setState(state => ({
          ...state,
          disabled: false,
          error:
            error.response.violations.length &&
            translate(error.response.violations[0].message),
        }))
        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      }
    )
  }

  const TooltipComponent = state.error || state.disabled ? 'span' : Tooltip

  const classes = useStyles()

  return (
    <>
      <TextField
        className={classes.input}
        value={state.value}
        disabled={state.disabled}
        onChange={handleChange}
        FormHelperTextProps={{ className: classes.helperText }}
        helperText={
          state.error ||
          (state.changed && translate('T_GENERAL_SAVE_TO_KEEP_CHANGES'))
        }
        error={!!state.error}
      />
      <TooltipComponent title={translate('T_GENERAL_SAVE')}>
        <SaveOutlined
          className={classes.submit}
          disabled={state.error || state.disabled}
          onClick={handleSubmit}
        />
      </TooltipComponent>
    </>
  )
}

InputCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string.isRequired,
  propertySchema: PropTypes.shape({
    validate: PropTypes.arrayOf(PropTypes.string),
  }),
  onSuccess: PropTypes.func.isRequired,
}
