import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'
import { getApiUrl } from '../../../../_services/apiUrlProvider'
import { authHeader } from '../../../../_helpers/authHeader'

const generateXls = (action, accessor, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let uuids = []

    const items = tableState.data.selected.map((isSelected, index) => {
      if (isSelected) {
        uuids.push(tableState.data.items[index]['uuid'])
      }

      return tableState.data.items[index]
    })

    let xTitle = null
    let responseError = null

    fetch(`${getApiUrl()}/api/shop_elektroniczny_nadawca_subscription_xls`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ orders: uuids }),
    })
      .then(response => {
        if (response.status !== 200) {
          responseError = response.json()
          throw new Error('Error')
        }
        xTitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xTitle ?? 'elektroniczny_nadawca.xls')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)

        tableStateDispatch({
          type: constants.MASS_SELECT_ACTION_SUCCESS,
          payload: { action, items },
        })

        notification(
          'success',
          'T_ELEKTRONICZNY_NADAWCA_GENERATE_XLS_SUCCESS',
          'T_FORM_SUCCESS'
        )
      })
      .catch(e => {
        tableStateDispatch({
          type: constants.MASS_SELECT_ACTION_SUCCESS,
          payload: { action, items },
        })
        notification(
          'error',
          'Nie można znaleźć zamówień dla wskazanych rekordów'
        )

        responseError.then(data => {
          notification('error', data.error)
        })
      })
  },
})

export const elektronicznyNadawcaSubscriptionXls = {
  generateXls: (
    action = 'generateXls',
    accessor = 'xls',
    title = 'T_ELEKTRONICZNY_NADAWCA_GENERATE_XLS'
  ) => generateXls(action, accessor, title),
}
