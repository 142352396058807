import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/news'
import routes from './routes'
import { status } from '../../components/table/_columns/status'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { useSelector } from 'react-redux'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'
import { MainCell } from './table/cells/MainCell'
import { isObjectEmpty } from '../../_helpers/isObjectEmpty'
import globalRoutes from '../routes'
import { Route } from 'react-router-dom'
import { NotFound } from '../NotFound'
import { hasResourceAccess } from '../../_helpers/hasResourceAccess'

export const Collection = () => {
  const apiConfig = useSelector(state => state.apiConfig)
  const isPPN = apiConfig.hostname.name === 'PPN_GATE'
  const profile = useSelector(state => state.profile)

  const accessableRoutes = useMemo(
    () =>
      !isObjectEmpty(profile)
        ? [
            ...globalRoutes(),
            {
              type: Route,
              render: props => <NotFound {...props} />,
              path: '/*',
            },
          ].filter(route => hasResourceAccess(route.resourceAccess, profile))
        : [],
    [profile]
  )

  const columns = useMemo(
    () => [
      {
        columnId: 'nativeTitle',
        header: translate('T_GENERAL_TITLE'),
        accessor: `nativeTitle`,
        filterable: true,
        sortable: true,
        width: '70%',
        Cell: MainCell,
        routes: accessableRoutes,
      },
      {
        columnId: 'dateFrom',
        header: translate('T_GENERAL_DATE_FROM'),
        accessor: `dateFromHuman`,
        filterName: 'dateFrom',
        sorterName: 'dateFrom',
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        filtersList: ['iri[hostname]'],
        columnId: 'hostname',
        header: translate('T_GENERAL_PARK'),
        accessor: `hostnameTrans`,
        filterable: true,
        sortable: true,
        sorterName: 'hostname',
        width: '10%',
        excluded: !isPPN,
        translateAccessor: true,
      },
      {
        columnId: 'visibleInPPN',
        ...status('visibleInPPN'),
        width: '5%',
        customEndpoint: '/api/news/visibility/@id',
        excluded: !isPPN,
        Filter: ChoiceFilter,
        choices: {
          true: 'Widoczne',
          false: 'Niewidoczne',
        },
      },
      {
        columnId: 'operations',
        ...operations(
          `nativeTitle`,
          true,
          !isPPN,
          true,
          true,
          !isPPN,
          schema.clone_endpoint
        ),
        width: '10%',
      },
    ],
    [isPPN]
  )

  let parameters = []
  let defaultSorters = isPPN ? { dateFrom: 'desc' } : { ord: 'desc' }

  if (!isPPN) {
    parameters = [
      {
        name: 'nativeTitle',
        in: 'query',
        type: 'string',
      },
      {
        name: 'dateFrom',
        in: 'query',
        type: 'string',
      },
    ]
  } else {
    parameters = [
      {
        name: 'nativeTitle',
        in: 'query',
        type: 'string',
      },
      {
        name: 'dateFrom',
        in: 'query',
        type: 'string',
      },
      {
        name: 'iri[hostname]',
        in: 'query',
        type: 'string',
      },
      {
        name: 'hostname',
        in: 'query',
        type: 'string',
      },
      {
        name: 'visibleInPPN',
        in: 'query',
        type: 'string',
      },
    ]
  }

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_NEWS_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
      >
        {translate('T_MODULE_NEWS')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={defaultSorters}
        parameters={parameters}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[nativeTitle]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[dateFrom]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[hostname]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'iri[hostname]',
            title: 'Park',
            Filter: ResourceFilter,
            endpoint: '/api/hostname_types?pagination=false',
            titleAccessor: 'trans',
            horizontal: true,
          },
        ]}
        orderable={!isPPN}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
