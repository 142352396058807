import React from 'react'
import { Route } from 'react-router-dom'
import { InfoOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/tourismNotice'
import { ArticleVersionCollection } from './Version/ArticleVersionCollection'
import { ArticleVersionEdit } from './Version/ArticleVersionEdit'

const basePath = '/tourism_notice_article'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: basePath,
        exact: true,
        icon: InfoOutlined,
      },
      versions: {
        subtitle: 'T_MODULE_BIP_PAGE_ARTICLE_VERSION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleVersionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/versions`,
        exact: true,
      },
      versionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleVersionEdit}
            {...props}
          />
        ),
        path: `${basePath}/version/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_TOURISM_NOTICE',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
