import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const contestAuto = () => ({
  name: BLOCK_TYPES_IRI.CONTEST,
  label: 'Blok "Poznaj inne konkursy"',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint: 'domyślnie trans "T_DISCOVER_CONTEST"'
        },
      },
    },
  },
})
