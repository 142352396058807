const schema = {
  endpoint: '/api/tourney_ranking',
  access: 'tourneys',
  resource: {
    definition: '#TourneyPackageStats',
    properties: {

    },
  },
}

export default schema
