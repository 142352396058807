const schema = {
  endpoint: '/api/sea_eye_fiacres',
  access: 'seaEyeFiacre',
  resource: {
    definition: '#SeaEyeFiacre',
    properties: {
      name: {
        description: 'T_GENERAL_GUIDE_NAME',
        validate: ['required'],
      },
      surname: {
        validate: ['required'],
      },
      email: {},
      phone: {
        validate: ['required'],
      },
      address: {
        validate: ['required'],
      },
      licenceNumber: {
        validate: ['required'],
      },
      registeredAt: {
        type: 'date',
        validate: ['required'],
      },
      withdrawnAt: {
        type: 'date',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
