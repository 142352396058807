export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema,
  currentHostnameIri
) => {
  let modifiedProperties = properties

  if (isOpenform) {
  modifiedProperties = {
    ...properties,
    location: {
      type: 'string',
      validate: ['required'],
      description: {
        text: '[OF] Nazwa punktu pogodowego (ID name)',
        color: '#cc0000',
      },
    },
    locationId: {
      type: 'number',
      validate: ['required'],
      description: {
        text: '[OF] ID punktu pogodowego',
        color: '#cc0000',
      },
    },
  }
  }

  // if (currentHostnameIri !== '/api/hostname_types/TATRZANSKI') {
  //   delete modifiedProperties.translations
  // }

  return modifiedProperties
}
