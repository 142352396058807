import React from 'react'
import { Form } from 'pages'
import schema from '_schema/matter'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const hostname = useSelector(state => state.apiConfig)?.hostname

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width="100%"
      modifyProperties={(properties, resource, schema) => {
        if (hostname.name !== 'TATRZANSKI') {
          delete properties.type
          delete properties.translations.properties?.consent1
          delete properties.translations.properties?.consent2
          delete properties.translations.properties?.consent3
        }

        return properties
      }}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
