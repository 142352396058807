import { authActions } from '_actions'
import { authConstants } from '_constants'
import { tokenVerify } from './tokenVerify'

export const isLogged = dispatch => {
  const token = localStorage.getItem('token')

  if (tokenVerify(token)) {
    dispatch(authActions.restore(token))
  } else {
    dispatch({ type: authConstants.LOGIN_RESTORE_FAILURE })
  }
}
