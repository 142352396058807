import React from 'react'
import { Route } from 'react-router-dom'
import { Group } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/volunteering'
import { SubmissionCollection } from './Submission/SubmissionCollection'
import { SubmissionNew } from './Submission/SubmissionNew'
import { SubmissionEdit } from './Submission/SubmissionEdit'
import { ContractCollection } from './Contract/ContractCollection'
import { ContractNew } from './Contract/ContractNew'
import { ContractEdit } from './Contract/ContractEdit'

const basePath = '/volunteering_users'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: Group,
        parkVisible: true,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      submissions: {
        subtitle: 'T_MODULE_VOLUNTEERING_SUBMISSION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubmissionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/submissions`,
        exact: true,
      },
      submissionNew: {
        subtitle: 'T_MODULE_VOLUNTEERING_SUBMISSION_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubmissionNew}
            {...props}
          />
        ),
        path: `${basePath}/submissions/new`,
        exact: true,
      },
      submissionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubmissionEdit}
            {...props}
          />
        ),
        path: `${basePath}/submissions/:id`,
        exact: true,
      },
      contracts: {
        subtitle: 'T_MODULE_VOLUNTEER_USER_CONTRACT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ContractCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/contract`,
        exact: true,
      },
      contractNew: {
        subtitle: 'T_MODULE_VOLUNTEER_USER_CONTRACT_NEW_BUTTON',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ContractNew}
            {...props}
          />
        ),
        path: `${basePath}/contracts/new`,
        exact: true,
      },
      contractEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ContractEdit}
            {...props}
          />
        ),
        path: `${basePath}/contracts/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_VOLUNTEERING_USER_LIST',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
