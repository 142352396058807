import React from 'react'
import { makeStyles } from '@material-ui/styles'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    // marginLeft: '1.0em',
  },
})

export const OpenIsCell = ({
  resource,
}) => {

  const classes = useStyles()

  return (
    <div className={classes.image}>

      <div className={classes.title}>
        { resource.open && resource.score === 0 ? <LiveHelpIcon color="secondary"/> : '' }
      </div>
    </div>
  )
}


