import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import routes from 'pages/AccommodationUser/routes'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import IconButton from '@material-ui/core/IconButton'
import FaceIcon from '@material-ui/icons/Face'
import HotelIcon from '@material-ui/icons/Hotel'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 20,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 10,
    verticalAlign: 'middle',
  },
}))

export const UserLinkType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const classes = useStyles()
  const linkTto = routes().accommodationIndex.path.replace(
    ':pid',
    value ?? 'null'
  )
  const linkTtoProfile = routes().edit.path.replace(':id', value ?? 'null')

  return (
    <>
      {value ? (
        <Typography className={classes.root}>
          <div className={classes.label}>{translate(label?.text || label)}</div>
          <IconButton color="primary" component={Link} to={linkTtoProfile}>
            <Tooltip title={translate('Profil')}>
              <FaceIcon />
            </Tooltip>
          </IconButton>

          <IconButton color="primary" component={Link} to={linkTto}>
            <Tooltip title={translate('Kwatery/Wizytówki')}>
              <HotelIcon />
            </Tooltip>
          </IconButton>
        </Typography>
      ) : null}
    </>
  )
}

UserLinkType.propTypes = {
  name: PropTypes.string.isRequired,
}
