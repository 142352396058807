import React from 'react'
import { Form } from 'pages'
import schema from '_schema/matter'
import routes from './routes'
import { useSelector } from 'react-redux'

export const New = ({ schema: definitions }) => {
  const hostname = useSelector(state => state.apiConfig)?.hostname

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={600}
      modifyProperties={(properties) => {
        if (hostname?.name !== 'TATRZANSKI') {
          delete properties.type
          delete properties.consent1
          delete properties.consent2
          delete properties.consent3
        }

        return properties
      }}
    />
  )
}
