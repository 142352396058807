const schema = {
  endpoint: '/api/tourism_notices',
  access: 'tourismNotice',
  resource: {
    definition: '#TourismNotice',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          homepageTitle: {
            description: 'T_GENERAL_HOMEPAGE_TITLE',
          },
          description: {
            type: 'tinymce',
            description: 'T_GENERAL_DESCRIPTION_NORMAL',
          },
        },
      },
      type: {
        type: 'resource',
        endpoint: '/api/tourism_notice_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_GENERAL_BLOCK_TYPE_TOURISM_INFORMATION_NOTICES_TYPE',
        validate: ['required'],
      },
      homepage: {
        description: 'T_GENERAL_SHOW_ON_HOMEPAGE',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
