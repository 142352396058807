import {
  text,
  // textWithPhoto,
  // textWithVideo,
  //textWithLink,
  // gallery,
  // files,
  //faq,
  //logotypes,
  anchors,
  alert,
  // banner1,
  // banner2,
  // links,
  list,
  // newsletter,
  // product,
  // trails,
  // column,
  // crew,
  // miginfo,
  // formsetBlock,
  tourismInformationNotices,
  trailConditions, textWithPhoto,
  // embed,
  // stops
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint,
  blockPricelistItemEndpoint,
  blockHeroBannerEndpoint,
  blockHeroBannerThumbEndpoint,
  definitionRefblockListItemEndpoint,
  definitionRefblockLinkEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    // textWithVideo(photoEndpoint, blockThumbEndpoint),
    //textWithLink(),
    // gallery(),
    // files(),
    //faq(),
    //logotypes(),
    anchors(blockAnchorEndpoint),
    alert(),
    // banner1(photoEndpoint, blockThumbEndpoint),
    // banner2(photoEndpoint, blockThumbEndpoint),
    // links(photoEndpoint, blockLinkEndpoint, blockLinkThumbEndpoint),
    list(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      definitionRefblockListItemEndpoint
    ),
    // column(blockListItemEndpoint),
    // newsletter(blockListItemEndpoint),
    // product(photoEndpoint, blockThumbEndpoint),
    // trails(photoEndpoint, blockThumbEndpoint, blockFileEndpoint),
    // crew(),
    // formsetBlock(),
    // miginfo(),
    tourismInformationNotices(),
    trailConditions(),
    // stops(blockLinkEndpoint, definitionRefblockLinkEndpoint),
    // embed(),
  ],
})

export default schema
