export const addOpenformProperties = (
  isOpenform,
  properties,
  resource,
  schema,
  currentHostnameIri
) => {
  let modifiedProperties = properties

  // if (isOpenform) {
  // modifiedProperties = {
  //   ...properties,
  //   embed: {
  //     type: 'string',
  //     description: {
  //       text: '[OF] Link do iframe',
  //       color: '#cc0000',
  //     },
  //   },
  // }
  // }

  if (currentHostnameIri !== '/api/hostname_types/TATRZANSKI') {
    delete modifiedProperties.translations
  }

  return modifiedProperties
}
