import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/matter'
import routes from './routes'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const apiConfig = useSelector(state => state.apiConfig)
  const isPPN = apiConfig.hostname.name === 'PPN_GATE'

  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `nativeTitle`,
        filterable: true,
        sortable: true,
        width: '80%',
      },
      // {
      //   header: translate('T_GENERAL_DATE_FROM'),
      //   accessor: `dateFromHuman`,
      //   filterable: true,
      //   sortable: true,
      //   width: '10%',
      // },
      {
        ...operations(
          `nativeTitle`,
          true,
          true,
          true,
          true,
          true,
          schema.clone_endpoint
        ),
        width: '10%',
      },
    ],
    []
  )

  return (
    !isPPN && (
      <Paper>
        <CollectionHeader
          buttonTitle={translate('Dodaj sprawę')}
          buttonPath={routes().new.path}
        >
          {translate('Lista spraw')}
        </CollectionHeader>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={schema.endpoint}
          definition={schema.resource.definition}
          columns={columns}
          customResourceSchema={schema.resource}
          defaultSorters={{ ord: 'desc' }}
          parameters={[
            {
              name: 'nativeTitle',
              in: 'query',
              type: 'string',
            },
          ]}
          schemaParameters={[
            {
              name: 'order[ord]',
              in: 'query',
              type: 'string',
            },
            {
              name: 'order[nativeTitle]',
              in: 'query',
              type: 'string',
            },
          ]}
          orderable={!isPPN}
          selectable={[publish.on(), publish.off()]}
          isRowLinkable={true}
          editPath={routes().edit.path}
          autoWidth={false}
        />
      </Paper>
    )
  )
}
